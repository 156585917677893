import React, { useState, useEffect, useContext } from 'react';
import { store, ACTIONS } from 'store';
import moment from 'moment';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstanceAdmin } from 'helpers/authenticationAdmin';
import { useHistory, useParams } from 'react-router-dom';
import { Heading, Button } from 'shared';
import {
  Summary,
} from 'components';
import {
  HeadingContainer,
  SubTitle,
  Icon,
  InvoiceContainer,
  EventDetailContainer,
  EventDetailContent,
  Info,
  InfoTitle,
  InfoFieldName,
  InfoValue,
  RightSection,
  Detail,
  DetailHeader,
  Body,
  Grid,
  EventCardLabel,
  EventCardText,
  EventCard,
  HeaderCardCancel,
  EventCardContent,
  EventCardBadge,
  ButtonContainer,
  DownloadFileContainer,
  AddInvoiceContainer,
  AddInvoiceText,
  DetailAction,
  ActionButton,
  IconAction,
} from 'pages/admin/dashboard-admin/event-details/style';
import {
  EventApproveModal,
  ChangePICModal,
  EventCancelModal,
  ChangeTypeFormIDModal,
} from 'pages/admin/dashboard-admin/event-details/modal';
import {
  AddInvoiceSideMenu,
  AddInvoiceSideMenu2,
  EditEventSideMenu,
} from 'pages/admin/dashboard-admin/event-details/sidemenu';
import LeftArrowSVG from 'assets/icons/left-arrow.svg';
import AddSVG from 'assets/icons/add.svg';
import AddInvoiceSVG from 'assets/icons/add-invoice.svg';
import PencilSVG from 'assets/icons/pencil-event-detail.svg';
import DeleteSVG from 'assets/icons/delete-event-detail.svg';
import { exportExcel } from 'helpers/helpers';

const EventHistoryDetail = ({ setIsLoading }) => {
  const { dispatch } = useContext(store);
  const history = useHistory();
  const [eventDetail, setEventDetail] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [operatorList, setOperatorList] = useState({});
  const [operatorSelected, setOperatorSelected] = useState('');
  const [typeformID, setTypeformID] = useState('')
  const [nextInvoiceCode, setNextInvoiceCode] = useState('');

  const [showModalEventApproval, setShowModalEventApproval] = useState(false);
  const [showModalEventCancel, setShowModalEventCancel] = useState(false);
  const [showModalEditPIC, setShowModalEditPIC] = useState(false);
  const [showModalEditTypeformID, setShowModalEditTypeformID] = useState(false);
  const [showSideAddInvoice, setShowSideAddInvoice] = useState(false);
  const [showSideAddInvoice2, setShowSideAddInvoice2] = useState(false);
  const [showSideEventEdit, setShowSideEventEdit] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [onDownload, setOndownload] = useState(false);
  const { id } = useParams();
  let summaryData = []

  const getOperatorList = async () => {
    axiosApiInstanceAdmin.get(ENDPOINTS('admin.operator.operator'))
      .then(({ data: { value: { admins } } }) => {
        let comboboxList = admins.reduce((acc, curr) => {
          acc.push({ value: curr._id, label: curr.fullname })
          return acc;
        }, [])
        setOperatorList(comboboxList);
      })
      .catch(() => {
        // error message
      });
  };

  const downloadReport = async () => {
    setOndownload(true)
    axiosApiInstanceAdmin.get(`${ENDPOINTS('admin.event.event')}/${id}/report`)
      .then(({ data: { value: {
        invitationsReport,
        tableReportGrouping,
        tableReportNotGrouping,
        unAssignTableGuest,
        comingPax,
        notComingPax,
        notYetConfirmedPax,
        comingInv,
        notComingInv,
        notYetConfirmedInv
      }
      } }) => {
        setOndownload(false)
        // tableReportGrouping
        // tableReportNotGrouping
        // unAssignTableGuest

        // sheetName data
        let parsedDataInvitation = parsedInvitation(eventDetail.name, invitationsReport.data, comingPax, notComingPax, notYetConfirmedPax, comingInv, notComingInv, notYetConfirmedInv);
        let fileNameInvitation = invitationsReport.sheetName;
        let parsedDataTableGrouping = parsedTableGrouping(tableReportGrouping.data);
        let fileNameTableGrouping = tableReportGrouping.sheetName;
        let parsedDataTableNotGrouping = parsedTableNotGrouping(tableReportNotGrouping.data);
        let fileNameTableNotGrouping = tableReportNotGrouping.sheetName;
        let parsedDataUnAssignTableGuest = parsedUnAssignTable(unAssignTableGuest.data);
        let fileNameUnAssignTableGuest = unAssignTableGuest.sheetName;
        var parsedData = [
          {
            data: parsedDataInvitation,
            sheetName: fileNameInvitation,
          },
          {
            data: parsedDataTableGrouping,
            sheetName: fileNameTableGrouping,
          },
          {
            data: parsedDataTableNotGrouping,
            sheetName: fileNameTableNotGrouping,
          },
          {
            data: parsedDataUnAssignTableGuest,
            sheetName: fileNameUnAssignTableGuest,
          }
        ];
        exportExcel(parsedData, 'Event Detail', true);

      })
      .catch(() => {
        setOndownload(false)
        // error message
      });
  };

  const parsedInvitation = (eventName, data, comingPax, notComingPax, notYetConfirmedPax, comingInv, notComingInv, notYetConfirmedInv) => {

    let parsed = [
      [`Hasil Laporan Konfirmasi Tamu Undangan (${eventName})`],
      [`Undangan Konfirmasi Akan Hadir : ${comingInv} | Undangan Konfirmasi Tidak Hadir : ${notComingInv} | Undangan Belum Konfirmasi : ${notYetConfirmedInv}`],
      [`Tamu Konfirmasi Akan Hadir : ${comingPax} | Tamu Konfirmasi Tidak Hadir : ${notComingPax} | Tamu Belum Konfirmasi : ${notYetConfirmedPax}`],
      [
        'No',
        'Nama undangan',
        'Nama guest',
        'Kategori',
        'Jumlah pax',
        'No telepon',
        'Konfirmasi kehadiran',
        'Address',
        'Notes'
      ],
    ];
    data.map((item, index) => {
      const arr = [
        index + 1,
        item['Invitation Name'],
        item['Guest Name'],
        item['Kategori'],
        item['Total Pax'],
        item['Phone Number'],
        item['Confirmation'],
        item['Address'],
        item['Notes']
      ]
      parsed.push(arr)
    })
    return parsed;
  }

  const parsedTableGrouping = (data) => {
    let parsed = [];
    data.map((item) => {
      parsed.push([`Table ${item.table}`]);
      parsed.push([
        'Nama guest',
        'Nama invitation',
        'No telepon',
        'Notes',
      ])
      item.data.map((item2) => {
        const arr = [
          item2['Guest'],
          item2['Invitation Name'],
          item2['Phone Number'],
          item2['Notes'],
        ]
        parsed.push(arr);
      })
      parsed.push([])
    })
    return parsed;
  }

  const parsedTableNotGrouping = (data) => {
    let parsed = [
      [
        'Table Number',
        'Nama Guest',
        'Nama Invitation',
        'Capacity',
        'No Telepon',
        'Notes',
      ],
    ];


    var tableNumber = 0;
    data.map((item) => {
      // looping inside guest name start here

      if (tableNumber == 0) {
        tableNumber = item['Table Number']
      } else if (tableNumber !== item['Table Number']) {
        parsed.push([]);
        tableNumber = item['Table Number']
      }

      const arr = [
        item['Table Number'],
        item['Guest'],
        item['Invitation Name'],
        item['Capacity'],
        item['Phone Number'],
        item['Notes'],
      ]
      parsed.push(arr);
      // end here
    })
    return parsed;
  }

  const parsedUnAssignTable = (data) => {

    let parsed = [
      [
        'Nama undangan',
        'Nama guest',
        'No telepon',
        'Notes',
      ],
    ];
    data.map((item) => {
      const arr = [
        item['Invitation Name'],
        item['Guest Name'],
        item['Phone Number'],
        item['Notes'],
      ]
      parsed.push(arr)
    })
    return parsed;
  }

  const getEventDetail = () => {
    setIsSubmiting(true);
    axiosApiInstanceAdmin.get(`${ENDPOINTS('admin.event.event')}/${id}`)
      .then(({ data: { value: { event, invoices, nextInvoiceCode } } }) => {
        setIsSubmiting(false);
        setEventDetail(event[0]);
        setInvoiceList(invoices);
        setNextInvoiceCode(nextInvoiceCode);
        setIsLoading(false);
      });
  }

  const approveEvent = () => {
    setIsSubmiting(true);
    let data = {
      active_status: true,
      adminId: operatorSelected,
    }
    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.event.event')}/${id}/approval`, data)
      .then(({ data: { m } }) => {
        setIsSubmiting(false);
        if (m === "Done") {
          setShowModalEventApproval(false);
          getEventDetail();
        }
      })
      .catch(() => setIsSubmiting(false));
    ;
  }

  const editPIC = () => {
    setIsSubmiting(true);
    let data = {
      adminId: operatorSelected,
    }
    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.event.event')}/${id}/assignee`, data)
      .then(({ data: { m } }) => {
        setIsSubmiting(false);
        if (m === "Done") {
          setShowModalEditPIC(false);
          getEventDetail();
        }
      }).catch(() => setIsSubmiting(false));
  }

  const editTypeform = () => {
    setIsSubmiting(true);
    let data = {
      typeform_id: typeformID,
    }
    if (!!typeformID) {
      axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.event.event')}/${id}/typeform-code`, data)
        .then(({ data: { m } }) => {
          setIsSubmiting(false);
          if (m === "Done" || m === "Done deleting data") {
            setShowModalEditTypeformID(false);
            getEventDetail();
          }
        }).catch(() => setIsSubmiting(false));
    } else {
      axiosApiInstanceAdmin.delete(`${ENDPOINTS('admin.event.event')}/${id}/typeform-code`)
        .then(({ data: { m } }) => {
          setIsSubmiting(false);
          if (m === "Done" || m === "Done deleting data") {
            setShowModalEditTypeformID(false);
            getEventDetail();
          }
        }).catch(() => setIsSubmiting(false));
    }
  }

  const cancelEvent = () => {
    setIsSubmiting(true);
    let data = {
      cancel_reason: 'cancel',
    }
    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.event.event')}/${id}/cancel`, data)
      .then(({ data: { m } }) => {
        setIsSubmiting(false);
        if (m === "Done") {
          setShowModalEventCancel(false);
          getEventDetail();
        }
      }).catch(() => setIsSubmiting(false));
  }

  const editEvent = (form) => {
    setIsSubmiting(true);
    let data = form;

    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.event.event')}/${id}`, data)
      .then(({ data: { m } }) => {
        setIsSubmiting(false);
        if (m === "Done") {
          setShowSideEventEdit(false);
          getEventDetail();
        }
      }).catch(() => setIsSubmiting(false));
  }

  const addInvoice = (form) => {
    setIsSubmiting(true);
    let data = form

    data.amount = Number(data.amount.replace(/[,.-]/g, ''));
    axiosApiInstanceAdmin.post(`${ENDPOINTS('admin.event.event')}/${id}/invoice`, data)
      .then(({ data: { value } }) => {
        setIsSubmiting(false);
        if (Object.keys(value).length) {
          setShowSideAddInvoice(false);
          getEventDetail();
        }
      }).catch(() => setIsSubmiting(false));
  }

  const addInvoice2 = (form) => {
    setIsSubmiting(true);
    let data = JSON.parse(JSON.stringify(form))
    var add_ons = []
    form.add_ons.length && form.add_ons.map((item) => add_ons.push({ name: item }));
    data.add_ons = add_ons;
    data.amount = Number(String(data.amount).replace(/[,.-]/g, ''));
    axiosApiInstanceAdmin.post(`${ENDPOINTS('admin.event.event')}/${id}/invoice`, data)
      .then(({ data: { value } }) => {
        setIsSubmiting(false);
        if (Object.keys(value).length) {
          setShowSideAddInvoice(false);
          getEventDetail();
        }
      }).catch(() => setIsSubmiting(false));
  }

  useEffect(() => {
    setIsLoading(true)
    getOperatorList();
    getEventDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (eventDetail.active_status === false) {
      setShowModalEventApproval(true);
    }

    setTypeformID(eventDetail.typeform_id);
    // eslint-disable-next-line
  }, [eventDetail])

  useEffect(() => {
    //handle side menu and modal close
    if (showModalEventApproval === false || showModalEditPIC === false) {
      setOperatorSelected('')
    }
  }, [showModalEventApproval, showModalEditPIC])
  if (eventDetail) {
    const {

      address,
      bride_name,
      category,
      event_date_string,
      event_venue,
      groom_name,
      name,
    } = eventDetail;
    if (category === "Wedding") {
      summaryData = [
        {
          fieldName: 'Groom\'s Full Name',
          value: groom_name,
        },
        {
          fieldName: 'Bride\'s Full Name',
          value: bride_name,
        },
        {
          fieldName: 'Event Date',
          value: moment.utc(event_date_string).format('DD-MM-YYYY'),
        },
        {
          fieldName: 'Event Time',
          value: moment.utc(event_date_string).format('hh:mm A'),
        },
        {
          fieldName: 'Event Summary',
          value: event_venue,
        },
        {
          fieldName: 'Venue Address',
          value: address,
        },
      ];
    } else {
      summaryData = [
        {
          fieldName: 'Event Name',
          value: name,
        },
        {
          fieldName: 'Event Date',
          value: moment.utc(event_date_string).format('DD-MM-YYYY'),
        },
        {
          fieldName: 'Event Time',
          value: moment.utc(event_date_string).format('hh:mm A'),
        },
        {
          fieldName: 'Event Summary',
          value: event_venue,
        },
        {
          fieldName: 'Venue Address',
          value: address,
        },
      ];
    }
  }

  const {
    category,
    maximum_capacity,
    name: eventName,
    active_status,
    type,
    admin: pic,
    typeform_id
  } = eventDetail;

  const redirectToUserDashboard = () => {
    axiosApiInstanceAdmin.get(`${ENDPOINTS('admin.event.event')}/${id}/dashboard`)
      .then(({ data: { value } }) => {
        dispatch({ type: ACTIONS.addUser, payload: value.user });
        localStorage.setItem('access_token', value.userToken);
        history.push('/event/dashboard', { eventID: id })
      }).catch((err) =>
        console.log(err)
      );
  }

  return (
    <>
      <EventApproveModal
        isOpen={showModalEventApproval}
        onRequestClose={() => setShowModalEventApproval(false)}
        operatorList={operatorList}
        setOperatorSelected={setOperatorSelected}
        approveEvent={approveEvent}
        isSubmiting={isSubmiting}
      />

      <ChangePICModal
        isOpen={showModalEditPIC}
        onRequestClose={() => setShowModalEditPIC(false)}
        operatorList={operatorList}
        setOperatorSelected={setOperatorSelected}
        editPIC={editPIC}
        isSubmiting={isSubmiting}
      />
      <ChangeTypeFormIDModal
        isOpen={showModalEditTypeformID}
        onRequestClose={() => setShowModalEditTypeformID(false)}
        setTypeformID={setTypeformID}
        typeformID={typeformID}
        editTypeform={editTypeform}
        isSubmiting={isSubmiting}
      />
      <EventCancelModal
        isOpen={showModalEventCancel}
        onRequestClose={() => setShowModalEventCancel(false)}
        cancelEvent={cancelEvent}
        isSubmiting={isSubmiting}
      />

      <AddInvoiceSideMenu
        isOpen={showSideAddInvoice}
        onRequestClose={() => { setShowSideAddInvoice(false) }}
        addInvoice={addInvoice}
        nextInvoiceCode={nextInvoiceCode}
        eventDetail={eventDetail}
        isSubmiting={isSubmiting}
      />
      <AddInvoiceSideMenu2
        isOpen={showSideAddInvoice2}
        onRequestClose={() => { setShowSideAddInvoice2(false) }}
        addInvoice={addInvoice2}
        nextInvoiceCode={nextInvoiceCode}
        isSubmiting={isSubmiting}
      />
      <EditEventSideMenu
        isOpen={showSideEventEdit}
        onRequestClose={() => setShowSideEventEdit(false)}
        eventDetail={eventDetail}
        editEvent={editEvent}
        isSubmiting={isSubmiting}
      />
      <Body>
        <HeadingContainer>
          <div style={{ position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '12px' }}>



            <Button
              small
              kind="secondary"
              type="submit"
              text="Compile Data"
              onClick={onDownload ? () => { } : downloadReport}
            />
            <Button
              small
              kind="secondary"
              type="button"
              text="Event Dashboard"
              onClick={redirectToUserDashboard}
            />
          </div>
          <Icon src={LeftArrowSVG} />
          <Heading
            kind="title"
            text="Event & Invoice Details"
            onClick={() => history.push('/admin/dashboard/event')}
          />

        </HeadingContainer>

        <Grid gridTemplateColumn='1fr 1fr 1fr'>
          {active_status === 0 ?
            <EventCard onClick={() => { setShowModalEventApproval(true) }}>
              <EventCardLabel>
                Approval status
        </EventCardLabel>
              <EventCardText type="error" >
                Waiting
          </EventCardText>
            </EventCard> :
            active_status === -1 ?
              <EventCard disabled={true}>
                <EventCardLabel>
                  Approval status
        </EventCardLabel>
                <EventCardText type="error" >
                  Cancel
          </EventCardText>
              </EventCard> :
              <EventCard>
                <EventCardLabel>
                  Approval status
        </EventCardLabel>
                <EventCardText type="success">
                  Approved
        </EventCardText>
              </EventCard>
          }


          <EventCard disabled={!pic} onClick={pic ? () => setShowModalEditPIC(true) : () => { }}>
            <EventCardLabel>
              PIC
        </EventCardLabel>
            <EventCardText>
              {pic || '-'}
            </EventCardText>
          </EventCard>

          <EventCard disabled={!pic} onClick={() => setShowModalEditTypeformID(true)}>
            <EventCardLabel>
              TYPEFORM ID
        </EventCardLabel>
            <EventCardText>
              {typeform_id || '-'}
            </EventCardText>
          </EventCard>

          {/* <HeaderCardCancel onClick={() => setShowModalEventCancel(true)}>
            <EventCardText type="error">
              Cancel Event?
        </EventCardText>
          </HeaderCardCancel> */}


        </Grid>

        <InvoiceContainer>
          <SubTitle>Invoice</SubTitle>
          <Grid gridTemplateColumn='1fr 1fr'>

            {invoiceList.map((item) => {
              return (<EventCard>
                {item.is_paid ? <EventCardBadge type="success">
                  Paid
              </EventCardBadge> :
                  <EventCardBadge type="error">
                    Unpaid
              </EventCardBadge>}
                <Grid gridTemplateColumn='1fr 1fr'>
                  <EventCardContent>
                    <EventCardLabel>
                      INVOICE CODE
                  </EventCardLabel>
                    <EventCardText>
                      2020/INV/0023 blm ada
                  </EventCardText>
                  </EventCardContent>
                  <EventCardContent>
                    <EventCardLabel>
                      Payment type
                  </EventCardLabel>
                    <EventCardText>
                      {item.payment_type}
                    </EventCardText>
                  </EventCardContent>
                </Grid>
              </EventCard>)
            })}
            {invoiceList && invoiceList.length ?
              invoiceList.length == 1 ?
                <AddInvoiceContainer onClick={() => setShowSideAddInvoice2(true)}>
                  <Icon src={AddInvoiceSVG} />
                  <AddInvoiceText>
                    Add Final payment Invoice
                  </AddInvoiceText>
                </AddInvoiceContainer> :
                <></>
              : <AddInvoiceContainer onClick={() => setShowSideAddInvoice(true)}>
                <Icon src={AddInvoiceSVG} />
                <AddInvoiceText>
                  Add Down payment Invoice
                </AddInvoiceText>
              </AddInvoiceContainer>
            }
          </Grid>
        </InvoiceContainer>

        <EventDetailContainer>
          <SubTitle>Event Detail</SubTitle>
          <EventDetailContent>
            <Info color="green">
              <ButtonContainer>

              </ButtonContainer>
              <InfoTitle>
                {eventName}
              </InfoTitle>
              <InfoFieldName>Category</InfoFieldName>
              <InfoValue>{category}</InfoValue>
              <InfoFieldName>Event Type</InfoFieldName>
              <InfoValue>{type}</InfoValue>
              <InfoFieldName>Total Guest</InfoFieldName>
              <InfoValue>{maximum_capacity} pax</InfoValue>
            </Info>
            <RightSection>
              <Detail>
                <DetailAction>
                  <ActionButton type="warning" onClick={() => setShowModalEventCancel(true)}>
                    <IconAction src={DeleteSVG} />
                  Cancel Event
                </ActionButton>
                  <ActionButton type="primary" onClick={() => { setShowSideEventEdit(true) }}>
                    <IconAction src={PencilSVG} />
                  Edit Event
                </ActionButton>
                </DetailAction>
                <DetailHeader>Event Details</DetailHeader>
                <Summary data={summaryData} />
              </Detail>
            </RightSection>
          </EventDetailContent>
        </EventDetailContainer>

      </Body>

    </>
  );
};

export default EventHistoryDetail;
