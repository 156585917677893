import styled, { css } from "styled-components";
import { Heading, Input, Button } from "shared/styles";

export const Icon = styled.img``;

export const Text = styled.p`
  font-size: 14px;
  text-align: center;
  font-family: "GT Walsheim Pro", sans-serif;
`;

export const TopInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Text} {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
`;

export const InputWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #dddddd;
  border-radius: 12px;
`;

export const TabGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const TabContent = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${(props) => (props.active ? "#096b81" : "#BDBDBD")};
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? "1px solid #096b81" : "none")};
`;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: ${(props) => (props.primary ? "40px" : "1rem 2.5rem")};
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.primary ? "24px" : "0")};
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.125rem;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;

  ${Heading.Heading} {
    text-align: left;
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: 0.03125rem;
    color: ${(props) => props.theme.navbar.background};
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    &:first-child {
      flex: 0.7;
    }
  }

  ${Input.Input} {
    border-radius: 0.5rem;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const TopLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const SortContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  position: relative;
  max-width: 480px;
`;

export const TopRightContainer = styled.div`
  display: flex;
  gap: 0.75rem;

  ${Button.Button} {
    border-radius: 0.5rem;
    &[kind="primary"] {
      width: 8.75rem;
      padding: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.primary ? "column" : "row")};
  align-items: center;
  justify-content: space-evenly;
  gap: ${(props) => (props.primary ? "24px" : "5px")};
  background-color: ${(props) =>
    props.primary
      ? "transparent"
      : props.theme.form.button.background.icon.normal};
  border-radius: 5px;

  ${Button.Button} {
    border-radius: 0.5rem;
    &[kind="primary"] {
      width: ${(props) => (props.full ? "100%" : "8.75rem")};
      padding: 0;
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 14px;
  padding: 6px 0;
  background-color: #7cc9da;
`;

export const TooltipBox = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  position: absolute;
  bottom: 60px;
  right: -32px;
  visibility: hidden;
  color: #474747;
  width: 110px;
  background-color: #fff;
  border-radius: 4px;

  // transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
  //   padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 50px;
    top: 20px;
    position: absolute;

    border: 10px solid #fff;
    transform: rotate(315deg);
    // transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled.div`
  position: relative;
  & ${Button.Button}:hover + ${TooltipBox} {
    visibility: visible;
    color: #474747;
    background-color: #fff;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent #fff #fff;
    }
  }
`;

export const EmptyGuestImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: 0.0125rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.empty.text};
`;
export const EmptyGuestImage = styled.img`
  margin-bottom: 1.5rem;
`;
export const EmptyGuestText = styled.p``;

export const ContentContainer = styled.div`
  height: 100%;
  padding-bottom: 40px;
  ${(props) =>
    !props.data &&
    css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

  ${(props) =>
    props.data &&
    css`
      margin-bottom: 2.75rem;
    `}
`;

export const TotalPaxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalPax = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  color: ${(props) => props.theme.modal.header.background};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
`;

export const ImportGuestListDesc = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  color: ${(props) => props.theme.heading.subtitle};
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
`;

export const DownloadExcelButtonContainer = styled.div`
  ${Button.Button} {
    width: 100%;
  }
  margin-bottom: 1.5rem;
`;

export const UploadExcelButton = styled.label`
  font-family: "GT Walsheim Pro", sans-serif;
  color: ${(props) => props.theme.heading.subtitle};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  border: 0.09375rem dashed ${(props) => props.theme.border.light};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1.5rem;

  ${Icon} {
    margin-top: 2.3125rem;
    margin-bottom: 1.3125rem;
  }

  &:hover {
    border: 0.09375rem dashed ${(props) => props.theme.border.primary};
  }
`;

export const FileImported = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.card.background};
  border-radius: 0.5rem;

  ${Icon} {
    margin-top: 1.25rem;
    margin-bottom: 1.8575rem;
  }

  ${Heading.Heading} {
    margin-top: 0;

    &[kind="title"] {
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.625rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      letter-spacing: 0.0125rem;
      margin-bottom: 1.5rem;
    }
  }
`;

export const AddNewCategoryInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #dddddd;
  div {
    flex: 1;
  }

  ${Input.Input} {
    margin-bottom: 0;
  }
`;

export const AddNewCategoryListContainer = styled.div`
  padding-top: 1.5rem;
`;

export const TableCategoryContainer = styled.div`
  display: flex;
  max-width: 12.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.125rem 0;
  align-items: center;
`;

export const CategoryMore = styled.div`
  margin-left: 4px;
  color: #727272;
`;

export const StatusContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #727272;
`;

export const SelectionContainer = styled.div`
  border-radius: 10px;
  padding: 12px 20px;
  margin-top: 8px;
  background-color: #ffffff;
`;

export const SelectionItem = styled.div`
  padding: 16px 0;
  border-bottom: 0.697397px solid #dddddd;

  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  &:last-child {
    border-bottom-width: 0;
  }
`;

export const SelectionLabel = styled.div`
  margin-left: 8px;
  color: #0a0a0a;
`;

export const UploadPorgressContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  background-color: #ffffff;
  padding: 31px 55px 24px;
  text-align: center;
`;

export const UploadProgressContent = styled.div`
  margin-top: 24px;
`;

export const UploadProgressFileName = styled.div``;

export const UploadProgressBar = styled.div`
  margin-top: 15px;
  width: 250px;
  height: 14px;
  background: #b5e0ea;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #474747;
`;

export const UploadProgressLoad = styled.div`
  background-color: #096b81;
  width: ${(props) => `${props.progress || 0}%`};
  height: 100%;
  border-radius: 20px;
`;

export const UploadProgressFileSize = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #bdbdbd;
`;

export const UploadFailContainer = styled.div`
  background-color: white;
  padding: 24px 30px;
  text-align: center;
  font-family: "GT Walsheim Pro", sans-serif;
`;

export const UploadFailWarning = styled.div`
  margin-top: 16px;
  padding: 0 10px;
`;

export const UploadFailHeading = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #0a0a0a;
`;

export const UploadFailBody = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #474747;
`;

export const UploadInput = styled.input`
  display: none;
`;

export const DownloadButton = styled.a`
  box-sizing: border-box;
  cursor: pointer;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  border: 0;
  border-radius: 0.5rem;
  padding: 0.75rem 2.375rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: ${(props) => props.theme.form.button.background.primary};
  color: ${(props) => props.theme.form.button.text.primary};

  &:hover {
    background-color: ${(props) => props.theme.form.button.background.hover};
    cursor: pointer;
  }

  &:disabled {
    background-color: ${(props) => props.theme.form.button.background.disabled};
  }

  &:active {
    text-decoration: none;
  }
`;

export const SearchContainer = styled.div`
  max-width: 275px;
`;

export const MessageBoxContainer = styled.div`
  margin-bottom: 24px;
`;

export const OverflowTableControl = styled.div`
  min-width: 100%;
  width: 300px;
  overflow-x: scroll;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
export const PaginationNumberContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100%;
`;
export const PaginationPageTotal = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #262626;
`;

export const ButtonEditContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.6rem;
`;
