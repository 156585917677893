import styled from "styled-components";

export const Dropdown = styled.ul`
  z-index: 99999;
  position: absolute;
  margin: 4.5rem 2.1325rem 0 0;
  right: 0;
  box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);
  border-radius: 0.625rem;
  background-color: ${(props) =>
    props.theme.profileButton.dropdown.background.normal};
  width: 15rem;
`;

export const DropdownOption = styled.li`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem;
  cursor: pointer;

  svg path {
    fill: ${(props) => props.theme.profileButton.dropdown.icon.normal};
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.profileButton.dropdown.background.hover};
    svg path {
      fill: ${(props) => props.theme.profileButton.dropdown.icon.hover};
    }
  }
  &:first-child {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
  }
  &:last-child {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
`;

export const Icon = styled.img`
  position: relative;
  top: 0.2rem;
  margin-right: 0.5rem;
`;

export const ProfileButton = styled.div`
  position: absolute;
  right: 0;
  background: ${(props) => props.theme.profileButton.background};
  border-radius: 2rem;
  -moz-border-radius: 2rem;
  -webkit-border-radius: 2rem;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.75rem;
  color: ${(props) => props.theme.profileButton.text};
  display: inline-block;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  width: 2rem;
  margin: 1.4375rem 2.1325rem 0 0;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  // -webkit-user-select: none; /* Safari */
  //  -khtml-user-select: none; /* Konqueror HTML */
  //    -moz-user-select: none; /* Old versions of Firefox */
  //     -ms-user-select: none; /* Internet Explorer/Edge */
  //         user-select: none; /* Non-prefixed version, currently
  //                               supported by Chrome, Edge, Opera and Firefox */
`;
