/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
} from 'shared';
import {
  Modal,
  Summary,
  ComboboxPayment,
} from 'components';
import {
  Icon,
  DetailClose,
  DetailHeader,
  DetailHeaderTitle,
  DetailContentContainer,
  DetailButtonContainer,
  InvoiceCodeContent,
  InvoiceCodeLabel,
  InvoiceCodeText,
  InvoiceAmountContainer,
  AmmountContent,
  AmountLabel,
  AmountText,
  UnpaidBadge,
  PaymentDetailsContainer,
  PaymentDetailTitle,
  PackageContainer,
  PackageContent,
  PackageDetailsContainer,
} from 'pages/admin/dashboard-admin/invoices/modal/invoice-detail/style';
import InvoiceDetailSVG from 'assets/icons/invoice-detail.svg';
import ExitSVG from 'assets/icons/exit.svg';
import ChekclistSVG from 'assets/icons/checklist.svg';
import { Scrollbars } from 'react-custom-scrollbars';

const InvoiceDetail = ({
  isOpen,
  onRequestClose,
  selectedInvoice,
  sendInvoice,
  sendReceipt,
  isSubmiting,
}) => {
  const [invoiceDetail, setInvoiceDetail] = useState({});

  useEffect(() => {
    setInvoiceDetail(selectedInvoice);
    // eslint-disable-next-line
  }, [isOpen]);

  const {
    payment_type,
    due_date,
    code,
    is_paid,
    is_sent,
    amount,
    package_details,
  } = invoiceDetail;

  const summaryData = [
    {
      fieldName: 'Payment Type',
      value: payment_type,
    },
    {
      fieldName: 'Due Date',
      value: moment(due_date).format('DD-MM-YYYY'),
    },
  ];
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: '40.625rem',
        },
      }}
    >
      <Scrollbars
        autoHide
        style={{ minHeight: '80vh' }}
      >
        <DetailClose
          src={ExitSVG}
          onClick={onRequestClose}
        />
        <DetailHeader>
          <Icon src={InvoiceDetailSVG} />
          <DetailHeaderTitle>
            Invoice Details
          </DetailHeaderTitle>
        </DetailHeader>
        <DetailContentContainer>
          <InvoiceCodeContent>
            <InvoiceCodeLabel>
              INVOICE CODE
            </InvoiceCodeLabel>
            <InvoiceCodeText>
              {code}
            </InvoiceCodeText>
          </InvoiceCodeContent>
          <InvoiceAmountContainer>
            <AmmountContent>
              <AmountLabel>
                Total
              </AmountLabel>
              <AmountText>
                Rp
                {' '}
                {amount && amount.toLocaleString('id-ID')}
              </AmountText>
            </AmmountContent>
            {is_sent
              ? (
                <ComboboxPayment
                  value={is_paid}
                  list={[
                    { value: true, label: 'paid' },
                    { value: false, label: 'unpaid' },
                  ]}
                  onChange={(value) => { setInvoiceDetail({ ...invoiceDetail, is_paid: value }); }}
                />
              )
              : (
                <UnpaidBadge>
                  unpaid
                </UnpaidBadge>
              )}

          </InvoiceAmountContainer>
          <PaymentDetailsContainer>
            <PaymentDetailTitle>PAYMENT DETAILS</PaymentDetailTitle>
            <Summary data={summaryData} />
          </PaymentDetailsContainer>

          <PackageDetailsContainer>
            <PaymentDetailTitle>PACKAGE DETAILS</PaymentDetailTitle>
            <PackageContainer>

              {package_details && package_details.length && package_details.map((item) => (
                <PackageContent>
                  <Icon src={ChekclistSVG} />
                  {' '}
                  {item.name}
                </PackageContent>
              ))}

            </PackageContainer>
          </PackageDetailsContainer>

          <DetailButtonContainer>
            {is_sent && (
            <Button
              kind="secondary"
              type="button"
              text="Cancel"
              onClick={onRequestClose}
            />
            )}
            {is_sent
              ? (is_paid
                ? (
                  <Button
                    kind="primary"
                    type="button"
                    text="Send Reciept"
                    onClick={sendReceipt}
                    disabled={isSubmiting}
                    isLoading={isSubmiting}
                  />
                )
                : (
                  <Button
                    kind="primary"
                    type="button"
                    text="Send Reminder"
                    onClick={sendInvoice}
                    disabled={isSubmiting}
                    isLoading={isSubmiting}
                  />
                ))
              : (
                <Button
                  kind="primary"
                  type="button"
                  text="Send Invoice"
                  onClick={sendInvoice}
                  disabled={isSubmiting}
                  isLoading={isSubmiting}
                />
              )}
          </DetailButtonContainer>
        </DetailContentContainer>
      </Scrollbars>
    </Modal>
  );
};

const { bool, func } = PropTypes;

InvoiceDetail.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

export default InvoiceDetail;
