import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components/checkbox/style';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

const CheckboxComponent = ({
  name,
  checked,
  onChange,
  label,
}) => (
  <div className="pretty p-svg p-round p-jelly" style={{ margin: 0 }}>
    <input type="checkbox" id={name} name={name} checked={checked} onChange={onChange} />
    <div className="state">
      <svg className="svg svg-icon" style={{ width: '24px', height: '24px', marginTop: '-3px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.2085 9.27079C15.4266 9.08769 15.7063 8.99134 15.9928 9.00061L15.9797 9.02634C16.2617 9.0397 16.527 9.16205 16.7179 9.36682C16.9088 9.57159 17.0099 9.84223 16.9992 10.1199C16.989 10.399 16.8668 10.6628 16.6594 10.8533L11.431 15.7166C11.2244 15.8945 10.9605 15.9948 10.686 15.9997H10.6729C10.5239 16.0037 10.3761 15.9728 10.2416 15.9096C10.1145 15.8547 10.0025 15.771 9.91482 15.6651L7.30064 13.0019C7.20445 12.9013 7.12899 12.7834 7.07844 12.6545C7.02597 12.5279 6.99932 12.3924 7.00001 12.2557C7.0008 12.1149 7.02737 11.9755 7.07844 11.844C7.13824 11.7176 7.22261 11.6039 7.32679 11.5095C7.42394 11.4119 7.53985 11.3343 7.66777 11.2813C7.79568 11.2283 7.93303 11.2009 8.07183 11.2007C8.34369 11.211 8.60304 11.3158 8.80379 11.4966L10.686 13.4393L15.2085 9.27079Z" fill="#096B81" />
      </svg>
      <Label style={{ marginLeft: '8px' }}>{label}</Label>
    </div>
  </div>
);

const { string, bool, func } = PropTypes;

CheckboxComponent.propTypes = {
  name: string,
  checked: bool,
  onChange: func,
  label: string,
};

CheckboxComponent.defaultProps = {
  name: '',
  checked: false,
  onChange: () => {},
  label: '',
};

export default CheckboxComponent;
