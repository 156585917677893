import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Icon = styled.img``;

export const ModalContainer = styled.div`
  padding: 32px 40px;
  background-color: #F4F4F4;
`;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1rem 2.5rem;
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.0.125rem;
    }
  }
`;

export const ModalHeaderWithoutBadge = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.0.125rem;
    }
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 0.5rem 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const DetailClose = styled.img`
  position: absolute;
  right: 1.8125rem;
  top: 1.8125rem;
  cursor: pointer;
`;

export const DetailHeader = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1.4375rem 2rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const DetailHeaderTitle = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const DetailButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 1rem;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const DetailContentContainer = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  background-color: ${(props) => props.theme.modal.background};
  padding: 1.75rem 2.5rem;
  padding-bottom: 0;
`;

export const InvoiceCodeContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InvoiceCodeLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #474747;
  text-aling: left;
`;

export const InvoiceCodeText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #0A0A0A;
  text-aling: right;
`;

export const InvoiceAmountContainer = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 24px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AmmountContent = styled.div``;

export const AmountLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const AmountText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #0A0A0A;
`;

export const UnpaidBadge = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #F27373;
  background: #FFE3E3;
  padding: 6px 12px;
`;

export const PaymentDetailsContainer = styled.div`
  margin-top: 24px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 24px;
`;

export const PaymentDetailTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #0A0A0A;
  margin-bottom:12px;
`;

export const PackageContainer = styled.div`
  padding: 8px 20px;
  background: #FFFFFF;
  border-radius: 10px;
`;

export const PackageContent = styled.div`
  display: flex;
  align-items: center;
  padding:16px 0;
  border-bottom: 0.697397px solid #DDDDDD;
  ${Icon} {
    margin-right: 12px;
  }
  &:nth-last-child(1){
    border-width: 0;
  }
`;

export const PackageDetailsContainer = styled.div``;
