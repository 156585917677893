import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
} from 'shared';
import {
  GuestContainer,
  LogoContainer,
  Icon,
  IconNext,
} from 'pages/check-in/guest-thankyou/style';
import { useHistory } from 'react-router-dom';
import LogoFooterSVG from 'assets/images/logo-footer.svg';

import BackSVG from 'assets/icons/back-checkin.svg';

const GuestThankYou = ({ eventName, withVideo }) => {
  const history = useHistory();
  return (
    <>
      <GuestContainer alignItems="center" withVideo={withVideo}>
        <Heading
          kind="title"
          text="Thank you for coming!"
        />
        <IconNext src={BackSVG} onClick={() => { history.push('/check-in/guest-welcome'); }} />
      </GuestContainer>
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};

const { string } = PropTypes;

GuestThankYou.propTypes = {
  eventName: string.isRequired,
};

export default GuestThankYou;
