import styled from 'styled-components';

export const Icon = styled.img`
  position: relative;
  }
`;

export const Body = styled.div`
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;
  padding-bottom:2.5rem;
  font-family: 'GT Walsheim Pro', sans-serif;
`;

export const Title = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.75rem;
  letter-spacing: 0.03125rem;
  color: ${(props) => props.theme.navbar.background};
  margin-bottom: 1.25rem;
`;

export const GridDashboard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:  ${(props) => props.gridTemplateColumn || '1fr'}} ;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-top: ${(props) => props.marginTop || 0};
`;

export const FlexColumnDashboard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  text-align: left;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.09375rem;
  margin-bottom:${(props) => ((props.marginBottom && props.marginBottom) || 0)};
`;

export const ToolTipText = styled.span`
  visibility: hidden;
  width: 22.5rem;
  background-color: white;
  color: #00000;
  text-align: left;
  border-radius: 0.375rem;
  padding: 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-weight: normal;
  margin-left: ${(props) => ((props.isLeft && '-21.875rem') || '-2.1875rem')};
  box-shadow:0.3125rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: ${(props) => (props.isLeft ? 'auto' : '50%')};
    right:${(props) => (props.isLeft ? '10%' : 'auto')};
    margin-left: -10.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;
export const ToolTip = styled.span`
  padding-left: 4px;
  position: relative;
  display: inline-block;
  &:hover {
    ${ToolTipText}{
      visibility: visible;
    }
  }
`;

export const CardTotalEventPieChart = styled.div`
  box-sizing: border-box;
  padding: 32px 40px;
  background: #096B81;
  border-radius: 10px;
  min-height: 238px;
  position: relative;
`;

export const CardTotalEventNumber = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
`;

export const CardTotalEventSubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
`;

export const TotalEventNumber = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
`;

export const TotalEventLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
`;

export const TotalEventsFooter = styled.div`
  margin-top: 28px;
  display: flex;
  flex: 1;
  color: #FFFFFF;
`;

export const TotalEventFooterContent = styled.div`
  padding-right:40px;
  &:last-child{
    padding-right: 0;
    padding-left: 40px;
    border-left : 1px solid #FFFFFF;
  }
`;

export const CardTotalEventPieChartContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 32px;
`;

export const CardTotalEventPieChartLegendContainer = styled.div`
  position: absolute;
  bottom:32px;
  left: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  display: flex;
`;

export const LegendContent = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

export const LegendTotalEvent = styled.div`
  background: ${((props) => (props.isFinished && '#F4F4F4') || '#C9B9B2')};
  border-radius: 3px;
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const Card = styled.div`
  background-color:${(props) => props.theme.dashboardPost.color.white};
  padding:0.9375rem 1.5rem 1.25rem;
  min-height:14.8125rem;
  border-radius:0.625rem;
  position:relative;

  &:hover{
    cursor: pointer;
  }

  ${Icon}{
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  ${SubTitle}{
    font-weight:normal;
    color:${(props) => props.theme.dashboardPost.color.gray};
  }
`;
