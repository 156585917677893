/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'shared';
import {
  SideMenu,
  SideMenuCollapsible,
  DatePicker,
} from 'components';
import {
  Icon,
  InputAmount,
  ContainerInputAmount,
  InputAmountPrefix,
  InvoiceCodeContainer,
  InvoiceCodeLabel,
  InvoiceCodeText,
  PackageContainer,
  PackageContent,
} from 'pages/admin/dashboard-admin/event-details/sidemenu/add-invoice/style';
import AddGuestSVG from 'assets/icons/add-guest.svg';
import ChekclistSVG from 'assets/icons/checklist.svg';

const AddInvoiceSideMenu = ({
  isOpen,
  onRequestClose,
  addInvoice,
  nextInvoiceCode,
  eventDetail,
  isSubmiting,
}) => {
  const [openedCollapsibleId, setOpenedCollapsibleId] = useState(0);
  const [packageDetail, setPackageDetail] = useState([]);
  const [totalOperators, setTotalOperators] = useState([]);

  const packageDetailsGenerator = (event) => {
    const package_details = [];

    // capacity
    package_details.push({
      name: `${event.maximum_capacity} ${event.type === 'Standing' ? 'Invitations' : 'Guests'}`,
    });

    // reports
    package_details.push({
      name: 'Event Reports',
    });

    // check in
    package_details.push({
      name: 'Guest QR Code Check-In',
    });

    // angpao
    package_details.push({
      name: 'Gift and Angpao Receiving Management',
    });

    // whatsapp
    package_details.push({
      name: 'Whatsapp Confirmation and Notification',
    });

    return package_details;
  };

  const [form, setForm] = useState({
    amount: 0,
    payment_type: 'Down Payment',
    due_date: new Date(),
    add_ons: [],
  });

  useEffect(() => {
    if (isOpen) {
      setPackageDetail(packageDetailsGenerator(eventDetail));
      setOpenedCollapsibleId(0);
      setForm({
        amount: 0,
        payment_type: 'Down Payment',
        due_date: new Date().toString(),
        package_details: packageDetailsGenerator(eventDetail),
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <SideMenu
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      finishFunc={() => {
        if (totalOperators) {
          const tempData = JSON.parse(JSON.stringify(form));
          tempData.package_details.push({ name: `${totalOperators} Operators` });
          addInvoice(tempData);
        } else {
          addInvoice(form);
        }
      }}
      openedId={openedCollapsibleId}
      openId={setOpenedCollapsibleId}
      title="Add Invoice"
      buttonText="Add Invoice"
      icon={AddGuestSVG}
      isLoading={isSubmiting}
      disabled={isSubmiting}
    >
      <SideMenuCollapsible
        id={0}
        openedId={openedCollapsibleId}
        openId={setOpenedCollapsibleId}
        title="payment details"
      >
        <InvoiceCodeContainer>
          <InvoiceCodeLabel>
            Invoice Code
          </InvoiceCodeLabel>
          <InvoiceCodeText>
            {nextInvoiceCode}
          </InvoiceCodeText>
        </InvoiceCodeContainer>

        <ContainerInputAmount>
          <form>
            <InputAmount
              type="text"
              value={(form.amount).toLocaleString()}
              onChange={(event) => {
                const tempValue = event.target.value.replace(/[,.-]/g, '');
                setForm({ ...form, amount: Number(tempValue).toLocaleString() });
              }}
              onKeyDown={((e) => {
                if (!((e.keyCode > 95 && e.keyCode < 106)
                  || (e.keyCode > 47 && e.keyCode < 58)
                  || e.keyCode === 8)) {
                  e.preventDefault();
                }
              })}
            />
          </form>
          <InputAmountPrefix>
            rp
          </InputAmountPrefix>
        </ContainerInputAmount>
        <Input
          type="text"
          label="Payment Type"
          name="paymenttype"
          placeholder="Placeholder"
          value={form.payment_type}
          disabled
        />
        <DatePicker
          label="Due Date"
          value={form.due_date}
          onChange={(value) => setForm({ ...form, due_date: value })}
        />

      </SideMenuCollapsible>
      <SideMenuCollapsible
        id={1}
        openedId={openedCollapsibleId}
        openId={setOpenedCollapsibleId}
        title="Package"
      >
        <Input
          type="text"
          label="Total Operator"
          name="totalOperator"
          placeholder="e.g. 5"
          value={totalOperators}
          onKeyDown={((e) => {
            if (!((e.keyCode > 95 && e.keyCode < 106)
              || (e.keyCode > 47 && e.keyCode < 58)
              || e.keyCode === 8)) {
              e.preventDefault();
            }
          })}
          onChange={(value) => {
            setTotalOperators(value);
          }}
        />
        <PackageContainer>
          {packageDetail.map((item) => (
            <PackageContent>
              <Icon src={ChekclistSVG} />
              {' '}
              {item.name}
            </PackageContent>
          ))}
        </PackageContainer>
      </SideMenuCollapsible>
    </SideMenu>
  );
};

const { bool, func } = PropTypes;

AddInvoiceSideMenu.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default AddInvoiceSideMenu;
