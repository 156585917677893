import styled, { css } from "styled-components";

export default {
  Container: styled.div``,
  Label: styled.label`
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 0.875rem;
    color: ${(props) => props.theme.form.label.primary};
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    display: block;
    margin-bottom: 0.5rem;
  `,

  InputContainer: styled.div`
    position: relative;

    ${(props) =>
      props.tel &&
      css`
        display: flex;
        justify-content: flex-start;
        gap: 0.75rem;
      `}
  `,

  CodeContainer: styled.div`
    position: relative;
    input {
      width: 100%;
    }
    cursor: pointer;
  `,

  InputIcon: styled.img`
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
  `,

  Input: styled.input`
    font-family: "GT Walsheim Pro", sans-serif;
    background-color: ${(props) => props.theme.form.input.background};
    border: 1px solid ${(props) => props.theme.form.input.border.normal};
    width: 100%;
    height: ${(props) => (props.isSearch && "2.25rem") || "3rem"};
    margin-bottom: 1.25rem;
    box-sizing: border-box;
    padding: 1rem;
    padding-right: ${(props) => (props.icon && "3.3rem") || "1rem"};
    border-radius: 0.625rem;
    font-size: ${(props) => (props.isSearch && "14pt") || "1rem"};
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    &::placeholder {
      ${(props) =>
        props.isSearch &&
        css`
          font-size: 14px;
        `}
      color: ${(props) => props.theme.form.placeholder};
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.form.input.border.focus};
    }

    ${(props) =>
      props.error &&
      css`
        border: 1px solid ${props.theme.form.input.border.error};

        &:focus {
          border: 1px solid ${props.theme.form.input.border.error};
        }
      `}

    &:disabled {
      background: ${(props) => props.theme.form.input.disabled};
      color: #727272;
      -webkit-text-fill-color: #727272;
    }
  `,

  Icon: styled.img`
    position: absolute;
    right: 1rem;
    top: ${(props) => (props.isSearch && "0.6775rem") || "1rem"};

    ${(props) =>
      props.expanded &&
      css`
        top: 0.8rem;
      `}
  `,

  ErrorMessageContainer: styled.div`
    position: relative;
  `,

  ErrorMessage: styled.small`
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    position: relative;
    top: -1rem;
    color: ${(props) => props.theme.form.error};
  `,

  ErrorIcon: styled.img`
    position: relative;
    top: 0.25rem;
    margin-right: 0.25rem;
  `,

  PhoneCodePouUpContainer: styled.div`
    position: absolute;
    width: 100%;
    background-color: white;
    top: 100%;
    margin-top: ${(props) => (props.withSearchFilter ? "0px" : "20px")};
    z-index: 20;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  `,

  PhoneCodeSearchContainer: styled.div`
    padding: 12px;
    width: auto;
    border-bottom: 1px solid #dddddd;
  `,

  PhoneCodeSearchInput: styled.input`
    font-family: "GT Walsheim Pro", sans-serif;
    padding: 12px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 10px;
    &::placeholder {
      ${(props) =>
        props.isSearch &&
        css`
          font-size: 14px;
        `}
      color: ${(props) => props.theme.form.placeholder};
    }
  `,

  PhoneCodeListContainer: styled.div`
    font-family: "GT Walsheim Pro", sans-serif;
    padding-bottom: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #0a0a0a;
  `,

  PhoneCodeList: styled.div`
    padding: 12px 16px;
    &:hover {
      background-color: #b5e0ea;
      cursor: pointer;
    }
  `,

  AutoCompleteContainer: styled.div`
    position: absolute;
    width: 100%;
    background-color: white;
    top: 100%;
    margin-top: 20px;
    z-index: 20;
    border-radius: 10px;
  `,

  AutoCompleteListContainer: styled.div`
    font-family: "GT Walsheim Pro", sans-serif;
    padding-bottom: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #0a0a0a;
  `,

  AutoCompleteList: styled.div`
    padding: 12px 16px;
    &:hover {
      cursor: pointer;
    }
  `,
};
