import styled from 'styled-components';

export const Icon = styled.img`
  cursor: pointer;
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.assigner.background};
  border-radius: 0.625rem;
  padding: 0 1.25rem;
`;

export const Title = styled.div`
  font-family: GT Walsheim Pro;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.assigner.title};
  padding: 1.25rem 0;

  b {
    font-weight: bold;
  }
`;

export const Value = styled.div`
  flex: 1;
  font-family: 'GT Walsheim Pro';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.assigner.value};
`;

export const Row = styled.div`
  display: flex;
  gap: 0.625rem;
  justify-content: space-between;
  padding: 1.25rem 0;
  border-bottom: 0.0435873125rem solid ${(props) => props.theme.assigner.border};

  &:hover ${Value}{
    color: ${((props) => (props.hasAssigned && props.theme.assigner.row.hover.assigned) || props.theme.assigner.row.hover.unassigned)};
    cursor: pointer;
  }

  &:last-child {
    border-bottom: 0;
  }

  svg {
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.assigner.icon};
    }
  }
`;

export const Status = styled.div`
  font-family: 'GT Walsheim Pro';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.assigner.status};
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const StatusIcon = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.375rem;
  background-color: ${(props) => (props.success && props.theme.assigner.success) || props.theme.assigner.danger};
`;
