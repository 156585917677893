import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Link } from 'shared';
import { Brand, Contact } from 'components';
import {
  Container,
  Section,
  HeadingContainer,
  ContactsContainer,
  Temp,
  ContactContent,
} from 'pages/events/steps/05/style';
import WhatsappSVG from 'assets/icons/whatsapp.svg';
import EmailSVG from 'assets/icons/email.svg';
import TempSVG from 'assets/images/temp.svg';

const Step7 = ({ setStep }) => {
  const {
    REACT_APP_WA_NUMBER,
    REACT_APP_EMAIL,
  } = process.env;
  return (
    <>
      <Brand />
      <Container>
        <Section>
          <HeadingContainer>
            <Heading
              kind="title"
              text={(
                <p>
                  Sorry we
                  <br />
                  can&apos;t proceed :(
                </p>
            )}
            />
            <Heading
              kind="subtitle"
              text="Please make a payment at your earliest convenience or contact us below"
            />
            <ContactsContainer>
              <ContactContent onClick={() => { window.open(`https://wa.me/${REACT_APP_WA_NUMBER}?text=`, '_blank'); }}>
                <Contact icon={WhatsappSVG} />
              </ContactContent>
              <ContactContent onClick={() => { window.open(`mailto:${REACT_APP_EMAIL}`, '_blank'); }}>
                <Contact icon={EmailSVG} />
              </ContactContent>
            </ContactsContainer>
            <Link
              to="/events"
              onClick={() => setStep(0)}
              text="Back to Event List"
            />
          </HeadingContainer>
        </Section>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <Temp src={TempSVG} alt="Temp" />
        </div>
      </Container>
    </>
  );
};

const { func } = PropTypes;

Step7.propTypes = {
  setStep: func.isRequired,
};

export default Step7;
