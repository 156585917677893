import React from 'react';
import PropTypes from 'prop-types';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

const CheckboxCheckIn = ({ checked, onChange }) => (
  <div className="pretty p-svg p-curve p-bigger p-jelly">
    <input type="checkbox" checked={checked} onChange={onChange}  style={{marginTop:'-40px'}}/>
    <div className="state" >
      <svg className="svg svg-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4ZM10.8274 5.2287C11.0918 4.90982 11.0476 4.43701 10.7287 4.17264C10.4098 3.90827 9.937 3.95246 9.67263 4.27134L6.44115 8.1691L4.25258 6.19331C3.94513 5.91575 3.47087 5.93998 3.19331 6.24744C2.91574 6.5549 2.93997 7.02915 3.24743 7.30672L6.01666 9.80672C6.16837 9.94368 6.36942 10.0128 6.57328 9.99807C6.77714 9.98334 6.96616 9.88605 7.09661 9.7287L10.8274 5.2287Z" fill="#096B81" />
      </svg>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label />
    </div>
  </div>
);

const { bool, func } = PropTypes;

CheckboxCheckIn.propTypes = {
  checked: bool,
  onChange: func,
};

CheckboxCheckIn.defaultProps = {
  checked: false,
  onChange: () => {},
};

export default CheckboxCheckIn;
