import React, { useState, useEffect, useContext } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import SVGInject from '@iconfu/svg-inject';
import {
  Dropdown,
  DropdownOption,
  Icon,
  ProfileButton,
} from 'components/profile-button-admin/style';
import { store } from 'store';
import { logout } from 'helpers/authenticationAdmin';
import LogoutIcon from 'assets/icons/logout.svg';

const DropdownComponent = () => {
  useEffect(() => {
    SVGInject(document.querySelectorAll('img.injectable'));
  }, []);

  const DropdownOptions = [
    {
      name: 'Logout',
      icon: LogoutIcon,
      page: () => logout(),
    },
  ];

  return (
    <Dropdown>
      {DropdownOptions.map((option) => (
        <DropdownOption
          key={option.name}
          onClick={() => option.page()}
        >
          <Icon
            className="injectable"
            alt={option.name}
            src={option.icon}
          />
          {option.name}
        </DropdownOption>
      ))}
    </Dropdown>
  );
};

const ProfileButtonComponent = () => {
  const { state: { user } } = useContext(store);
  const [letter, setLetter] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (user === undefined) {
      setLetter(user.fullname[0]);
    } else {
      setLetter(user.fullname[0]);
    }
  }, [user]);

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => setIsDropdownOpen(false)}
      >
        <ProfileButton onClick={() => setIsDropdownOpen((prevState) => !prevState)}>
          {letter.toUpperCase()}
        </ProfileButton>
        {isDropdownOpen && <DropdownComponent />}
      </OutsideClickHandler>
    </>
  );
};

export default ProfileButtonComponent;
