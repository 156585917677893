import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextsContainer = styled.form`
  width: 50%;
  text-align: left;

  ${Heading.Heading} {
    text-align: left;

    &[kind="title"] {
      font-size: 3.25rem;
      line-height: 4.125rem;
      margin-bottom: 1rem;
    }

    &[kind="subtitle"] {
      margin-bottom: 0;
    }
  }

  ${Button.Button} {
    margin-top: 1.75rem;
  }
`;
