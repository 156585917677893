const forms = {
  invitation: {
    AddInvitation: {
      name: "",
      phone: "",
      phone_code: "+62",
      email: "",
      address: "",
      notes: "",
      pax: 1,
      guests: [
        {
          name: "",
          notes: "",
          arrival_flight_number: "",
          arrival_flight_date_string: "",
          departure_flight_number: "",
          departure_flight_date_string: "",
        },
      ],
      category: [],
      accommodation_check_in_date_string: "",
      accommodation_check_out_date_string: "",
      accommodation_room_number: "",
    },
    EditInvitation: {
      name: "",
      phone: "",
      phone_code: "+62",
      email: "",
      address: "",
      notes: "",

      pax: 0,
      category: [],
      guests: ["test from static"],
      attending_status: false,
      confirmation_status: false,

      newGuests: [],
      editedGuests: [],
      deletedGuests: [],
      accommodation_check_in_date_string: "",
      accommodation_check_out_date_string: "",
      accommodation_room_number: "",
    },
    AddCategory: {
      name: "",
    },
    EditCategory: {
      name: "",
    },
  },
  table: {
    QuickSetup: {
      totalTable: 0,
      capacityPerTable: 0,
    },
    AddTable: {
      name: "",
      capacity: "",
      category: [],
    },
    EditTable: {
      name: "",
      capacity: "",
      category: [],
    },
  },
  setting: {
    HealtCheck: {
      health_check_status: false,
    },
    HospitalityCheck: {
      flight_details_status: false,
      accommodation_status: false,
    },
    Whatsapp: {
      hash_tag: "",
      wa_signature: "",
      wa_last_confirmation_string: "",
      wa_reminder_date_string: "",
    },
    Email: {
      email_salutation: "",
      email_body: "",
      email_reminder_date_string: "",
      email_signature: "",
      email_subject: "",
    },
    EInvitation: {
      language: "",
      removeImage: false,
      image: "",
    },
  },
  event: {
    EditBasicWedding: {
      groom_name: "",
      groom_mother: "",
      groom_fahter: "",
      bride_name: "",
      bride_mother: "",
      bride_father: "",
      event_date_string: "",
    },
    EditBasicGeneral: {
      name: "",
      event_date_string: "",
    },
    EditLocation: {
      event_venue: "",
      event_city: "",
      address: "",
      latitude: "",
      longitude: "",
    },
  },
  admin: {
    operators: {
      AddOperators: {
        fullname: "",
        email: "",
        extension: "+62",
        phone: "",
        role: "",
      },
    },
    eventDetail: {
      AddInvoice: {
        amount: 0,
        payment_type: "",
        due_date: "",
        add_ons: [],
      },
      AddInvoice2: {
        amount: 0,
        payment_type: "",
        due_date: "",
        add_ons: [],
      },
    },
  },
};

export default forms;
