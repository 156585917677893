import React from 'react';
import PropTypes from 'prop-types';
import { ListContainer, ListItem } from 'pages/check-in/guest-search/list/style';
import { Scrollbars } from 'react-custom-scrollbars';

const List = ({ data, selectName }) => (
  <ListContainer>
    <Scrollbars
      autoHide
      style={{ minHeight:data.length >= 3 ?  '170px' : data.length === 2 ? '116px' : data.length === 1 ? '63px' : '0'}}
    >
      {data.map((item, index) => (
        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={() => selectName(item)}
        >
          {item.name}
        </ListItem>
      ))}
    </Scrollbars>
  </ListContainer>
);

const {
  arrayOf,
  func,
  oneOfType,
  object,
  string,
} = PropTypes;

List.propTypes = {
  selectName: func,
  data: arrayOf(oneOfType([object, string])),
};

List.defaultProps = {
  selectName: () => { },
  data: [],
};

export default List;
