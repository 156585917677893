/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Button,
  Heading,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalButtonContainer,
  ModalClose,
  ModalContainer,
  ModalHeaderWithoutBadge,
  ListContainer,
  ListItem,
} from 'pages/guest-list/floating-menu/style';
import ExitSVG from 'assets/icons/exit.svg';
import InvitationFailSVG from 'assets/images/invitation-fail.svg';

const InvitationFailModal = ({
  isOpen,
  onRequestClose,
  data,
  sendEmailInvitation,
  sendWAInvitation,
  setShowInvitationFail,
  invitationFailType,
  isSubmiting,
}) => {
  const [failUser, setFailUser] = useState([]);
  const doSend = () => {
    if (invitationFailType === 'email') {
      sendEmailInvitation(invitationFailType, setShowInvitationFail);
    } else {
      sendWAInvitation(invitationFailType, setShowInvitationFail);
    }
  };

  useEffect(() => {
    setFailUser(data.reduce((acc, curr) => {
      if (curr.checked) {
        acc.push(curr);
      }
      return acc;
    }, []));
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <>
        <ModalClose
          src={ExitSVG}
          onClick={onRequestClose}
        />
        <ModalContainer>
          <ModalHeaderWithoutBadge>
            <Icon src={InvitationFailSVG} style={{width:'100px',height:'100px',marginLeft:'auto',marginRight:'auto'}}/>
            <Heading
              kind="title"
              text="Oops!"
            />
            <Heading
              kind="subtitle"
              text="Looks like that we are unable to send the invitation to guest / guests below, please check their invitation detail to resend the invitation. "
              marginBottom="0.5rem"
            />
          </ModalHeaderWithoutBadge>
          <ListContainer>
            <Scrollbars
              autoHide
              style={{ minHeight: failUser.length > 3 ? '165px' : failUser.length === 2 ? '110px' : failUser.length === 1 ? '55px' : '0', width: '343px' }}
            >
              {failUser.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {item.name}
                </ListItem>
              ))}
            </Scrollbars>
          </ListContainer>
          <ModalButtonContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Cancel"
              onClick={onRequestClose}
            />
            <Button
              small
              kind="primary"
              type="button"
              text="Proceed"
              onClick={doSend}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ModalButtonContainer>
        </ModalContainer>

      </>
    </Modal>
  );
};

const {
  bool,
  func,
  shape,
  arrayOf,
  string,
} = PropTypes;



InvitationFailModal.propTypes = {
  isOpen: func.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf([shape({})]),
  sendEmailInvitation: func.isRequired,
  sendWAInvitation: func.isRequired,
  setShowInvitationFail: func.isRequired,
  invitationFailType: string.isRequired,
};

InvitationFailModal.defaultProps = {
  data: [],
};

export default InvitationFailModal;
