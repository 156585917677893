export const EVENT_CATEGORIES = {
  wedding: 'wedding',
  birthday: 'birthday',
  seminar: 'seminar',
  holyMatrimony:'holy matrimony',
};

export const EVENT_TYPES = {
  standing: 'standing',
  seated: 'seated',
};

export const EVENT_TYPES_EXPLICIT = {
  standing: 'Standing Party',
  seated: 'Table Party',
};

export const ACTIVE_STATUS = {
  '-1': 'Event canceled',
  0: 'Event not yet approved',
  1: 'Event active and already approved',
  2: 'Event already finished',
};
