import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  color: ${(props) => props.theme.dashboardCard.number.big};
  font-size:2rem ;
  line-height:2.5rem;
  letter-spacing:0.03125rem; 
`;

export const SubTitle = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  color: ${(props) => props.theme.dashboardCard.number.small};
  font-size:1rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem ; 
`;

export const DashboardCard = styled.div`
  position: relative;
  border-radius: 0.625rem;
  border-left: ${(props) => ((props.warning && `0.625rem solid ${props.theme.dashboardCard.border.warning}`)
    || `0.625rem solid ${props.theme.dashboardCard.border.available}`)};
  background-color: ${(props) => props.theme.section.detail.background};
  padding:${(props) => (props.isAdmin && '21px 40px 22px') || '1.875rem 2.625rem'} ;
  display: flex;
  align-items:"center;
  cursor: pointer;
  flex-direction:column;
  &:hover {
    box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${SubTitle} {
    padding-left: 0.25rem;
  }
`;
