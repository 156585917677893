import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { store, ACTIONS } from "store";
import { Loading } from "pages";
import ENDPOINTS from "helpers/endpoints";

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("event_token");
  window.location.href = "/sign-in";
};

export const getUserToken = (item) => localStorage.getItem(item);

export const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      authorization: getUserToken("access_token"),
      authorizationevent: getUserToken("event_token"),
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error.message);
    if (error.response.status === 403) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("event_token");
      window.location.href = "/sign-in";
    }
    return Promise.reject(error);
  }
);

const decodeToken = () =>
  axiosApiInstance.get(ENDPOINTS("customer.authentication.decodeToken"));

export const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      !getUserToken("access_token") ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      ) : (
        <Redirect to="/welcome" />
      )
    }
  />
);

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useContext(store);
  useEffect(() => {
    let isMounted = true;
    decodeToken()
      .then((res) => {
        if (isMounted) {
          dispatch({ type: ACTIONS.addUser, payload: res.data.value.user });

          setIsAuthenticated(true);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("event_token");
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Route
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          render={(props) =>
            isAuthenticated ? (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Component {...props} />
            ) : (
              <Redirect to="/sign-in" />
            )
          }
        />
      )}
    </>
  );
};

const { func } = PropTypes;

GuestRoute.propTypes = {
  component: func,
};

ProtectedRoute.propTypes = {
  component: func,
};

GuestRoute.defaultProps = {
  component: () => {},
};

ProtectedRoute.defaultProps = {
  component: () => {},
};
