import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { store, ACTIONS } from 'store';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { Heading } from 'shared';
import {
  Brand,
  ProfileButton,
  Card,
  Contact,
  Modal,
} from 'components';
import { EVENT_CATEGORIES } from 'helpers/constants';
import {
  Container,
  HeadingContainer,
  Section,
  AddNewEventButton,
  Icon,
  ModalContent,
  ModalClose,
  ModalHeader,
  ModalDescContainer,
  ModalContact,
  ModalContactTitle,
  ModalContactDesc,
} from 'pages/events/style';
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
} from 'pages/events/steps';
import PlusCircledSVG from 'assets/icons/plus-circled.svg';
import ExitSVG from 'assets/icons/exit.svg';
import WhatsappSVG from 'assets/icons/whatsapp.svg';
import EmailSVG from 'assets/icons/email.svg';
import PaymentNeededSVG from 'assets/icons/payment-needed.svg';

const PaymentNeeded = ({
  isOpen,
  onRequestClose,
}) => {
  const {
    REACT_APP_WA_NUMBER,
    REACT_APP_WA_NUMBER_PREVIEW,
    REACT_APP_EMAIL,
  } = process.env;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalContent>
        <ModalClose
          src={ExitSVG}
          onClick={onRequestClose}
        />
        <ModalHeader>
          <Icon src={PaymentNeededSVG} style={{width:'100px',height:'100px',marginLeft:'auto',marginRight:'auto'}}/>
          <Heading
            kind="title"
            text="Payment Needed"
          />
          <Heading
            kind="subtitle"
            text="Please make a payment at your earliest convenience or contact us below"
          />
        </ModalHeader>
        <ModalContact>
          <Contact icon={WhatsappSVG} />
          <ModalDescContainer href={`https://wa.me/${REACT_APP_WA_NUMBER}?text=`} target="_blank">
            <ModalContactTitle>Whatsapp</ModalContactTitle>
            <ModalContactDesc>{REACT_APP_WA_NUMBER_PREVIEW}</ModalContactDesc>
          </ModalDescContainer>
        </ModalContact>
        <ModalContact>
          <Contact icon={EmailSVG} />
          <ModalDescContainer href={`mailto:${REACT_APP_EMAIL}`} target="_blank">
            <ModalContactTitle>Email</ModalContactTitle>
            <ModalContactDesc>info@intisinergi.com</ModalContactDesc>
          </ModalDescContainer>
        </ModalContact>
      </ModalContent>
    </Modal>
  );
};

const SelectEvent = ({ setStep }) => {
  const { state: { user }, dispatch } = useContext(store);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [events, setEvents] = useState([]);
  const { type: userType } = user;

  const resetEventForm = () => {
    dispatch({
      type: ACTIONS.createEvent,
      payload: {
        groom_name: '',
        bride_name: '',
        event_date_string: '',
        event_time: '',
        event_venue: '',
        address: '',
        event_city: '',
        latitude: '-6.17540',
        longitude: '106.82719',
        category: '',
        type: '',
        maximum_capacity: '',
      },
    });
  };

  useEffect(() => {
    resetEventForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _endpoints = userType === 'admin' ? 'customer.tab.eventList' : 'customer.event.event';
    axiosApiInstance.get(ENDPOINTS(_endpoints))
      .then((res) => {
        if (userType === 'admin') {
          setEvents(res.data.value.events);
        } else {
          setEvents(res.data.value);
        }
      });
  }, [userType]);

  return (
    <>
      <Brand />
      <ProfileButton />
      <PaymentNeeded
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        setStep={setStep}
      />
      <Container isModalOpen={isModalOpen}>
        <HeadingContainer>
          <Heading
            kind="title"
            text="Select an Event"
          />
        </HeadingContainer>
        <Section>
          {userType !== 'admin' && (
          <AddNewEventButton onClick={() => setStep(1)}>
            <Icon src={PlusCircledSVG} />
            Add New Event
          </AddNewEventButton>
          )}
          {events.map((event) => (
            <div>
            <Card
              paymentNeeded={event.active_status === 0}
              // eslint-disable-next-line no-underscore-dangle
              key={event._id}
              headerImage
              category={event.category}
              title={event.name}
              place={event.event_venue}
              time={moment.utc(event.event_date_string).format('DD/MM/YY - HH.mm')}
              onClick={() => {
                if (event.active_status === 0) {
                  setIsModalOpen(true);
                } else {
                  history.push('/event/dashboard', {
                    // eslint-disable-next-line no-underscore-dangle
                    eventID: event._id,
                  });
                }
              }}
            />
            </div>
          ))}
        </Section>
      </Container>
    </>
  );
};

const Events = () => {
  const [step, setStep] = useState(0);

  const STEPS = {
    0: <SelectEvent setStep={setStep} />,
    1: <Step1 setStep={setStep} />,
    2: <Step2 setStep={setStep} />,
    3: <Step3 setStep={setStep} />,
    4: <Step4 setStep={setStep} />,
    5: <Step5 setStep={setStep} />,
  };

  return STEPS[step];
};

const { bool, func } = PropTypes;

PaymentNeeded.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

SelectEvent.propTypes = {
  setStep: func.isRequired,
};

export default Events;
