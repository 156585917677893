/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect, useContext } from "react";
import { store } from "store";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import SVGInject from "@iconfu/svg-inject";
import Joi from "joi";
import { useLocation } from "react-router-dom";
import { camelCase, queryRead } from "helpers/helpers";
import ENDPOINTS from "helpers/endpoints";
import ENDPOINTSV2 from "helpers/endpoints-v2";
import { axiosApiInstance } from "helpers/authentication";
import forms from "data/static/form";
import { Heading, Input, TextArea, Button } from "shared";
import {
  List,
  Modal,
  Table,
  TableLabel,
  TableApproval,
  SideMenu,
  SideMenuCollapsible,
  TableButton,
  BadgeSelect,
  Checkbox,
  MessageBox,
  ToastBox,
  Pagination,
  Dropdown,
  DatePicker,
  TimePicker,
} from "components";
import {
  Icon,
  ModalContent,
  ModalClose,
  ModalHeader,
  Body,
  TopContainer,
  TopLeftContainer,
  SortContainer,
  TopRightContainer,
  ButtonContainer,
  ContentContainer,
  EmptyGuestImageContainer,
  EmptyGuestImage,
  EmptyGuestText,
  TotalPaxContainer,
  TotalPax,
  ImportGuestListDesc,
  DownloadExcelButtonContainer,
  UploadExcelButton,
  FileImported,
  AddNewCategoryInputContainer,
  AddNewCategoryListContainer,
  TableCategoryContainer,
  CategoryMore,
  StatusContainer,
  SelectionContainer,
  SelectionItem,
  SelectionLabel,
  UploadPorgressContainer,
  UploadProgressContent,
  UploadProgressFileName,
  UploadProgressBar,
  UploadProgressLoad,
  UploadProgressFileSize,
  UploadFailContainer,
  UploadFailWarning,
  UploadFailHeading,
  UploadFailBody,
  UploadInput,
  DownloadButton,
  SearchContainer,
  MessageBoxContainer,
  OverflowTableControl,
  InputWrapper,
  Divider,
  TooltipBox,
  TooltipCard,
  Text,
  TopInputWrapper,
  TabGroup,
  TabContent,
  ButtonEditContainer,
} from "pages/guest-list/style";
import GuestListFloatingMenu from "pages/guest-list/floating-menu";
import SearchSVG from "assets/icons/search.svg";
import GuestCategorySVG from "assets/icons/guest-category.svg";
import DownloadSVG from "assets/icons/download.svg";
import ExportSVG from "assets/icons/table-export.svg";
import AddSVG from "assets/icons/add.svg";
import EmptyGuestSVG from "assets/images/empty-guest.svg";
import ExitSVG from "assets/icons/exit.svg";
import ExcelDownloadedSVG from "assets/icons/excel-downloaded.svg";
import PlusSmallSVG from "assets/icons/plus-small.svg";
import UploadSVG from "assets/icons/upload.svg";
import FileUploadedSVG from "assets/icons/file-uploaded.svg";
import AddGuestSVG from "assets/icons/add-guest.svg";
import ImportGuestSVG from "assets/icons/import-guest.svg";
import AddCategorySVG from "assets/icons/add-category.svg";
import EditSVG from "assets/icons/edit.svg";
import UnionSVG from "assets/icons/union-input.svg";
import UnionDisableSVG from "assets/icons/union-disable.svg";
import UploadingSVG from "assets/images/uploading.svg";
import UploadFailSVG from "assets/images/upload-fail.svg";
import DDaySVG from "assets/icons/d-day.svg";
import SortSVG from "assets/icons/sort.svg";
import Invitation from "pages/invitation";
import { useHistory } from "react-router-dom";
import { set, transform } from "lodash";
import moment from "moment";
import { ACTIONS } from "store";
import Loading from "pages/loading";

const ExcelDownloaded = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    <ModalContent>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      <ModalHeader>
        <Icon
          src={ExcelDownloadedSVG}
          style={{
            width: "100px",
            height: "100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Heading kind="title" text="Excel template downloaded!" />
        <Heading
          kind="subtitle"
          text="To make it easier for you and us, we provided required template for the guest list"
        />
      </ModalHeader>
    </ModalContent>
  </Modal>
);

const EmptyGuestList = () => (
  <EmptyGuestImageContainer>
    <EmptyGuestImage
      src={EmptyGuestSVG}
      style={{
        width: "130px",
        height: "130px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
    <EmptyGuestText>
      Looks like you have not invited any guest to the event.
      <br />
      Lets start adding your guests!
    </EmptyGuestText>
  </EmptyGuestImageContainer>
);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ModalDday = ({
  isOpen,
  onRequestClose,
  downloadGuest,
  fakeLoading,
  isDownloadProgress,
  downloadExcel,
  disabled,
  fileName,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    <ModalContent primary>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      {isDownloadProgress === 1 ? (
        <UploadPorgressContainer>
          <Icon src={UploadingSVG} />
          <UploadProgressContent>
            <UploadProgressFileName>{fileName}</UploadProgressFileName>
            <UploadProgressBar>
              <UploadProgressLoad progress={fakeLoading} />
            </UploadProgressBar>
            <UploadProgressFileSize>
              {((downloadExcel.size / 1024) * (fakeLoading / 100)).toFixed(2)}/
              {(downloadExcel.size / 1024).toFixed(2)}
              kb
            </UploadProgressFileSize>
          </UploadProgressContent>
        </UploadPorgressContainer>
      ) : isDownloadProgress === 2 ? (
        <>
          <ModalHeader>
            <Icon
              src={DDaySVG}
              style={{
                height: "100px",
                width: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Heading kind="title" text="Export Guest List" />
            <Heading
              kind="subtitle"
              text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              marginBottom="0.5rem"
            />
          </ModalHeader>
          <ButtonContainer primary full>
            <Button
              small
              disabled={disabled}
              kind="primary"
              type="button"
              text="Download Guest Data"
              onClick={downloadGuest}
            />
            <Button
              small
              kind="secondary"
              type="button"
              text="Cancel"
              onClick={onRequestClose}
            />
          </ButtonContainer>
        </>
      ) : isDownloadProgress === 3 ? (
        <FileImported>
          <Icon
            src={FileUploadedSVG}
            style={{
              height: "100px",
              width: "92px",
            }}
          />
          <Heading kind="title" text="Failed To Export File !" />
          <Heading kind="subtitle" text="Something Went Wrong !" />
        </FileImported>
      ) : (
        <FileImported>
          <Icon
            src={FileUploadedSVG}
            style={{
              height: "100px",
              width: "92px",
            }}
          />
          <Heading kind="title" text="File Successfully Exported!" />
          <Heading kind="subtitle" text="Now enjoy your updated Guest List" />
        </FileImported>
      )}
    </ModalContent>
  </Modal>
);

const GuestList = ({ eventTokenCommited, setIsLoading }) => {
  const history = useHistory();
  // const { search } = useLocation();
  const query = useQuery();
  const {
    state: { eventDetail },
    dispatch,
  } = useContext(store);

  useEffect(() => {
    if (
      !eventDetail.flight_details_status &&
      !eventDetail.accommodation_status
    ) {
      dispatch({
        type: ACTIONS.getEventDetail,
        payload: {
          ...eventDetail,
          flight_details_status: false,
          accommodation_status: false,
        },
      });
    }
  }, []);

  let cols;
  if (eventDetail?.health_check_status) {
    cols = [
      {
        title: "Invitation Name",
        position: "",
        width: "300px",
        render: (rowData) => <span>{rowData.name}</span>,
      },
      {
        title: "Category",
        position: "",
        width: "100px",
        render: (rowData) => (
          <TableCategoryContainer>
            {!!rowData.categories.length && (
              <TableLabel type="default" text={rowData.categories[0]} />
            )}
            {rowData.categories.length > 1 && (
              <CategoryMore>+{rowData.categories.length - 1} More</CategoryMore>
            )}
          </TableCategoryContainer>
        ),
      },
      {
        title: "PAX",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <div style={{ textAlign: "center", width: "100%" }}>
            {rowData.pax}
          </div>
        ),
      },
      {
        title: "Invitation",
        position: "",
        width: "100px",
        render: (rowData) => (
          <span>
            {rowData.wa_blast_status ? (
              <TableLabel
                type={
                  waStats(rowData) === "sent"
                    ? "sent"
                    : waStats(rowData) === "rejected"
                    ? "error"
                    : waStats(rowData) === "delivered"
                    ? "default"
                    : waStats(rowData) === "read"
                    ? "read"
                    : waStats(rowData) === "pending"
                    ? "pending"
                    : "error"
                }
                text={waStats(rowData)}
                style={{ textTransform: "capitalize" }}
              />
            ) : (
              <TableLabel type="error" text="Not Sent" />
            )}
          </span>
        ),
      },
      {
        title: "Confirmation",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <TableApproval status={rowData.confirmation_status} />
        ),
      },
      {
        title: "Attending",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <TableApproval status={rowData.attending_status} />
        ),
      },
      {
        title: "Health Check ",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <TableApproval status={rowData.health_check_status} />
        ),
      },
      {
        title: "",
        position: "center",
        // static: "right",
        width: "100px",
        render: (rowData) => (
          <ButtonEditContainer>
            <TableButton
              text="Edit"
              icon={EditSVG}
              onClick={() => openEdit(rowData._id)}
            />
          </ButtonEditContainer>
        ),
      },
    ];
  } else {
    cols = [
      {
        title: "Invitation Name",
        position: "",
        width: "200px",
        render: (rowData) => <span>{rowData.name}</span>,
      },
      {
        title: "Category",
        position: "",
        width: "auto",
        render: (rowData) => (
          <TableCategoryContainer>
            {!!rowData.categories.length && (
              <TableLabel type="default" text={rowData.categories[0]} />
            )}
            {rowData.categories.length > 1 && (
              <CategoryMore>+{rowData.categories.length - 1} More</CategoryMore>
            )}
          </TableCategoryContainer>
        ),
      },
      {
        title: "PAX",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <div style={{ textAlign: "center", width: "100%" }}>
            {rowData.pax}
          </div>
        ),
      },
      {
        title: "Invitation",
        position: "",
        width: "100px",
        render: (rowData) => (
          <span>
            {rowData.wa_blast_status ? (
              <TableLabel
                type={
                  waStats(rowData) === "sent"
                    ? "sent"
                    : waStats(rowData) === "rejected"
                    ? "error"
                    : waStats(rowData) === "delivered"
                    ? "default"
                    : waStats(rowData) === "read"
                    ? "read"
                    : waStats(rowData) === "pending"
                    ? "pending"
                    : "error"
                }
                text={waStats(rowData)}
                style={{ textTransform: "capitalize" }}
              />
            ) : (
              <TableLabel type="error" text="Not Sent" />
            )}
          </span>
        ),
      },
      {
        title: "Confirmation",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <TableApproval status={rowData.confirmation_status} />
        ),
      },
      {
        title: "Attending",
        position: "center",
        width: "100px",
        render: (rowData) => (
          <TableApproval status={rowData.attending_status} />
        ),
      },
      {
        title: "",
        position: "center",
        // static: "right",
        width: "100px",
        render: (rowData) => (
          <ButtonEditContainer>
            <TableButton
              text="Edit"
              icon={EditSVG}
              onClick={() => openEdit(rowData._id)}
            />
          </ButtonEditContainer>
        ),
      },
    ];
  }

  const { REACT_APP_EXCEL_DOWNLOAD } = process.env;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openedSideMenuId, setOpenedSideMenuId] = useState(0);
  const [openedCollapsibleId, setOpenedCollapsibleId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isExcelUploaded, setIsExcelUploaded] = useState(false);
  const [isProcessUpload, setIsProcessUpload] = useState(0);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isDownloadProgress, setIsDownloadProgress] = useState(2);
  const [downloadExcel, setDownloadExcel] = useState([]);
  const [sheetTitles, setSheetTitles] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [sortCollapsed, setSortCollapsed] = useState(false);
  const [filterCollapsed, setFilterCollapsed] = useState(false);
  const [sortString, setSortString] = useState("Sort by");
  const [filterString, setFilterString] = useState("Filter by");
  const [downloadFileName, setDownloadFileName] = useState("guest.xlsx");

  // status
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isCreateInvitationProceed, setIsCreateInvitationProceed] =
    useState(false);
  const [isEditInvitationProceed, setIsEditInvitationProceed] = useState(false);
  const [isSendInvitationEmailProceed, setIsSendInvitationEmailProceed] =
    useState(false);
  const [isSendInvitationWAProceed, setIsSendInvitationWAProceed] =
    useState(false);
  const [isDeleteInvitationProceed, setIsDeleteInvitationProceed] =
    useState(false);
  const [isCreateCategoryProceed, setIsCreateCategoryProceed] = useState(false);
  const [isAssignCategoryProceed, setIsAssignCategoryProceed] = useState(false);
  const [isSendCovid19Proceed, setIsSendCovid19Proceed] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(0);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const waStats = (data) => {
    const {
      wa_read_time,
      wa_sent_time,
      wa_delivered_time,
      wa_pending_time,
      wa_rejected_time,
    } = data;

    if (wa_read_time && wa_read_time > wa_delivered_time) {
      return "read";
    } else if (wa_delivered_time && wa_delivered_time > wa_sent_time) {
      return "delivered";
    } else if (wa_sent_time && wa_sent_time > wa_pending_time) {
      return "sent";
    } else if (wa_pending_time) {
      return "pending";
    } else if (wa_rejected_time) {
      return "rejected";
    } else {
      return "not sent";
    }
  };

  //pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [guestsList, setGuestsList] = useState([]);

  const [CategoryError, setCategoryError] = useState({
    status: false,
    title: "",
    text: "",
  });

  const [AddGuestError, setAddGuestError] = useState({
    type: "",
    status: false,
    title: "",
    text: "",
  });

  const [EditGuestError, setEditGuestError] = useState({
    status: false,
    title: "",
    text: "",
  });

  // data
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [sort, setSort] = useState(query.get("type") || "");
  const [defaultGuests, setDefaultGuests] = useState([]);
  const [uploadExcel, setUploadExcel] = useState("");
  const [active, setActive] = useState("arrival");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [invitationInfo, setInvitationInfo] = useState({});
  const [invitationFailType, setInvitationFailType] = useState("");

  const [createInvitationData, setCreateInvitationData] = useState(
    forms.invitation.AddInvitation
  );
  const [createInvitationBulkData, setCreateInvitationBulkData] = useState([]);
  const [editInvitationData, setEditInvitationData] = useState(
    forms.invitation.EditInvitation
  );
  const [addCategoryData, setAddCategoryData] = useState(
    forms.invitation.AddCategory
  );

  const [invitationId, setInvitationId] = useState("");

  const [sendWAInvitationToast, setSendWAInvitationToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [sendCovid19Toast, setSendCovid19Toast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [editInvitationToast, setEditInvitationToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const closeModal = () => setIsModalOpen(false);
  const closeSideMenu = () => {
    setOpenedCollapsibleId(0);
    setOpenedSideMenuId(0);
    setIsExcelUploaded(false);
    setUploadExcel("");
    setCreateInvitationBulkData([]);
    setIsExcelUploaded(false);
    setIsProcessUpload(0);
    setFakeLoading(0);
    setAddCategoryData({ name: "" });
  };

  // functions
  const closeCategoryError = () => {
    setCategoryError({
      status: false,
      title: "",
      message: "",
    });
  };

  const closeAddGuestError = () => {
    setAddGuestError({
      type: "",
      status: false,
      title: "",
      message: "",
    });
  };

  const closeEditGuestError = () => {
    setAddGuestError({
      type: "",
      status: false,
      title: "",
      message: "",
    });
  };

  const handleImportList = (e) => {
    const file = e.target.files[0];
    setUploadExcel(file);
    setIsExcelUploaded(true);
    const reader = new FileReader();
    reader.onload = () => {
      const workbook = XLSX.read(reader.result, { type: "binary" });
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const datas = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1 }); // end
      if (datas) {
        const firstRow = datas[0];
        const headerRow = datas[9];
        if (
          firstRow[0] !== "IMPORT GUEST LIST TEMPLATE" ||
          headerRow[1] !== "Invitation Name" ||
          headerRow[2] !== "Guest Name" ||
          headerRow[3] !== "Phone Number" ||
          headerRow[4] !== "Email" ||
          headerRow[5] !== "Category ( category can be applied more than 1)" ||
          headerRow[6] !== "Address"
        ) {
          // format error
          setIsProcessUpload(2);

          return false;
        }
        const dataGuest = [];
        const invitationName = [];
        const doubleName = [];
        for (let i = 11; i < datas.length; i++) {
          const row = datas[i];
          if (row[1]) {
            if (invitationName.indexOf(String(row[1]).trim()) === -1) {
              const guests = String(row[2]).split(";");
              if (guests && guests.length > 0) {
                const category = row[5] ? String(row[5]).split(";") : [];
                // var number = str
                const phone =
                  row[3] &&
                  String(row[3]).includes("(") &&
                  String(row[3]).includes(")")
                    ? row[3].split(")")[row[3].split(")").length - 1]
                    : "";
                const phoneCode =
                  row[3] &&
                  String(row[3]).includes("(") &&
                  String(row[3]).includes(")")
                    ? row[3].substring(
                        row[3].indexOf("(") + 1,
                        row[3].indexOf(")")
                      )
                    : "";
                dataGuest.push({
                  name: String(row[1]).trim(),
                  phone,
                  phone_code: phoneCode,
                  email: row[4] ? String(row[4]).trim() : "",
                  address: row[6] ? String(row[6]) : "",
                  guests: guests.map((guest) => String(guest).trim()),
                  category: category.map((table) => String(table).trim()),
                });
              }
            } else {
              doubleName.push(String(row[1]).trim());
            }

            invitationName.push(String(row[1]).trim());
          }
        }

        if (doubleName.length > 0) {
          // tambah status jadi 3 apabila name duplicat kasih tombol lanjut
          setIsProcessUpload(2);
          return false;
        }

        setCreateInvitationBulkData(dataGuest);
      }
    };
    file && reader.readAsBinaryString(file);
  };

  const addInvitation = async () => {
    const form = createInvitationData;
    const categoryIds = getCategoryIds();
    form.pax = form.guests.length;
    form.category = categoryIds;

    if (!eventDetail.flight_details_status) {
      form.guests.map((guest) => {
        delete guest.arrival_flight_date_string;
        delete guest.arrival_flight_number;
        delete guest.departure_flight_date_string;
        delete guest.departure_flight_number;
      });
    }

    if (!eventDetail.accommodation_status) {
      delete form.accommodation_check_in_date_string;
      delete form.accommodation_check_out_date_string;
      delete form.accommodation_room_number;
    }
    try {
      const schema = Joi.object({
        name: Joi.string()
          .trim()
          .required()
          .error((err) => {
            if (err[0].code === "string.empty") {
              return new Error("Please input your name");
            }
            return new Error("Your name format not valid");
          }),
        phone: Joi.string()
          .trim()
          .required()
          .error((err) => {
            if (err[0].code === "string.empty") {
              return new Error("Please input phone number");
            }
            return new Error("Your phone format not valid");
          }),
        phone_code: Joi.string().trim().required(),
        email: Joi.string().default("").allow(""),
        address: Joi.string().default("").allow(""),
        notes: Joi.string().default("").allow(""),

        pax: Joi.number().required(),
        guests: Joi.array().items(
          Joi.object({
            name: Joi.string().trim(),
            notes: Joi.string().trim().default("").allow(""),
            arrival_flight_number: Joi.string().trim().default("").allow(""),
            arrival_flight_date_string: Joi.string()
              .trim()
              .default("")
              .allow(""),
            departure_flight_number: Joi.string().trim().default("").allow(""),
            departure_flight_date_string: Joi.string()
              .trim()
              .default("")
              .allow(""),
          })
        ),
        category: Joi.array().items(Joi.string().trim()),
        accommodation_check_in_date_string: Joi.string()
          .trim()
          .default("")
          .allow(""),
        accommodation_check_out_date_string: Joi.string()
          .trim()
          .default("")
          .allow(""),
        accommodation_room_number: Joi.string().trim().default("").allow(""),
      });

      const value = await schema.validateAsync(form);
      setIsSubmiting(true);
      axiosApiInstance
        .post(ENDPOINTSV2("customer.invitation.invitation"), value)
        .then((res) => {
          setIsCreateInvitationProceed(true);
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.m) {
            setAddGuestError({
              type: "warning",
              status: true,
              title: "Can't add Guest",
              text: err.response.data.m,
            });
          }
          setIsSubmiting(false);
          // error message
        });
    } catch (err) {
      setAddGuestError({
        type: "warning",
        status: true,
        title: "Can't add Guest",
        text: err.message,
      });
    }
  };

  const addInvitationBulk = async () => {
    const form = createInvitationBulkData;

    axiosApiInstance
      .post(ENDPOINTS("customer.invitation.addBulk"), form)
      .then(() => {
        getInvitationList();
        setUploadExcel("");
        setIsProcessUpload(1);
      })
      .catch(() => {
        // error message

        setUploadExcel("");
        setIsProcessUpload(2);
      });
  };

  const editInvitation = async () => {
    setIsSubmiting(true);

    try {
      const form = JSON.parse(JSON.stringify(editInvitationData));
      const categoryIds = getCategoryIds();
      const editedGuests = [];
      const newGuests = [];
      const deletedGuests = [];
      defaultGuests.map((guestBefore) => {
        const checkEdited = form.guests.findIndex(
          (currentGuest) =>
            String(currentGuest._id) === String(guestBefore._id) &&
            currentGuest.name !== guestBefore.name
        );
        const checkRemoved = form.guests.findIndex(
          (currentGuest) => String(currentGuest._id) === String(guestBefore._id)
        );
        if (checkRemoved === -1) {
          deletedGuests.push(guestBefore._id);
        }
        // if (checkEdited !== -1) {
        //   editedGuests.push({
        //     _id: form.guests[checkEdited]._id,
        //     name: form.guests[checkEdited].name,
        //   });
        // }
        return true;
      });
      let guestCount = 0;
      form.guests.map((item) => {
        if (!item._id && item.name !== "") {
          newGuests.push({
            name: item.name,
            notes: item.notes,
            arrival_flight_number: item.arrival_flight_number,
            arrival_flight_date_string: item.arrival_flight_date_string,
            departure_flight_number: item.departure_flight_number,
            departure_flight_date_string: item.departure_flight_date_string,
          });
        }
        if (item._id && item.name !== "") {
          editedGuests.push({
            _id: item._id,
            name: item.name,
            notes: item.notes,
            arrival_flight_number: item.arrival_flight_number,
            arrival_flight_date_string: item.arrival_flight_date_string,
            departure_flight_number: item.departure_flight_number,
            departure_flight_date_string: item.departure_flight_date_string,
          });
        }

        if (item.name !== "") {
          guestCount++;
        }
        return true;
      });

      form.newGuests = newGuests;
      form.deletedGuests = deletedGuests;
      form.editedGuests = editedGuests;
      form.category = categoryIds;
      form.pax = guestCount;
      delete form.guests;

      !eventDetail?.health_check_status && delete form.health_check_status;

      if (!eventDetail.flight_details_status) {
        form.newGuests.map((guest) => {
          delete guest.arrival_flight_date_string;
          delete guest.arrival_flight_number;
          delete guest.departure_flight_date_string;
          delete guest.departure_flight_number;
        });

        form.editedGuests.map((guest) => {
          delete guest.arrival_flight_date_string;
          delete guest.arrival_flight_number;
          delete guest.departure_flight_date_string;
          delete guest.departure_flight_number;
        });
      }

      if (!eventDetail.accommodation_status) {
        delete form.accommodation_check_in_date_string,
          delete form.accommodation_check_out_date_string,
          delete form.accommodation_room_number;
      }

      const editGuest = Joi.object().keys({
        name: Joi.string().required(),
        _id: Joi.string().required(),
        notes: Joi.string().default("").allow(""),
        arrival_flight_number: Joi.string().default("").allow(""),
        arrival_flight_date_string: Joi.string().default("").allow(""),
        departure_flight_number: Joi.string().default("").allow(""),
        departure_flight_date_string: Joi.string().default("").allow(""),
      });

      const schema = Joi.object({
        name: Joi.string()
          .trim()
          .required()
          .error((err) => {
            if (err[0].code === "string.empty") {
              return new Error("Please input your name");
            }
            return new Error("Your name format not valid");
          }),
        phone: Joi.string()
          .trim()
          .required()
          .error((err) => {
            if (err[0].code === "string.empty") {
              return new Error("Please input your phone");
            }
            return new Error("Your phone format not valid");
          }),
        phone_code: Joi.string().default("").allow(""),
        email: Joi.string().default("").allow(""),
        address: Joi.string().default("").allow(""),
        notes: Joi.string().default("").allow(""),
        health_check_status: Joi.boolean().allow(""),
        pax: Joi.number().required(),
        category: Joi.array().items(Joi.string().trim()),
        confirmation_status: Joi.boolean().required(),
        attending_status: Joi.boolean().required(),

        editedGuests: Joi.array().items(editGuest),
        newGuests: Joi.array().items(
          Joi.object({
            name: Joi.string().trim(),
            notes: Joi.string().trim().default("").allow(""),
            arrival_flight_number: Joi.string().trim().default("").allow(""),
            arrival_flight_date_string: Joi.string()
              .trim()
              .default("")
              .allow(""),
            departure_flight_number: Joi.string().trim().default("").allow(""),
            departure_flight_date_string: Joi.string()
              .trim()
              .default("")
              .allow(""),
          })
        ),
        deletedGuests: Joi.array().items(Joi.string().trim()),
        accommodation_check_in_date_string: Joi.string()
          .trim()
          .default("")
          .allow(""),
        accommodation_check_out_date_string: Joi.string()
          .trim()
          .default("")
          .allow(""),
        accommodation_room_number: Joi.string().trim().default("").allow(""),
      });

      const value = await schema.validateAsync(form);
      axiosApiInstance
        .put(
          `${ENDPOINTSV2("customer.invitation.invitation")}/${invitationId}`,
          value
        )
        .then(() => {
          setEditInvitationToast({
            status: true,
            type: "success",
            title: "Guest Invitation Updated!",
          });
          setIsEditInvitationProceed(true);
        })
        .catch(() => {
          // error message
          setIsSubmiting(false);
        });
    } catch (err) {
      setEditGuestError({
        status: true,
        title: "Can't Edited Guest",
        text: err.message,
      });
    }
  };

  const addCategory = async () => {
    const form = addCategoryData;
    const schema = Joi.object({
      name: Joi.string().trim().required(),
    });

    const value = await schema.validateAsync(form);

    axiosApiInstance
      .post(ENDPOINTS("customer.category.category"), value)
      .then(() => {
        setIsCreateCategoryProceed(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.m) {
          setCategoryError({
            status: true,
            title: "Can't be Added",
            text: err.response.data.m,
          });
        }
        // error message
      });
  };

  const sendEmailInvitation = async (type, openFail, showSendInvitation) => {
    const form = {
      invitations: data.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
    };

    setIsSubmiting(true);
    axiosApiInstance
      .post(ENDPOINTS("customer.invitation.sendEmail"), form)
      .then(
        ({
          data: {
            value: { cannotSending },
          },
        }) => {
          setIsSubmiting(false);
          if (cannotSending.length) {
            setData(
              data.map((item) => {
                const findIndex = cannotSending.findIndex(
                  (current) => String(current.name) === item.name
                );
                item.checked = findIndex !== -1;

                return item;
              })
            );

            showSendInvitation && showSendInvitation(false);
            openFail(true);
            setInvitationFailType(type);
          } else {
            setIsSubmiting(false);
            openFail(false);
            setIsSendInvitationEmailProceed(true);
          }
        }
      )
      .catch(() => {
        // error message
      });
  };

  const sendWAInvitation = async (type, openFail, showSendInvitation) => {
    const form = {
      invitations: data.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
    };
    setIsSubmiting(true);
    axiosApiInstance
      .post(ENDPOINTS("customer.invitation.sendWA"), form)
      .then(
        ({
          data: {
            value: { cannotSending },
          },
        }) => {
          setIsSubmiting(false);

          if (cannotSending.length) {
            setData(
              data.map((item) => {
                const findIndex = cannotSending.findIndex(
                  (current) => String(current.name) === item.name
                );
                item.checked = findIndex !== -1;

                return item;
              })
            );

            showSendInvitation && showSendInvitation(false);
            openFail(true);
            setInvitationFailType(type);
          } else {
            setSendWAInvitationToast({
              status: true,
              type: "success",
              title: "Invitation successfully sent!",
              text: "",
            });
            setIsSubmiting(false);
            openFail(false);
            setIsSendInvitationWAProceed(true);
          }
        }
      )
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.m) {
          setSendWAInvitationToast({
            status: true,
            type: "warning",
            title: "Invitation has not been sent",
            text: err.response.data.m,
          });
        }

        setIsSubmiting(false);
        // error message
      });
  };

  const deleteInvitations = async (
    setShowDeleteFail,
    setDeleteDataFail,
    closeModalConfirm
  ) => {
    const form = {
      invitations: data.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
    };
    setIsSubmiting(true);
    axiosApiInstance
      .post(ENDPOINTS("customer.invitation.deleteInvitations"), form)
      .then(
        ({
          data: {
            value: { cannotDeleted },
          },
        }) => {
          setIsSubmiting(false);
          if (!cannotDeleted.length) {
            setIsDeleteInvitationProceed(true);
          } else {
            setDeleteDataFail(cannotDeleted);
            closeModalConfirm();
            setShowDeleteFail(true);
          }
        }
      )
      .catch(() => {
        setIsSubmiting(false);
        // error message
      });
  };

  const sendCovid19 = async (selectedIds, formCovid19) => {
    try {
      const dateSplit = (formCovid19?.test_date).split("-");
      const form = {
        invitations: selectedIds.map((item) => {
          return item?._id;
        }),
        test_date: `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]} ${formCovid19?.test_time}`,
        address: formCovid19?.address,
        latitude: formCovid19?.latitude,
        longitude: formCovid19?.longitude,
        address_detail: formCovid19?.address_detail,
      };
      const schema = Joi.object().keys({
        invitations: Joi.array().items(Joi.string().required()),
        test_date: Joi.date().required(),
        address: Joi.string().required(),
        latitude: Joi.number().required(),
        longitude: Joi.number().required(),
        address_detail: Joi.string().required(),
      });

      const value = await schema.validateAsync(form);
      setIsSubmiting(true);
      const response = await axiosApiInstance.post(
        ENDPOINTS("customer.invitation.sendHealthCheck"),
        value
      );
      setIsSubmiting(false);
      setIsSendCovid19Proceed(true);
      setSendCovid19Toast({
        status: true,
        type: "success",
        title: "Health Check Invitation successfully sent!",
      });
    } catch (err) {
      setIsSubmiting(false);
      setSendCovid19Toast({
        status: true,
        type: "warning",
        title: "Health Check Invitation has not been sent",
        text: err?.message || err.response.data.m,
      });
    }
  };

  const assignCategory = async (categoriesIds) => {
    const form = {
      invitations: data.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
      category: categoriesIds.reduce((acc, curr) => {
        if (curr.select) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
    };

    const schema = Joi.object({
      category: Joi.array().items(Joi.string()), // categoryId
      invitations: Joi.array().items(Joi.string()), // invitationId
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .post(ENDPOINTS("customer.invitation.setCategory"), value)
      .then(() => {
        setIsSubmiting(false);
        setIsAssignCategoryProceed(true);
      })
      .catch(() => {
        setIsSubmiting(false);
        // error message
      });
  };

  const assignCategoryIntialize = () => {
    const categoriesSelected = data.reduce((acc, curr) => {
      if (curr.checked) {
        acc = acc.concat(curr.categories);
      }

      return acc;
    }, []);

    const selectedCategory = selectedCategories.map((category) => {
      const findIndex = categoriesSelected.findIndex(
        (current) => String(current) === category.name
      );
      category.select = findIndex !== -1;

      return category;
    });

    setSelectedCategories(selectedCategory);
  };

  const getInvitationList = async () => {
    axiosApiInstance
      .get(`${ENDPOINTSV2("customer.invitation.invitation")}?type=${sort}`)
      .then(
        ({
          data: {
            value: { invitations },
          },
        }) => {
          setIsLoading(false);
          setIsSelectedAll(false);
          // console.log(invitations.length);

          // setData(JSON.parse(JSON.stringify(invitations.slice(0,50)))); // success
          // setData(JSON.parse(JSON.stringify(invitations.slice(50,100)))); // success
          setData(JSON.parse(JSON.stringify(invitations)));
          setTempData(JSON.parse(JSON.stringify(invitations)));
        }
      )
      .catch(() => {
        // error message
      });
  };

  const fetchCategories = async () => {
    axiosApiInstance
      .get(ENDPOINTS("customer.category.category"))
      .then(
        ({
          data: {
            value: { categories },
          },
        }) => {
          setCategories(categories);
          setSelectedCategories(
            categories.map((c) => ({
              ...c,
              select: false,
            }))
          );
        }
      )
      .catch(() => {
        // error message
        // test
      });
  };

  const selectCategory = (i) => {
    const tempCategories = [];

    selectedCategories.map((item, index) => {
      if (index === i) {
        tempCategories.push({ ...item, select: !item.select });
      } else {
        tempCategories.push(item);
      }
      return true;
    });

    setSelectedCategories(tempCategories);
  };

  const getCategoryIds = () => {
    let tempIdCategory = [];
    tempIdCategory = selectedCategories
      .filter((item) => item.select === true)
      .map((item) => item._id);
    return tempIdCategory;
  };

  const openEdit = (value) => {
    setOpenedSideMenuId(4);
    setLoadingEdit(true);
    axiosApiInstance
      .post(`${ENDPOINTSV2("customer.invitation.invitation")}/detail`, {
        invitationIds: [value],
      })
      .then(
        ({
          data: {
            value: { invitations },
          },
        }) => {
          setLoadingEdit(false);
          const categoryId = [];
          console.log("invitations", invitations);
          const categoriesSelected = categories.map((category) => {
            const findOldCategory = invitations[0].category?.findIndex(
              (current) => current.name === String(category.name)
            );
            category.select = findOldCategory !== -1;
            return category;
          });

          console.log("categoriesSelected", categoriesSelected);

          const defaultGuest = JSON.parse(
            JSON.stringify(invitations[0].guests)
          );
          const tempGuests = JSON.parse(JSON.stringify(invitations[0].guests));
          setDefaultGuests(defaultGuest);
          setSelectedCategories(categoriesSelected);
          setInvitationId(invitations[0]._id);
          setEditInvitationData({
            name: invitations[0]?.name,
            phone: invitations[0]?.phone,
            phone_code: invitations[0]?.phone_code,
            email: invitations[0]?.email,
            address: invitations[0]?.address,
            notes: invitations[0]?.notes,
            pax: invitations[0]?.pax,
            category: invitations[0]?.category,
            guests: tempGuests,
            attending_status: invitations[0]?.attending_status,
            confirmation_status: invitations[0]?.confirmation_status,
            health_check_status: invitations[0]?.health_check_status,
            newGuests: [],
            editedGuests: [],
            deletedGuests: [],
            accommodation_check_in_date_string:
              invitations[0]?.accommodation_check_in_date_string,
            accommodation_check_out_date_string:
              invitations[0]?.accommodation_check_out_date_string,
            accommodation_room_number:
              invitations[0]?.accommodation_room_number,
          });

          // setSelectedCategories();
        }
      );
  };

  const getSettingData = () => {
    axiosApiInstance
      .get(ENDPOINTS("customer.invitationSetting.setting"))
      .then(
        ({
          data: {
            value: { event },
          },
        }) => {
          setInvitationInfo(event);
        }
      )
      .catch(() => {
        // error message
      });
  };

  useEffect(() => {
    if (isCreateInvitationProceed === true) {
      setIsSubmiting(false);
      setOpenedSideMenuId(0);
      setOpenedCollapsibleId(0);
      getInvitationList();
      setIsCreateInvitationProceed(false);
      setCreateInvitationData(forms.invitation.AddInvitation);
    }

    if (isEditInvitationProceed === true) {
      setOpenedSideMenuId(0);
      getInvitationList();
      setIsEditInvitationProceed(false);
      setEditInvitationData(forms.invitation.EditInvitation);
      setInvitationId("");
      setOpenedCollapsibleId(0);
      setIsSubmiting(false);
    }

    if (isCreateCategoryProceed === true) {
      fetchCategories();
      setIsCreateCategoryProceed(false);
      setAddCategoryData(forms.invitation.AddCategory);
    }

    if (isSendInvitationEmailProceed === true) {
      setIsSendInvitationEmailProceed(false);
      setShowFloatingMenu(false);
      getInvitationList();
      setIsSelectedAll(false);
    }

    if (isSendInvitationWAProceed === true) {
      setIsSendInvitationWAProceed(false);
      setShowFloatingMenu(false);
      getInvitationList();
      setIsSelectedAll(false);
    }

    if (isDeleteInvitationProceed === true) {
      setIsDeleteInvitationProceed(false);
      setShowFloatingMenu(false);
      getInvitationList();
      setIsSelectedAll(false);
    }

    if (isAssignCategoryProceed === true) {
      setIsAssignCategoryProceed(false);
      setShowFloatingMenu(false);
      getInvitationList();
      setIsSelectedAll(false);
    }

    if (isSendCovid19Proceed === true) {
      setIsSendCovid19Proceed(false);
      setShowFloatingMenu(false);
      getInvitationList();
      setIsSelectedAll(false);
    }
    // eslint-disable-next-line
  }, [
    isSendCovid19Proceed,
    isCreateInvitationProceed,
    isEditInvitationProceed,
    isCreateCategoryProceed,
    isSendInvitationEmailProceed,
    isSendInvitationWAProceed,
    isDeleteInvitationProceed,
    isAssignCategoryProceed,
  ]);

  // useEffect(() => {
  //   SVGInject(document.querySelectorAll("img.injectable"));
  //   const { type } = queryRead(search);
  //   if (type) {
  //     setSort(type);
  //   }
  //   // eslint-disable-next-line
  // }, [search]);

  const _handleOpenModalDownload = async () => {
    setOpenedSideMenuId(5);
  };

  const _exportToExcel = async () => {
    setLoading(true);
    const fileType = "xlsx";
    await axiosApiInstance
      .get(ENDPOINTS("customer.invitation.downloadGuest"))
      .then((res) => {
        setLoading(false);
        const titles = Object.keys(res.data.value);
        setSheetTitles(titles);
        let data = [];
        for (let i = 0; i < Object.keys(res.data.value).length; i++) {
          data.push(res.data.value[Object.keys(res.data.value)[i]]);
        }
        setDataExcel(data);
        const sheet1 = XLSX.utils.json_to_sheet(data[0]);
        const sheet2 = XLSX.utils.json_to_sheet(data[1]);
        const sheet3 = XLSX.utils.json_to_sheet(data[2]);
        const sheet4 = XLSX.utils.json_to_sheet(data[3]);
        const sheet5 = XLSX.utils.json_to_sheet(data[4]);
        const sheet6 = XLSX.utils.json_to_sheet(data[5]);
        const sheet7 = XLSX.utils.json_to_sheet(data[6]);
        const sheet8 = XLSX.utils.json_to_sheet(data[7]);
        const wb = {
          Sheets: {
            [titles[0]]: sheet1,
            [titles[1]]: sheet2,
            [titles[2]]: sheet3,
            [titles[3]]: sheet4,
            [titles[4]]: sheet5,
            [titles[5]]: sheet6,
            [titles[6]]: sheet7,
            [titles[7]]: sheet8,
          },
          SheetNames: [
            titles[0],
            titles[1],
            titles[2],
            titles[3],
            titles[4],
            titles[5],
            titles[6],
            titles[7],
          ],
        };
        const excelBuffer = XLSX.write(wb, {
          bookType: fileType,
          type: "array",
        });
        const datas = new Blob([excelBuffer], { type: fileType });
        setDownloadExcel(datas);
        FileSaver.saveAs(datas, "guest.xlsx");
      })
      .catch((error) => {
        // setIsSubmiting(false);
        setIsDownloadProgress(3);
        console.log(error);
        // error message
      })
      .finally(() => {
        setIsDownloadProgress(1);
        setTimeout(() => {
          const incrementer = setInterval(() => {
            setFakeLoading((c) => {
              if (c < 99) return c + 20;
              clearInterval(incrementer);
              return c;
            });
          }, 200);
          setTimeout(() => {
            setIsDownloadProgress(0);
            setFakeLoading(0);
          }, 3000);
        }, 10000);
      });
  };

  useEffect(() => {
    if (eventTokenCommited) {
      setIsLoading(true);
      setIsModalOpen(false);
      getInvitationList();
      fetchCategories();
      getSettingData();
    }
    // eslint-disable-next-line
  }, [eventTokenCommited]);

  useEffect(() => {
    closeAddGuestError();
    closeEditGuestError();
    if (openedSideMenuId === 1) {
      setSelectedCategories(
        categories.map((c) => ({
          ...c,
          select: false,
        }))
      );

      setCreateInvitationData(forms.invitation.AddInvitation);
    }
    // eslint-disable-next-line
  }, [openedSideMenuId]);

  useEffect(() => {
    if (isExcelUploaded && !isProcessUpload) {
      if (fakeLoading < 83) {
        setTimeout(() => {
          setFakeLoading(fakeLoading + 100 / 6);
        }, 5000);
      } else {
        setTimeout(() => {
          setFakeLoading(95);
        }, 5000);
      }
    }
    // eslint-disable-next-line
  }, [isExcelUploaded, isProcessUpload, fakeLoading]);

  useEffect(() => {
    if (createInvitationBulkData.length) {
      addInvitationBulk();
    }
    // eslint-disable-next-line
  }, [createInvitationBulkData, createInvitationData, editInvitationData]);

  // useEffect(() => {
  //   setData(tempData.filter((item) => {
  //     const phoneNumber = `${item.phone_code}${item.phone}`
  //     const phoneNumber2 = `0${item.phone}`
  //     return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  //       || item.categories
  //         .findIndex((item) => item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) > -1
  //       || item.guestNames
  //         .findIndex((item) => item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) > -1
  //       || phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  //       || phoneNumber2.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  //   }
  //   ));
  //   // eslint-disable-next-line
  // }, [searchTerm]);

  const filterItems = [
    {
      label: "Not Send",
      value: "not send",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Sent",
      value: "sent",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
    {
      label: "Read",
      value: "read",
    },
  ];

  const dataFilter = data.filter((item) => {
    if (filter && filter !== "not send") {
      return waStats(item) === filter;
    }
    if (filter === "not send") {
      return (
        waStats(item) !== filterItems[5].value &&
        waStats(item) !== filterItems[4].value &&
        waStats(item) !== filterItems[3].value &&
        waStats(item) !== filterItems[2].value &&
        waStats(item) !== filterItems[1].value
      );
    }
    return item;
  });

  const dataTable = dataFilter
    .filter((item) => {
      if (searchTerm) {
        const phoneNumber = `${item.phone_code}${item.phone}`;
        const phoneNumber2 = `0${item.phone}`;
        return (
          item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          item.categories.findIndex(
            (item) => item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          ) > -1 ||
          item.guestNames.findIndex(
            (item) => item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          ) > -1 ||
          phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          phoneNumber2.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      }
      return item;
    })
    .slice((page - 1) * perPage, page * perPage);

  const dataPagination = dataFilter.filter((item) => {
    if (searchTerm) {
      const phoneNumber = `${item.phone_code}${item.phone}`;
      const phoneNumber2 = `0${item.phone}`;
      return (
        item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        item.categories.findIndex(
          (item) => item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        ) > -1 ||
        item.guestNames.findIndex(
          (item) => item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        ) > -1 ||
        phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        phoneNumber2.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    }
    return item;
  });
  // useEffect(() => {
  //   if (guestsList.length) {
  //     setData(JSON.parse(JSON.stringify(guestsList[page - 1])));
  //     setTempData(JSON.parse(JSON.stringify(guestsList[page - 1])));
  //   }
  // }, [guestsList, page]);

  const sortItems = [
    {
      label: "Attending Guest",
      value: "guest will attend",
    },
    {
      label: "Unconfirmed Guest",
      value: "unconfirmed guest",
    },
    {
      label: "Not Attending Guest",
      value: "not attending guest",
    },
    {
      label: "Guest Has Been Invited",
      value: "guest have been invited",
    },
    {
      label: "Guest Has Not Been Invited",
      value: "guest have not been sent",
    },
    {
      label: "Latest Added Guest Has Not Been Invited",
      value: "latest added not sent",
    },
  ];

  useEffect(() => {
    if (query.get("type") === sortItems[0].value) {
      setSortString(sortItems[0].label);
      getInvitationList();
    } else if (query.get("type") === sortItems[1].value) {
      setSortString(sortItems[1].label);
      getInvitationList();
    } else if (query.get("type") === sortItems[2].value) {
      setSortString(sortItems[2].label);
      getInvitationList();
    } else if (query.get("type") === sortItems[3].value) {
      setSortString(sortItems[3].label);
      getInvitationList();
    } else if (query.get("type") === sortItems[4].value) {
      setSortString(sortItems[4].label);
      getInvitationList();
    } else if (query.get("type") === sortItems[5].value) {
      setSortString(sortItems[5].label);
      getInvitationList();
    } else if (!query) {
      setSortString("Sort by");
      setSort("");
      getInvitationList();
    } else {
      getInvitationList();
      setSort("");
      setSortString("Sort by");
    }
  }, [sort, query]);

  // useEffect(() => {
  //   dataTable.filter((item) => {
  //     return item.qr_blast_status === filter;
  //   });
  // }, [filter]);

  return (
    <>
      {sendWAInvitationToast.status && (
        <ToastBox
          isOpen={sendWAInvitationToast}
          type={sendWAInvitationToast.type}
          title={sendWAInvitationToast.title}
          text={sendWAInvitationToast.text}
          onRequestClose={() => {
            setSendWAInvitationToast({
              ...sendWAInvitationToast,
              status: false,
            });
          }}
        />
      )}

      {sendCovid19Toast.status && (
        <ToastBox
          isOpen={sendCovid19Toast}
          type={sendCovid19Toast.type}
          title={sendCovid19Toast.title}
          text={sendCovid19Toast.text}
          onRequestClose={() => {
            setSendCovid19Toast({ ...sendCovid19Toast, status: false });
          }}
        />
      )}

      {editInvitationToast.status && (
        <ToastBox
          isOpen={editInvitationToast}
          type={editInvitationToast.type}
          title={editInvitationToast.title}
          text={editInvitationToast.text}
          onRequestClose={() => {
            setEditInvitationToast({ ...editInvitationToast, status: false });
          }}
        />
      )}
      <ExcelDownloaded isOpen={isModalOpen} onRequestClose={closeModal} />
      <SideMenu
        isOpen={openedSideMenuId === 1}
        onRequestClose={closeSideMenu}
        finishFunc={addInvitation}
        openedId={openedCollapsibleId}
        openId={setOpenedCollapsibleId}
        title="Add Guest"
        buttonText="Add Guest"
        icon={AddGuestSVG}
        isCollapsible
        disabled={isSubmiting}
        isLoading={isSubmiting}
      >
        {AddGuestError.status && (
          <MessageBoxContainer>
            <MessageBox
              type={AddGuestError.type}
              title={AddGuestError.title}
              text={AddGuestError.text}
              onRequestClose={closeAddGuestError}
            />
          </MessageBoxContainer>
        )}
        <SideMenuCollapsible
          id={0}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="Invitation Detail"
        >
          <Input
            type="text"
            label="Invitation Name"
            name="invitationName"
            placeholder="e.g. Leonard Joe"
            value={createInvitationData.name}
            onChange={(value) => {
              // createInvitationData.guests[0].name = value;
              setCreateInvitationData({
                ...createInvitationData,
                name: value,
                // guests: createInvitationData.guests,
              });
            }}
          />

          <Input
            type="tel"
            label="Phone Number"
            name="phoneNumber"
            placeholder="e.g. 822 7788 555"
            value={[
              createInvitationData.phone_code,
              createInvitationData.phone,
            ]}
            onChange={(phoneCode, phone) =>
              setCreateInvitationData({
                ...createInvitationData,
                phone_code: phoneCode,
                phone,
              })
            }
          />

          <Input
            type="email"
            label="Email"
            name="email"
            placeholder="e.g. leonardjoe@gmail.com"
            value={createInvitationData.email}
            onChange={(value) =>
              setCreateInvitationData({ ...createInvitationData, email: value })
            }
          />

          <TextArea
            label="Address"
            name="address"
            placeholder="e.g. Jl. Sudirman 60, Jakarta"
            value={createInvitationData.address}
            onChange={(value) =>
              setCreateInvitationData({
                ...createInvitationData,
                address: value,
              })
            }
          />

          <Input
            type="text"
            label="Notes"
            name="notes"
            placeholder="Leave additional notes"
            value={createInvitationData.notes}
            onChange={(value) => {
              // createInvitationData.guests[0].notes = value;
              setCreateInvitationData({
                ...createInvitationData,
                notes: value,
                // guests: createInvitationData.guests,
              });
            }}
          />
        </SideMenuCollapsible>
        <SideMenuCollapsible
          id={1}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="Guest Detail"
        >
          <TotalPaxContainer>
            <TotalPax>
              Total Pax:
              {createInvitationData.guests.length}
            </TotalPax>
            <TableButton
              text="Add Guest"
              icon={PlusSmallSVG}
              onClick={() =>
                setCreateInvitationData({
                  ...createInvitationData,
                  guests: [
                    ...createInvitationData.guests,
                    {
                      name: "",
                      notes: "",
                      arrival_flight_date_string: "",
                      arrival_flight_number: "",
                      departure_flight_date_string: "",
                      departure_flight_number: "",
                    },
                  ],
                })
              }
            />
          </TotalPaxContainer>
          {createInvitationData.guests.map((item, index) => (
            <InputWrapper key={index}>
              <TopInputWrapper>
                <Text>{`GUEST ${index + 1}`}</Text>
                <Icon
                  src={index === 0 ? UnionDisableSVG : UnionSVG}
                  alt="disable"
                  onClick={() => {
                    if (createInvitationData.guests.length === 1) {
                      return false;
                    }
                    const tempList = [];
                    createInvitationData.guests.map((guest) =>
                      tempList.push(guest)
                    );
                    tempList.splice(index, 1);
                    setCreateInvitationData({
                      ...createInvitationData,
                      guests: tempList,
                    });
                  }}
                />
              </TopInputWrapper>
              <Input
                // icon={
                //   index === 0
                //     ? UnionDisableSVG
                //     : item.name !== ""
                //     ? UnionDisableSVG
                //     : UnionSVG
                // }
                type="text"
                label="Name"
                name="guestName1"
                placeholder="e.g. Leonard Joe"
                iconClick={() => {
                  if (createInvitationData.guests.length === 1) {
                    return false;
                  }
                  const tempList = [];
                  createInvitationData.guests.map((guest) =>
                    tempList.push(guest)
                  );
                  tempList.splice(index, 1);
                  setCreateInvitationData({
                    ...createInvitationData,
                    guests: tempList,
                  });
                }}
                value={item.name}
                onChange={(value) => {
                  const tempList = [];
                  createInvitationData.guests.map((guest) =>
                    tempList.push(guest)
                  );
                  tempList[index].name = value;
                  const newState = [...createInvitationData.guests];
                  newState[index].name = tempList[index].name;
                  setCreateInvitationData({
                    ...createInvitationData,
                    guests: newState,
                  });
                }}
              />
              <Input
                type="text"
                name="notes1"
                placeholder="Enter your food preference"
                label="Food Preferences"
                value={item.notes}
                onChange={(value) => {
                  const tempList = [];
                  createInvitationData.guests.map((guest) =>
                    tempList.push(guest)
                  );
                  tempList[index].notes = value;
                  const newState = [...createInvitationData.guests];
                  newState[index].notes = tempList[index].notes;
                  setCreateInvitationData({
                    ...createInvitationData,
                    guests: newState,
                  });
                }}
              />
              {eventDetail.flight_details_status && (
                <>
                  <TabGroup>
                    <TabContent
                      active={active === "arrival"}
                      onClick={() => setActive("arrival")}
                    >
                      ARRIVAL
                    </TabContent>
                    <TabContent
                      active={active === "departure"}
                      onClick={() => setActive("departure")}
                    >
                      DEPARTURE
                    </TabContent>
                  </TabGroup>
                  <Input
                    type="text"
                    name="fligthNumber1"
                    placeholder="Enter flight number"
                    label="Flight Number"
                    value={
                      active === "arrival"
                        ? item.arrival_flight_number
                        : item.departure_flight_number
                    }
                    onChange={(value) => {
                      const tempList = [];
                      createInvitationData.guests.map((guest) =>
                        tempList.push(guest)
                      );
                      if (active === "arrival") {
                        tempList[index].arrival_flight_number = value;
                        const newState = [...createInvitationData.guests];
                        newState[index].arrival_flight_number =
                          tempList[index].arrival_flight_number;
                        setCreateInvitationData({
                          ...createInvitationData,
                          guests: newState,
                        });
                      }
                      if (active === "departure") {
                        tempList[index].departure_flight_number = value;
                        const newState = [...createInvitationData.guests];
                        newState[index].departure_flight_number =
                          tempList[index].departure_flight_number;
                        setCreateInvitationData({
                          ...createInvitationData,
                          guests: newState,
                        });
                      }
                    }}
                  />
                  <DatePicker
                    primary
                    label="Date"
                    isEmpty
                    value={
                      active === "arrival"
                        ? item.arrival_flight_date_string
                          ? item.arrival_flight_date_string
                          : ""
                        : item.departure_flight_date_string
                        ? item.departure_flight_date_string
                        : ""
                    }
                    onChange={(newDate) => {
                      const tempList = [];
                      createInvitationData.guests.map((guest) =>
                        tempList.push(guest)
                      );
                      if (active === "arrival") {
                        const dateTemp =
                          tempList[index].arrival_flight_date_string?.split(
                            " "
                          );
                        tempList[
                          index
                        ].arrival_flight_date_string = `${newDate} ${
                          dateTemp[1] ? dateTemp[1] : "00:00"
                        }`;
                        const newState = [...createInvitationData.guests];
                        newState[index].arrival_flight_date_string =
                          tempList[index].arrival_flight_date_string;
                        setCreateInvitationData({
                          ...createInvitationData,
                          guests: newState,
                        });
                      } else {
                        const dateTemp =
                          tempList[index].departure_flight_date_string?.split(
                            " "
                          );
                        tempList[
                          index
                        ].departure_flight_date_string = `${newDate} ${
                          dateTemp[1] ? dateTemp[1] : "00:00"
                        }`;
                        const newState = [...createInvitationData.guests];
                        newState[index].departure_flight_date_string =
                          tempList[index].departure_flight_date_string;
                        setCreateInvitationData({
                          ...createInvitationData,
                          guests: newState,
                        });
                      }
                    }}
                  />
                  <TimePicker
                    primary
                    isEmpty
                    label="Time"
                    // disabled={
                    //   active === "arrival"
                    //     ? !item.arrival_flight_date_string
                    //     : active === "departure"
                    //     ? !item.departure_flight_date_string
                    //     : false
                    // }
                    value={
                      active === "arrival"
                        ? moment
                            .utc(item.arrival_flight_date_string)
                            .format("HH:mm")
                        : moment
                            .utc(item.departure_flight_date_string)
                            .format("HH:mm")
                    }
                    onChange={(value) => {
                      const tempList = [];
                      createInvitationData.guests.map((guest) =>
                        tempList.push(guest)
                      );
                      if (active === "arrival") {
                        const dateTemp =
                          tempList[index].arrival_flight_date_string.split(" ");
                        tempList[index].arrival_flight_date_string = `${
                          dateTemp.length > 1
                            ? dateTemp[0]
                            : moment(new Date()).format("MM-DD-YYYY")
                        } ${value}`;
                        const newState = [...createInvitationData.guests];
                        newState[index].arrival_flight_date_string =
                          tempList[index].arrival_flight_date_string;
                        setCreateInvitationData({
                          ...createInvitationData,
                          guests: newState,
                        });
                      } else {
                        const dateTemp =
                          tempList[index].departure_flight_date_string.split(
                            " "
                          );
                        tempList[index].departure_flight_date_string = `${
                          dateTemp.length > 1
                            ? dateTemp[0]
                            : moment(new Date()).format("MM-DD-YYYY")
                        } ${value}`;
                        const newState = [...createInvitationData.guests];
                        newState[index].departure_flight_date_string =
                          tempList[index].departure_flight_date_string;
                        setCreateInvitationData({
                          ...createInvitationData,
                          guests: newState,
                        });
                      }
                    }}
                  />
                </>
              )}
            </InputWrapper>
          ))}
        </SideMenuCollapsible>
        {eventDetail.accommodation_status && (
          <SideMenuCollapsible
            id={2}
            openedId={openedCollapsibleId}
            openId={setOpenedCollapsibleId}
            title="Accommodation"
          >
            <InputWrapper>
              <DatePicker
                primary
                isEmpty
                label="Check-In Date"
                value={createInvitationData.accommodation_check_in_date_string}
                onChange={(newDate) => {
                  setCreateInvitationData({
                    ...createInvitationData,
                    accommodation_check_in_date_string: newDate,
                  });
                }}
              />
              <DatePicker
                primary
                isEmpty
                label="Check-Out Date"
                value={createInvitationData.accommodation_check_out_date_string}
                onChange={(newDate) => {
                  setCreateInvitationData({
                    ...createInvitationData,
                    accommodation_check_out_date_string: newDate,
                  });
                }}
              />
              <Input
                type="text"
                label="Room Number"
                name="roomNumber"
                placeholder="Enter Room Number"
                value={createInvitationData.accommodation_room_number}
                onChange={(value) => {
                  setCreateInvitationData({
                    ...createInvitationData,
                    accommodation_room_number: value,
                  });
                }}
              />
            </InputWrapper>
          </SideMenuCollapsible>
        )}
        <SideMenuCollapsible
          id={3}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="Guest Category"
        >
          <BadgeSelect
            data={selectedCategories}
            setSelected={selectCategory}
            useIcon
          />
        </SideMenuCollapsible>
      </SideMenu>
      <SideMenu
        isOpen={openedSideMenuId === 2}
        onRequestClose={closeSideMenu}
        title="Import Guest List"
        buttonText={
          ((isProcessUpload === 1 || isProcessUpload === 2) && "Close") || ""
        }
        finishFunc={closeSideMenu}
        icon={ImportGuestSVG}
      >
        {isExcelUploaded ? (
          isProcessUpload === 1 ? (
            <FileImported>
              <Icon src={FileUploadedSVG} />
              <Heading kind="title" text="File Successfully Imported!" />
              <Heading
                kind="subtitle"
                text="Now enjoy your updated Guest List"
              />
            </FileImported>
          ) : isProcessUpload === 2 ? (
            <UploadFailContainer>
              <Icon src={UploadFailSVG} />
              <UploadFailWarning>
                <UploadFailHeading>Oops!</UploadFailHeading>
                <UploadFailBody>
                  The file you uploaded is not compatible with our system.
                  Please go to Import List and download template to get our
                  required template.
                </UploadFailBody>
              </UploadFailWarning>
            </UploadFailContainer>
          ) : (
            <UploadPorgressContainer>
              <Icon src={UploadingSVG} />
              <UploadProgressContent>
                <UploadProgressFileName>
                  {uploadExcel.name}
                </UploadProgressFileName>
                <UploadProgressBar>
                  <UploadProgressLoad progress={fakeLoading} />
                </UploadProgressBar>
                <UploadProgressFileSize>
                  {((uploadExcel.size / 1024) * (fakeLoading / 100)).toFixed(2)}
                  /{(uploadExcel.size / 1024).toFixed(2)}
                  kb
                </UploadProgressFileSize>
              </UploadProgressContent>
            </UploadPorgressContainer>
          )
        ) : (
          <>
            <ImportGuestListDesc>
              Make sure you import the right template. If you don’t have one
              please download below.
            </ImportGuestListDesc>
            <DownloadExcelButtonContainer>
              <DownloadButton
                href={REACT_APP_EXCEL_DOWNLOAD}
                onClick={() => {
                  setOpenedSideMenuId(0);
                  setIsModalOpen(true);
                }}
                download
              >
                Download Excel Template
              </DownloadButton>
            </DownloadExcelButtonContainer>
            <UploadExcelButton htmlFor="excel-upload">
              <Icon src={UploadSVG} />
              Click here to upload file
            </UploadExcelButton>
            <UploadInput
              id="excel-upload"
              value={uploadExcel.name}
              type="file"
              onChange={(e) => handleImportList(e)}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
        )}
      </SideMenu>
      <SideMenu
        isOpen={openedSideMenuId === 3}
        onRequestClose={closeSideMenu}
        title="Add Category"
        icon={AddCategorySVG}
      >
        {CategoryError.status && (
          <MessageBoxContainer>
            <MessageBox
              type="warning"
              title={CategoryError.title}
              text={CategoryError.text}
              onRequestClose={closeCategoryError}
            />
          </MessageBoxContainer>
        )}
        <AddNewCategoryInputContainer>
          <Input
            type="text"
            name="addNewCategory"
            placeholder="Add new category"
            value={addCategoryData.name}
            onChange={(value) => {
              setAddCategoryData({ name: value });
            }}
          />

          <Button
            kind="secondary"
            type="submit"
            text="Add"
            onClick={addCategory}
          />
        </AddNewCategoryInputContainer>
        <AddNewCategoryListContainer>
          <List data={categories} fetchCategories={fetchCategories} />
        </AddNewCategoryListContainer>
      </SideMenu>
      {/* edit */}
      <SideMenu
        isOpen={openedSideMenuId === 4}
        onRequestClose={closeSideMenu}
        finishFunc={editInvitation}
        openedId={openedCollapsibleId}
        openId={setOpenedCollapsibleId}
        isCollapsible
        title="Edit Guest"
        buttonText="Save Changes"
        icon={AddGuestSVG}
        disabled={isSubmiting}
        isLoading={isSubmiting}
        isEditDrawer
        collapsibleContent={eventDetail.accommodation_status ? 6 : 5}
      >
        {loadingEdit ? (
          <Loading secondary />
        ) : (
          <>
            {EditGuestError.status && (
              <MessageBoxContainer>
                <MessageBox
                  type="warning"
                  title={EditGuestError.title}
                  text={EditGuestError.text}
                  onRequestClose={closeEditGuestError}
                />
              </MessageBoxContainer>
            )}
            <SideMenuCollapsible
              id={0}
              openedId={openedCollapsibleId}
              openId={setOpenedCollapsibleId}
              title="Status"
            >
              <StatusContainer>
                Confirmation
                <SelectionContainer>
                  <SelectionItem
                    onClick={() =>
                      setEditInvitationData({
                        ...editInvitationData,
                        confirmation_status: true,
                      })
                    }
                  >
                    <Checkbox
                      checked={editInvitationData.confirmation_status}
                    />
                    <SelectionLabel>Confirmed</SelectionLabel>
                  </SelectionItem>
                  <SelectionItem
                    onClick={() =>
                      setEditInvitationData({
                        ...editInvitationData,
                        confirmation_status: false,
                      })
                    }
                  >
                    <Checkbox
                      checked={!editInvitationData.confirmation_status}
                    />
                    <SelectionLabel>Unconfirmed</SelectionLabel>
                  </SelectionItem>
                </SelectionContainer>
              </StatusContainer>

              <StatusContainer>
                Attendance
                <SelectionContainer>
                  <SelectionItem
                    onClick={() =>
                      setEditInvitationData({
                        ...editInvitationData,
                        attending_status: true,
                      })
                    }
                  >
                    <Checkbox checked={editInvitationData.attending_status} />
                    <SelectionLabel>Attending</SelectionLabel>
                  </SelectionItem>
                  <SelectionItem
                    onClick={() =>
                      setEditInvitationData({
                        ...editInvitationData,
                        attending_status: false,
                      })
                    }
                  >
                    <Checkbox checked={!editInvitationData.attending_status} />
                    <SelectionLabel>Can’t Attend</SelectionLabel>
                  </SelectionItem>
                </SelectionContainer>
              </StatusContainer>

              {eventDetail?.health_check_status ? (
                <StatusContainer>
                  Health Check
                  <SelectionContainer>
                    <SelectionItem
                      onClick={() =>
                        setEditInvitationData({
                          ...editInvitationData,
                          health_check_status: true,
                        })
                      }
                    >
                      <Checkbox
                        checked={editInvitationData.health_check_status}
                      />
                      <SelectionLabel>Tested</SelectionLabel>
                    </SelectionItem>
                    <SelectionItem
                      onClick={() =>
                        setEditInvitationData({
                          ...editInvitationData,
                          health_check_status: false,
                        })
                      }
                    >
                      <Checkbox
                        checked={!editInvitationData.health_check_status}
                      />
                      <SelectionLabel>No Tested</SelectionLabel>
                    </SelectionItem>
                  </SelectionContainer>
                </StatusContainer>
              ) : (
                <></>
              )}
            </SideMenuCollapsible>
            <SideMenuCollapsible
              id={1}
              openedId={openedCollapsibleId}
              openId={setOpenedCollapsibleId}
              title="Invitation Detail"
            >
              <Input
                type="text"
                label="Invitation Name"
                name="invitationName"
                placeholder="e.g. Leonard Joe"
                value={editInvitationData.name}
                onChange={(value) => {
                  // if (editInvitationData.guests[0].name) {
                  //   editInvitationData.guests[0].name = value;
                  // }
                  setEditInvitationData({
                    ...editInvitationData,
                    name: value,
                    // guests: editInvitationData.guests,
                  });
                }}
              />

              <Input
                type="tel"
                label="Phone Number"
                name="phoneNumber"
                placeholder="e.g. 822 7788 555"
                value={[
                  editInvitationData.phone_code,
                  editInvitationData.phone,
                ]}
                onChange={(phoneCode, phone) =>
                  setEditInvitationData({
                    ...editInvitationData,
                    phone_code: phoneCode,
                    phone,
                  })
                }
              />

              <Input
                type="email"
                label="Email"
                name="email"
                placeholder="e.g. leonardjoe@gmail.com"
                value={editInvitationData.email}
                onChange={(value) =>
                  setEditInvitationData({ ...editInvitationData, email: value })
                }
              />

              <TextArea
                label="Address"
                name="address"
                placeholder="e.g. Jl. Sudirman 60, Jakarta"
                value={editInvitationData?.address || ""}
                onChange={(value) =>
                  setEditInvitationData({
                    ...editInvitationData,
                    address: value,
                  })
                }
              />

              <Input
                type="text"
                label="Notes"
                name="notes"
                placeholder="Leave additional notes"
                value={editInvitationData.notes}
                onChange={(value) => {
                  // if (editInvitationData.notes) {
                  //   editInvitationData.notes = value;
                  // }
                  setEditInvitationData({
                    ...editInvitationData,
                    notes: value,
                    // guests: editInvitationData.guests,
                  });
                }}
              />
            </SideMenuCollapsible>
            <SideMenuCollapsible
              id={2}
              openedId={openedCollapsibleId}
              openId={setOpenedCollapsibleId}
              title="Guest Detail"
            >
              <TotalPax>
                Origin Pax:
                {editInvitationData.origin_pax
                  ? editInvitationData.origin_pax
                  : editInvitationData.pax}
              </TotalPax>
              <TotalPaxContainer>
                <TotalPax>
                  Total Pax:
                  {editInvitationData.guests.length}
                </TotalPax>
                <TableButton
                  text="Add Guest"
                  icon={PlusSmallSVG}
                  onClick={() =>
                    setEditInvitationData({
                      ...editInvitationData,
                      guests: [
                        ...editInvitationData.guests,
                        {
                          name: "",
                          notes: "",
                          arrival_flight_number: "",
                          departure_flight_number: "",
                          arrival_flight_date_string: "",
                          departure_flight_date_string: "",
                        },
                      ],
                    })
                  }
                />
              </TotalPaxContainer>
              {editInvitationData.guests.map((item, index) => (
                <InputWrapper key={index}>
                  <TopInputWrapper>
                    <Text>{`GUEST ${index + 1}`}</Text>
                    <Icon
                      src={
                        index === 0
                          ? UnionDisableSVG
                          : item.name !== ""
                          ? UnionDisableSVG
                          : UnionSVG
                      }
                      alt="disable"
                      onClick={
                        !item.name
                          ? () => {
                              if (editInvitationData.guests.length === 1) {
                                return false;
                              }
                              const tempList = [];
                              editInvitationData.guests.map((guest) =>
                                tempList.push(guest)
                              );
                              tempList.splice(index, 1);
                              setEditInvitationData({
                                ...editInvitationData,
                                guests: tempList,
                              });
                              return true;
                            }
                          : () => {}
                      }
                    />
                  </TopInputWrapper>
                  <Input
                    // icon={
                    //   index === 0
                    //     ? UnionDisableSVG
                    //     : item.name !== ""
                    //     ? UnionDisableSVG
                    //     : UnionSVG
                    // }
                    type="text"
                    label={`Name`}
                    name="guestName1"
                    placeholder="e.g. Leonard Joe"
                    iconClick={
                      !item.name
                        ? () => {
                            if (editInvitationData.guests.length === 1) {
                              return false;
                            }
                            const tempList = [];
                            editInvitationData.guests.map((guest) =>
                              tempList.push(guest)
                            );
                            tempList.splice(index, 1);
                            setEditInvitationData({
                              ...editInvitationData,
                              guests: tempList,
                            });
                            return true;
                          }
                        : () => {}
                    }
                    value={item.name}
                    onChange={(value) => {
                      const tempList = [];
                      editInvitationData.guests.map((guest) =>
                        tempList.push(guest)
                      );
                      tempList[index].name = value;
                      const newState = [...editInvitationData.guests];
                      newState[index].name = tempList[index].name;
                      setEditInvitationData({
                        ...editInvitationData,
                        guests: newState,
                      });
                    }}
                  />
                  <Input
                    type="text"
                    name="foodPreference1"
                    label="Food Preferences"
                    placeholder="Enter your food preference"
                    value={item.notes || ""}
                    onChange={(value) => {
                      const tempList = [];
                      editInvitationData.guests.map((guest) =>
                        tempList.push(guest)
                      );
                      tempList[index].notes = value;
                      const newState = [...editInvitationData.guests];
                      newState[index].notes = tempList[index].notes;
                      setEditInvitationData({
                        ...editInvitationData,
                        guests: newState,
                      });
                    }}
                  />
                  {eventDetail.flight_details_status && (
                    <>
                      <TabGroup>
                        <TabContent
                          active={active === "arrival"}
                          onClick={() => setActive("arrival")}
                        >
                          ARRIVAL
                        </TabContent>
                        <TabContent
                          active={active === "departure"}
                          onClick={() => setActive("departure")}
                        >
                          DEPARTURE
                        </TabContent>
                      </TabGroup>
                      <Input
                        type="text"
                        name="fligthNumber1"
                        placeholder="Enter flight number"
                        label="Flight Number"
                        value={
                          active === "arrival"
                            ? item.arrival_flight_number
                            : item.departure_flight_number
                        }
                        onChange={(value) => {
                          const tempList = [];
                          editInvitationData.guests.map((guest) =>
                            tempList.push(guest)
                          );
                          if (active === "arrival") {
                            tempList[index].arrival_flight_number = value;
                            const newState = [...editInvitationData.guests];
                            newState[index].arrival_flight_number =
                              tempList[index].arrival_flight_number;
                            setEditInvitationData({
                              ...editInvitationData,
                              guests: newState,
                            });
                          }
                          if (active === "departure") {
                            tempList[index].departure_flight_number = value;
                            const newState = [...editInvitationData.guests];
                            newState[index].departure_flight_number =
                              tempList[index].departure_flight_number;
                            setEditInvitationData({
                              ...editInvitationData,
                              guests: newState,
                            });
                          }
                        }}
                      />
                      {active === "arrival" ? (
                        <>
                          <DatePicker
                            primary
                            label="Date"
                            isEmpty
                            value={
                              item.arrival_flight_date_string
                                ? item.arrival_flight_date_string
                                : ""
                            }
                            onChange={(newDate) => {
                              const tempList = [];
                              editInvitationData.guests.map((guest) =>
                                tempList.push(guest)
                              );
                              if (!tempList[index].arrival_flight_date_string) {
                                tempList[
                                  index
                                ].arrival_flight_date_string = `${newDate} ${"00:00"}`;
                              }
                              const dateTemp =
                                tempList[
                                  index
                                ].arrival_flight_date_string.split(" ");
                              tempList[
                                index
                              ].arrival_flight_date_string = `${newDate} ${
                                dateTemp[1] ? dateTemp[1] : "00:00"
                              }`;
                              const newState = [...editInvitationData.guests];
                              newState[index].arrival_flight_date_string =
                                tempList[index].arrival_flight_date_string;
                              setEditInvitationData({
                                ...editInvitationData,
                                guests: newState,
                              });
                            }}
                          />
                          <TimePicker
                            primary
                            isEmpty
                            label="Time"
                            value={
                              item.arrival_flight_date_string
                                ? moment
                                    .utc(item.arrival_flight_date_string)
                                    .format("HH:mm")
                                : ""
                            }
                            onChange={(value) => {
                              const tempList = [];
                              editInvitationData.guests.map((guest) =>
                                tempList.push(guest)
                              );
                              if (!tempList[index].arrival_flight_date_string) {
                                tempList[
                                  index
                                ].arrival_flight_date_string = `${moment(
                                  new Date()
                                ).format("MM-DD-YYYY")} ${value}`;
                              }
                              const dateTemp =
                                tempList[
                                  index
                                ].arrival_flight_date_string.split(" ");
                              tempList[index].arrival_flight_date_string = `${
                                dateTemp.length > 1
                                  ? dateTemp[0]
                                  : moment(new Date()).format("MM-DD-YYYY")
                              } ${value}`;
                              const newState = [...editInvitationData.guests];
                              newState[index].arrival_flight_date_string =
                                tempList[index].arrival_flight_date_string;
                              setEditInvitationData({
                                ...editInvitationData,
                                guests: newState,
                              });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <DatePicker
                            primary
                            label="Date"
                            isEmpty
                            value={
                              item.departure_flight_date_string
                                ? item.departure_flight_date_string
                                : ""
                            }
                            onChange={(newDate) => {
                              const tempList = [];
                              editInvitationData.guests.map((guest) =>
                                tempList.push(guest)
                              );
                              if (
                                !tempList[index].departure_flight_date_string
                              ) {
                                tempList[
                                  index
                                ].departure_flight_date_string = `${newDate} ${"00:00"}`;
                              }
                              const dateTemp =
                                tempList[
                                  index
                                ].departure_flight_date_string.split(" ");
                              tempList[
                                index
                              ].departure_flight_date_string = `${newDate} ${
                                dateTemp[1] ? dateTemp[1] : "00:00"
                              }`;
                              const newState = [...editInvitationData.guests];
                              newState[index].departure_flight_date_string =
                                tempList[index].departure_flight_date_string;
                              setEditInvitationData({
                                ...editInvitationData,
                                guests: newState,
                              });
                            }}
                          />
                          <TimePicker
                            primary
                            isEmpty
                            label="Time"
                            value={
                              item.departure_flight_date_string
                                ? moment
                                    .utc(item.departure_flight_date_string)
                                    .format("HH:mm")
                                : ""
                            }
                            onChange={(value) => {
                              const tempList = [];
                              editInvitationData.guests.map((guest) =>
                                tempList.push(guest)
                              );
                              if (
                                !tempList[index].departure_flight_date_string
                              ) {
                                tempList[
                                  index
                                ].departure_flight_date_string = `${moment(
                                  new Date()
                                ).format("MM-DD-YYYY")} ${value}`;
                              }
                              const dateTemp =
                                tempList[
                                  index
                                ].departure_flight_date_string.split(" ");
                              tempList[index].departure_flight_date_string = `${
                                dateTemp.length > 1
                                  ? dateTemp[0]
                                  : moment(new Date()).format("MM-DD-YYYY")
                              } ${value}`;
                              const newState = [...editInvitationData.guests];
                              newState[index].departure_flight_date_string =
                                tempList[index].departure_flight_date_string;
                              setEditInvitationData({
                                ...editInvitationData,
                                guests: newState,
                              });
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </InputWrapper>
              ))}
            </SideMenuCollapsible>
            {eventDetail.accommodation_status && (
              <SideMenuCollapsible
                id={3}
                openedId={openedCollapsibleId}
                openId={setOpenedCollapsibleId}
                title="Accommodation"
              >
                <InputWrapper>
                  <DatePicker
                    primary
                    isEmpty
                    label="Check-In Date"
                    value={
                      editInvitationData.accommodation_check_in_date_string
                    }
                    onChange={(newDate) => {
                      setEditInvitationData({
                        ...editInvitationData,
                        accommodation_check_in_date_string: newDate,
                      });
                    }}
                  />
                  <DatePicker
                    primary
                    isEmpty
                    label="Check-Out Date"
                    value={
                      editInvitationData.accommodation_check_out_date_string
                    }
                    onChange={(newDate) => {
                      setEditInvitationData({
                        ...editInvitationData,
                        accommodation_check_out_date_string: newDate,
                      });
                    }}
                  />
                  <Input
                    type="text"
                    label="Room Number"
                    name="roomNumber"
                    placeholder="Enter Room Number"
                    value={editInvitationData.accommodation_room_number}
                    onChange={(value) => {
                      setEditInvitationData({
                        ...editInvitationData,
                        accommodation_room_number: value,
                      });
                    }}
                  />
                </InputWrapper>
              </SideMenuCollapsible>
            )}
            <SideMenuCollapsible
              id={eventDetail.accommodation_status ? 4 : 3}
              openedId={openedCollapsibleId}
              openId={setOpenedCollapsibleId}
              title="Guest Category"
            >
              <BadgeSelect
                data={selectedCategories}
                setSelected={selectCategory}
                useIcon
              />
            </SideMenuCollapsible>
          </>
        )}
      </SideMenu>

      <ModalDday
        isOpen={openedSideMenuId === 5}
        onRequestClose={() => {
          setOpenedSideMenuId(null);
          setIsDownloadProgress(2);
        }}
        fileName={downloadFileName}
        isDownloadProgress={isDownloadProgress}
        downloadGuest={_exportToExcel}
        fakeLoading={fakeLoading}
        downloadExcel={downloadExcel}
        disabled={loading}
      />
      <Body>
        <Heading kind="title" text="Guest List" />
        <TopContainer>
          <TopLeftContainer>
            <SearchContainer>
              <Input
                isSearch
                type="text"
                name="searchCategory"
                value={searchTerm}
                icon={SearchSVG}
                onChange={(value) => {
                  setSearchTerm(value);
                  setPage(1);
                }}
                placeholder="Search by name or category"
              />
            </SearchContainer>
            <SortContainer>
              <Dropdown
                isFilter
                icon={SortSVG}
                title={filterString}
                data={filterItems}
                collapsed={filterCollapsed}
                onClick={() => {
                  setFilterCollapsed((prev) => !prev);
                  setSortCollapsed(false);
                }}
                onClickItems={(value) => {
                  setFilterCollapsed(false);
                  setFilter(value.value);
                  setFilterString(value.label);
                  setPage(1);
                }}
              />
            </SortContainer>
            <SortContainer>
              <Dropdown
                icon={SortSVG}
                title={sortString}
                data={sortItems}
                collapsed={sortCollapsed}
                onClick={() => {
                  setSortCollapsed((prev) => !prev);
                  setFilterCollapsed(false);
                }}
                onClickItems={(value) => {
                  setSortCollapsed(false);
                  setSort(value.value);
                  setSortString(value.label);
                  history.push(`/event/guest-list?type=${value.value}`);
                }}
              />
            </SortContainer>
          </TopLeftContainer>
          <TopRightContainer>
            <Button
              kind="icon"
              type="button"
              icon={GuestCategorySVG}
              onClick={() => setOpenedSideMenuId(3)}
            />
            <ButtonContainer>
              <Button
                kind="icon"
                type="button"
                icon={DownloadSVG}
                onClick={() => setOpenedSideMenuId(2)}
              />
              <Divider />
              <TooltipCard>
                <Button
                  kind="icon"
                  type="button"
                  icon={ExportSVG}
                  onClick={_handleOpenModalDownload}
                />
                <TooltipBox>
                  <Text>Export Guests</Text>
                </TooltipBox>
              </TooltipCard>
            </ButtonContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Add Guest"
              icon={AddSVG}
              onClick={() => setOpenedSideMenuId(1)}
            />
          </TopRightContainer>
        </TopContainer>
        <ContentContainer data={data}>
          {data.length ? (
            <OverflowTableControl>
              <Table
                cols={cols}
                data={dataTable}
                // data={data.slice((page - 1) * perPage, ((page) * perPage))}
                setData={setData}
                tempData={tempData}
                setTempData={setTempData}
                setShowFloatingMenu={setShowFloatingMenu}
                isSelectedAll={
                  dataTable.length &&
                  dataTable.filter((item) => item.checked).length ==
                    dataTable.length
                }
                horizontalScroll={eventDetail?.health_check_status}
                pagination
              />{" "}
            </OverflowTableControl>
          ) : (
            <EmptyGuestList />
          )}
          {Math.ceil(data.length / perPage) > 1 ? (
            <Pagination
              setPage={setPage}
              page={page}
              totalPage={Math.ceil(dataPagination.length / perPage)}
            />
          ) : null}
          {data.findIndex((item) => item.checked == true) > -1 && (
            <GuestListFloatingMenu
              isSubmiting={isSubmiting}
              data={data}
              tempData={tempData}
              searchTerm={searchTerm}
              setData={setData}
              setTempData={setTempData}
              setShowFloatingMenu={setShowFloatingMenu}
              setIsSelectedAll={setIsSelectedAll}
              availableCategories={selectedCategories}
              assignCategory={assignCategory}
              deleteInvitations={deleteInvitations}
              assignCategoryIntialize={assignCategoryIntialize}
              invitationInfo={invitationInfo}
              sendEmailInvitation={sendEmailInvitation}
              sendWAInvitation={sendWAInvitation}
              invitationFailType={invitationFailType}
              sendCovid19={sendCovid19}
            />
          )}
        </ContentContainer>
      </Body>
    </>
  );
};

const { bool, func } = PropTypes;

ExcelDownloaded.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

GuestList.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default GuestList;
