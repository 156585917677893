import styled from 'styled-components';

export const GridDashboard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:  ${(props) => props.gridTemplateColumn || '1fr'}} ;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-top: ${(props) => props.marginTop || 0};
`;

export const FlexColumnDashboard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  text-align: left;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.09375rem;
  margin-bottom:${(props) => ((props.marginBottom && props.marginBottom) || 0)};
`;

export const ToolTipText = styled.span`
  visibility: hidden;
  width: 22.5rem;
  background-color: white;
  color: #00000;
  text-align: left;
  border-radius: 0.375rem;
  padding: 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-weight: normal;
  margin-left: ${(props) => ((props.isLeft && '-21.875rem') || '-2.1875rem')};
  box-shadow:0.3125rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: ${(props) => (props.isLeft ? 'auto' : '50%')};
    right:${(props) => (props.isLeft ? '10%' : 'auto')};
    margin-left: -10.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;
export const ToolTip = styled.span`
  padding-left: 4px;
  position: relative;
  display: inline-block;
  &:hover {
    ${ToolTipText}{
      visibility: visible;
    }
  }
`;

export const Icon = styled.img`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
  top: 0.2rem;
`;

export const IconCustom = styled.img``;
export const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
