/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SVGInject from '@iconfu/svg-inject';
import ENDPOINTS from 'helpers/endpoints';
import { useLocation } from 'react-router-dom';
import { queryRead } from 'helpers/helpers';
import { axiosApiInstanceAdmin } from 'helpers/authenticationAdmin';
import {
  Heading,
  Input,
} from 'shared';
import {
  Table,
  TableLabel,
} from 'components';
import {
  Body,
  TopContainer,
  ContentContainer,
  EmptyGuestImageContainer,
  EmptyGuestImage,
  EmptyGuestText,
} from 'pages/admin/dashboard-admin/invoices/style';
import SearchSVG from 'assets/icons/search.svg';
import EmptyGuestSVG from 'assets/images/empty-guest.svg';

import {
  InvoiceDetail,
  InvoiceSent,
} from 'pages/admin/dashboard-admin/invoices/modal';

const EmptyOperatorList = () => (
  <EmptyGuestImageContainer>
    <EmptyGuestImage src={EmptyGuestSVG} style={{height:'130px',width:'130px',marginLeft:'auto',marginRight:'auto'}}/>
    <EmptyGuestText>
      Looks like you have not invited any guest to the event.
      <br />
      Lets start adding your guests!
    </EmptyGuestText>
  </EmptyGuestImageContainer>
);

const InvoicesList = ({ setIsLoading }) => {
  const { search } = useLocation();

  const [searchTerm, setSearchTerm] = useState('');

  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [sort, setSort] = useState('');

  const [showInvoiceDetail, setShowInvoiceDetail] = useState(false);
  const [showInvoiceSend, setShowInvoiceSend] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const getInvoiceList = async () => {
    axiosApiInstanceAdmin.get(`${ENDPOINTS('admin.invoice.invoice')}?type=${sort}`)
      .then(({ data: { value } }) => {
        setIsLoading(false);
        setData(JSON.parse(JSON.stringify(value)));
        setTempData(JSON.parse(JSON.stringify(value)));
      })
      .catch(() => {
        // error message
      });
  };

  const getInvoiceDetail = (rowData) => {
    const { _id: id } = rowData;

    axiosApiInstanceAdmin.get(`${ENDPOINTS('admin.invoice.invoice')}/${id}`)
      .then(({ data: { value } }) => {
        setSelectedInvoice(value);
        setShowInvoiceDetail(true);
      })
      .catch(() => {
        // error message
      });
  };

  const sendInvoice = () => {
    setIsSubmiting(true);
    const { _id: invoiceId } = selectedInvoice;
    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.invoice.invoice')}/${invoiceId}/sent`)
      .then(({ data: { m } }) => {
        setIsSubmiting(false);
        if (m === 'Done') {
          setSelectedInvoice({});
          setShowInvoiceSend(true);
          setShowInvoiceDetail(false);
          getInvoiceList();
        }
      })
      .catch(() => {
        setIsSubmiting(false);
        // error message
      });
  };

  const sendReceipt = () => {
    setIsSubmiting(true);
    const { _id: invoiceId } = selectedInvoice;
    const form = {
      is_paid: true,
    };
    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.invoice.invoice')}/${invoiceId}/payment`, form)
      .then(({ data: { m } }) => {
        setIsSubmiting(false);
        if (m === 'Done') {
          setSelectedInvoice({});
          setShowInvoiceSend(true);
          setShowInvoiceDetail(false);
          getInvoiceList();
        }
      })
      .catch(() => {
        setIsSubmiting(false);
        // error message
      });
  };

  useEffect(() => {
    SVGInject(document.querySelectorAll('img.injectable'));
    const { type } = queryRead(search);
    if (type) {
      setSort(type);
    }
    getInvoiceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInvoiceList();
    // eslint-disable-next-line
  }, [sort]);

  useEffect(() => {
    setData(tempData.filter((item) => item.eventName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      || item.code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));
    // eslint-disable-next-line
  },[searchTerm])

  const cols = [
    {
      title: 'Invoice Code',
      position: '',
      render: (rowData) => (<span>{rowData.code}</span>),
    },
    {
      title: 'Event Name',
      position: '',
      render: (rowData) => (
        <div style={{
          width: '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
        }}
        >
          {rowData.eventName}
        </div>
      ),
    },
    {
      title: 'Create Date',
      position: 'center',
      render: (rowData) => (<span>{moment(rowData.createdAt).format('DD-MM-YYYY')}</span>),
    },
    {
      title: 'Due Date',
      position: 'center',
      render: (rowData) => (<span>{moment(rowData.due_date).format('DD-MM-YYYY')}</span>),
    },
    {
      title: 'Type',
      position: '',
      render: (rowData) => (<span>{rowData.payment_type}</span>),
    },
    {
      title: 'Status',
      position: '',
      render: (rowData) => (
        <span>
          {rowData.is_sent
            ? (
              <TableLabel
                type="success"
                text="Send"
              />
            ) : (
              <TableLabel
                type="error"
                text="Not Send"
              />
            )}
        </span>
      ),
    },
    {
      title: 'Payment',
      position: '',
      render: (rowData) => (
        <span>
          {rowData.is_paid
            ? (
              <TableLabel
                type="success"
                text="Paid"
              />
            ) : (
              <TableLabel
                type="error"
                text="Unpaid"
              />
            )}
        </span>
      ),
    },
  ];

  return (
    <>
      <InvoiceDetail
        isOpen={showInvoiceDetail}
        onRequestClose={() => setShowInvoiceDetail(false)}
        selectedInvoice={selectedInvoice}
        sendInvoice={sendInvoice}
        sendReceipt={sendReceipt}
        isSubmiting={isSubmiting}
      />
      <InvoiceSent
        isOpen={showInvoiceSend}
        onRequestClose={() => setShowInvoiceSend(false)}
      />
      <Body>
        <Heading
          kind="title"
          text="Invoices"
        />
        <TopContainer>
          <Input
            isSearch
            type="text"
            name="searchCategory"
            value={searchTerm}
            icon={SearchSVG}
            onChange={setSearchTerm}
            placeholder="Search by code or event name"
          />
        </TopContainer>
        <ContentContainer data={data.length}>
          {data.length ? (
            <Table
              readOnly
              cols={cols}
              data={data}
              rowClickable
              rowFunc={getInvoiceDetail}
            />
          ) : (
            <EmptyOperatorList />
          )}
        </ContentContainer>
      </Body>
    </>
  );
};

const { bool, func } = PropTypes;

InvoicesList.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default InvoicesList;
