import styled from 'styled-components';

export const Icon = styled.img``;
export const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
`;
export const PaginationNumberContainer = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  display:flex;
  flex-direction:row;
  height:100%;
`;
export const PaginationPageTotal = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #262626;
`;

export const InputPage = styled.input`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #262626;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  margin-right: 4px;
  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;