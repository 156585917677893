/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { store } from 'store';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Button,
  Heading,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalButtonContainer,
  ModalClose,
  ModalContainer,
  ModalHeaderWithoutBadge,
  ListContainer,
  ListItem,
} from 'pages/guest-list/floating-menu/style';
import ExitSVG from 'assets/icons/exit.svg';
import DeleteConfirmSVG from 'assets/images/delete-confirm.svg';

const DeleteConfirmModal = ({
  isOpen,
  onRequestClose,
  deleteInvitations,
  data,
  isSubmiting,
  setShowDeleteFail,
  setDeleteDataFail,
}) => {
  const [deletedUser, setDeletedUser] = useState([]);

  useEffect(() => {
    setDeletedUser(data.reduce((acc, curr) => {
      if (curr.checked) {
        acc.push(curr);
      }
      return acc;
    }, []));
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <>
        <ModalClose
          src={ExitSVG}
          onClick={onRequestClose}
        />
        <ModalContainer>
          <ModalHeaderWithoutBadge>
            <Icon src={DeleteConfirmSVG} style={{height:'100px',width:'100%'}} />
            <Heading
              kind="subtitle"
              text=" Sure you want to delete the guest / guests from the event?"
              marginBottom="0.5rem"
            />
          </ModalHeaderWithoutBadge>
          <ListContainer>
            <Scrollbars
              autoHide
              style={{ minHeight: deletedUser.length > 3 ? '165px' : deletedUser.length === 2 ? '110px' : deletedUser.length === 1 ? '55px' : '0', width: '343px' }}
            >
              {deletedUser.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {item.name}
                </ListItem>
              ))}
            </Scrollbars>
          </ListContainer>
          <ModalButtonContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Back"
              onClick={onRequestClose}
            />
            <Button
              small
              kind="warning"
              type="button"
              text="Delete"
              onClick={() => deleteInvitations(
                setShowDeleteFail,
                setDeleteDataFail,
                onRequestClose,
              )}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ModalButtonContainer>
        </ModalContainer>
      </>

    </Modal>
  );
};

const {
  bool,
  func,
  shape,
  arrayOf,
  string,
} = PropTypes;



DeleteConfirmModal.propTypes = {
  isOpen: func.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf([shape({})]),
  isSubmiting: bool.isRequired,
  deleteInvitations: func.isRequired,
  setShowDeleteFail: func.isRequired,
  setDeleteDataFail: func.isRequired,
};

DeleteConfirmModal.defaultProps = {
  data: [],
};

export default DeleteConfirmModal;
