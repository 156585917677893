// eslint-disable-next-line import/prefer-default-export
import queryString from "query-string";
import XLSX from "xlsx";

export const capitalize = (word) => {
  var wordSplit = word.split(" ");
  wordSplit.map((item, index) => {
    wordSplit[index] = item.charAt(0).toUpperCase() + item.slice(1);
  });
  return wordSplit.join(" ");
};

export const queryRead = (search) => queryString.parse(search);

export const dateParse = (currDate, callback, newDate) => {
  const dateTemp = currDate.split(" ");
  const value = `${newDate} ${dateTemp[1]}`;
  return callback(value);
};

export const timeParse = (currDate, callback, newTime) => {
  const dateTemp = currDate.split(" ");
  const value = `${dateTemp[0]} ${newTime}`;
  return callback(value);
};

export const exportExcel = (data, fileName, multipleSheet = false) => {
  const stylingCols = (data, ws) => {
    const wscols = [];
    if (data[0]) {
      data[0].forEach((el, i) => {
        if (el === "No") wscols.push({ wch: 10 });
        wscols.push({ wch: 20 });
      });
    }
    return (ws["!cols"] = wscols);
  };

  const stylingRows = (data, ws) => {
    const wsrows = [];
    data[0].forEach((el, i) => {
      wsrows.push({ hpt: 25 });
    });
    return (ws["!rows"] = wsrows);
  };

  const createSheet = (data) => XLSX.utils.aoa_to_sheet(data);
  const new_workbook = XLSX.utils.book_new();
  if (multipleSheet) {
    const arrayData = [];
    const arrayWorksheet = [];
    data.map((item) => {
      arrayData.push(item.data);
      arrayWorksheet.push(createSheet(item.data));
    });
    arrayWorksheet.forEach((el, i) => {
      stylingCols(arrayData[i], el);
      stylingRows(arrayData[i], el);
      XLSX.utils.book_append_sheet(new_workbook, el, data[i].sheetName);
    });
  } else {
    const worksheet = createSheet(data);
    stylingCols(data, worksheet);
    stylingRows(data, worksheet);
    XLSX.utils.book_append_sheet(new_workbook, worksheet, `${fileName}`);
  }
  XLSX.writeFile(new_workbook, `${fileName}.xlsx`);
};

export const camelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};
