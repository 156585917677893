import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 50%;

  ${Heading.Heading} {
    position: relative;
    right: 1.5rem;
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  position: relative;
  right: 1.3125rem;
`;

export const Section = styled.div`
  width: 50%;
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LeftSection = styled.div`
  ${Heading.Heading} {
    font-family: 'GT Walsheim Pro', sans-serif;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.09375rem;
    margin: 0;
  }
`;
export const RightSection = styled.div``;

export const ButtonContainer = styled.div`
  text-align: right;
`;

export const Form = styled.form`
  ${Button.Button} {
    padding: 0.5rem 2.9375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
  }
`;
