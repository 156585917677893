import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SVGInject from "@iconfu/svg-inject";
import {
  AvailabilityContainer,
  Icon,
} from "components/table/availability/style";
import PersonSmallSVG from "assets/icons/person-small.svg";

const AvailabilityComponent = ({ text, primary = false }) => {
  const current = text.split("/")[0];
  const max = text.split("/")[1];
  const isFull = Number(current) >= Number(max);

  useEffect(() => {
    SVGInject(document.querySelectorAll("img.injectable"));
  }, []);

  return (
    <AvailabilityContainer isFull={isFull && primary}>
      <Icon className="injectable" src={PersonSmallSVG} />
      {isFull && primary ? "FULL" : text}
    </AvailabilityContainer>
  );
};

const { string } = PropTypes;

AvailabilityComponent.propTypes = {
  text: string.isRequired,
};

export default AvailabilityComponent;
