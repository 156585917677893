import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';
import {
  NotFoundContainer,
  Icon,
  IconBack,
  IconNext,
} from 'pages/check-in/guest-qris/style';
import BackQrisSVG from 'assets/icons/back-guestqris.svg';


const GuestQris = ({ qrisBca }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  
  const doneQRIS = () => {
    if (isLoading === false) {
      setIsLoading(true)
      axiosApiInstance.post(`${ENDPOINTS('customer.tab.sendGiftQRIS')}/${location.state.invId}`, {})
        .then(() => {
          setIsLoading(false)
          history.push('/check-in/guest-welcome');
        }).catch(() => {
          setIsLoading(false)
        })
    }
  }
  
  return (
    <>
      <NotFoundContainer>
        <IconBack src={BackQrisSVG} onClick={() => { history.goBack(); }} />
        <Icon
          src={qrisBca}
        />
        <IconNext src={BackQrisSVG} onClick={doneQRIS} />
      </NotFoundContainer>
    </>
  );
};

const { string } = PropTypes;

GuestQris.propTypes = {
  qrisBca: string.isRequired,
};

export default GuestQris;
