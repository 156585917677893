/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Button,
  Input,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalContainer,
  ModalClose,
  ModalHeaderWithoutBadge,
  ModalButtonContainer,
} from 'pages/admin/dashboard-admin/event-details/modal/change-pic/style';
import ExitSVG from 'assets/icons/exit.svg';
import TypeformSVG from 'assets/images/typeform.svg';

const ChangeTypeformIDModal = ({
  isOpen,
  onRequestClose,
  editTypeform,
  isSubmiting,
  setTypeformID,
  typeformID,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{ content: { overflow: 'visible' } }}
  >
    <>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon src={TypeformSVG} />

          <Heading
            kind="subtitle"
            text="Typeform ID"
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>
        {/* <ComboboxWhite
          list={operatorList}
          label="PIC"
          onChange={(value) => { setOperatorSelected(value); }}
        /> */}
         <Input
          type="text"
          label=""
          name="typeform"
          placeholder=""
          onChange={(value)=>{setTypeformID(value)}}
          value={typeformID}
        />
        <ModalButtonContainer>
          {/* <Button
            small
            kind="secondary"
            type="button"
            text="Back"
            onClick={onRequestClose}
          /> */}
          <Button
            small
            kind="primary"
            type="button"
            text="Confirm"
            onClick={editTypeform}
            disabled={isSubmiting}
            isLoading={isSubmiting}
          />

        </ModalButtonContainer>
      </ModalContainer>

    </>
  </Modal>
);

const { bool, func } = PropTypes;

ChangeTypeformIDModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

export default ChangeTypeformIDModal;
