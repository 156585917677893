import colors from "theme/colors";

export default {
  // noselect: `
  //   -webkit-touch-callout: none;
  //     -webkit-user-select: none;
  //      -khtml-user-select: none;
  //        -moz-user-select: none;
  //         -ms-user-select: none;
  //             user-select: none;
  // `,
  background: {
    primary: colors.gray.background,
  },
  brand: {
    primary: colors.black.brand,
  },
  heading: {
    title: colors.black.text1,
    subtitle: colors.black.text2,
  },
  form: {
    label: {
      primary: colors.gray.gray3,
    },
    input: {
      background: colors.white.white,
      border: {
        normal: "transparent",
        focus: colors.blue.active,
        error: colors.red.error,
      },
      value: colors.black.text1,
      disabled: colors.gray.gray5,
    },
    placeholder: colors.gray.gray4,
    error: colors.red.error,
    icon: {
      normal: colors.black.black,
      active: colors.blue.active,
    },
    button: {
      background: {
        primary: colors.green.primary,
        secondary: colors.gray.background,
        whatsapp: "#EFFAF6",
        email: "#E3F8FF",
        hover: colors.green.secondary,
        icon: {
          normal: colors.blue.select,
          hover: colors.blue.active,
        },
        disabled: colors.gray.gray5,
      },
      text: {
        primary: colors.white.white,
        secondary: colors.black.text2,
        hover: colors.green.secondary,
        whatsapp: "#219653",
        email: "#2F80ED",
        disabled: colors.gray.gray3,
      },
      border: {
        secondary: colors.green.primary,
        disabled: colors.gray.gray4,
      },
    },
  },
  border: {
    primary: colors.green.primary,
    light: colors.gray.gray4,
  },
  modal: {
    background: colors.gray.gray7,
    header: {
      background: colors.green.primary,
      text: colors.white.white,
    },
  },
  link: {
    normal: colors.blue.link,
    hover: colors.blue.hover,
  },
  profileButton: {
    background: colors.gold.gold,
    text: colors.white.white,
    dropdown: {
      background: {
        normal: colors.white.white,
        hover: colors.blue.select,
      },
      text: colors.black.text1,
      icon: {
        normal: colors.gray.gray4,
        hover: colors.green.primary,
      },
    },
  },
  piechart: {
    background: {
      gray: colors.gray.gray7,
      white: colors.white.white,
    },
    color: colors.gray.gray8,
  },
  fullreport: {
    border: colors.blue.select,
    font: {
      white: colors.white.white,
    },
    background: colors.green.primary,
  },
  card: {
    background: colors.white.white,
    category: {
      green: colors.green.primary,
      violet: colors.violet.violet,
      gold: colors.gold.gold,
    },
    border: {
      green: colors.blue.active,
      violet: colors.violet.secondary,
      gold: colors.gold.gold,
    },
    warning: {
      background: colors.red.light,
      text: colors.red.error,
    },
    boxShadow: {
      green: "0px 10px 30px rgba(170, 218, 228, 0.8)",
      violet: "0px 6px 30px #EABDE0",
      gold: "0px 10px 30px rgba(170, 218, 228, 0.8)",
    },
    title: colors.black.text1,
    dateAndTime: colors.black.text2,
    titleWhite: colors.white.white,
  },
  dashboardPost: {
    background: {
      green: colors.green.primary,
    },
    color: {
      white: colors.white.white,
      gray: colors.gray.gray1,
    },
  },
  guestRequest: {
    font: {
      text1: colors.black.text1,
      text2: colors.black.text2,
      gray3: colors.gray.gray3,
      green: colors.green.primary,
    },
    background: colors.white.white,
  },
  dashboardCard: {
    number: {
      big: colors.black.text1,
      small: colors.gray.gray3,
    },
    border: {
      warning: colors.red.error,
      available: colors.green.available,
    },
  },
  section: {
    info: {
      background: {
        green: colors.green.primary,
        violet: colors.violet.violet,
        gold: colors.gold.gold,
      },
      text: colors.white.white,
      border: colors.blue.active,
    },
    detail: {
      background: colors.white.white,
      heading: colors.black.text1,
      fieldName: colors.gray.gray3,
      value: colors.black.text1,
      border: colors.gray.gray5,
      title: colors.gray.gray1,
      subtitle: colors.black.text2,
    },
  },
  loading: {
    primary: colors.green.primary,
    empty: colors.gray.gray5,
  },
  list: {
    background: colors.blue.select,
    text: colors.green.primary,
  },
  contact: {
    background: colors.green.primary,
  },
  navbar: {
    background: colors.black.black,
    text: {
      primary: colors.white.white,
      secondary: colors.gray.gray4,
      mute: colors.black.text2,
      hover: colors.blue.select,
    },
  },
  checkin: {
    color: {
      white: colors.white.white,
      text1: colors.black.text1,
      text2: colors.black.text2,
      black: colors.black.black,
      gray3: colors.gray.gray3,
      gray5: colors.gray.gray5,
      gray7: colors.gray.gray7,
    },
  },
  empty: {
    text: colors.gray.gray3,
  },
  table: {
    background: colors.white.white,
    text: {
      header: colors.gray.gray3,
      body: colors.black.text1,
    },
    selected: colors.green.primary,
    checkbox: colors.gray.gray4,
    label: {
      default: {
        background: colors.gray.gray6,
        text: colors.black.text2,
      },
      error: {
        background: colors.red.light,
        text: colors.red.error,
      },
      success: {
        background: colors.green.tertiary,
        text: colors.green.success,
      },
      pending: {
        background: colors.yellow.yellow,
        text: colors.yellow.warning,
      },
      sent: {
        background: colors.blue.link,
        text: colors.blue.active,
      },
      read: {
        background: colors.green.tertiary,
        text: colors.green.success,
      },
    },
    button: {
      border: colors.gray.gray4,
      text: colors.blue.link,
    },
    floatingMenu: {
      background: {
        light: colors.white.white,
        dark: colors.black.text1,
      },
      text: {
        light: colors.black.text2,
        dark: colors.white.white,
      },
      button: {
        default: {
          background: colors.black.text2,
        },
        danger: {
          background: colors.red.error,
        },
        success: {
          background: colors.blue.link,
        },
      },
    },
  },
  sideMenu: {
    title: colors.green.primary,
    subtitle: colors.gray.gray3,
    border: colors.gray.gray4,
  },
  assigner: {
    icon: colors.blue.link,
    border: colors.gray.gray5,
    background: colors.white.white,
    title: colors.black.text2,
    value: colors.black.black,
    status: colors.gray.gray3,
    success: "#5BCE90",
    danger: "#EB5757",
    row: {
      hover: {
        assigned: colors.red.error,
        unassigned: colors.blue.link,
      },
    },
  },
  combobox: {
    border: colors.black.black,
    color: {
      text1: colors.black.text1,
    },
    options: {
      select: colors.blue.select,
    },
  },
};
