import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { store, ACTIONS } from 'store';
import {
  Button,
  Heading,
  Input,
  Link,
} from 'shared';
import {
  Brand,

  // Decoratives
  HalfBall,
  SemiCircle,
  Confetti,
} from 'components';
import {
  Container,
  Form,
  SubtitleContainer,
  ForgotPasswordContainer,
} from 'pages/sign-in/style';
import NoInternetModal from 'pages/sign-in/modal';

const SignIn = () => {
  const history = useHistory();
  const { dispatch } = useContext(store);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModalNoInternet, setShowModalNoInternet] = useState(false);

  useEffect(() => setErrorMessage(''), [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const schema = Joi.object({
        email: Joi.string()
          .required()
          .email({ tlds: { allow: false } })
          .error((err) => {
            if (err[0].code === 'string.empty') {
              return new Error('Please input your email');
            }
            return new Error('Your email format not valid');
          }),
        password: Joi.string()
          .required()
          .error(() => new Error('Please input your password')),
      });

      const value = await schema.validateAsync({ email, password });
      axiosApiInstance.post(ENDPOINTS('customer.authentication.signin'), value)
        .then((res) => {
          dispatch({ type: ACTIONS.addUser, payload: res.data.value.user });
          localStorage.setItem('access_token', res.data.value.userToken);
          if (res.data && res.data.value.user && res.data.value.user.type == 'admin') {
            history.push('/events');
          } else {
            history.push('/welcome');
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (err
            && err.response
            && err.response.data
            && err.response.data.m) {
            setErrorMessage(err.response.data.m);
          } else {
            setShowModalNoInternet(true);
          }
        });
    } catch (err) {
      setIsSubmitting(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <NoInternetModal
        isOpen={showModalNoInternet}
        onRequestClose={() => { setShowModalNoInternet(false); }}
      />
      <Brand />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Heading
            kind="title"
            text="Sign in"
          />
          <SubtitleContainer>
            <Heading
              kind="subtitle"
              text="Don't have an account?"
            />
            <Link
              to="/sign-up"
              text="Sign up"
            />
          </SubtitleContainer>
          <Input
            type="text"
            label="Email Address"
            name="email"
            value={email}
            onChange={setEmail}
            placeholder="Type your email address"
            errorMessage={errorMessage && ' '}
            errorStatus={errorMessage}
            disabled={isSubmitting}
            isForm
          />
          <Input
            type="password"
            label="Password"
            name="password"
            value={password}
            onChange={setPassword}
            placeholder="Type your password"
            errorMessage={errorMessage}
            errorStatus={errorMessage}
            disabled={isSubmitting}
            isForm
          />
          <Button
            kind="primary"
            type="submit"
            text="Sign In"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
          <ForgotPasswordContainer>
            <Link
              to="/forgot-password"
              text="Forgot Password?"
            />
          </ForgotPasswordContainer>
        </Form>
      </Container>

      {/* Decoratives *************************************** */}
      <HalfBall />
      <SemiCircle />
      <Confetti />
      {/* *************************************************** */}
    </>
  );
};

export default SignIn;
