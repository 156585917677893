import styled, { css } from 'styled-components';

export default {
  Container: styled.div``,

  Label: styled.label`
    font-family: 'GT Walsheim Pro', sans-serif;
    font-size: 0.875rem;
    color: ${(props) => props.theme.form.label.primary};
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    display: block;
    margin-bottom: 0.5rem;
  `,

  TextArea: styled.textarea`
    font-family: 'GT Walsheim Pro', sans-serif;
    background-color: ${(props) => props.theme.form.input.background};
    border: 1px solid ${(props) => props.theme.form.input.border.normal};
    width: 100%;
    margin-bottom: 1.25rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.625rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    resize: none;
    &::placeholder {
      color: ${(props) => props.theme.form.placeholder};
    }

    &:disabled {
      background-color: #ddd
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.form.input.border.focus};
    }

    ${(props) => props.error && css`
      border: 1px solid ${props.theme.form.input.border.error};

      &:focus {
        border: 1px solid ${props.theme.form.input.border.error};
      }
    `}
  `,

  ErrorMessageContainer: styled.div`
    position: relative;
  `,

  ErrorMessage: styled.small`
    font-family: 'GT Walsheim Pro', sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    position: relative;
    top: -1rem;
    color: ${(props) => props.theme.form.error};
  `,

  ErrorIcon: styled.img`
    position: relative;
    top: 0.25rem;
    margin-right: 0.25rem;
  `,
};
