import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Heading } from 'shared';
import { Brand, ProfileButton, Card } from 'components';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { EVENT_CATEGORIES } from 'helpers/constants';
import {
  Container,
  HeadingContainer,
  Icon,
  Section,
} from 'pages/event-history/style';
import LeftArrowSVG from 'assets/icons/left-arrow.svg';

const EventHistory = () => {
  const history = useHistory();
  const [eventHistory, setEventHistory] = useState([]);
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    axiosApiInstance.get(ENDPOINTS('customer.eventHistory.eventHistory'))
      .then(({ data: { value: { events } } }) => {
        setEventHistory(events);
      });
  }, []);

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <HeadingContainer>
          <Heading
            kind="title"
            text={(
              <p>
                <Icon src={LeftArrowSVG} />
                Event History
              </p>
            )}
            onClick={() => history.goBack()}
          />
        </HeadingContainer>
        <Section>
          {eventHistory.map((item) => (
            <Card
              category={item.category === 'Wedding' ? EVENT_CATEGORIES.wedding : EVENT_CATEGORIES.birthday}
              title={item.name}
              place={item.event_venue}
              time={moment.utc(item.event_date_string).format('DD/MM/YY - hh.mm')}
              onClick={() => history.push(`/event-history/${item._id}`)}
            />
          ))}

          {/* <Card
            category={EVENT_CATEGORIES.seminar}
            title="Lorem Ipsum Seminar"
            place="Jakarta"
            time="01/01/2020 - 10.00"
            onClick={() => history.push('/event-history/3')}
          /> */}
        </Section>
      </Container>
    </>
  );
};

export default EventHistory;
