/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Button,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalContainer,
  ModalClose,
  ModalHeaderWithoutBadge,
  ModalButtonContainer,
} from 'pages/admin/dashboard-admin/event-details/modal/event-cancel/style';
import DeleteConfirmSVG from 'assets/images/delete-confirm.svg';
import ExitSVG from 'assets/icons/exit.svg';

const EventCancel = ({
  isOpen,
  onRequestClose,
  cancelEvent,
  isSubmiting,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon src={DeleteConfirmSVG} />

          <Heading
            kind="subtitle"
            text="Are you sure wanna cancel this event?"
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>

        <ModalButtonContainer>
          <Button
            small
            kind="warning"
            type="button"
            text="Delete"
            onClick={cancelEvent}
            disabled={isSubmiting}
            isLoading={isSubmiting}
          />
        </ModalButtonContainer>
      </ModalContainer>

    </>
  </Modal>
);

const { bool, func } = PropTypes;

EventCancel.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

export default EventCancel;
