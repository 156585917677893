import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTSV2 from 'helpers/endpoints-v2';
import {
  Heading,
  Button,
} from 'shared';
import {
  GuestContainer,
  GuestContent,
  Card,
  CardText,
  LogoContainer,
  Icon,
} from 'pages/check-in/guest-table/style';
import Table from 'pages/check-in/guest-table/table';
import LogoFooterSVG from 'assets/images/logo-footer.svg';
import moment from 'moment';

const GuestTable = ({
  eventName, guestDetail, withVideo, setGuestDetail, guestList
}) => {
  const [tableList, setTableList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const cols = [
    {
      title: 'table number',
      render: (rowData) => rowData.tableName,
    },
    {
      title: 'pax number',
      render: (rowData) => rowData.pax,
    }];

  const history = useHistory();


  const unselectedList = guestList.reduce((acc, curr) => {
    if (!curr?.selected) {
      acc.push(curr._id)
    }
    return acc;
  }, []);

  const checkIn = () => {
    setIsloading(true)
    // eslint-disable-next-line no-underscore-dangle
    const time_string = moment(new Date()).format('YYYY-MM-DD HH:mm');
    const arrival_or_leave = guestDetail.arrival_status ? 'leave_time_string' : 'arrival_time_string';
    axiosApiInstance.post(`${ENDPOINTSV2('customer.tab.checkIn')}/${guestDetail._id}?${arrival_or_leave}=${time_string}`,
      { unArrivedGuestIds: unselectedList, booth_id: "Admin Check In" }
    )
      .then((res) => {
        setIsloading(false)
        setGuestDetail((prevState) => ({ ...prevState, order: res.data.value.order }));
        history.push('/check-in/guest-redenvelop');
      }).catch(err => {
        setIsloading(false)
      })
  };

  useEffect(() => {
    if (!Object.keys(guestDetail).length) {
      history.push('/check-in/guest-search');
    } else {
      const selectedList = guestList.reduce((acc, curr) => {
        if (curr?.selected) {
          acc.push(curr)
        }
        return acc;
      }, [])
      let tableFinal = selectedList.reduce((acc, curr) => {
        let checkDoubleTable = acc.findIndex(a => a.tableName === curr?.table?.name);
        if (curr?.table?.name) {
          if (checkDoubleTable === -1) {
            acc.push({
              tableName: curr.table.name,
              pax: 1
            })
          } else {
            acc[checkDoubleTable].pax++
          }
        }

        return acc
      }, [])
      setTableList(tableFinal);
    }
  }, [guestList, history]);

  return (
    <>
      <GuestContainer withVideo={withVideo}>
        <GuestContent>
          <Heading
            kind="subtitle"
            text={eventName}
          />
          <Card>
            <CardText>
              {tableList.length ? 'we have assigned table for you' : 'we haven’t assigned a table for you. Please go to admin desk.'}
            </CardText>

            {tableList.length ? <Table cols={cols} list={tableList} /> : ''}
            {tableList.length
              ? (
                <Button
                  kind="primary"
                  type="button"
                  text="Next"
                  onClick={checkIn}
                  disabled={isLoading}
                />
              )
              : (
                <Button
                  kind="primary"
                  type="button"
                  text="Back to Home"
                  onClick={() => history.push('/check-in/guest-welcome')}
                />
              )}

          </Card>
        </GuestContent>
      </GuestContainer>
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};

const { string, shape } = PropTypes;

GuestTable.propTypes = {
  eventName: string.isRequired,
  guestDetail: shape({}),
};

GuestTable.defaultProps = {
  guestDetail: {},
};

export default GuestTable;
