import styled from 'styled-components';
/* eslint no-mixed-operators: ["error",
{"groups": [["&", "|", "^", "~", "<<", ">>", ">>>"], ["&&", "||"]]}] */

export const DonutChart = styled.div`
  margin:${(props) => (props.center ? 'auto' : '0')};
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: ${(props) => props.size / 16}rem;
  height: ${(props) => props.size / 16}rem;
  background: ${(props) => (props.isGray && props.number < 50 && (props.isGradient ? 'radial-gradient(138.11% 138.11% at 57.41% 18.68%, #EBDCD5 0%, #C9B9B2 17%, #75625B 61%, #675650 76%, #5A4B47 86%)' : '#C9B9B2')) ||
  (!props.isGray && props.number < 50 && (props.isGradient ? 'radial-gradient(138.11% 138.11% at 57.41% 18.68%, #EBDCD5 0%, #C9B9B2 17%, #75625B 61%, #675650 76%, #5A4B47 86%)' : '#096B81')) ||
  (!props.isGray && props.number > 50 && (props.isGradient ? 'radial-gradient(138.11% 138.11% at 57.41% 18.68%, #EBDCD5 0%, #C9B9B2 17%, #75625B 61%, #675650 76%, #5A4B47 86%)' : '#C1934D')) ||
  props.theme.piechart.background.gray
};
`;
export const RingOne = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(${(props) => `0 ${props.size / 16}rem ${props.size / 2 / 16}rem 0`});
  -webkit-transform: rotate(${(props) => (props.number < 50 ? props.number / 100 * 360 + 90 : 90)}deg);
  transform:rotate(${(props) => (props.number < 50 ? props.number / 100 * 360 + 90 : 90)}deg);
  &::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    -webkit-transform: rotate(0deg);
    transform:rotate(0deg);
    background: ${(props) => (props.isGray && props.number < 50 && '#F4F4F4') ||
    (props.isGray && props.number > 50 && '#C9B9B2') || 
    (!props.isGray && props.number < 50 && '#C1934D') ||
    (!props.isGray && props.number > 50 && '#096B81') ||
    (props.isGradient ? 'radial-gradient(138.11% 138.11% at 57.41% 18.68%, #EBDCD5 0%, #C9B9B2 17%, #75625B 61%, #675650 76%, #5A4B47 86%)' : props.theme.piechart.color)} 0 0 no-repeat;
    background-position:initial;
    -webkit-transform: rotate(${(props) => (props.number < 50 ? (props.number / 100 * 360 + 90) : (360 - 90))}deg);
    transform:rotate(${(props) => (props.number < 50 ? (props.number / 100 * 360 + 90) : (360 - 90))}deg);
  }
`;

export const RingTwo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(${(props) => `0 ${props.size / 2 / 16}rem ${props.size / 16}rem 0`});
  -webkit-transform: rotate(${(props) => (props.number < 50 ? 0 : props.number / 100 * 360)}deg);
  transform: rotate(${(props) => (props.number < 50 ? 0 : props.number / 100 * 360)}deg);
  &::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    -webkit-transform: rotate(${(props) => (props.number < 50 ? 0 : 360 - (props.number / 100 * 360))}deg);
    transform:rotate(${(props) => (props.number < 50 ? 0 : 360 - (props.number / 100 * 360))}deg);
    background: ${(props) => (props.isGray && props.number < 50 && '#F4F4F4') ||
    (props.isGray && props.number > 50 && '#C9B9B2') || 
    (!props.isGray && props.number < 50 && '#C1934D') ||
    (!props.isGray && props.number > 50 && '#096B81') ||
    (props.isGradient ? 'radial-gradient(138.11% 138.11% at 57.41% 18.68%, #EBDCD5 0%, #C9B9B2 17%, #75625B 61%, #675650 76%, #5A4B47 86%)' : props.theme.piechart.color)} 0 0 no-repeat;
    background-position:initial;
}
`;

export const ChartCenter = styled.div`
  position: absolute;
  border-radius: 50%;
  top: ${(props) => props.width / 16}rem;
  left: ${(props) => props.width / 16}rem;
  width: ${(props) => (props.size - (props.width * 2)) / 16}rem;
  height: ${(props) => (props.size - (props.width * 2)) / 16}rem;
  background: ${(props) => props.backgroundColor};
`;

export const ChartNumber = styled.div`
  display: block;
  text-align: center;
  font-size: ${(props) => props.fontSize / 16}rem;
  line-height: ${(props) => (props.size - (props.width * 2)) / 16}rem;
  font-weight: ${(props) => props.fontWeight};
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.isBlack ? '#0A0A0A' : props.theme.piechart.color};
  &:after{
    content: "${(props) => props.number || 0}%";
  }
`;
