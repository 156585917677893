import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';

export const getAutoComplete = async (value) => {
  try {
    const response = await axiosApiInstance.get(`${ENDPOINTS('customer.gmap.autocomplete')}${value}`)
    const predictions = response?.data?.value?.data?.predictions || []
    return predictions.map((item) => { return { ...item, label: item.description } })
  } catch (err) {
    return [];
  }
}

export const geoCode = async (value) => {
  try {
    const response = await axiosApiInstance.get(`${ENDPOINTS('customer.gmap.geocode')}${value}`);
    return response?.data?.value?.data?.results[0] || {};
  } catch (err) {
    return {}
  }
}