import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';
import {
  Heading,
  Button,
} from 'shared';
import {
  GuestContainer,
  GuestContent,
  Card,
  CardText,
  ButtonContainer,
  LogoContainer,
  Icon,
} from 'pages/check-in/guest-redenvelop/style';
import LogoFooterSVG from 'assets/images/logo-footer.svg';
import { print } from 'helpers/print';

const GuestRedEnvelop = ({ eventName, guestDetail, withVideo, eventType, guestList, device }) => {

  const { name: guestName, order } = guestDetail;
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);




  const printSetup = () => {

    const selectedList = guestList.reduce((acc, curr) => {
      if (curr?.selected) {
        acc.push(curr)
      }
      return acc;
    }, [])
    if (eventType.toLowerCase() === 'seated') {
      let tableFinal = selectedList.reduce((acc, curr) => {
        let checkDoubleTable = acc.findIndex(a => a.tableName === curr?.table?.name);
        if (curr?.table?.name) {
          if (checkDoubleTable === -1) {
            acc.push({
              tableName: curr.table.name,
              pax: 1
            })
          } else {
            acc[checkDoubleTable].pax++
          }
        }

        return acc
      }, [])
      let tableAndPax = tableFinal.reduce((acc, curr) => {
        acc.pax += curr.pax
        acc.table.push(curr?.tableName)
        return acc;

      }, { table: [], pax: 0 })
      const joinTable = tableAndPax?.table.join(', ')
      print(device, eventType.toLowerCase(), order, guestName, tableAndPax.pax, joinTable)
    } else {
      print(device, eventType.toLowerCase(), order, guestName, selectedList.length || 0)
    }
  }

  const sendGift = (gift) => {
    setIsLoading(true)
    // eslint-disable-next-line no-underscore-dangle
    axiosApiInstance.put(`${ENDPOINTS('customer.tab.sendGift')}/${guestDetail._id}`, { send_gift: gift })
      .then(() => {
        if (gift == true) {
          setIsLoading(false);
          printSetup();
          setTimeout(() => {
            history.push('/check-in/guest-number');
          }, 500)
        } else {
          history.push('/check-in/guest-welcome');
        }
      }).catch(() => {
        setIsLoading(false)
      })
  };

  useEffect(() => {
    if (!Object.keys(guestDetail).length) {
      history.push('/check-in/guest-search');
    }
  }, [guestDetail, history]);

  return (
    <>
      <GuestContainer withVideo={withVideo}>
        <GuestContent>
          <Heading
            kind="subtitle"
            text={eventName}
          />
          <Card>
            <CardText>
              DO you bring gift / red envelope?
            </CardText>
            <ButtonContainer>
              <Button
                kind="secondary"
                type="button"
                text="No"
                onClick={() => { sendGift(false); }}
                disabled={isLoading}
                isLoading={isLoading}
              />
              <Button
                kind="primary"
                type="button"
                text="Yes"
                onClick={() => { sendGift(true); }}
                disabled={isLoading || !device}
                isLoading={isLoading}
              />
            </ButtonContainer>
          </Card>
        </GuestContent>
      </GuestContainer>
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};
const { string, shape } = PropTypes;

GuestRedEnvelop.propTypes = {
  eventName: string.isRequired,
  guestDetail: shape({}),
};

GuestRedEnvelop.defaultProps = {
  guestDetail: {},
};

export default GuestRedEnvelop;
