import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { store, ACTIONS } from 'store';
import { Heading, Button } from 'shared';
import {
  Brand,
  LoadingBar,
  ProfileButton,
  Card,
} from 'components';
import { EVENT_CATEGORIES } from 'helpers/constants';
import { capitalize } from 'helpers/helpers';
import {
  Container,
  HeadingContainer,
  Section,
  FooterContainer,
  FooterContent,
  Icon,
} from 'pages/events/steps/01/style';
import Step1of4SVG from 'assets/images/1-of-4.svg';

const Step1 = ({ setStep }) => {
  const { state, dispatch } = useContext(store);
  const [category, setCategory] = useState('');

  const nextStep = () => {
    dispatch({ type: ACTIONS.createEvent, payload: { category: capitalize(category) } });
    setStep((prevState) => prevState + 1);
  };

  const SelectedCategory = (value) => {
    setCategory(value);
    dispatch({ type: ACTIONS.createEvent, payload: { category: capitalize(value) } });
    setStep((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (_.has(state.eventForm, 'category')) {
      setCategory(state.eventForm.category.toLowerCase());
    }
  }, [state]);

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <HeadingContainer>
          <Heading
            kind="title"
            text="Event Category"
          />
        </HeadingContainer>
        <Section>
          <Card
            isActive={category === EVENT_CATEGORIES.wedding}
            headerOnly
            category={EVENT_CATEGORIES.wedding}
            onClick={() => SelectedCategory(EVENT_CATEGORIES.wedding)}
          />
          <Card
            isActive={category === EVENT_CATEGORIES.birthday}
            headerOnly
            category={EVENT_CATEGORIES.birthday}
            onClick={() => SelectedCategory(EVENT_CATEGORIES.birthday)}
          />
           <Card
            isActive={category === EVENT_CATEGORIES.holyMatrimony}
            headerOnly
            category={EVENT_CATEGORIES.holyMatrimony}
            onClick={() => SelectedCategory(EVENT_CATEGORIES.holyMatrimony)}
          />
        </Section>
        <FooterContainer>
          <FooterContent>
          <Button
            small
            kind="secondary"
            type="button"
            text="Back"
            onClick={() => setStep((prevState) => prevState - 1)}
          />
          <Icon src={Step1of4SVG} style={{width:'328px',height:'28px',margin:'auto'}}/>
          <Button
            small
            kind="primary"
            type="button"
            text="Next"
            onClick={nextStep}
            disabled={!category}
          />
          </FooterContent>
        </FooterContainer>
      </Container>
    </>
  );
};

const { func } = PropTypes;

Step1.propTypes = {
  setStep: func.isRequired,
};

export default Step1;
