import styled from "styled-components";

export const Summary = styled.table`
  width: 100%;
  background-color: ${(props) => props.theme.section.detail.background};
  tr: first-child td {
    padding-top: 0;
  }
`;

export const SummaryFieldName = styled.td`
  width: 40%;
  font-family: "GT Walsheim Pro", sans-serif;
  color: ${(props) => props.theme.section.detail.fieldName};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  vertical-align: middle !important;
`;

export const SummaryBody = styled.tbody``;

export const SummaryValue = styled.td`
  font-family: "GT Walsheim Pro", sans-serif;
  color: ${(props) => props.theme.section.detail.value};
  padding: 0.875rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.section.detail.border};
`;

export const SummaryRow = styled.tr`
  &:nth-last-child(1) {
    ${SummaryValue} {
      border-bottom-width: 0;
    }
  }
`;

export const SummaryHeader = styled.td`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.09365rem;
  text-transform: uppercase;
  padding: 0.875rem 0;
`;

export const SummaryTD = styled.td`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 14px;
  color: #727272;
`;
