import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import NextScrollSVG from "assets/icons/next-scroll.svg";

import {
  AnimationContainer,
  DescriptionContainer,
  DescriptionSubtitle,
  DescriptionTitle,
  Icon,
  LeftContainer,
  LeftToRight,
  RightContainer,
  RightToLeft,
  Session2,
  Session2Box,
  SessionContent,
} from "./style";
import { useInView } from "react-intersection-observer";

const Animations = ({
  title,
  icon,
  descriptionTitle,
  descriptionSubtitle,
  srcLink,
  rightToLeft = false,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <AnimationContainer>
      {rightToLeft ? (
        <RightToLeft active={inView ? true : false} moveOut={!inView} ref={ref}>
          <Session2>
            <LeftContainer>
              <SessionContent>
                <Session2Box>
                  {title} <Icon src={icon} />
                </Session2Box>
                <DescriptionContainer>
                  <DescriptionTitle>{descriptionTitle}</DescriptionTitle>
                  <DescriptionSubtitle>
                    {descriptionSubtitle}
                  </DescriptionSubtitle>
                </DescriptionContainer>
                <Icon src={NextScrollSVG} style={{ width: "60px" }} />
              </SessionContent>
            </LeftContainer>
            <RightContainer>
              <img src={srcLink} alt="img-1" />
            </RightContainer>
          </Session2>
        </RightToLeft>
      ) : (
        <LeftToRight active={inView ? true : false} moveOut={!inView} ref={ref}>
          <Session2>
            <RightContainer inverted>
              <img src={srcLink} alt="img-1" />
            </RightContainer>
            <LeftContainer>
              <SessionContent>
                <Session2Box inverted>
                  {title}
                  <Icon src={icon} />
                </Session2Box>
                <DescriptionContainer>
                  <DescriptionTitle>{descriptionTitle}</DescriptionTitle>
                  <DescriptionSubtitle>
                    {descriptionSubtitle}
                  </DescriptionSubtitle>
                </DescriptionContainer>
                <Icon src={NextScrollSVG} style={{ width: "60px" }} />
              </SessionContent>
            </LeftContainer>
          </Session2>
        </LeftToRight>
      )}
    </AnimationContainer>
  );
};

export default Animations;
