import styled from 'styled-components';

export const Icon = styled.img`transform:${(props) => ((props.up && 'scaleY(-1)') || 'scaleY(1)')};`;

export const Combobox = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  background-color: #FFFFFF;
  width: 100%;
  position: relative;
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  min-height: 2rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.2px;
  ${Icon}{
    position: absolute;
    right: 0.625rem;
    width: 1rem;

  }
  &:hover{
    background-color: #FFFFFF;
  }
  &:focus-within{
    background-color: #FFFFFF;
  }
`;

export const Select = styled.div`
  &::after{
    position: absolute;
    content: "";
    top: 0.875rem;
    right: 0.625rem;
    width: 0;
    height: 0;
  }
`;

export const Options = styled.div`
  position: absolute;
  background-color: white;
  top: 3rem;
  right: 0;
  z-index: 99;
  width: 100%;
  border-radius: 0.625rem;
  padding: 0.5rem 0;
`;

export const Item = styled.div`
  color: black;
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.combobox.color.text1};

  &:hover, &:focus{
    background-color:  ${(props) => props.theme.combobox.options.select};
  }
`;

export const Placeholder = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #BDBDBD;
`;

export const ComboboxLabel = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #727272;
  margin-bottom: 8px;
`;
