import styled, { keyframes, css } from "styled-components";
import { Link } from "react-router-dom";
import { Input, Button } from "shared/styles";
export const Icon = styled.img``;

export const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f4f4f4;
`;

const LtR = keyframes`
from {
  transform: translateX(100%);
  opacity:0;
}
to {
  transform: translateX(0%);
  opacity:1;
}
`;

const MoveOutRight = keyframes`
to {transform: translateX(-100%);}
`;

const RtL = keyframes`
from {
  transform: translateX(-100%);
  opacity:0;
}
to {
  transform: translateX(0%);
  opacity:1;
}
`;

const MoveOutLeft = keyframes`
to {transform: translateX(100%);}
`;

export const Title = styled.div`
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  text-align: ${(props) => (props.start ? "left" : "center")};
  color: #0a0a0a;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: ${(props) => (props.start ? "left" : "center")};
  color: #474747;
`;

export const Container = styled.div`
  font-family: GT Walsheim;
  max-width: 1340px;
  margin: 0 auto;
  background-color: #f4f4f4;
`;

export const NavbarContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  width: 100%;
  height: 88px;
  z-index: 10;
  @media (max-width: 1279px) {
    height: 60px;
  }
`;

export const NavbarContent = styled.div`
  width: 100%;
  padding: 0 28px;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: 1440px) {
    padding 0 120px;
  }
`;

export const NavbarMenu = styled.div`
  display: flex;
  gap: 32px;
  font-family: GT Walsheim;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const BurgerContainer = styled.div`
  display: none;
  @media (max-width: 1279px) {
    display: block;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const NavbarMenuItem = styled.div`
  cursor: pointer;
  &:hover {
    color: #096b81;
  }
`;

export const TopBanner = styled.div`
  display: flex;
  width: 100%;
  background-color: #f4f4f4;
  height: 100vh;
  flex-direction: column;
  scroll-snap-align: start;
`;

export const TopBannerContent = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: fit-content;
  ${Title} {
    max-width: 480px;
    @media (max-width: 1279px) {
      max-width: 500px;
    }
  }

  ${SubTitle} {
    max-width: 480px;
    @media (max-width: 1279px) {
      max-width: 500px;
    }
  }
`;

export const VideoContainer = styled.video`
  background-color: #f4f4f4;
  height: calc(100vh - 345px);
`;

export const Session = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  padding-bottom: 88px;
  background-color: #f4f4f4;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
`;

export const SecondBannerList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 100px;
  justify-content: center;
  @media (max-width: 1279px) {
    gap: 32px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const SecondBannerItem = styled.div`
  display: flex;
  gap: 12px;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 1279px) {
    font-style: normal;
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
  }
`;

export const SecondBannerVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 100px;
  @media (max-width: 1279px) {
    max-width: 640px;
    height: auto;
    margin: 0 auto;
  }
`;

export const SecondBannerVideo = styled.video`
  margin: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: transparent;
  width: 80vw;
`;

export const Session2Items = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
`;

export const RightContainer = styled.div`
  height: 100%;
  width: 50%;
`;

export const Session2 = styled.div`
  font-family: GT Walsheim;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

export const RightToLeft = styled.div`
  @media (max-width: 1279px) {
    display: none;
  }
  visibility: hidden;
  width: 100%;
  ${(props) =>
    props.active &&
    css`
      visibility: visible;
      animation: ${RtL} 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    `}
`;

export const LeftToRight = styled.div`
  @media (max-width: 1279px) {
    display: none;
  }
  visibility: hidden;
  width: 100%;
  ${(props) =>
    props.active &&
    css`
      visibility: visible;
      animation: ${LtR} 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    `}
`;

export const Session3 = styled.div`
  font-family: GT Walsheim;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

export const Session4 = styled.div`
  font-family: GT Walsheim;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 88px);
  width: 100%;
`;

export const Session5 = styled.div`
  font-family: GT Walsheim;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  height: calc(100vh + 300px);
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
`;

export const AnimationContainer = styled.div`
  background-color: #f4f4f4;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
`;

export const Body = styled.div`
  box-sizing: border-box;
  height: calc(100vh - 88px);
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  ${AnimationContainer} {
    @media (max-width: 1279px) {
      display: none;
    }
  }

  ${Session2} {
    ${LeftContainer} {
      background-color: #eaeaea;
    }
    ${RightContainer} {
      background-color: #096b81;
    }
  }

  ${Session3} {
    ${LeftContainer} {
      background-color: #eaeaea;
    }
    ${RightContainer} {
      background-color: #c1934d;
    }
  }

  ${Session4} {
    ${LeftContainer} {
      background-color: #eaeaea;
    }
    ${RightContainer} {
      background-color: #9b558c;
    }
  }
`;
export const SessionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  margin: 0 auto;
  width: 428px;
  height: calc(100% - 248px);
  justify-content: space-between;
`;

export const Session5Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: auto;
  overflow: hidden;
  max-width: 500px;
`;

export const Session2Box = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 48px;
  padding: 0 20px;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  flex-direction: row;
  width: fit-content;
  background: #096b81;
  border-radius: 5px;
  color: #ffffff;
`;

export const Session3Box = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 48px;
  padding: 0 20px;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  flex-direction: row;
  width: fit-content;
  background: #c1934d;
  border-radius: 5px;
  color: #ffffff;
`;

export const Session4Box = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 48px;
  padding: 0 20px;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  flex-direction: row;
  width: fit-content;
  background: #9b558c;
  border-radius: 5px;
  color: #ffffff;
`;

export const DescriptionContainer = styled.div`
  // white-space: pre;
  width: 500px;
  height: 200px;
  transition: ease 2000ms;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DescriptionTitle = styled.div`
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: #0a0a0a;
`;

export const DescriptionSubtitle = styled.div`
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #373f41;
  display: inline-block;
  text-align: justify;
  text-justify: inter-word;
  max-width: 100%;
  margin-bottom: 36px;
`;

export const TestimoniImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 504px;
  margin: 0 auto;
`;

export const TestimoniImageLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  ${Icon} {
    border-radius: 16px;
  }
`;

export const TestimoniImageRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 71px;
  flex: 1;
  ${Icon} {
    border-radius: 16px;
  }
`;

export const Session5Top = styled.div`
  padding: 20px 14px;
  display: flex;
  flex-direction: row;
  margin-bottom: 300px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

export const Session5Bot = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #0a0a0a;
  padding-top: 250px;
  padding-bottom: 63px;
  gap: 52px;
  scroll-snap-align: end;
  scroll-snap-stop: normal;
`;
export const SubscribeTitle = styled.div`
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  letter-spacing: 0.2px;
`;

export const ImageIllustration = styled.img`
  max-width: 45%;
  position: absolute;
  right: 0;
  height: 100%;
  object-fit: cover;
`;

export const SubscribeContent = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SubscribeContainer = styled.div`
  width : 90%;
  max-height: 300px;
  position relative;
  padding: 28px 32px;
  display:flex;
  justify-content: space-between;
  align-items:center;
  margin:0 auto;
  border-radius: 20px;
  background-color: #F4F4F4;
  margin-top: -370px;
  filter: drop-shadow(0px -3px 12px rgba(0, 0, 0, 0.01)), drop-shadow(0px 30px 100px rgba(0, 0, 0, 0.15));
  box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.01), 0px 30px 100px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    background-image: url(${(props) => props.bgMobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    ${SubscribeTitle} {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.2px;
    }
    ${ImageIllustration} {
      display: none;
    }
    ${SubscribeContent} {
      width: 100%;
    }
  }
`;

export const SubscribeInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  ${Input.Input} {
    width: 260px;
  }
  ${Button.Button} {
    max-width: 129px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 16px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContactContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1340px;
  padding: 0 100px;
  margin: 0 auto;
  color: white;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 28px;
  }
`;

export const ContactLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const ContactRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const Session1Mobile = styled.div`
  display: none;
  position: relative;
  height: calc(100vh - 88px);
  padding: 80px 0 34px;
  background-color: #096b81;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    padding: 80px 0 34px;
    align-items: center;
    ${Title} {
      font-family: GT Walsheim;
      font-style: normal;
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #f4f4f4;
      width: 428px;
    }

    ${SubTitle} {
      font-family: GT Walsheim;
      font-style: normal;
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #f4f4f4;
      width: 428px;
    }
  }
  @media (max-width: 767px) {
    display: flex;
  }
`;

export const LeftTab = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: -100px;
  height: 60px;
  width: 240px;

  justify-content: center;
  align-items: center;
  color: #096b81;
  background-color: #f4f4f4;
  transform: rotate(270deg);

  font-family: GT Walsheim;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 2px;
  text-transform: uppercase;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 21px;

  gap: 12px;
`;
export const Session2Mobile = styled.div`
  display: none;
  position: relative;
  height: calc(100vh - 88px);
  padding: 80px 0 34px;
  background-color: #c1934d;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  @media (max-width: 1279px) {
    ${LeftTab} {
      color: #c1934d;
    }
    display: flex;
    flex-direction: column;
    padding: 80px 0 34px;
    align-items: center;
    ${Title} {
      font-family: GT Walsheim;
      font-style: normal;
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #f4f4f4;
      width: 428px;
    }

    ${SubTitle} {
      font-family: GT Walsheim;
      font-style: normal;
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #f4f4f4;
      width: 428px;
    }
  }
  @media (max-width: 767px) {
    display: flex;
  }
`;

export const Session3Mobile = styled.div`
  display: none;
  position: relative;
  height: calc(100vh - 88px);
  padding: 80px 0 34px;
  background-color: #9b558c;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  @media (max-width: 1279px) {
    ${LeftTab} {
      color: #9b558c;
    }
    display: flex;
    flex-direction: column;
    padding: 80px 0 34px;
    align-items: center;
    ${Title} {
      font-family: GT Walsheim;
      font-style: normal;
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #f4f4f4;
      width: 428px;
    }

    ${SubTitle} {
      font-family: GT Walsheim;
      font-style: normal;
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #f4f4f4;
      width: 428px;
    }
  }
  @media (max-width: 767px) {
    display: flex;
  }
`;

export const Session5TopMobile = styled.div`
  display: none;
  flex-direction: column;
  margin-bottom: 240px;
  padding: 45px;

  @media (max-width: 1279px) {
    display: flex;
    gap: 80px;
  }
`;

export const ImageScroll = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-y: scroll;
  white-space: nowrap;
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  @media (max-width: 426px) {
    ${Icon} {
      width: 140px;
      height: 140px;
    }
  }
`;

export const TestimoniContainer = styled.div`
  font-family: GT Walsheim;
  margin: 0 auto;
  @media (max-width: 1279px) {
    max-width: 480px;
  }
  @media (max-width: 767px) {
    max-width: 320px;
  }
`;

export const TestimoniText = styled.div`
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
`;

export const TestimoniName = styled.div`
  margin-top: 36px;
  font-style: normal;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.2px;
`;

export const TestimoniStatus = styled.div`
  font-style: italic;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-top: 12px;
`;

export const SlideShowDot = styled.div`
  text-align: ${(props) => (props.center ? "center" : "left")};
`;

export const Dot = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: ${(props) => (props.active ? "#096B81" : "#C4C4C4")};
`;

export const ButtonContainer = styled.div`
  max-width: 264px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  border: 1px solid #096b81;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;

  ${SubTitle} {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
`;

export const ImageContainer = styled.img`
  width: 20px;
  height: 20px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  @media (min-width: 767px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
  }
`;
