import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GuestRoute, ProtectedRoute } from "helpers/authentication";
import {
  AdminGuestRoute,
  AdminProtectedRoute,
} from "helpers/authenticationAdmin";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import {
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
  Welcome,
  Settings,
  EventHistory,
  EventHistoryDetail,
  Events,
  Event,
  CheckIn,
  VerifyEmail,
  UserVerification,
  Home,
  PrivacyPolicy,
  TypeForm,
} from "pages";
import {
  ForgotPasswordAdmin,
  ResetPasswordAdmin,
  SignInAdmin,
  DashboardAdmin,
} from "pages/admin";

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <GuestRoute exact path="/" component={Home} />
        <GuestRoute path="/privacy-policy" component={PrivacyPolicy} />
        <GuestRoute path="/forgot-password" component={ForgotPassword} />
        <GuestRoute path="/reset-password/:userID" component={ResetPassword} />
        <GuestRoute path="/sign-in" component={SignIn} />
        <GuestRoute path="/sign-up" component={SignUp} />
        <GuestRoute path="/verify-email" component={VerifyEmail} />
        <GuestRoute
          path="/user-verification/:id"
          component={UserVerification}
        />
        <Route path="/form/:id" component={TypeForm} />
        <ProtectedRoute path="/welcome" component={Welcome} />
        <ProtectedRoute
          path="/event-history/:id"
          component={EventHistoryDetail}
        />
        <ProtectedRoute path="/event-history" component={EventHistory} />
        <ProtectedRoute path="/events" component={Events} />
        <ProtectedRoute path="/event" component={Event} />
        <ProtectedRoute path="/check-in" component={CheckIn} />
        <ProtectedRoute path="/settings" component={Settings} />
        <AdminGuestRoute
          path="/admin/forgot-password"
          component={ForgotPasswordAdmin}
        />
        <AdminGuestRoute
          path="/admin/reset-password/:userID"
          component={ResetPasswordAdmin}
        />
        <AdminGuestRoute path="/admin/sign-in" component={SignInAdmin} />
        <AdminProtectedRoute
          path="/admin/dashboard"
          component={DashboardAdmin}
        />
      </Switch>
    </Router>
  </ThemeProvider>
);

export default App;
