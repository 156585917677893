/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from "react";
import { store } from "store";
import PropTypes from "prop-types";
import {
  FloatingMenu,
  Selected,
  ButtonMenu,
  Icon,
  FloatingMenuContainer,
} from "pages/guest-list/floating-menu/style";
import FloatingMenuCloseSVG from "assets/icons/floating-menu-close.svg";
import GuestInfoSVG from "assets/icons/guest-info.svg";
import SendInvitationSVG from "assets/icons/send-invitation.svg";
import SendHealthCheckSVG from "assets/icons/send-health-check.svg";
import AssignCategorySVG from "assets/icons/assign-category.svg";
import DeleteTableSVG from "assets/icons/delete-table.svg";

import InfoModal from "pages/guest-list/floating-menu/info-modal";
import ConfirmDetailModal from "pages/guest-list/floating-menu/confirmation-detail-modal";
import InvitationStatusModal from "pages/guest-list/floating-menu/invitation-status-modal";
import QrCodeStatusModal from "pages/guest-list/floating-menu/qrcode-status-modal";
import InvitationModal from "pages/guest-list/floating-menu/invitation-modal";
import InvitationFailModal from "pages/guest-list/floating-menu/invitation-fail-modal";
import AssignCategoryModal from "pages/guest-list/floating-menu/assign-category-modal";
import DeleteConfirmModal from "pages/guest-list/floating-menu/delete-confirm-modal";
import DeleteFailModal from "pages/guest-list/floating-menu/delete-fail-modal";
import CovidModal from "pages/guest-list/floating-menu/covid19-modal";

const FloatingMenuComponent = ({
  data,
  tempData,
  setTempData,
  searchTerm,
  setData,
  setShowFloatingMenu,
  availableCategories,
  assignCategory,
  deleteInvitations,
  assignCategoryIntialize,
  invitationInfo,
  sendEmailInvitation,
  sendWAInvitation,
  invitationFailType,
  isSubmiting,
  sendCovid19,
}) => {
  const {
    state: { eventDetail },
  } = useContext(store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmDetail, setShowConfirmDetail] = useState(false);
  const [showInvitationStatus, setShowInvitationStatus] = useState(false);
  const [showQrCodeStatus, setShowQrCodeStatus] = useState(false);
  const [confirmDetailIndex, setConfirmDetailIndex] = useState(0);
  const [showInvitation, setShowInvitation] = useState(false);
  const [showInvitationFail, setShowInvitationFail] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteFail, setShowDeleteFail] = useState(false);
  const [showAssigner, setShowAssigner] = useState(false);
  const [showCovid, setShowCovid] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const [deleteDataFail, setDeleteDataFail] = useState([]);
  const closeModal = () => setIsModalOpen(false);

  const unSelectAll = () => {
    setShowFloatingMenu(false);
    const tempList = [];
    data.forEach((item) => {
      console.log("id", item);
      tempList.push({ ...item, checked: false });
    });
    tempList.forEach((item) => {
      item.checked = false;
    });
    setData(tempList);
    setTempData(tempList);
  };

  useEffect(() => {
    let tempCount = 0;
    if (!searchTerm) {
      data.forEach((item) => {
        if (item.checked === true) {
          tempCount += 1;
        }
      });
    } else {
      tempData.forEach((item) => {
        if (item.checked === true) {
          tempCount += 1;
        }
      });
    }
    setNumSelected(tempCount);
  }, [data]);

  return (
    <FloatingMenuContainer>
      <InfoModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        data={data}
        openConfirmDetail={(index) => {
          setConfirmDetailIndex(index);
          setIsModalOpen(false);
          setShowConfirmDetail(true);
        }}
        openInvitationStatus={(index) => {
          setConfirmDetailIndex(index);
          setIsModalOpen(false);
          setShowInvitationStatus(true);
        }}
        openQrCodeStatus={(index) => {
          setConfirmDetailIndex(index);
          setIsModalOpen(false);
          setShowQrCodeStatus(true);
        }}
      />
      <InvitationStatusModal
        isOpen={showInvitationStatus}
        confirmDetailIndex={confirmDetailIndex}
        onRequestClose={() => {
          setShowInvitationStatus(false);
          setIsModalOpen(true);
        }}
        data={data}
      />
      <QrCodeStatusModal
        isOpen={showQrCodeStatus}
        confirmDetailIndex={confirmDetailIndex}
        onRequestClose={() => {
          setShowQrCodeStatus(false);
          setIsModalOpen(true);
        }}
        data={data}
      />
      <ConfirmDetailModal
        isOpen={showConfirmDetail}
        confirmDetailIndex={confirmDetailIndex}
        onRequestClose={() => {
          setShowConfirmDetail(false);
          setIsModalOpen(true);
        }}
        data={data}
      />
      <InvitationModal
        isOpen={showInvitation}
        onRequestClose={() => {
          setShowInvitation(false);
        }}
        invitationInfo={invitationInfo}
        setShowInvitationFail={setShowInvitationFail}
        sendEmailInvitation={sendEmailInvitation}
        sendWAInvitation={sendWAInvitation}
        data={data}
        isSubmiting={isSubmiting}
      />
      <InvitationFailModal
        isOpen={showInvitationFail}
        onRequestClose={() => {
          setShowInvitationFail(false);
        }}
        invitationInfo={invitationInfo}
        sendEmailInvitation={sendEmailInvitation}
        sendWAInvitation={sendWAInvitation}
        invitationFailType={invitationFailType}
        data={data}
      />
      <AssignCategoryModal
        isOpen={showAssigner}
        onRequestClose={() => {
          setShowAssigner(false);
        }}
        availableCategories={availableCategories}
        data={data}
        assignCategory={assignCategory}
        isSubmiting={isSubmiting}
      />
      <DeleteConfirmModal
        isOpen={showDeleteConfirm}
        onRequestClose={() => {
          setShowDeleteConfirm(false);
        }}
        deleteInvitations={deleteInvitations}
        data={data}
        isSubmiting={isSubmiting}
        setShowDeleteFail={setShowDeleteFail}
        setDeleteDataFail={setDeleteDataFail}
      />
      <DeleteFailModal
        isOpen={showDeleteFail}
        onRequestClose={() => {
          setShowDeleteFail(false);
        }}
        failData={deleteDataFail}
      />
      <CovidModal
        isOpen={showCovid}
        onRequestClose={() => setShowCovid(false)}
        data={data}
        sendCovid19={sendCovid19}
        isSubmiting={isSubmiting}
      />
      <FloatingMenu>
        <Selected>
          <Icon src={FloatingMenuCloseSVG} onClick={unSelectAll} />
          {`${numSelected} Guest Selected`}
        </Selected>
        <ButtonMenu onClick={() => setIsModalOpen(true)}>
          <Icon src={GuestInfoSVG} />
          Guest Info
        </ButtonMenu>
        <ButtonMenu
          onClick={() => {
            setShowInvitation(true);
          }}
        >
          <Icon src={SendInvitationSVG} />
          Send Invitation
        </ButtonMenu>
        {eventDetail?.health_check_status ? (
          <ButtonMenu
            onClick={() => {
              setShowCovid(true);
            }}
          >
            <Icon src={SendHealthCheckSVG} />
            Send Health Check
          </ButtonMenu>
        ) : (
          <></>
        )}
        <ButtonMenu
          onClick={() => {
            setShowAssigner(true);
            assignCategoryIntialize();
          }}
        >
          <Icon src={AssignCategorySVG} />
          Assign Category
        </ButtonMenu>
        <ButtonMenu
          danger
          onClick={() => {
            setShowDeleteConfirm(true);
          }}
        >
          <Icon src={DeleteTableSVG} />
          Delete Guest
        </ButtonMenu>
      </FloatingMenu>
    </FloatingMenuContainer>
  );
};

const { bool, func, shape, arrayOf, string } = PropTypes;

FloatingMenuComponent.propTypes = {
  data: arrayOf(shape({})),
  tempData: arrayOf(shape({})),
  searchTerm: string,
  setData: func,
  setShowFloatingMenu: func,
  availableCategories: arrayOf(shape({})),
  assignCategory: func.isRequired,
  deleteInvitations: func.isRequired,
  assignCategoryIntialize: func.isRequired,
  sendEmailInvitation: func.isRequired,
  sendWAInvitation: func.isRequired,
  isSubmiting: bool,
  invitationInfo: arrayOf(shape({})),
  invitationFailType: string,
  sendCovid19: func,
};

FloatingMenuComponent.defaultProps = {
  data: [],
  tempData: [],
  searchTerm: "",
  setData: () => {},
  setShowFloatingMenu: () => {},
  availableCategories: [],
  isSubmiting: false,
  invitationInfo: [],
  invitationFailType: "",
  sendCovid19: () => {},
};

export default FloatingMenuComponent;
