/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVGInject from '@iconfu/svg-inject';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { queryRead } from 'helpers/helpers';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstanceAdmin } from 'helpers/authenticationAdmin';

import {
  Heading,
  Input,
} from 'shared';
import {
  Table,
  TableLabel,
  TableApproval,
  TableButton,
  Combobox,
} from 'components';
import {
  Body,
  TopContainer,
  ContentContainer,
  EmptyGuestImageContainer,
  EmptyGuestImage,
  EmptyGuestText,
  TopRightContainer,
} from 'pages/admin/dashboard-admin/event-list/style';
import SearchSVG from 'assets/icons/search.svg';
import EmptyGuestSVG from 'assets/images/empty-guest.svg';

const EmptyOperatorList = () => (
  <EmptyGuestImageContainer>
    <EmptyGuestImage src={EmptyGuestSVG} style={{height:'130px',width:'130px',marginLeft:'auto',marginRight:'auto'}}/>
    <EmptyGuestText>
      Looks like you have not invited any guest to the event.
      <br />
      Lets start adding your guests!
    </EmptyGuestText>
  </EmptyGuestImageContainer>
);

const EventList = ({ setIsLoading }) => {
  const history = useHistory();
  const { search } = useLocation();

  const cols = [
    {
      title: 'Event Name',
      position: '',
      render: (rowData) => (
        <div style={{
          width: '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
        }}
        >
          {rowData.name}
        </div>
      ),
    },
    {
      title: 'Date',
      position: '',
      render: (rowData) => (<span>{moment.utc(rowData.event_date_string).format('DD-MM-YYYY')}</span>),
    },
    {
      title: 'Guest Req',
      position: 'center',
      render: (rowData) => (<div style={{ width: '100%', textAlign: 'center' }}>{rowData.guestRequestCount}</div>),
    },
    {
      title: 'Invoice Status',
      position: '',
      render: (rowData) => (
        <span>
          {rowData.invoice_status
            ? (
              <TableLabel
                type="success"
                text="Compelete"
              />
            ) : (
              <TableLabel
                type="error"
                text="Incompelete"
              />
            )}
        </span>
      ),
    },
    {
      title: 'Approval',
      position: 'center',
      render: (rowData) => (
        <span>
          {' '}
          <TableApproval status={rowData.approvalStatus} />
        </span>
      ),
    },
    {
      title: 'PIC',
      position: '',
      render: (rowData) => (
        <span>
          {' '}
          {rowData.adminName ? rowData.adminName : '-'}
        </span>
      ),
    },
    {
      render: (rowData) => (
        <TableButton
          text="Event Details"
          onClick={() => history.push(`/admin/dashboard/event/${rowData._id}`)}
        />
      ),
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');

  // data
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [filterData, setFilterData] = useState({ value: '', label: 'All' });
  const [sort, setSort] = useState({});

  const EventListFilter = [
    { value: '', label: 'All' },
    { value: 'Active', label: 'Active' },
    { value: 'Cancel', label: 'Cancel' },
    { value: 'Finish', label: 'Finish' },
    { value: 'events needs approval', label: 'Events Needs Approval' },
    { value: 'total guest request', label: 'Total Guest Request' },
  ];

  // functions
  const getEventList = () => {
    axiosApiInstanceAdmin.get(`${ENDPOINTS('admin.event.event')}?type=${filterData.value}`)
      .then(({ data: { value } }) => {
        setData(JSON.parse(JSON.stringify(value)));
        setTempData(JSON.parse(JSON.stringify(value)));
        setIsLoading(false);
      })
      .catch(() => {
        // error message
      });
  };

  useEffect(() => {
    setIsLoading(true);
    SVGInject(document.querySelectorAll('img.injectable'));

    // eslint-disable-next-line
  }, []);

  const { type } = queryRead(search);
  useEffect(() => {
    if (type) {
      const temp = EventListFilter.filter((item) => item.value === type);
      if (temp && temp.length) {
        setSort(temp[0]);
      } else {
        setSort({ value: '', label: 'All' });
      }
    }
  }, [type]);
  useEffect(() => {
    setFilterData(sort);
  }, [sort]);

  useEffect(() => {
    console.log('cek');
    getEventList();
    // eslint-disable-next-line
  }, [filterData]);

  useEffect(() => {
    setData(tempData.filter((item) => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      || (item.adminName && item.adminName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)));
    // eslint-disable-next-line
  },[searchTerm])

  return (
    <>

      <Body>
        <Heading
          kind="title"
          text="Event List"
        />
        <TopContainer>
          <Input
            isSearch
            type="text"
            name="searchCategory"
            value={searchTerm}
            icon={SearchSVG}
            onChange={setSearchTerm}
            placeholder="Search by name or role"
          />
          <TopRightContainer>

            <Combobox
              placeholder="All"
              list={EventListFilter}
              value={filterData.value}
              onChange={(value) => {
                setFilterData(value);
              }}
            />
          </TopRightContainer>
        </TopContainer>
        <ContentContainer data={data}>
          {data.length ? (
            <Table
              readOnly
              cols={cols}
              data={data}
            />
          ) : (
            <EmptyOperatorList />
          )}
        </ContentContainer>
      </Body>
    </>
  );
};

const { bool, func } = PropTypes;

EventList.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default EventList;
