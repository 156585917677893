import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'reset-css/reset.css';
import GlobalStyle from 'theme/global';
import * as serviceWorker from 'serviceWorker';
import { StateProvider } from 'store';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <StateProvider>
      <App />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
