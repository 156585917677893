import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MobileSection, PageAnimation } from "components";
import { Input, Button } from "shared";
import {
  Icon,
  Container,
  NavbarContainer,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  TopBanner,
  TopBannerContent,
  Title,
  SubTitle,
  VideoContainer,
  Session,
  SecondBannerList,
  SecondBannerItem,
  SecondBannerVideoContainer,
  SecondBannerVideo,
  Session2Items,
  LeftContainer,
  RightContainer,
  Session2,
  Body,
  SessionContent,
  Session2Box,
  DescriptionContainer,
  DescriptionTitle,
  DescriptionSubtitle,
  Session3,
  Session3Box,
  Session4,
  Session4Box,
  Session5,
  Session5Top,
  Session5TopMobile,
  Session5Bot,
  Session5Content,
  TestimoniImageContainer,
  TestimoniImageLeft,
  TestimoniImageRight,
  RightToLeft,
  LeftToRight,
  SubscribeContainer,
  SubscribeContent,
  SubscribeTitle,
  SubscribeInputContainer,
  ContactContainer,
  ContactLeft,
  ContactRight,
  BurgerContainer,
  Session1Mobile,
  Session2Mobile,
  Session3Mobile,
  LeftTab,
  AnimationContainer,
  ImageScroll,
  TestimoniContainer,
  TestimoniText,
  TestimoniName,
  TestimoniStatus,
  SlideShowDot,
  Dot,
  ImageContainer,
  ButtonContainer,
  ImageIllustration,
  Text,
} from "pages/home/style";
import LogoSVG from "assets/icons/new-logo.svg";
import CheckSVG from "assets/icons/check.svg";
import PreEventSVG from "assets/icons/pre-event.svg";
import Home1SVG from "assets/images/home1.svg";
import Home2SVG from "assets/images/home2.svg";
import Home3SVG from "assets/images/home3.svg";
import Home4SVG from "assets/images/home4.svg";
import BurgerSVG from "assets/icons/burger.svg";
import PlaybackSVG from "assets/icons/playback.svg";
import DDaySVG from "assets/icons/dday.svg";
import IGSVG from "assets/icons/ig.svg";
import SpotifySVG from "assets/icons/spotify.svg";
import BackdropPNG from "assets/images/backdrop.png";
import BackdropMobilePNG from "assets/images/backdrop-mobile.png";
import PreEventPNG from "assets/images/image-1.png";
import CheckInPNG from "assets/images/image-2.png";
import ReportPNG from "assets/images/image-3.png";
import NextScrollSVG from "assets/icons/next-scroll.svg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { useRef } from "react";

const Home = () => {
  const history = useHistory();
  const { REACT_APP_WA_NUMBER, REACT_APP_WA_NUMBER_PREVIEW, REACT_APP_EMAIL } =
    process.env;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [offSetHeight, setOffsetHeight] = useState(0);
  const [animation1JSON, setAnimation1JSON] = useState({});
  const [animation2JSON, setAnimation2JSON] = useState({});
  const [animation3JSON, setAnimation3JSON] = useState({});
  const [animation4JSON, setAnimation4JSON] = useState({});
  const [animation5JSON, setAnimation5JSON] = useState({});
  const [animation6JSON, setAnimation6JSON] = useState({});
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const videoRef = useRef(null);
  const delay = 2500;
  const [testi, setTesti] = useState([]);

  const testimonies = [
    {
      description: `“EventCo has been revolutionary! We are delighted with how easy it is to use and lorem ipsum dolor.”`,
      name: "Fenny Palijama",
      status: "Kenisha Wedding Organizer",
    },
    {
      description: `“Great App !!.”`,
      name: "Kevin Himawan",
      status: "EventCo",
    },
    {
      description: `“Saya sangat senang bisa ikut serta dalam acara ini.”`,
      name: "Rizky",
      status: "Wedding Organizer",
    },
  ];

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === testimonies.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
      setTesti(testimonies[index]);
    };
  }, [index]);

  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    let handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);

    observer.observe(videoRef.current);
  }, [videoRef]);

  // useEffect(() => {
  //   // window.addEventListener('resize', ()=>{console.log('cek')})
  //   const currentOffsetHeight =
  //     document.getElementById("scrollcek").offsetHeight;
  //   setOffsetHeight(currentOffsetHeight);
  //   document.getElementById("scrollcek").addEventListener("scroll", () => {
  //     if (
  //       currentOffsetHeight * 2 ==
  //         document.getElementById("scrollcek").scrollTop ||
  //       currentOffsetHeight * 3 ==
  //         document.getElementById("scrollcek").scrollTop ||
  //       currentOffsetHeight * 4 ==
  //         document.getElementById("scrollcek").scrollTop ||
  //       currentOffsetHeight * 5 ==
  //         document.getElementById("scrollcek").scrollTop ||
  //       currentOffsetHeight * 6 ==
  //         document.getElementById("scrollcek").scrollTop ||
  //       currentOffsetHeight * 7 ==
  //         document.getElementById("scrollcek").scrollTop
  //     ) {
  //       console.log(document.getElementById("scrollcek").scrollTop);
  //       setScrollPosition(document.getElementById("scrollcek").scrollTop);
  //     }
  //   });

  //   // (async () => {
  //   //   try{
  //   //     const res = await getVideo(1);
  //   //     setAnimation1JSON(res)
  //   //     // await getVideo(2);
  //   //     // await getVideo(3);
  //   //     // await getVideo(4);
  //   //     // await getVideo(5);
  //   //     // await getVideo(6);
  //   //   } catch(err){
  //   //     setAnimation1JSON({})
  //   //   }

  //   // })()

  //   Promise.all([
  //     getVideo(1),
  //     getVideo(2),
  //     getVideo(3),
  //     getVideo(4),
  //     getVideo(5),
  //     getVideo(6),
  //   ])
  //     .then((res) => {
  //       if (res[0]) {
  //         setAnimation1JSON(res[0]);
  //       } else {
  //         setAnimation1JSON({});
  //       }
  //       if (res[1]) {
  //         setAnimation2JSON(res[1]);
  //       } else {
  //         setAnimation2JSON({});
  //       }
  //       if (res[2]) {
  //         setAnimation3JSON(res[2]);
  //       } else {
  //         setAnimation3JSON({});
  //       }
  //       if (res[3]) {
  //         setAnimation4JSON(res[3]);
  //       } else {
  //         setAnimation4JSON({});
  //       }
  //       if (res[4]) {
  //         setAnimation5JSON(res[4]);
  //       } else {
  //         setAnimation5JSON({});
  //       }
  //       if (res[5]) {
  //         setAnimation6JSON(res[5]);
  //       } else {
  //         setAnimation6JSON({});
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // return () => window.removeEventListener('scroll');
  // }, [scrollPosition, offSetHeight]);

  // const getVideo = (number) => {
  //   try {
  //     return axios.get(`https://eventco.b-cdn.net/${number}.json`);
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  return (
    <>
      <NavbarContainer>
        <NavbarContent>
          <NavbarMenu>
            <NavbarMenuItem>Features</NavbarMenuItem>
            <NavbarMenuItem>FAQ</NavbarMenuItem>
          </NavbarMenu>
          <Icon src={LogoSVG} />
          <BurgerContainer>
            <Icon src={BurgerSVG} />
          </BurgerContainer>
          <NavbarMenu>
            <NavbarMenuItem>Contact</NavbarMenuItem>
            <NavbarMenuItem
              onClick={() => {
                history.push("/sign-in");
              }}
            >
              Sign-in
            </NavbarMenuItem>
          </NavbarMenu>
        </NavbarContent>
      </NavbarContainer>
      <Body id="scrollcek">
        <TopBanner>
          <Container>
            <TopBannerContent>
              <Title>The Seamless Guest Management Service</Title>
              <SubTitle>
                From pre-event to the day of, we got you covered! Leave your
                email or WhatsApp number for us to contact 🤙🏻
              </SubTitle>
            </TopBannerContent>
          </Container>
          <VideoContainer
            autoPlay
            loop
            src="https://storage.googleapis.com/assets.eventcompany.id/video/Hero_Video_Test.mp4"
          />
          <SecondBannerList>
            <SecondBannerItem>
              <Icon src={CheckSVG} />
              <div>WhatsApp RSVP</div>
            </SecondBannerItem>
            <SecondBannerItem>
              <Icon src={CheckSVG} />
              <div>Table Seating Management</div>
            </SecondBannerItem>
            <SecondBannerItem>
              <Icon src={CheckSVG} />
              <div>QR Code Check-In</div>
            </SecondBannerItem>
            <SecondBannerItem>
              <Icon src={CheckSVG} />
              <div>Digital Angpao</div>
            </SecondBannerItem>
          </SecondBannerList>
        </TopBanner>
        <Session>
          <SecondBannerVideo
            loop
            controls
            ref={videoRef}
            src={
              "https://storage.googleapis.com/assets.eventcompany.id/video/Eventco%20Campaign%20Video.mp4"
            }
          />
        </Session>
        <PageAnimation
          rightToLeft
          title="Pre-Event"
          icon={PreEventSVG}
          descriptionTitle="Online Reservation and Confirmation"
          descriptionSubtitle="Manage guest RSVP through our integrated multi-channel platform."
          srcLink={PreEventPNG}
        />
        <PageAnimation
          title="D-Day!"
          icon={DDaySVG}
          descriptionTitle="Gift & Angbao Collect Service"
          descriptionSubtitle="Receiving gift/angbao becomes more practical through a
                      well-integrated guest list record and available through
                      both manual and online payment systems."
          srcLink={CheckInPNG}
        />
        <PageAnimation
          rightToLeft
          title="POST-EVENT 🔖"
          descriptionTitle="Event Reports"
          descriptionSubtitle="Get your wrap-up report once your event is over! Report
                      will be sent to your email or you can download it in our
                      Web Dashboard."
          srcLink={ReportPNG}
        />
        {/* <AnimationContainer>
          <RightToLeft
            active={inView ? true : false}
            moveOut={!inView}
            ref={ref}
          >
            <Session2>
              <LeftContainer>
                <SessionContent>
                  <Session2Box>
                    Pre-Event <Icon src={PreEventSVG} />
                  </Session2Box>
                  <DescriptionContainer>
                    <DescriptionTitle>
                      Online Reservation and Confirmation
                    </DescriptionTitle>
                    <DescriptionSubtitle>
                      Manage guest RSVP through our integrated multi-channel
                      platform.
                    </DescriptionSubtitle>
                  </DescriptionContainer>
                  <Icon src={NextScrollSVG} style={{ width: "60px" }} />
                </SessionContent>
              </LeftContainer>
              <RightContainer>
                <Player
                  autoplay
                  loop
                  src={"https://eventco.b-cdn.net/1.json"}
                  style={{ height: "auto", width: "500px" }}
                />
              </RightContainer>
            </Session2>
          </RightToLeft>
        </AnimationContainer> */}
        {/* <AnimationContainer>
          <LeftToRight
            active={offSetHeight * 3 == scrollPosition}
            moveOut={offSetHeight * 3 < scrollPosition}
          >
            <Session2>
              <RightContainer>
                <Player
                  autoplay
                  loop
                  src={"https://eventco.b-cdn.net/2.json"}
                  style={{ height: "auto", width: "550px" }}
                />
              </RightContainer>
              <LeftContainer>
                <SessionContent>
                  <Session2Box>
                    Pre-Event <Icon src={PreEventSVG} />
                  </Session2Box>
                  <DescriptionContainer>
                    <DescriptionTitle>
                      Table Seating Management
                    </DescriptionTitle>
                    <DescriptionSubtitle>
                      Spend less time stuck in spreadsheets, assign guests to
                      tables straight from your guest list.
                    </DescriptionSubtitle>
                  </DescriptionContainer>
                  <Icon src={NextScrollSVG} style={{ width: "60px" }} />
                </SessionContent>
              </LeftContainer>
            </Session2>
          </LeftToRight>
        </AnimationContainer> */}
        {/* <AnimationContainer>
          <LeftToRight
            active={inView ? true : false}
            moveOut={offSetHeight * 3 < scrollPosition}
            ref={ref}
          >
            <Session3>
              <RightContainer>
                <Player
                  autoplay
                  loop
                  src={"https://eventco.b-cdn.net/3.json"}
                  style={{ height: "auto", width: "500px" }}
                />
              </RightContainer>
              <LeftContainer>
                <SessionContent>
                  <Session3Box>
                    D-Day! <Icon src={DDaySVG} />
                  </Session3Box>
                  <DescriptionContainer>
                    <DescriptionTitle>
                      Event Check-In with QR Code
                    </DescriptionTitle>
                    <DescriptionSubtitle>
                      Guests scan their barcode in the registration area and
                      receive their table number right away.
                    </DescriptionSubtitle>
                  </DescriptionContainer>
                  <Icon src={NextScrollSVG} style={{ width: "60px" }} />
                </SessionContent>
              </LeftContainer>
            </Session3>
          </LeftToRight>
        </AnimationContainer> */}
        {/* <AnimationContainer>
          <LeftToRight
            active={offSetHeight * 5 == scrollPosition}
            moveOut={offSetHeight * 5 < scrollPosition}
          >
            <Session3>
              <LeftContainer>
                <SessionContent>
                  <Session3Box>
                    D-Day! <Icon src={DDaySVG} />
                  </Session3Box>
                  <DescriptionContainer>
                    <DescriptionTitle>
                      Gift & Angbao Collect Service
                    </DescriptionTitle>
                    <DescriptionSubtitle>
                      Receiving gift/angbao becomes more practical through a
                      well-integrated guest list record and available through
                      both manual and online payment systems.
                    </DescriptionSubtitle>
                  </DescriptionContainer>
                  <Icon src={NextScrollSVG} style={{ width: "60px" }} />
                </SessionContent>
              </LeftContainer>
              <RightContainer>
                <Player
                  autoplay
                  loop
                  src={"https://eventco.b-cdn.net/4.json"}
                  style={{ height: "auto", width: "550px" }}
                />
              </RightContainer>
            </Session3>
          </LeftToRight>
        </AnimationContainer> */}
        {/* <AnimationContainer>
          <RightToLeft
            active={offSetHeight * 6 == scrollPosition}
            moveOut={offSetHeight * 6 < scrollPosition}
          >
            <Session3>
              <RightContainer>
                <Player
                  autoplay
                  loop
                  src={"https://eventco.b-cdn.net/5.json"}
                  style={{ height: "auto", width: "550px" }}
                />
              </RightContainer>
              <LeftContainer>
                <SessionContent>
                  <Session3Box>
                    D-Day! <Icon src={DDaySVG} />
                  </Session3Box>
                  <DescriptionContainer>
                    <DescriptionTitle>Real-Time Guest Counter</DescriptionTitle>
                    <DescriptionSubtitle>
                      Monitor arriving guest in real-time and get to know how
                      many guests haven’t checked in yet.
                    </DescriptionSubtitle>
                  </DescriptionContainer>
                  <Icon src={NextScrollSVG} style={{ width: "60px" }} />
                </SessionContent>
              </LeftContainer>
            </Session3>
          </RightToLeft>
        </AnimationContainer> */}
        {/* <AnimationContainer>
          <RightToLeft
            active={true}
            moveOut={offSetHeight * 4 < scrollPosition}
          >
            <Session4>
              <LeftContainer>
                <SessionContent>
                  <Session4Box>POST-EVENT 🔖</Session4Box>
                  <DescriptionContainer>
                    <DescriptionTitle>Event Report</DescriptionTitle>
                    <DescriptionSubtitle>
                      Get your wrap-up report once your event is over! Report
                      will be sent to your email or you can download it in our
                      Web Dashboard.
                    </DescriptionSubtitle>
                  </DescriptionContainer>
                  <Icon src={NextScrollSVG} style={{ width: "60px" }} />
                </SessionContent>
              </LeftContainer>
              <RightContainer>
                <Player
                  autoplay
                  loop
                  src={"https://eventco.b-cdn.net/6.json"}
                  style={{ height: "auto", width: "550px" }}
                ></Player>
              </RightContainer>
            </Session4>
          </RightToLeft>
        </AnimationContainer> */}

        {/* mobile */}
        <MobileSection
          color="primary"
          title="PRE-EVENT"
          icon={PreEventSVG}
          caption="Online Reservation and Confirmation"
          subCaption="Manage guest RSVP through our integrated multi-channel platform."
          video="https://eventco.b-cdn.net/1.json"
        />
        <MobileSection
          color="secondary"
          title="D-DAY"
          icon={DDaySVG}
          caption="Gift & Angbao Collect Service"
          subCaption="Receiving gift/angpao becomes more practical through a well-integrated guest list record and available through both manual and online payment systems."
          video="https://eventco.b-cdn.net/3.json"
        />
        <MobileSection
          title="POST-EVENT 🔖"
          caption="Event Report"
          subCaption="Get your wrap-up report once your event is over! The detailed report will be sent to your email or you can download it from your event dashboard."
          video="https://eventco.b-cdn.net/6.json"
        />
        {/* <Session1Mobile>
          <LeftTab>
            PRE-EVENT <Icon src={PreEventSVG} />
          </LeftTab>

          <div>
            <Title>Online Reservation and Confirmation</Title>
            <SubTitle>
              Manage guest RSVP through our integrated multi-channel platform.
            </SubTitle>
          </div>
          <div>
            <Player
              autoplay
              loop
              src={"https://eventco.b-cdn.net/1.json"}
              style={{ height: "auto", width: "500px" }}
            />
          </div>
        </Session1Mobile>
        <Session1Mobile>
          <LeftTab>
            PRE-EVENT <Icon src={PreEventSVG} />
          </LeftTab>

          <div>
            <Title>Table Seating Management </Title>
            <SubTitle>
              Spend less time stuck in spreadsheets, assign guests to tables
              straight from your guest list.
            </SubTitle>
          </div>
          <div>
            <Player
              autoplay
              loop
              src={"https://eventco.b-cdn.net/2.json"}
              style={{ height: "auto", width: "500px" }}
            />
          </div>
        </Session1Mobile>
        <Session2Mobile>
          <LeftTab>
            D-DAY <Icon src={DDaySVG} />
          </LeftTab>

          <div>
            <Title>Event Check-In with QR Code</Title>
            <SubTitle>
              SGuests scan their barcode in the registration area and receive
              their table number right away.
            </SubTitle>
          </div>
          <div>
            <Player
              autoplay
              loop
              src={"https://eventco.b-cdn.net/3.json"}
              style={{ height: "auto", width: "500px" }}
            />
          </div>
        </Session2Mobile>
        <Session2Mobile>
          <LeftTab>
            D-DAY <Icon src={DDaySVG} />
          </LeftTab>

          <div>
            <Title>Gift & Angbao Collect Service </Title>
            <SubTitle>
              Receiving gift/angbao becomes more practical through a
              well-integrated guest list record and available through both
              manual and online payment systems.
            </SubTitle>
          </div>
          <div>
            <Player
              autoplay
              loop
              src={"https://eventco.b-cdn.net/4.json"}
              style={{ height: "auto", width: "500px" }}
            />
          </div>
        </Session2Mobile>
        <Session2Mobile>
          <LeftTab>
            D-DAY <Icon src={DDaySVG} />
          </LeftTab>

          <div>
            <Title>Real-Time Guest Counter </Title>
            <SubTitle>
              Monitor arriving guest in real-time and get to know how many
              guests haven’t checked in yet.
            </SubTitle>
          </div>
          <div>
            <Player
              autoplay
              loop
              src={"https://eventco.b-cdn.net/5.json"}
              style={{ height: "auto", width: "500px" }}
            />
          </div>
        </Session2Mobile>
        <Session3Mobile>
          <LeftTab>POSt-EVENT 🔖</LeftTab>

          <div>
            <Title>Table Seating Management </Title>
            <SubTitle>
              Spend less time stuck in spreadsheets, assign guests to tables
              straight from your guest list.
            </SubTitle>
          </div>
          <div>
            <Player
              autoplay
              loop
              src={"https://eventco.b-cdn.net/6.json"}
              style={{ height: "auto", width: "500px" }}
            />
          </div>
        </Session3Mobile> */}

        <Session5>
          <Session5Top>
            <LeftContainer>
              <Session5Content>
                <DescriptionContainer
                // style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  <DescriptionSubtitle>{testi.description}</DescriptionSubtitle>
                  <Title start>
                    {testi.name} <br />
                  </Title>
                  <SubTitle start>{testi.status}</SubTitle>
                </DescriptionContainer>
                <SlideShowDot>
                  {testimonies.map((testimony, idx) => (
                    <Dot
                      active={index === idx}
                      onClick={() => {
                        setIndex(idx);
                        setTesti(testimony);
                      }}
                    />
                  ))}
                </SlideShowDot>
                <ButtonContainer>
                  <SubTitle>Watch Testimonial Video</SubTitle>
                  <ImageContainer src={PlaybackSVG} />
                </ButtonContainer>
              </Session5Content>
            </LeftContainer>
            <RightContainer>
              <TestimoniImageContainer>
                <TestimoniImageLeft>
                  <div
                    style={{
                      height: "240px",
                      width: "240px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <Icon
                      src={Home1SVG}
                      style={{ height: "340px", width: "340px" }}
                    />
                  </div>
                  <Icon src={Home3SVG} />
                </TestimoniImageLeft>
                <TestimoniImageRight>
                  <Icon src={Home2SVG} />
                  <Icon src={Home4SVG} />
                </TestimoniImageRight>
              </TestimoniImageContainer>
            </RightContainer>
          </Session5Top>

          <Session5TopMobile>
            <ImageScroll>
              <Icon src={Home1SVG} />
              <Icon src={Home3SVG} />
              <Icon src={Home2SVG} />
              <Icon src={Home4SVG} />
            </ImageScroll>
            <TestimoniContainer>
              <TestimoniText>{testi.description}</TestimoniText>
              <TestimoniName>{testi.name}</TestimoniName>
              <TestimoniStatus>{testi.status}</TestimoniStatus>
            </TestimoniContainer>
            <SlideShowDot center>
              {testimonies.map((testimony, idx) => (
                <Dot
                  active={index === idx}
                  onClick={() => {
                    setIndex(idx);
                    setTesti(testimony);
                  }}
                />
              ))}
            </SlideShowDot>
          </Session5TopMobile>
          <Session5Bot>
            <SubscribeContainer bgMobile={BackdropMobilePNG}>
              <SubscribeContent>
                <SubscribeTitle>Ready to get started?</SubscribeTitle>
                <SubscribeInputContainer>
                  <Input type="text" placeholder="Leave your WhatsApp number" />
                  <Button text="Contact Me" kind="primary" type="button" />
                </SubscribeInputContainer>
              </SubscribeContent>
              <ImageIllustration src={BackdropPNG} />
            </SubscribeContainer>
            <ContactContainer>
              <ContactLeft>
                {/* <div
                  style={{
                    paddingRight: "32px",
                    borderRight: "1px solid white",
                    marginRight: "32px",
                  }}
                >
                  Contact
                </div>
                <div
                  onClick={() => {
                    history.push("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </div> */}
                <Text>Contact</Text>
                <Text>|</Text>
                <Text>Privacy Policy</Text>
              </ContactLeft>
              <ContactRight>
                <Icon src={IGSVG} />
                <Icon src={SpotifySVG} />
              </ContactRight>
            </ContactContainer>
          </Session5Bot>
        </Session5>
      </Body>
    </>
  );
};

export default Home;
