import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListContainer, ListItem } from 'pages/check-in/guest-search/list/style';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  TableCheckbox,
} from 'components';

const List = ({ data, setData }) => {
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  return (
    <ListContainer>
      <Scrollbars
        autoHide
        style={{ minHeight: (data.length > 1 && '160px') || (data.length === 1 && '119px') || '0' }}
      >
        <ListItem
          onClick={() => setIsSelectedAll((prevState) => {
            setData(data.reduce((acc, curr) => {
              acc.push({
                ...curr,
                selected: !prevState
              })
              return acc;
            }, [])
            )
            return !prevState
          })}
        >
          <TableCheckbox
            checked={isSelectedAll}

          />
          <div>
            ALL ATTEND
          </div>

        </ListItem>
        {data.map((item, index) => {
          return (<>
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => {
                setData((prevState) => {

                  const nextState = prevState.reduce((acc, curr, currIdx) => {
                    if (currIdx == index) {
                      acc.push({ ...curr, selected: !curr?.selected })
                    } else {
                      acc.push(curr)
                    }
                    return acc;
                  }, [])
                  setIsSelectedAll(nextState.findIndex(item => item?.selected == false) == -1 ? true : false)

                  return nextState;
                })
              }}
            >
              <TableCheckbox
                checked={item?.selected}
              />
              {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',width:'100%' }}> */}
                <div>
                  {item.name}
                </div>
                {/* <div style={{color:'#727272'}}>
                  {item?.table?._id ? 'Assigned' : 'Unassigned'}
                </div> */}
              {/* </div> */}

            </ListItem>
          </>
          )
        })}

      </Scrollbars>
    </ListContainer>
  );
}
const {
  arrayOf,
  func,
  oneOfType,
  object,
  string,
} = PropTypes;

List.propTypes = {
  selectName: func,
  data: arrayOf(oneOfType([object, string])),
};

List.defaultProps = {
  selectName: () => { },
  data: [],
};

export default List;
