import styled, { css } from 'styled-components';

export const Icon = styled.img`
    margin-top: 0.8rem;
    width: 1rem;
    height: 1rem;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.heading.title};
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.01875rem;
  border: 0;
  display: inline-block;
  height: 2.5rem;
  &::placeholder {
    color: #BDBDBD;
  }
  ${(props) => props.disabled && css`
    background-color: ${props.theme.section.detail.background};
    cursor: pointer;
  `}
`;

export const InputContainers = styled.div`
  border-width:0;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 2.75rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Text = styled.div`
    font-family: 'GT Walsheim Pro', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem
`

export const Title = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.6rem;
  color: #096B81;
`;

export const SubTitleDashboard = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  color: #0A0A0A;
  font-size:0.8rem;
  line-height: 1.2rem;
  letter-spacing:0.01rem;
`;

export const ButtonIcon = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow:hidden;
  width: 100%;
  // height:${(props) => ((props.isDashboard && '100%') || '11rem')};
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.section.detail.background};
  padding: 0.6rem 0.8rem;
  display: flex;
  gap: 0.6rem;
  align-items:${(props) => ((props.isDashboard && 'top') || 'center')};
  flex-direction:${(props) => ((props.isDashbaord && 'row') || 'row')};
  cursor: pointer;
  ${(props) => props.infographic && css`
    display: block;

    ${Icon} {
      position: relative;
      top: 0.2rem;
    }

    ${Title} {
      color: ${props.theme.heading.subtitle};
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.09375rem;
      text-transform: uppercase;
      height: fit-content;
    }
  `}

  ${(props) => props.editable && css`
    cursor: auto;
  `}

  &:hover {
    box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);
  }

  ${(props) => props.isActive && css`
    border: 0.25rem solid ${props.theme.form.button.background.icon.normal};
    border-radius: 0.625rem;
    padding: calc(1.25rem - 0.25rem) calc(1.5rem - 0.25rem) calc(1rem - 0.25rem);

    ${Icon}{
      right: -0.25rem;
      bottom: -0.25rem;
    }
  `}
`;
