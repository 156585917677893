import React from 'react';
import PropTypes from 'prop-types';
import {
  DonutChart,
  RingOne,
  RingTwo,
  ChartCenter,
  ChartNumber,
} from 'components/pie-chart/style';

const PieChartComponent = ({
  number,
  size,
  width,
  fontSize,
  fontWeight,
  isGradient,
  center,
  backgroundColor,
  isGray,
  isBlack
}) => (
  <DonutChart
    number={number}
    size={size}
    width={width}
    isGradient={isGradient}
    center={center}
    isGray={isGray}
  >
    <RingOne
      number={number}
      size={size}
      width={width}
      isGradient={isGradient}
      isGray={isGray}
    />
    <RingTwo
      number={number}
      size={size}
      width={width}
      isGradient={isGradient}
      isGray={isGray}
    />
    <ChartCenter
      size={size}
      width={width}
      backgroundColor={backgroundColor}
    >
      {number && (
        <ChartNumber
          number={number}
          size={size}
          width={width}
          fontSize={fontSize}
          fontWeight={fontWeight}
          isBlack={isBlack}
        />
      )}
    </ChartCenter>
  </DonutChart>
);

const {
  bool,
  string,
} = PropTypes;

PieChartComponent.propTypes = {
  number: string,
  size: string,
  isGradient: bool,
  width: string,
  fontSize: string,
  fontWeight: string,
  center: bool,
  backgroundColor: string,
  isGray: bool,
};

PieChartComponent.defaultProps = {
  number: '',
  size: '',
  isGradient: false,
  width: '',
  fontSize: '',
  fontWeight: '',
  center: false,
  backgroundColor: '',
  isGray: false,
};

export default PieChartComponent;
