import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const HalfBall2 = styled.img`
  position: absolute;

  ${(props) => (props.v2 && css`
    transform: rotate(90deg);
    transform-origin: top;
    left: 0;
    bottom: 8.99rem;
  `) || css`
    bottom: 0;
    right: 5.4275rem;
  `}
`;
