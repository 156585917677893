import React, { useState } from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProfileButtonAdmin, NavbarAdmin } from 'components';
import { Container, EventContainer } from 'pages/admin/dashboard-admin/style';
import Dashboard from 'pages/admin/dashboard-admin/dashboard';
import Operators from 'pages/admin/dashboard-admin/operators';
import EventList from 'pages/admin/dashboard-admin/event-list';
import EventDetail from 'pages/admin/dashboard-admin/event-details';
import InvoicesList from 'pages/admin/dashboard-admin/invoices';
import {
  Loading,
} from 'pages';

const DashboardAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      {isLoading && <Loading /> }
      <EventContainer isLoading={isLoading}>
        <ProfileButtonAdmin />
        <Container>
          <NavbarAdmin />
          <Switch>
            <Route
              exact
              path="/admin/dashboard"
              render={() => (
                <Dashboard
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/admin/dashboard/operators"
              render={() => (
                <Operators
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/admin/dashboard/invoices"
              render={() => (
                <InvoicesList
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              exact
              path="/admin/dashboard/event"
              render={() => (
                <EventList
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/admin/dashboard/event/:id"
              render={() => (
                <EventDetail
                  setIsLoading={setIsLoading}
                />
              )}
            />
          </Switch>
        </Container>
      </EventContainer>

    </>
  );
};

const { shape } = PropTypes;

DashboardAdmin.propTypes = {
  location: shape({}).isRequired,
};

export default DashboardAdmin;
