import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstanceAdmin } from 'helpers/authenticationAdmin';
import {
  DashboardCard,
} from 'components';
import {
  Body,
  Title,
  GridDashboard,
  CardTotalEventPieChart,
  CardTotalEventNumber,
  CardTotalEventSubTitle,
  TotalEventNumber,
  TotalEventLabel,
  TotalEventFooterContent,
  TotalEventsFooter,
  Card,
  SubTitle,
  Icon,
} from 'pages/admin/dashboard-admin/dashboard/style';
import GuestRequestSVG from 'assets/images/guest-request.svg';
import UnpaidSVG from 'assets/images/unpaid.svg';

const Dashboard = ({
  eventTokenCommited,
  redirect,
  setIsLoading,
}) => {
  const history = useHistory();
  const [data, setData] = useState({});

  const getDashboardData = () => {
    // ubah endpoint
    axiosApiInstanceAdmin.get(ENDPOINTS('admin.dashboard.dashboard'))
      .then(({ data: { value } }) => {
        const percentage = (parseInt(value.totalFinishedEventsThisMonthCount) / (parseInt(value.totalFinishedEventsThisMonthCount) + parseInt(value.totalOnGoingEventsThisMonthCount)).toFixed(0) * 100);
        setData({ ...value, eventPerentage: percentage || 0 });
        setIsLoading(false);
      })
      .catch(() => {
        // error message
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getDashboardData();
    // eslint-disable-next-line
  }, []);

  const sort = {
    eventsNeedsApproval: 'events needs approval',
    totalGuestRequest: 'total guest request',
    unpaidInvoice: 'unpaid invoice',
    active: 'Active',
  };

  return (
    <>

      <Body>
        <Title>
          Dashboard
        </Title>
        <GridDashboard gridTemplateColumn="1fr 1fr">
          <CardTotalEventPieChart>
            <CardTotalEventNumber>
              {data.totalEventsThisMonthCount}
            </CardTotalEventNumber>
            <CardTotalEventSubTitle>
              Total Events
            </CardTotalEventSubTitle>

            <TotalEventsFooter>
              <TotalEventFooterContent>
                <TotalEventNumber>
                  {data.totalFinishedEventsThisMonthCount || 0}

                </TotalEventNumber>
                <TotalEventLabel>
                  Finished Events
                </TotalEventLabel>
              </TotalEventFooterContent>
              <TotalEventFooterContent>
                <TotalEventNumber>
                  {data.totalOnGoingEventsThisMonthCount || 0}
                </TotalEventNumber>
                <TotalEventLabel>
                  Ongoing Events
                </TotalEventLabel>
              </TotalEventFooterContent>
            </TotalEventsFooter>

            {/* <CardTotalEventPieChartContainer>
              <PieChart
                center
                size="164"
                width="45"
                number={data.eventPerentage}
                fontSize="24"
                fontWeight="bold"
                backgroundColor="#096B81"
              />
            </CardTotalEventPieChartContainer>
            <CardTotalEventPieChartLegendContainer>
              <LegendContent>
                <LegendTotalEvent isFinished />
                Finished
              </LegendContent>
              <LegendContent>
                <LegendTotalEvent />
                Ongoing
              </LegendContent>
            </CardTotalEventPieChartLegendContainer> */}
          </CardTotalEventPieChart>
          <GridDashboard>
            <DashboardCard
              isAdmin
              warning
              title={data.needApprovalEventsCount}
              text="Events Need Approval"
              onClick={() => { history.push(`/admin/dashboard/event?type=${sort.eventsNeedsApproval}`); }}
            />
            <DashboardCard
              isAdmin
              title={data.activeEventsCount}
              text="Active Events"
              onClick={() => { history.push(`/admin/dashboard/event?type=${sort.active}`); }}
            />
          </GridDashboard>
        </GridDashboard>
        <GridDashboard gridTemplateColumn="1fr 1fr 1fr" marginTop="2rem">
          <Card
            onClick={() => { history.push(`/admin/dashboard/invoices?type=${sort.unpaidInvoice}`); }}
          >
            <Title>{data.unPaidInvoicesCount}</Title>
            <SubTitle>Unpaid Invoice</SubTitle>
            <Icon src={UnpaidSVG} />
          </Card>
          <Card
            onClick={() => { history.push(`/admin/dashboard/event?type=${sort.totalGuestRequest}`); }}
          >
            <Title>{data.guestRequestsCount}</Title>
            <SubTitle>Total Guest Requests</SubTitle>
            <Icon src={GuestRequestSVG} />
          </Card>
        </GridDashboard>
      </Body>
    </>
  );
};

const {
  bool,
  func,
} = PropTypes;

Dashboard.propTypes = {
  eventTokenCommited: bool.isRequired,
  redirect: func.isRequired,
  setIsLoading: func.isRequired,
};

export default Dashboard;
