import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.dashboardCard.number.big};
  font-size:20px ;
  line-height:1.5rem;
  letter-spacing:0.03125rem; 
  width: 21%;
`;

export const SubTitleMain = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  color: black;
  font-size:2rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem; 
  margin-bottom:.8rem;
  width: 65%;
`;

export const HeaderSubTitleMain = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  color: #474747;
  font-size:1rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem; 
  margin-bottom:1rem;
  width: 65%;
`;

export const HeaderSubTitle = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  color: ${(props) => props.theme.dashboardCard.number.small};
  font-size:1rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem; 
`;

export const HeaderSubTitle2 = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  color: ${(props) => props.theme.dashboardCard.number.small};
  font-size:1rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem;
  margin-left: 1.2rem
`;

export const SubTitle = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 400;
  color: #474747;
  font-size:1.5rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem; 
  margin-left: .8rem
`;

export const SubTitle2 = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 400;
  color: #474747;
  font-size:1.5rem ;
  line-height: 1.25rem ;
  letter-spacing:0.0125rem; 
  margin-left: .8rem;
`;

export const DashboardCard = styled.div`
  position: relative;
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.section.detail.background};
  padding:${(props) => (props.isAdmin && '21px 40px 22px') || '1.875rem 2.625rem'} ;
  display: flex;
  align-items:center;
  flex-direction:row;
  cursor: pointer;
  &:hover {
    box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);
  }
`;

export const SubCard = styled.div`
  position: relative;
  border-radius: 0.625rem;
  background-color: #F4F4F4;
  padding: 1.2rem 1.5rem;
  display: flex;
  flex-direction:row;
  margin-left: 2.3rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 79%;
`;

export const Divider = styled.div`
  border-left:0.09375rem solid #BDBDBD;
  margin-left: 1.5rem;
`;

export const Divider2 = styled.div`
  border-left:0.09375rem solid #BDBDBD;
  margin-left: 2.5rem;
`;
