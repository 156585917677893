import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 30%;

  ${Button.Button} {
    width: 100%;
    margin-top: 1.5rem;
  }
`;

export const SubtitleContainer = styled.div`
  text-align: center;

  ${Heading.Heading} {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;
