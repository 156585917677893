import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Heading,
} from 'shared';
import {
  Brand,
  ProfileButton,

  // Decoratives
  Stick,
  SemiCircle,
  Confetti,
} from 'components';
import { Container, TextsContainer } from 'pages/welcome/style';
import { store } from 'store';

const Welcome = () => {
  const history = useHistory();
  const { state: { user } } = useContext(store);
  const [fullname, setFullname] = useState('');

  useEffect(() => {
    if (user === undefined) {
      if (user.events && user.events > 0) {
        history.push('/events');
      } else if (user.type == 'admin') {
        history.push('/events');
      } else {
        setFullname(user.fullname);
      }
    } else if (user.events && user.events > 0) {
      history.push('/events');
    } else if (user.type == 'admin') {
      history.push('/events');
    } else {
      setFullname(user.fullname);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <TextsContainer>
          <Heading
            kind="title"
            text={(
              <p>
                Welcome,
                <br />
                <div style={{ textTransform: 'capitalize' }}>
                  {`${fullname}!`}
                </div>
              </p>
            )}
          />
          <Heading
            kind="subtitle"
            text={(
              <p>
                Let us help manage your event right from the beginning;
                <br />
                send invitations, review guest, even arrange who sits where.. So, shall we?
              </p>
            )}
          />
          <Button
            kind="primary"
            type="button"
            text="Create Event"
            onClick={() => history.push('/events')}
          />
        </TextsContainer>
      </Container>

      {/* Decoratives *************************************** */}
      <Stick v2 />
      <SemiCircle />
      <Confetti />
      {/* *************************************************** */}
    </>
  );
};

export default Welcome;
