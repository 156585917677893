const { REACT_APP_API_URL } = process.env;

const SEMVER = "/api/v1/";

const ENDPOINTS = {
  customer: {
    gmap: {
      autocomplete: "map/gmaps-place?input=",
      geocode: "map/gmaps-geocode?address=",
    },
    setting: {
      updateProfile: "setting/profile",
      updatePassword: "setting/change-password",
    },
    authentication: {
      decodeToken: "auth/decode-token",
      signin: "auth/sign-in",
      signup: "auth/sign-up",
      forgotPassword: "auth/forgot-password",
      resetPassword: "auth/reset-password",
      userVerfication: "/auth/verification",
      resendVerification: "/auth/resend-verification",
    },
    event: {
      event: "event",
      select: "event/select-event",
      detail: "event/detail",
      editBasicInfo: "event/basic-info",
      editLocation: "event/location",
      qris: "event/d-day-qris",
      removeQris: "event/remove/d-day-qris",
      video: "event/d-day-video",
      removeVideo: "event/remove/d-day-video",
    },
    eventHistory: {
      eventHistory: "event-history",
    },
    invitationSetting: {
      setting: "invitation-setting",
      whatsapp: "invitation-setting/whatsapp",
      email: "invitation-setting/email",
      removeImage: "invitation-setting/remove-file",
      healtCheck: "invitation-setting/health-check",
      hospitalityCheck: "invitation-setting/hospitality",
    },
    table: {
      table: "table",
      quickSetup: "table/quick-setup",
      deleteTable: "table/delete-tables",
      guests: "table/guest",
      setCategory: "table/set-category",
    },
    assignTable: {
      guestCondition: "assign-table",
    },
    category: {
      category: "category",
    },
    invitation: {
      invitation: "invitation",
      addBulk: "invitation/bulk",
      sendEmail: "invitation/email",
      sendWA: "invitation/whatsapp",
      deleteInvitations: "invitation/delete-invitations",
      setCategory: "invitation/set-category",
      sendHealthCheck: "invitation/send-health-check",
      downloadGuest: "invitation/download-data",
    },
    tab: {
      tab: "tab",
      findInvitation: "tab/find-invitation",
      checkIn: "tab/invitation-checkin",
      sendGift: "tab/send-gift",
      sendGiftQRIS: "tab/send-angpao-qris",
      eventList: "tab/event-list",
      eventSelect: "tab/select-event",
    },
    dashboard: {
      dashboard: "dashboard",
      guestCheckIn: "dashboard/guest-check-in",
      guestHasNotArrived: "dashboard/guest-has-not-arrived",
      foodCompiling: "dashboard/food-compiling",
      dashboardReport: "dashboard/download",
    },
    guestRequest: {
      guestRequest: "guest-request",
      updateGuestRequest: "guest-request/status",
    },
    config: {
      typeformLink: "config/typeform-link",
    },
    userManagement: {
      userList: "event/detail/users",
      sendInvitation: "event/detail/users",
      deleteInvitation: "event/detail/users/",
    },
  },
  admin: {
    authentication: {
      decodeToken: "admin/decode-token",
      signin: "admin/sign-in",
      forgotPassword: "admin/forgot-password",
      resetPassword: "admin/reset-password",
    },
    profile: {
      profile: "admin/profile",
      changePassword: "admin/profile/change-password",
      changeProfile: "admin/profile",
    },
    operator: {
      operator: "admin/operator",
    },
    dashboard: {
      dashboard: "admin/dashboard",
    },
    event: {
      event: "admin/events",
    },
    invoice: {
      invoice: "admin/invoices",
    },
  },
};

// eslint-disable-next-line no-confusing-arrow, no-restricted-globals
const ENDPOINT_RESOLVER = (path, obj) =>
  path
    .split(".")
    .reduce((prev, curr) => (prev ? prev[curr] : null), obj || window.self);
const ENDPOINT_FACTORY = (path) =>
  `${REACT_APP_API_URL}${SEMVER}${ENDPOINT_RESOLVER(path, ENDPOINTS)}`;

export default ENDPOINT_FACTORY;
