import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'shared/styles';

const HeadingComponent = ({
  kind,
  text,
  uppercase,
  onClick,
  marginBottom,
}) => (
  <Heading.Heading
    kind={kind}
    onClick={onClick}
    marginBottom={marginBottom}
  >
    {uppercase ? text.toUpperCase() : text}
  </Heading.Heading>
);

const {
  oneOfType,
  string,
  object,
  bool,
  func,
} = PropTypes;

HeadingComponent.propTypes = {
  kind: string,
  text: oneOfType([string, object]),
  uppercase: bool,
  onClick: func,
  marginBottom: string,
};

HeadingComponent.defaultProps = {
  kind: '',
  text: '',
  uppercase: false,
  onClick: () => {},
  marginBottom: '',
};

export default HeadingComponent;
