import styled from 'styled-components';

export const Icon = styled.img``;

export const MessageBoxContainer = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  width: 100%;
  align-items: flex-start;
  padding: 12px 20px;
  background: ${(props) => (props.type === 'warning' && '#FCF1F1') || (props.type === 'success' && '#D9F1F6')};
  border: 1px solid ${(props) => (props.type === 'warning' && '#E76E6E') || (props.type === 'success' && '#40A790')};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  ${Icon} {
    margin-right: 10px;
  }
`;

export const MessageBoxTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${(props) => props.type === 'warning' && '#E76E6E'};
`;

export const MessageBoxText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #474747;
`;

export const MessageBoxContent = styled.div`
  text-align: left;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
  &:hover {
    cursor: pointer;
  }

  ${Icon} {
    margin: 0;
  }
`;
