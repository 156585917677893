import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/styles';
import LoadingButtonGIF from 'assets/images/loading-button.gif';

const ButtonComponent = ({
  kind,
  type,
  text,
  icon,
  onClick,
  small,
  disabled,
  isLoading,
  active
}) => (
  <Button.Button
    kind={kind}
    type={type}
    icon={icon}
    onClick={onClick}
    small={small}
    disabled={disabled}
    isLoading={isLoading}
    active={active}
  >
    {icon && (
      <Button.Icon src={icon} alt={text} />
    )}


    { isLoading ?
      (
        <Button.Icon
          src={LoadingButtonGIF}
          style={{ marginLeft: '6px', width: '18px', height: '18px' }}
        />
      ) : text}
  </Button.Button>
);

const { string, func, bool } = PropTypes;

ButtonComponent.propTypes = {
  kind: string,
  type: string,
  text: string,
  icon: string,
  onClick: func,
  small: bool,
  disabled: bool,
  isLoading: bool,
  active: bool
};

ButtonComponent.defaultProps = {
  kind: '',
  type: '',
  text: '',
  icon: '',
  onClick: () => { },
  small: false,
  disabled: false,
  isLoading: false,
  active: false
};

export default ButtonComponent;
