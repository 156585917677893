import React, { useState } from 'react';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { Heading, Button } from 'shared';
import { Brand } from 'components';
import {
  Container,
  Section,
  Icon,
  HeadingContainer,
} from 'pages/verify-email/style';
import { useLocation } from 'react-router-dom';
import VerifyEmailSVG from 'assets/images/verify-email.svg';

const VerifyEmail = () => {
  const location = useLocation();
  const { state: { email } } = location;
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [countDown, setCountDown] = useState('');

  const startCountDown = () => {
    let time = 60;
    var timer = setInterval(() => {
      time -= 1;
      setCountDown(time);
      if (time === 0) {
        setCountDown(60);
        setIsSubmiting(false);
        clearInterval(timer);
      }
    }, 1000);
  };

  const resendVerification = () => {
    setIsSubmiting(true);
    startCountDown();
    const form = {
      email,
    };
    axiosApiInstance.put(ENDPOINTS('customer.authentication.resendVerification'), form)
      .then(({ data: { m } }) => {
        if (m === 'success') {
        // TO DO messag succes
        }
      })
      .catch((err) => {
      // TO DO messag fail
      });
  };

  return (
    <>
      <Brand />
      <Container>
        <Section>
          <Icon src={VerifyEmailSVG} />
          <HeadingContainer>
            <Heading
              kind="title"
              text="Verify your email first"
            />
            <Heading
              kind="subtitle"
              text="Check your email. If the email doesn't show up soon, check your spam folder. We sent it from info@intisinergi.com."
            />
            <Button
              kind="primary"
              type="submit"
              text={`Resend Verification ${(isSubmiting && countDown && `${countDown}s`) || ''}`}
              onClick={resendVerification}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </HeadingContainer>
        </Section>
      </Container>
    </>
  );
};

export default VerifyEmail;
