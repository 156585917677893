import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Stick = styled.img`
  position: absolute;

  ${(props) => (props.v2 && css`
    left: 0;
    top: 6rem;
  `) || css`
    left: 0;
    bottom: 16.746875rem;
  `}
`;
