/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'shared';
import {
  SideMenu,
  SideMenuCollapsible,
  TableButton,
  DatePicker,
} from 'components';
import {
  TotalPaxContainer,
  InputAmount,
  ContainerInputAmount,
  InputAmountPrefix,
  InvoiceCodeContainer,
  InvoiceCodeLabel,
  InvoiceCodeText,
} from 'pages/admin/dashboard-admin/event-details/sidemenu/add-invoice/style';
import AddGuestSVG from 'assets/icons/add-guest.svg';
import PlusSmallSVG from 'assets/icons/plus-small.svg';
import UnionSVG from 'assets/icons/union-input.svg';
import UnionDisableSVG from 'assets/icons/union-disable.svg';

const AddInvoiceSideMenu = ({
  isOpen,
  onRequestClose,
  addInvoice,
  nextInvoiceCode,
  isSubmiting,
}) => {
  const [openedCollapsibleId, setOpenedCollapsibleId] = useState(1);
  const [form, setForm] = useState({
    amount: 0,
    payment_type: 'Final Payment',
    due_date: new Date(),
    add_ons: [],
  });
  // data
  useEffect(() => {
    setForm({
      amount: 0,
      payment_type: 'Final Payment',
      due_date: new Date().toString(),
      add_ons: [],
    });
    setOpenedCollapsibleId(0);
  }, [isOpen]);

  return (
    <SideMenu
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      finishFunc={() => { addInvoice(form); }}
      openedId={openedCollapsibleId}
      openId={setOpenedCollapsibleId}
      title="Add Invoice"
      buttonText="Add Invoice"
      icon={AddGuestSVG}
      isLoading={isSubmiting}
      disabled={isSubmiting}
    >
      <SideMenuCollapsible
        id={0}
        openedId={openedCollapsibleId}
        openId={setOpenedCollapsibleId}
        title="payment details"
      >
        <InvoiceCodeContainer>
          <InvoiceCodeLabel>
            Invoice Code
          </InvoiceCodeLabel>
          <InvoiceCodeText>
            {nextInvoiceCode}
          </InvoiceCodeText>
        </InvoiceCodeContainer>

        <ContainerInputAmount>
          <form>
            <InputAmount
              type="text"
              value={form.amount}
              onChange={(event) => {
                const tempValue = event.target.value.replace(/[,.-]/g, '');
                setForm({ ...form, amount: Number(tempValue).toLocaleString() });
              }}
              onKeyDown={((e) => {
                if (!((e.keyCode > 95 && e.keyCode < 106)
                  || (e.keyCode > 47 && e.keyCode < 58)
                  || e.keyCode == 8)) {
                  e.preventDefault();
                }
              })}
            />
          </form>
          <InputAmountPrefix>
            rp
          </InputAmountPrefix>
        </ContainerInputAmount>
        <Input
          type="text"
          label="Payment Type"
          name="paymenttype"
          placeholder="Placeholder"
          value={form.payment_type}
          disabled
        />
        <DatePicker
          label="Due Date"
          value={form.due_date}
          onChange={(value) => setForm({ ...form, due_date: value })}
        />

      </SideMenuCollapsible>
      <SideMenuCollapsible
        id={1}
        openedId={openedCollapsibleId}
        openId={setOpenedCollapsibleId}
        title="Add-ons"
      >
        <TotalPaxContainer>
          {/* <TotalPax>
            Total Pax:
            {form.add_ons.length}
          </TotalPax> */}
          <TableButton
            text="Add Add-Ons"
            icon={PlusSmallSVG}
            onClick={() => setForm({ ...form, add_ons: [...form.add_ons, ''] })}
          />
        </TotalPaxContainer>
        {form.add_ons.map((item, index) => (
          <Input
            icon={item !== '' ? UnionDisableSVG : UnionSVG}
            type="text"
            name="guestName1"
            placeholder="Type Add-Ons"
            iconClick={() => {
              const tempList = [];
              form.add_ons.map((guest) => tempList.push(guest));
              tempList.splice(index, 1);
              setForm({ ...form, add_ons: tempList });
            }}
            value={item}
            onChange={
              (value) => {
                const tempList = [];
                form.add_ons.map((guest) => tempList.push(guest));
                tempList[index] = value;
                setForm({ ...form, add_ons: tempList });
              }
            }
          />
        ))}
      </SideMenuCollapsible>
    </SideMenu>
  );
};

const { bool, func } = PropTypes;

AddInvoiceSideMenu.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default AddInvoiceSideMenu;
