import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Header,
  Body,
  Category,
  Title,
  Warning,
  DateAndTime,
  Icon,
  Place,
  Time,
  DashboardReportBackground,
  ChartContainer,
  ChartHeader,
  ChartBody,
  ChartLegend,
  ChartList,
  OpenNewIcon,
} from 'components/card/style';
import { EVENT_CATEGORIES } from 'helpers/constants';
import WeddingCardHeaderSVG from 'assets/images/wedding-card-header.svg';
import WeddingCardDashboardHeaderSVG from 'assets/images/weading-card-dashboard-header.svg';
import ConfettiSVG from 'assets/images/confetti.svg';
import BirthdayCardHeaderSVG from 'assets/images/birthday-card-header.svg';
import ConeSVG from 'assets/images/cone.svg';
import HolyMatrimonyCardHeaderSVG from 'assets/images/holy-matrimony-card-header.svg';
import PenSVG from 'assets/images/pen.svg';

import LocationSVG from 'assets/icons/location-card.svg';
import ClockSVG from 'assets/icons/clock.svg';
import WarningSVG from 'assets/icons/warning.svg';
import DashboardReportSVG from 'assets/icons/dashboard-report.svg';
import OpenNewSVG from 'assets/icons/open-new.svg';

const CARD_TYPES = [
  {
    category: EVENT_CATEGORIES.wedding,
    color: 'green',
    header: WeddingCardHeaderSVG,
    header2: ConfettiSVG,
    header3: WeddingCardDashboardHeaderSVG,
  },
  {
    category: EVENT_CATEGORIES.birthday,
    color: 'violet',
    header: BirthdayCardHeaderSVG,
    header2: ConeSVG,
    header3: WeddingCardDashboardHeaderSVG,
  },
  {
    category: EVENT_CATEGORIES.holyMatrimony,
    color: 'gold',
    header: HolyMatrimonyCardHeaderSVG,
    header2: PenSVG,
    header3: WeddingCardDashboardHeaderSVG,
  },
];

const CardComponent = ({
  headerImage,
  headerOnly,
  paymentNeeded,
  category,
  title,
  place,
  time,
  onClick,
  isDashboard,
  isDashboardReport,
  isDashboardReportBackground,
  isDDay,
  isActive,
  subTitle,
  chart,
  noCursor,
  openNew,
}) => {
  const {
    color,
    header,
    header2,
    header3,
  } = CARD_TYPES.find((type) => type.category === category.toLowerCase());

  const limitText = title.length > 52 ? `${title.slice(0, 52)}...` : title;

  return (
    <Card
      isDashboardReport={isDashboardReport}
      isDashboard={isDashboard}
      headerImage={headerImage}
      headerOnly={headerOnly}
      color={color}
      onClick={onClick}
      noCursor={noCursor}
      isActive={isActive}
    >
      {
        (isDashboard || headerImage || headerOnly || isDashboardReport)
        && (
          <Header
            src={(() => {
              if (headerOnly) {
                return header2;
              }

              if (isDashboard) {
                return header3;
              }

              return header;
            })()}
            alt={category}
          />
        )
      }

      {isDDay && (
      <ChartContainer>
        <OpenNewIcon src={OpenNewSVG} onClick={openNew} />
        <ChartHeader>{chart}</ChartHeader>
        <ChartBody>
          <ChartList>
            <ChartLegend fill="true" />
            Guest already check-in
          </ChartList>
          <ChartLegend />
          Guest haven&apos;t arrived yet
        </ChartBody>
      </ChartContainer>
      )}

      {(isDashboard || isDashboardReport) && (
        <Body
          isDashboard={isDashboard}
          isDashboardReport={isDashboardReport}
        >
          <Title
            isDashboard={isDashboard}
            isDashboardReport={isDashboardReport}
          >
            {title}
          </Title>
          {subTitle && (
          <Title
            subTitle
            isDashboardReport={isDashboardReport}
          >
            {' '}
            {subTitle}
          </Title>
          )}
        </Body>
      )}

      {!headerOnly && !isDashboard && !isDashboardReport ? (
        <Body>
          <Category>{category}</Category>
          <Title>{limitText}</Title>
          <DateAndTime>
            <Place>
              <Icon src={LocationSVG} alt="Calendar" />
              {place}
            </Place>
            <Time>
              <Icon src={ClockSVG} alt="Clock" />
              {time}
            </Time>
          </DateAndTime>
        </Body>
      ) : (
        <Category>{category}</Category>
      )}

      {isDashboardReportBackground
        && <DashboardReportBackground src={DashboardReportSVG} style={{ bottom: 0 }} />}
      {paymentNeeded && (
        <Warning>
          <Icon src={WarningSVG} />
          Payment Needed
        </Warning>
      )}
    </Card>
  );
};

const {
  bool,
  string,
  func,
  oneOfType,
  number,
  object,
} = PropTypes;

CardComponent.propTypes = {
  isDashboardReport: bool,
  isDashboardReportBackground: bool,
  isDDay: bool,
  isDashboard: bool,
  isActive: bool,
  headerImage: bool,
  headerOnly: bool,
  paymentNeeded: bool,
  category: string,
  title: oneOfType([string, number]),
  subTitle: oneOfType([object, string]),
  place: string,
  time: string,
  onClick: func,
  chart: oneOfType([object, string]),
  noCursor: bool,
  openNew: func,
  active: bool,
};

CardComponent.defaultProps = {
  isDashboardReport: false,
  isDashboardReportBackground: false,
  isDDay: false,
  isDashboard: false,
  isActive: false,
  headerImage: false,
  headerOnly: false,
  paymentNeeded: false,
  category:'',
  title: '',
  subTitle: '',
  place: '',
  time: '',
  onClick: () => {},
  chart: {},
  noCursor: false,
  openNew: () => {},
  active: false,
};

export default CardComponent;
