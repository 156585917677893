/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ENDPOINTS from 'helpers/endpoints';
import Joi from 'joi';
import { axiosApiInstance } from 'helpers/authentication';
import {
  ListContainer,
  List,
  Icon,
  Input,
} from 'components/list/style';
import PencilSVG from 'assets/icons/pencil.svg';
import DoneSVG from 'assets/icons/done.svg';
import RemoveSVG from 'assets/icons/remove.svg';

const ListComponent = ({ data,setCategories }) => {
  const inputEl = useRef(null);
  const [isEdited, setIsEdited] = useState('');
  const [editedValue, setEditedValue] = useState('');

  const deleteCategory = (id) => {
    let tempCategories = JSON.parse(JSON.stringify(data));
    tempCategories.splice(id,1)
    setCategories(tempCategories)
      setIsEdited('');
      setEditedValue('');
  };

  const handleSubmitEditCategory = async (id) => {
    try {
      const schema = Joi.object({
        name: Joi.string().required(),
      });

      const value = await schema.validateAsync({ name: editedValue });
      
      // edit cat
      let tempCategories = JSON.parse(JSON.stringify(data));
      tempCategories.splice(id,1)
      // tempCategories[id] = editedValue;
      setCategories([editedValue,...tempCategories])
      setIsEdited('');
      setEditedValue('');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

   
  };

  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      handleSubmitEditCategory(id);
    }
  };

  const editCategory = (id, value) => {
    if (isEdited === '') {
      setIsEdited(id);
      setEditedValue(value);
    } else {
      handleSubmitEditCategory(id);
    }
  };

  useEffect(() => {
    if (isEdited) {
      inputEl.current.focus();
    }
  }, [isEdited]);

  return (
    <>
      {data.length > 0 && (
        <ListContainer>
          {data.map((value,index) => (
            // eslint-disable-next-line react/no-array-index-key
            <List isEdited={isEdited === index} key={index}>
              {isEdited === index ? (
                <Input
                  value={editedValue}
                  onChange={({ target: { value: val } }) => setEditedValue(val)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  charLength={editedValue.length}
                  ref={inputEl}
                // onClick={()=>{}}
                />
              ) : value}
              {isEdited === index ? (
                <Icon
                  src={DoneSVG}
                  onClick={() => editCategory(index, value)}
                />
              ) : (
                <Icon
                  src={PencilSVG}
                  onClick={() => editCategory(index, value)}
                />
              )}
              <Icon
                src={RemoveSVG}
                onClick={() => deleteCategory(index)}
              />
            </List>
          ))}
        </ListContainer>
      )}
    </>
  );
};

const {
  arrayOf,
  shape,
  string,
  func,
  bool,
} = PropTypes;

ListComponent.propTypes = {
  data: arrayOf(shape({
    _id: string,
    name: string,
  })),
  fetchCategories: func,
  bypass: bool,
};

ListComponent.defaultProps = {
  data: [],
  fetchCategories: () => { },
  bypass: false,
};

export default ListComponent;
