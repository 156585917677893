import React from 'react';
import { Confetti2 } from 'components/decoratives/confetti-2/style';
import Confetti2SVG from 'assets/images/confetti-2.svg';

const Confetti2Component = () => (
  <Confetti2
    src={Confetti2SVG}
    alt="Confetti 2"
  />
);

export default Confetti2Component;
