/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import OutsideClickHandler from "react-outside-click-handler";

import {
  TimeContainer,
  TimeLabel,
  TimeInputContainer,
  TimeSelectedContainer,
  TimeText,
  TimepickerPopupContainer,
  TimepickerItemPopupContainer,
  TimeIconContainer,
  Icon,
  PlaceholderText,
  SearchContainer,
} from "components/timepicker/style";

import SearchSVG from "assets/icons/search.svg";
import ClockSVG from "assets/icons/clock-timepicker.svg";
import TimeStatic from "data/static/time.json";
import FullTimeStatic from "data/static/full-time.json";
import { Input } from "shared";

const TimePicker = ({
  value,
  label,
  onChange,
  isEmpty,
  primary = false,
  disabled = false,
}) => {
  const placeholder = "Select Time";
  const [showPopup, setShowPopup] = useState(false);
  const [valueTime, setValueTime] = useState(isEmpty ? "" : "00:00");
  const [searchTerm, setSearchTerm] = useState("");

  const getTime = (time) => {
    const temp = TimeStatic.filter((item) => item.value === time);
    return temp[0];
  };

  const getFullTime = (time) => {
    const temp = FullTimeStatic.filter((item) => item.value === time);
    return temp[0];
  };

  useEffect(() => {
    if (value) {
      setValueTime(value);
    }
  }, [value]);
  const onSelectedTime = (time) => {
    setValueTime(time);
    onChange(time);
    setShowPopup(false);
  };

  const dataSearch = FullTimeStatic.filter((item) => {
    if (searchTerm === "") {
      return item;
    }
    if (item.label.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item;
    }
  });

  return (
    <TimeContainer>
      <TimeLabel>{label}</TimeLabel>
      <TimeInputContainer
        disabled={disabled}
        onClick={
          disabled
            ? null
            : () => {
                setShowPopup((prev) => !prev);
              }
        }
      >
        <TimeSelectedContainer>
          {primary ? (
            <TimeText>
              {valueTime !== "" ? (
                getFullTime(valueTime) ? (
                  getFullTime(valueTime).label
                ) : (
                  <PlaceholderText>{placeholder}</PlaceholderText>
                )
              ) : (
                <PlaceholderText>{placeholder}</PlaceholderText>
              )}
            </TimeText>
          ) : (
            <TimeText>
              {valueTime !== "" ? (
                getTime(valueTime) && getTime(valueTime).label
              ) : (
                <PlaceholderText>{placeholder}</PlaceholderText>
              )}
            </TimeText>
          )}

          <TimeIconContainer>
            <Icon src={ClockSVG} />
          </TimeIconContainer>
        </TimeSelectedContainer>
      </TimeInputContainer>
      {showPopup && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowPopup(false);
          }}
        >
          <TimepickerPopupContainer>
            <SearchContainer
              isSearch
              type="text"
              name="searchCategory"
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(value.target.value);
              }}
              placeholder="Search time"
            />
            {primary ? (
              <Scrollbars
                autoHide
                style={{ minHeight: "240px", width: "auto" }}
              >
                {dataSearch.map((item, index) => (
                  <TimepickerItemPopupContainer
                    key={index}
                    isActive={item.value === valueTime}
                    onClick={() => {
                      onSelectedTime(item.value);
                    }}
                  >
                    {item.label}
                  </TimepickerItemPopupContainer>
                ))}
              </Scrollbars>
            ) : (
              <Scrollbars
                autoHide
                style={{ minHeight: "240px", width: "auto" }}
              >
                {TimeStatic.map((item, index) => (
                  <TimepickerItemPopupContainer
                    key={index}
                    isActive={item.value === valueTime}
                    onClick={() => {
                      onSelectedTime(item.value);
                    }}
                  >
                    {item.label}
                  </TimepickerItemPopupContainer>
                ))}
              </Scrollbars>
            )}
          </TimepickerPopupContainer>
        </OutsideClickHandler>
      )}
    </TimeContainer>
  );
};

const { string, func } = PropTypes;
TimePicker.propTypes = {
  value: string,
  label: string,
  onChange: func,
};

TimePicker.defaultProps = {
  value: "",
  label: "",
  onChange: () => {},
};

export default TimePicker;
