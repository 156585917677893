import React from 'react';
import PropTypes from 'prop-types';
import { HalfBall2 } from 'components/decoratives/half-ball-2/style';
import HalfBall2SVG from 'assets/images/half-ball-2.svg';

const HalfBall2Component = ({ v2 }) => (
  <HalfBall2
    v2={v2}
    src={HalfBall2SVG}
    alt="Half Ball 2"
  />
);

const { bool } = PropTypes;

HalfBall2Component.propTypes = {
  v2: bool,
};

HalfBall2Component.defaultProps = {
  v2: false,
};

export default HalfBall2Component;
