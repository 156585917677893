import styled from 'styled-components';
/* eslint no-mixed-operators: ["error",
{"groups": [["&", "|", "^", "~", "<<", ">>", ">>>"], ["&&", "||"]]}] */

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16.25rem;
  height: 16.25rem;
`
