import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components/table/label/style';

const LabelComponent = ({
  type, text, onClick, style,
}) => (
  <Label type={type} onClick={onClick} style={style}>
    {text}
  </Label>
);

const { string } = PropTypes;

LabelComponent.propTypes = {
  type: string,
  text: string,
};

LabelComponent.defaultProps = {
  type: '',
  text: '',
};

export default LabelComponent;
