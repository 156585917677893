import React from 'react';
import { Heading } from 'shared';
import { Brand } from 'components';
import {
  Container,
  Section,
  Icon,
  HeadingContainer,
} from 'components/email-sent/style';
import EmailSentSVG from 'assets/images/email-sent.svg';

const EmailSentComponent = () => (
  <>
    <Brand />
    <Container>
      <Section>
        <Icon src={EmailSentSVG} />
        <HeadingContainer>
          <Heading
            kind="title"
            text="Email sent!"
          />
          <Heading
            kind="subtitle"
            text="Check your email. If the email doesn't show up soon, check your spam folder. We sent it from info@intisinergi.com."
          />
        </HeadingContainer>
      </Section>
    </Container>
  </>
);

export default EmailSentComponent;
