import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Joi from 'joi';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { Button, Heading, Input } from 'shared';
import { Brand, PasswordUpdated } from 'components';
import { Container, Form } from 'pages/reset-password/style';

const ResetPasswordForm = ({ passwordReset }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userID } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const schema = Joi.object({
        password: Joi.string().required(),
        confirmPassword: Joi.ref('password'),
      });

      const value = await schema.validateAsync({ password, confirmPassword });
      delete value.confirmPassword;
      axiosApiInstance.put(`${ENDPOINTS('customer.authentication.resetPassword')}/${userID}`, value)
        .then(() => passwordReset())
        .catch((err) => {
          setIsSubmitting(false);
          setErrorMessage(err.response.data.m);
        });
    } catch (err) {
      setIsSubmitting(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <Brand />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Heading
            kind="title"
            text="Reset Password"
          />
          <Input
            type="password"
            label="New Password"
            name="password"
            value={password}
            onChange={setPassword}
            placeholder="Type your new password"
            errorMessage={errorMessage && ' '}
            disabled={isSubmitting}
          />
          <Input
            type="password"
            label="Confirm Password"
            name="confirm-password"
            value={confirmPassword}
            onChange={setConfirmPassword}
            placeholder="Retype your new password"
            errorMessage={errorMessage}
            disabled={isSubmitting}
          />
          <Button
            kind="primary"
            type="submit"
            text="Reset Password"
            disabled={isSubmitting}
          />
        </Form>
      </Container>
    </>
  );
};

const ResetPassword = () => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const passwordReset = () => setIsPasswordReset(true);

  return (
    <>
      {
        isPasswordReset
          ? <PasswordUpdated />
          : <ResetPasswordForm passwordReset={passwordReset} />
      }
    </>
  );
};

const { func } = PropTypes;

ResetPasswordForm.propTypes = {
  passwordReset: func.isRequired,
};

export default ResetPassword;
