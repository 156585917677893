import styled from 'styled-components';
import { Heading, Button, Input } from 'shared/styles';

export const Icon = styled.img`
`;

export const GuestContainer = styled.div`
  position: absolute;
  background-color: ${(props) => (props.withVideo && 'rgba(0,0,0,0.5)') || 'transparent'};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items ${((props) => props.alignItems || 'stretch')};

  ${Input.Input} {
    width: 18.75rem;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${Button.Button} {
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const GuestContent = styled.div`
  position: absolute;
  top: 4.375rem;

  ${Heading.Heading} {
    &[kind="subtitle"] {
      width: 25rem;
      margin: 0 auto;
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      line-height: 2.75rem;
      text-align: center;
      letter-spacing: 0.03125rem;
      color: ${(props) => props.theme.checkin.color.white};
      margin-bottom: 1.75rem;
    }
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  margin: auto;
  width: 30rem;
  background: rgba(243, 242, 245, 0.5);
  border-radius: 0.625rem;
  padding: 2.5rem;
`;

export const CardText = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.625rem;
  text-align: center;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.checkin.color.white};
  margin-bottom: 1.25rem;
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 3.9375rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
