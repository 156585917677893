import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstanceAdmin } from 'helpers/authenticationAdmin';
import { store, ACTIONS } from 'store';
import {
  Button,
  Heading,
  Input,
  Link,
} from 'shared';
import {
  Brand,

  // Decoratives
  HalfBall,
  SemiCircle,
  Confetti,
} from 'components';
import {
  Container,
  Form,
  SubtitleContainer,
  ForgotPasswordContainer,
} from 'pages/admin/sign-in/style';

const SignIn = () => {
  const history = useHistory();
  const { dispatch } = useContext(store);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => setErrorMessage(''), [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const schema = Joi.object({
        email: Joi.string().required(),
        password: Joi.string().required(),
      });
      const value = await schema.validateAsync({ email, password });
      axiosApiInstanceAdmin.post(ENDPOINTS('admin.authentication.signin'), value)
        .then((res) => {
          if (res.data.value.admin.type === 'admin') {
            history.push('/admin/dashboard');
            dispatch({ type: ACTIONS.addUser, payload: res.data.value.user });
            localStorage.setItem('access_token_admin', res.data.value.userToken);
          } else {
            setErrorMessage('Only admin can access');
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          setErrorMessage(err.response.data.m);
        });
    } catch (err) {
      setIsSubmitting(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <Brand />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Heading
            kind="title"
            text="Sign in"
          />
          <SubtitleContainer />
          <Input
            type="text"
            label="Email Address"
            name="email"
            value={email}
            onChange={setEmail}
            placeholder="Type your email address"
            errorMessage={errorMessage && ' '}
            disabled={isSubmitting}
            isForm
          />
          <Input
            type="password"
            label="Password"
            name="password"
            value={password}
            onChange={setPassword}
            placeholder="Type your password"
            errorMessage={errorMessage}
            disabled={isSubmitting}
            isForm
          />
          <Button
            kind="primary"
            type="submit"
            text="Sign In"
            disabled={isSubmitting}
          />
          <ForgotPasswordContainer>
            <Link
              to="/admin/forgot-password"
              text="Forgot Password?"
            />
          </ForgotPasswordContainer>
        </Form>
      </Container>

      {/* Decoratives *************************************** */}
      <HalfBall />
      <SemiCircle />
      <Confetti />
      {/* *************************************************** */}
    </>
  );
};

export default SignIn;
