import React, { useState, useEffect, useContext } from 'react';
import {
  Switch,
  useHistory,
  Route,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { store, ACTIONS } from 'store';
import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';
import { ProfileButton, Navbar } from 'components';
import { Container, EventContainer } from 'pages/event/style';
import {
  Loading,
  Dashboard,
  GuestList,
  TableManagement,
  Invitation,
  EventDetails,
  GuestRequest,
  FoodInformation,
} from 'pages';

const Event = ({ location }) => {
  const history = useHistory();
  const { state: { user }, dispatch } = useContext(store);
  const [eventTokenCommited, setEventCommited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { type: userType } = user;

  const redirect = (route) => {
    history.push(route);
  };

  useEffect(() => {
    const selectEvent = (eventID) => {
      // eslint-disable-next-line no-underscore-dangle
      const _endpoint = userType === 'admin' ? 'customer.tab.eventSelect' : 'customer.event.select';
      axiosApiInstance.get(`${ENDPOINTS(_endpoint)}/${eventID}`)
        .then(({ data: { value: { eventToken, event } } }) => {
          localStorage.setItem('event_token', eventToken);
          dispatch({ type: ACTIONS.getEventDetail, payload: event });
          setEventCommited(true);
        })
        .catch(() => history.push('/events'));
    };

    const getDetail = () => {
      axiosApiInstance.get(ENDPOINTS('customer.event.detail'))
        .then(({ data: { value: { event } } }) => {
          dispatch({ type: ACTIONS.getEventDetail, payload: event });
          setEventCommited(true);
        })
        .catch(() => history.push('/events'));
    };

    if (location.state !== undefined) {
      const { eventID } = location.state;
      selectEvent(eventID);
    } else if (localStorage.getItem('event_token')) {
      getDetail();
    } else {
      history.push('/events');
    }
    // eslint-disable-next-line
  }, [history, location.state, userType]);

  return (
    <>
      {isLoading && <Loading /> }
      {eventTokenCommited && (
      <EventContainer isLoading={isLoading}>
        <ProfileButton />
        <Container>
          <Navbar />
          <Switch>
            <Route
              path="/event/guest-request"
              render={
                  () => (
                    <GuestRequest
                      setIsLoading={setIsLoading}
                    />
                  )
              }
            />
            <Route
              path="/event/dashboard"
              render={() => (
                <Dashboard
                  eventTokenCommited={eventTokenCommited}
                  redirect={redirect}
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/event/food-information"
              render={() => (
                <FoodInformation
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/event/guest-list"
              render={() => (
                <GuestList
                  eventTokenCommited={eventTokenCommited}
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/event/table-management"
              render={() => (
                <TableManagement
                  eventTokenCommited={eventTokenCommited}
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/event/invitation"
              render={() => (
                <Invitation
                  eventTokenCommited={eventTokenCommited}
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path="/event/event-details"
              render={() => (
                <EventDetails
                  eventTokenCommited={eventTokenCommited}
                  setIsLoading={setIsLoading}
                />
              )}
            />
          </Switch>
        </Container>
      </EventContainer>
      )}

    </>
  );
};

const { shape } = PropTypes;

Event.propTypes = {
  location: shape({}).isRequired,
};

export default withRouter(Event);
