import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Combobox,
  Select,
  Options,
  Item,
  Icon,
} from 'components/combobox-payment/style';
import ArrowDownSVG from 'assets/icons/down-arrow.svg';
import OutsideClickHandler from 'react-outside-click-handler';

const ComboboxPayment = ({
  placeholder,
  onChange,
  list,
  value,
}) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState({});

  const selectItem = (item) => {
    setSelected({ value: item.value, label: item.label });
    if (onChange !== undefined) {
      onChange(item.value);
    }
  };

  const toggleList = () => setShowList(!showList);
  const closeList = () => setShowList(false);
  const spaceOpenList = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      toggleList();
    }
  };

  const enterSelected = (event, item) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      selectItem(item);
      closeList();
    }
  };

  useEffect(() => {
    if (value) {
      selectItem({ value: true, label: 'paid' });
    } else {
      selectItem({ value: false, label: 'unpaid' });
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <OutsideClickHandler onOutsideClick={closeList}>
      <Combobox value={selected.label} onClick={toggleList} tabIndex="0" onKeyUp={(event) => spaceOpenList(event)}>
        {showList ? <Icon src={ArrowDownSVG} up /> : <Icon src={ArrowDownSVG} />}
        <Select>
          {(selected && selected.label) || placeholder}
        </Select>
        {showList && (
        <Options>
          {list.map((item, index) => (
            <Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              tabIndex="0"
              onKeyUp={(event) => enterSelected(event, item)}
              onClick={() => selectItem(item)}
            >
              {item.label}
            </Item>
          ))}
        </Options>
        )}
      </Combobox>
    </OutsideClickHandler>
  );
};
const {
  string,
  func,
  arrayOf,
  shape,
} = PropTypes;

ComboboxPayment.propTypes = {
  placeholder: string,
  onChange: func,
  list: arrayOf(shape({})),
};

ComboboxPayment.defaultProps = {
  placeholder: 'Select',
  onChange: () => {},
  list: [],
};

export default ComboboxPayment;
