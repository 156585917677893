import styled from 'styled-components';
import { Heading } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 3.25rem;

  img {
    display: block;
    width: 11.25rem;
    height: 11.25rem;
  }

  ${Heading.Heading} {
    text-align: left;

    &[kind="title"] {
      font-size: 3.25rem;
      line-height: 4.125rem;
      letter-spacing: 0.03125rem;
      margin-bottom: 1rem;
    }

    &[kind="subtitle"] {
      width: 31.625rem;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.00125rem;
    }
  }
`;

export const Icon = styled.img``;

export const HeadingContainer = styled.div``;
