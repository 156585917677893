import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Label = styled.label`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.heading.title};

  &:before {
    top: calc((0% - (100% - 1.2em)) - 8%) !important;
  }
`;
