import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'shared/styles';
import ErrorSVG from 'assets/icons/error.svg';

const TextAreaComponent = ({
  name,
  rows,
  cols,
  label,
  value,
  placeholder,
  onChange,
  errorMessage,
  style,
  disabled
}) => (
  <TextArea.Container >
    {label && (
      <TextArea.Label
        htmlFor={name}
      >
        {label}
      </TextArea.Label>
    )}
    <TextArea.TextArea
      id={name}
      name={name}
      rows={rows}
      cols={cols}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      style={style}
      disabled={disabled}
    />
    {!!errorMessage && (
      <TextArea.ErrorMessageContainer>
        <TextArea.ErrorMessage>
          <TextArea.ErrorIcon src={ErrorSVG} />
          {errorMessage}
        </TextArea.ErrorMessage>
      </TextArea.ErrorMessageContainer>
    )}
  </TextArea.Container>
);

const { string, func, shape, bool } = PropTypes;

TextAreaComponent.propTypes = {
  name: string,
  rows: string,
  cols: string,
  label: string,
  value: string,
  placeholder: string,
  onChange: func,
  errorMessage: string,
  style: shape({}),
  disabled: bool
};

TextAreaComponent.defaultProps = {
  name: '',
  rows: '',
  cols: '',
  label: '',
  value: '',
  placeholder: '',
  onChange: () => { },
  errorMessage: '',
  style: {},
  disabled: false
};

export default TextAreaComponent;
