import styled, { css } from 'styled-components';

export const Header = styled.img``;

export const DashboardReportBackground = styled.img`
  position:absolute;
  bottom:0;
  left:0;
  max-width:9.375rem;
  width:100%
`;

export const OpenNewIcon = styled.img`
  position:absolute;
  top:1.25rem;
  right:1.25rem;
  cursor:pointer;
`;

export const Category = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.09375rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
`;

export const Card = styled.div`
box-sizing: border-box;
background-color: ${(props) => ((props.headerOnly || props.isDashboard || props.isDashboardReport) && props.theme.card.category[props.color])
    || props.theme.card.background};
border-radius: 0.625rem;
min-height: ${(props) => (props.headerImage && '16.875rem')
  || (props.headerOnly && '8.75rem')
  || (props.isDashboard && '3.5rem')
  || '11.25rem'};
position: relative;
height: 100%;
overflow: hidden;
cursor: ${(props) => ((props.noCursor && 'auto') || 'pointer')};
  ${Header} {
    background-color: ${(props) => props.theme.card.category[props.color]};
    ${(props) => props.isDashboardReport && css`
      display:none;
    `}
    ${(props) => props.headerOnly && css`
      position: absolute;
      right: 0;
      bottom: 0;
      width: 7.5rem;
      height: 7.5rem;
      border-bottom-right-radius: 0.625rem;
    `}

    ${(props) => !props.headerOnly && css`
      max-height: 5.875rem;
      width: 100%;
      object-fit: cover;
      border-radius: 0.625rem 0.625rem 0 0;
    `}

    ${(props) => (props.isDashboard) && css`
      min-height: 6.5rem;
      width: 100%;
      object-fit: cover;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      position:absolute;
    `}
  }

  ${Category} {
    color: ${(props) => props.theme.card.category[props.color]};
    ${(props) => (props.isDashboard || props.isDashboardReport) && css`
      display:none;
    `}

    ${(props) => (props.headerOnly || props.isDashboard) && css`
      margin-top: 1.25rem;
      margin-left: 1.5rem;
      color: ${props.theme.card.background};
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.01875rem;
      text-transform: capitalize;
    `}
  }

  &:hover {
    box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);

    ${(props) => (props.headerOnly || props.isDashboard) && css`
      box-shadow: ${props.theme.card.boxShadow[props.color]};
    `}
  }

  ${(props) => props.isActive && css`
    border: 0.25rem solid ${props.theme.card.border[props.color]};
  `}
`;

export const Body = styled.div`
  padding: 1.25rem;
  position: ${(props) => (((props.isDashboard || props.isDashboardReport) && 'absolute') || 'static')};
`;

export const ChartContainer = styled.div`
  padding:1.75rem 3.3125rem;
  position:relative;
`;

export const ChartHeader = styled.div`
  padding-bottom:1.5rem;
  border-bottom:0.0625rem solid #CE8FC1;
`;

export const ChartBody = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  padding-top:1.5rem;
  color:#ffffff;
`;

export const ChartLegend = styled.div`
  height:0.75rem;
  width:0.75rem;
  background-color: ${(props) => ((props.fill && '#C9B9B2') || '#F4F4F4')};
  margin-right:0.5rem;
  display:inline-block;
`;

export const ChartList = styled.div`
  padding-bottom:0.5rem;
`;

export const Icon = styled.img`
  position: relative;
  top: 0.22rem;
  margin-right: 0.4375rem;
`;

export const Warning = styled.div`
  position: absolute;
  top: 1rem;
  left: 1.25rem;
  display: flex;
  justrify-content: center;
  border-radius: 0.375rem;
  font-family: 'GT Walsheim Pro', sans-serif;
  padding: 0.3125rem 0.875rem;
  background-color: ${(props) => props.theme.card.warning.background};
  color: ${(props) => props.theme.card.warning.text};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;

  ${Icon} {
    position: relative;
    top: 0;
    margin-right: 0.25rem;
  }
`;

export const Title = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: ${(props) => ((props.subTitle && 'normal') || '500')};
  font-size: ${((props) => (props.isDashboard && '2rem') || (props.isDashboardReport && '1.25rem') || (props.subTitle && '1.25rem') || '1.5rem')};
  line-height: 2rem;
  letter-spacing: 0.01875rem;
  color: ${(props) => (((props.isDashboard || props.isDashboardReport || props.subTitle) && props.theme.card.titleWhite) || props.theme.card.title)};
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
`;

export const DateAndTime = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 1.25rem;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.875rem;
  color: ${(props) => props.theme.card.dateAndTime};
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;

  div {
   &:first-child {
    margin-right: 1.375rem;
   }
  }
`;

export const Place = styled.div`
width: 132px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

export const PlaceText = styled.div`
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Time = styled.div``;
