/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  THead,
  TBody,
  TR,
  TD,
  TableScroll,
} from "components/table/style";
import { TableCheckbox } from "components";
import { Item } from "components/combobox/style";

const TableComponent = ({
  readOnly,
  cols,
  data,
  setData,
  tempData,
  pagination = false,
  setTempData,
  setShowFloatingMenu,
  isSelectedAll,
  rowClickable,
  rowFunc,
  horizontalScroll = false,
}) => {
  // const [defaultData, setDefaultData] = useState();
  // useEffect(() => {
  //   setDefaultData(JSON.parse(JSON.stringify(tempData)))
  // }, [])

  const selectOne = (rowId) => {
    const tempList = [];
    let checkList = false;
    let checkToast = false;
    if (pagination) {
      tempData.forEach((item) => {
        if (item?._id == rowId) {
          tempList.push({
            ...item,
            checked: !item.checked,
          });
        } else {
          tempList.push(item);
        }
      });
    } else {
      data.forEach((item) => {
        if (item?._id == rowId) {
          tempList.push({
            ...item,
            checked: !item.checked,
          });
        } else {
          tempList.push(item);
        }
      });
    }

    setData(JSON.parse(JSON.stringify(tempList)));
    setTempData((prevState) => {
      const tempDataRestore = prevState.reduce((acc, curr) => {
        if (curr?._id == rowId) {
          acc.push({
            ...curr,
            checked: !curr?.checked,
          });
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
      tempDataRestore.forEach((item) => {
        if (!item.checked) {
          checkList = true;
        } else {
          checkToast = true;
        }
      });
      setShowFloatingMenu(checkToast);

      return tempDataRestore;
    });
  };

  const selectAll = () => {
    let checkList = false;
    const tempList = [];
    if (pagination) {
      console.log(tempData.filter((item) => item.checked == true)?.length);
      tempData.map((item, index) => {
        console.log(data.length);
        if (data.findIndex((item2) => item2._id == item._id) > -1) {
          tempList.push({ ...item, checked: !isSelectedAll });
          checkList = !isSelectedAll;
        } else {
          tempList.push(item);
        }
      });
    } else {
      data.forEach((item) => {
        tempList.push(item);
        if (!item.checked) {
          checkList = true;
        }
      });
      tempList.forEach((item) => {
        item.checked = checkList;
      });
    }
    setShowFloatingMenu(checkList);

    setData(JSON.parse(JSON.stringify(tempList)));

    setTempData((prevState) => {
      return prevState.reduce((acc, curr, index) => {
        if (data.findIndex((item) => curr?._id == item?._id) > -1) {
          acc.push({
            ...curr,
            checked: !curr?.checked,
          });
        } else {
          acc.push(curr);
        }

        return acc;
      }, []);
    });
  };

  return (
    <TableScroll horizontalScroll={horizontalScroll}>
      <Table horizontalScroll={horizontalScroll}>
        <THead>
          <TR>
            {!readOnly && (
              <TD style={{ width: "10px" }}>
                <TableCheckbox checked={isSelectedAll} onChange={selectAll} />
              </TD>
            )}

            {cols.map((item, i) => {
              if (item?.static) {
                return (
                  <td
                    key={i}
                    style={{
                      width: item?.width,
                      display: "inline-block",
                      position: "sticky",
                      right: 0,
                      background: "#F4F4F4",
                      height: "56px",
                      marginBottom: "-10px",
                    }}
                  ></td>
                );
              } else {
                return (
                  <TD
                    key={i}
                    position={item?.position}
                    style={
                      horizontalScroll
                        ? { minWidth: item?.width || "auto", width: "100%" }
                        : {}
                    }
                  >
                    {item?.title}
                  </TD>
                );
              }
            })}
          </TR>
        </THead>
        <TBody>
          {data.map((item, i) => (
            <React.Fragment key={i}>
              <TR
                rowClickable={rowClickable}
                checked={item.checked}
                onClick={
                  rowClickable
                    ? () => {
                        rowFunc(item);
                      }
                    : () => {}
                }
              >
                {!readOnly && (
                  <TD style={{ width: "10px" }}>
                    <TableCheckbox
                      checked={item.checked}
                      onChange={() => {
                        selectOne(item?._id);
                      }}
                    />
                  </TD>
                )}
                {cols.map((col, j) => {
                  if (col?.static) {
                    return (
                      <td
                        key={j}
                        style={{
                          width: col?.width,
                          display: "inline-block",
                          position: "sticky",
                          right: 0,
                          background: "white",
                          height: "56px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {col?.render(item, i)}
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <TD
                        style={
                          horizontalScroll
                            ? { minWidth: item?.width || "auto", width: "100%" }
                            : {}
                        }
                        key={j}
                      >
                        {col?.render(item, i)}
                      </TD>
                    );
                  }
                })}
              </TR>
            </React.Fragment>
          ))}
        </TBody>
      </Table>
    </TableScroll>
  );
};

const { shape, arrayOf, bool, func } = PropTypes;

TableComponent.propTypes = {
  readOnly: bool,
  cols: arrayOf(shape({})),
  data: arrayOf(shape({})),
  setData: func,
  setTempData: func,
  setShowFloatingMenu: func,
  isSelectedAll: bool,
};

TableComponent.defaultProps = {
  readOnly: false,
  cols: [],
  data: [],
  setData: () => {},
  setTempData: () => {},
  setShowFloatingMenu: () => {},
  isSelectedAll: false,
};

export default TableComponent;
