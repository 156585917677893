/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ENDPOINTS from 'helpers/endpoints';
import Joi from 'joi';
import { axiosApiInstance } from 'helpers/authentication';
import {
  ListContainer,
  List,
  Icon,
  Input,
} from 'components/list/style';
import PencilSVG from 'assets/icons/pencil.svg';
import DoneSVG from 'assets/icons/done.svg';
import RemoveSVG from 'assets/icons/remove.svg';

const ListComponent = ({ data, fetchCategories, bypass }) => {
  const inputEl = useRef(null);
  const [isEdited, setIsEdited] = useState('');
  const [editedValue, setEditedValue] = useState('');

  const deleteCategory = (id) => {
    axiosApiInstance.delete(`${ENDPOINTS('customer.category.category')}/${id}`, {
      headers: {
        bypass,
      },
    })
      .then(() => {
        fetchCategories();
        setIsEdited('');
        setEditedValue('');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const handleSubmitEditCategory = async (id) => {
    try {
      const schema = Joi.object({
        name: Joi.string().required(),
      });

      const value = await schema.validateAsync({ name: editedValue });

      axiosApiInstance.put(`${ENDPOINTS('customer.category.category')}/${id}`, value, {
        headers: {
          bypass,
        },
      })
        .then(() => {
          fetchCategories();
          setIsEdited('');
          setEditedValue('');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      handleSubmitEditCategory(id);
    }
  };

  const editCategory = (id, value) => {
    if (isEdited === '') {
      setIsEdited(id);
      setEditedValue(value);
    } else {
      handleSubmitEditCategory(id);
    }
  };

  useEffect(() => {
    if (isEdited) {
      inputEl.current.focus();
    }
  }, [isEdited]);

  return (
    <>
      {data.length > 0 && (
        <ListContainer>
          {data.map((value) => (
            // eslint-disable-next-line react/no-array-index-key
            <List isEdited={isEdited === value._id} key={value._id}>
              {isEdited === value._id ? (
                <Input
                  value={editedValue}
                  onChange={({ target: { value: val } }) => setEditedValue(val)}
                  onKeyDown={(e) => handleKeyDown(e, value._id)}
                  charLength={editedValue.length}
                  ref={inputEl}
                // onClick={()=>{}}
                />
              ) : value.name}
              {isEdited === value._id ? (
                <Icon
                  src={DoneSVG}
                  onClick={() => editCategory(value._id, value.name)}
                />
              ) : (
                <Icon
                  src={PencilSVG}
                  onClick={() => editCategory(value._id, value.name)}
                />
              )}
              <Icon
                src={RemoveSVG}
                onClick={() => deleteCategory(value._id)}
              />
            </List>
          ))}
        </ListContainer>
      )}
    </>
  );
};

const {
  arrayOf,
  shape,
  string,
  func,
  bool,
} = PropTypes;

ListComponent.propTypes = {
  data: arrayOf(shape({
    _id: string,
    name: string,
  })),
  fetchCategories: func,
  bypass: bool,
};

ListComponent.defaultProps = {
  data: [],
  fetchCategories: () => { },
  bypass: false,
};

export default ListComponent;
