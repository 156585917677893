import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 30%;

  ${Heading.Heading} {
    margin-bottom: 2rem;
  }

  ${Button.Button} {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;
