import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Icon = styled.img``;

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  @media (max-width:1279px){
    max-width: 769px;
  }
  
  @media (max-width:767px){
    max-width: 412px;
  }  

`;

export const Navbar = styled.div`
  height: 3.75rem;
  position: relative;
`;

export const Login = styled(Link)`
  position: absolute;
  top: 17px;
  right: 98px;
  font-style: normal;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: white;
  text-decoration: none;
  @media (max-width:767px){
    right: 24px;
  }
`;

export const TopImage = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  height: 100%;
  width: 100%;
  background-color: #0A0A0A;
`;

export const Brand = styled.img`
  position: absolute;
  cursor: pointer;
  top: 1.3125rem;
  left: 6.25rem;

  @media (max-width:767px){
    left: 20px;
  }
`;

export const TopImageContent = styled.div`
  display: flex;
  height: 100%;
  padding: 90px 0 48px;
  flex-direction: column;
  max-width: 835px;
  margin: 0 auto;
`;

export const TopImageTitle = styled.div`
  max-width: 500px;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 67px;
  text-align: left;
  letter-spacing: 0.5px;
  color: #F4F4F4;
`;

export const TopImageText = styled.div`
  margin-top: 60px;
  width: 100%;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  color: #F4F4F4;
  text-align: justify;
`;

export const Footer = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  background: #0A0A0A;
  width: 100%;
  
  ${Container} {
    padding: 90px 138px 90px 100px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1279px) {
      padding: 80px;
    }
    
    @media (max-width:767px){
      flex-direction: column;
      padding: 80px 48px;
    }
  }
`;

export const FooterRight = styled.div`
  width: 289px;
  font-style: normal;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #FFFFFF;

  @media (max-width: 767px) {
    margin-top: 40px;
  }
  
`;

export const FooterAddress = styled.div`
  margin-top: 32px;
`;

export const FooterCopyright = styled.div`
  margin-top: 24px;
`;

export const FooterPrivacyPolicy = styled.div`
  text-decoration: underline;
  &:hover{
    cursor: pointer;
  }
`;

export const FooterLeft = styled.div`
  font-style: normal;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
`;

export const FooterLeftTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  margin-bottom: 40px;
`;

export const FooterLeftList = styled.div`
  display: flex;
  &:hover{
    cursor: pointer;
  }
  &:nth-child(2){
    margin-bottom: 8px;
  }
  
  @media (max-width:767px){
    &:nth-child(3){
      padding-bottom: 52px;
      border-bottom: 1px solid #F4F4F4;
    }
  }
`;

export const TextIcon = styled.div`
  font-style: normal;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3px;
  margin-right:8px;
`;
