import styled from "styled-components";
import { Heading, Button } from "shared/styles";

export const Icon = styled.img`
  position: relative;
  top: 0.4rem;
  }
`;

export const Body = styled.div`
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
`;

export const Title = styled.div`
  text-align: left;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.75rem;
  letter-spacing: 0.03125rem;
  color: ${(props) => props.theme.navbar.background};
  margin-bottom: 1.25rem;
`;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1rem 2.5rem;

  ${Button.Button} {
    width: 100%;

    &:nth-last-child(1) {
      margin-top: 0.5rem;
    }
  }
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.125rem;
    }
  }
`;
