/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from "react";
import { store } from "store";
import PropTypes from "prop-types";
import { Button } from "shared";
import { Modal } from "components";
import {
  Icon,
  ContentContainer,
  ConfirmationContent,
  ModalHeader,
  ModalHeaderTitle,
  ModalButtonContainer,
  ConfirmationContentTile,
  IconClose,
  ConfirmationList,
  ConfirmationItem,
  ConfirmationItemRight,
  ConfirmationItemLeft,
} from "pages/guest-list/floating-menu/style";
import InfoTableSVG from "assets/icons/info-table.svg";
import ExitSVG from "assets/icons/exit.svg";

const InfoModal = ({ isOpen, onRequestClose, data, confirmDetailIndex }) => {
  const {
    state: { eventDetail },
  } = useContext(store);
  const [selectedTable, setSelectedTable] = useState([]);
  // const [categories, setCategories] = useState([]);
  useEffect(() => {
    const tableSelected = data.reduce((acc, curr) => {
      if (curr.checked) {
        const categories = curr.categories.reduce((acc2, curr2) => {
          acc2.push({ name: curr2, select: true });

          return acc2;
        }, []);
        acc.push({ ...curr, categories });
      }

      return acc;
    }, []);
    setSelectedTable(tableSelected);
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <IconClose src={ExitSVG} onClick={onRequestClose} />
      <ModalHeader>
        <Icon src={InfoTableSVG} />
        <ModalHeaderTitle>Confirmation Detail</ModalHeaderTitle>
      </ModalHeader>
      <ContentContainer>
        <ConfirmationContent>
          <ConfirmationContentTile>confirmation record</ConfirmationContentTile>
          <ConfirmationList>
            <ConfirmationItem>
              <ConfirmationItemRight>Attendance</ConfirmationItemRight>
              <ConfirmationItemLeft>
                {confirmDetailIndex?.confirmation_attending_status || "-"}
              </ConfirmationItemLeft>
            </ConfirmationItem>
            <ConfirmationItem>
              <ConfirmationItemRight>Total PAX</ConfirmationItemRight>
              <ConfirmationItemLeft>
                {confirmDetailIndex?.confirmation_pax || "-"}
              </ConfirmationItemLeft>
            </ConfirmationItem>
            <ConfirmationItem>
              <ConfirmationItemRight>Address</ConfirmationItemRight>
              <ConfirmationItemLeft>
                {confirmDetailIndex?.confirmation_address || "-"}
              </ConfirmationItemLeft>
            </ConfirmationItem>
            <ConfirmationItem>
              <ConfirmationItemRight>Notes</ConfirmationItemRight>
              <ConfirmationItemLeft>
                {confirmDetailIndex?.confirmation_notes || "-"}
              </ConfirmationItemLeft>
            </ConfirmationItem>
          </ConfirmationList>
        </ConfirmationContent>
        <ModalButtonContainer>
          <Button
            small
            kind="primary"
            type="button"
            text="Back"
            onClick={onRequestClose}
          />
        </ModalButtonContainer>
      </ContentContainer>
    </Modal>
  );
};

const { bool, func, shape, arrayOf, string } = PropTypes;

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf(shape({})),
};

InfoModal.defaultProps = {
  data: [],
};

export default InfoModal;
