import React, { useEffect, useState } from 'react';
import { Heading, Button } from 'shared';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { Brand } from 'components';
import { useParams, useHistory } from 'react-router-dom';
import VerifyEmailSVG from 'assets/images/verify-email.svg';
import { Loading } from 'pages';
import {
  Container,
  Section,
  Icon,
  HeadingContainer,
} from 'pages/user-verification/style';

const VerifyEmail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      axiosApiInstance.put(`${ENDPOINTS('customer.authentication.userVerfication')}/${id}`, {})
        .then((res) => {
          if (res && res.data && res.data.value && res.data.value.userToken) {
            localStorage.setItem('access_token', res.data.value.userToken);
            history.push('/');
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading
        ? <Loading />
        : (
          <>
            <Brand />
            <Container>
              <Section>
                <Icon src={VerifyEmailSVG} />
                <HeadingContainer>
                  <Heading
                    kind="title"
                    text="Verification Expired"
                  />
                  <Heading
                    kind="subtitle"
                    text="Please Sign Up againt"
                  />
                  <Button
                    kind="primary"
                    type="submit"
                    text="Back to Sign Up"
                    onClick={() => history.push('/sign-up')}
                  />
                </HeadingContainer>
              </Section>
            </Container>
          </>
        )}

    </>
  );
};

export default VerifyEmail;
