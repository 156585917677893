import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import {
  Button,
  Heading,
  Input,
} from 'shared';
import {
  Brand,
  EmailSent,

  // Decoratives
  HalfBall2,
  Confetti2,
} from 'components';
import { Container, Form, ButtonsContainer } from 'pages/forgot-password/style';

const ForgotPasswordForm = ({ emailSent }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const schema = Joi.object({
        email: Joi.string().required(),
      });

      const value = await schema.validateAsync({ email });
      axiosApiInstance.post(ENDPOINTS('customer.authentication.forgotPassword'), value)
        .then(() => emailSent())
        .catch((err) => {
          setIsSubmitting(false);
          setErrorMessage(err.response.data.m);
        });
    } catch (err) {
      setIsSubmitting(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <Brand />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Heading
            kind="title"
            text="Forgot Password"
          />
          <Input
            type="text"
            label="Email Address"
            name="email"
            value={email}
            onChange={setEmail}
            placeholder="Type your email address"
            errorMessage={errorMessage}
            disabled={isSubmitting}
          />
          <ButtonsContainer>
            <Button
              kind="secondary"
              type="button"
              text="Back"
              onClick={() => history.goBack()}
              disabled={isSubmitting}
            />
            <Button
              kind="primary"
              type="submit"
              text="Send Email"
              disabled={isSubmitting}
            />
          </ButtonsContainer>
        </Form>
      </Container>

      {/* Decoratives *************************************** */}
      <HalfBall2 v2 />
      <Confetti2 />
      {/* *************************************************** */}
    </>
  );
};

const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);

  const emailSent = () => setIsEmailSent(true);

  return (
    <>
      {
        isEmailSent
          ? <EmailSent />
          : <ForgotPasswordForm emailSent={emailSent} />
      }
    </>
  );
};

const { func } = PropTypes;

ForgotPasswordForm.propTypes = {
  emailSent: func.isRequired,
};

export default ForgotPassword;
