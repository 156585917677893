import styled from 'styled-components';
import { Heading } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 60%;

  ${Heading.Heading} {
    position: relative;
    right: 1.5rem;
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  position: relative;
  right: 1.3125rem;
`;

export const Section = styled.div`
  width: 60%;
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
`;
