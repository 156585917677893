import styled from 'styled-components';

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
  flex-wrap: wrap;
`;

export const List = styled.div`
  border-radius: 0.625rem;
  padding: 0.5rem 0.625rem;
  background-color: ${(props) => (props.isEdited ? props.theme.list.background : '#DDDDDD')};
  color: ${(props) => (props.isEdited ? props.theme.list.text : '#727272')};
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
`;

export const Icon = styled.img`
  position: relative;
  top: 0.2rem;
  margin-left: 0.375rem;
  cursor: pointer;
`;

export const Input = styled.input`
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.list.text};
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  width:${(props) => `${(props.charLength + 2) * 0.5}rem`};
`;
