import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { useHistory, useParams } from 'react-router-dom';
import { Heading } from 'shared';
import {
  Brand,
  ProfileButton,
  ButtonIcon,
  Summary,
} from 'components';
import {
  Container,
  HeadingContainer,
  Icon,
  Section,
  Info,
  InfoTitle,
  InfoFieldName,
  InfoValue,
  RightSection,
  DownloadButtonContainers,
  Detail,
  DetailHeader,
} from 'pages/event-history/detail/style';
import LeftArrowSVG from 'assets/icons/left-arrow.svg';
import EventReportSVG from 'assets/icons/event-report.svg';
import BillReportSVG from 'assets/icons/bill-report.svg';

const EventHistoryDetail = () => {
  const history = useHistory();
  const [eventHistoryDetail, setEventHistoryDetail] = useState({});
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    axiosApiInstance.get(`${ENDPOINTS('customer.eventHistory.eventHistory')}/${id}`)
      .then(({ data: { value: { event } } }) => {
        setEventHistoryDetail(event);
      });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (eventHistoryDetail) {
      const {
        address,
        bride_name,
        category,
        event_date_string,
        event_venue,
        groom_name,
      } = eventHistoryDetail;
      if (category === 'Wedding') {
        const summaryData = [
          {
            fieldName: 'Groom\'s Full Name',
            value: groom_name,
          },
          {
            fieldName: 'Bride\'s Full Name',
            value: bride_name,
          },
          {
            fieldName: 'Event Date',
            value: moment.utc(event_date_string).format('DD-MM-YYYY'),
          },
          {
            fieldName: 'Event Time',
            value: moment.utc(event_date_string).format('hh:mm'),
          },
          {
            fieldName: 'Event Summary',
            value: event_venue,
          },
          {
            fieldName: 'Venue Address',
            value: address,
          },
        ];
        setData(summaryData);
      } else {

      }
    }
  }, [eventHistoryDetail]);

  const {
    category,
    maximum_capacity,
    name: eventName,
    type,
  } = eventHistoryDetail;

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <HeadingContainer>
          <Heading
            kind="title"
            text={(
              <p>
                <Icon src={LeftArrowSVG} />
                History Detail
              </p>
            )}
            onClick={() => history.goBack()}
          />
        </HeadingContainer>
        <Section>
          <Info color="green">
            <InfoTitle>
              {eventName}
            </InfoTitle>
            <InfoFieldName>Category</InfoFieldName>
            <InfoValue>{category}</InfoValue>
            <InfoFieldName>Event Type</InfoFieldName>
            <InfoValue>{type}</InfoValue>
            <InfoFieldName>Total Guest</InfoFieldName>
            <InfoValue>
              {maximum_capacity}
              {' '}
              pax
            </InfoValue>
          </Info>
          <RightSection>
            <DownloadButtonContainers>
              <ButtonIcon
                text={(
                  <p>
                    Download
                    <br />
                    Event Report
                  </p>
                )}
                icon={EventReportSVG}
              />
              <ButtonIcon
                text={(
                  <p>
                    Download
                    <br />
                    Bill Report
                  </p>
                )}
                icon={BillReportSVG}
              />
            </DownloadButtonContainers>
            <Detail>
              <DetailHeader>Event Details</DetailHeader>
              <Summary data={data} />
            </Detail>
          </RightSection>
        </Section>
      </Container>
    </>
  );
};

export default EventHistoryDetail;
