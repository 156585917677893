import React from "react";
import { Root, Title, DropwdownItems, ItemsContainer } from "./style";

const Dropwdown = ({
  icon,
  title,
  data,
  onClick,
  onClickItems,
  collapsed = false,
  isFilter = false,
}) => {
  return (
    <>
      <Root onClick={onClick}>
        <img src={icon} alt="dropdown" />
        <Title title={title}>{title}</Title>
      </Root>
      {collapsed && (
        <DropwdownItems isFilter={isFilter}>
          {data.map((item, index) => {
            return (
              <ItemsContainer key={index} onClick={() => onClickItems(item)}>
                <Title>{item.label}</Title>
              </ItemsContainer>
            );
          })}
        </DropwdownItems>
      )}
    </>
  );
};

export default Dropwdown;
