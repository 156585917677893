import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SVGInject from "@iconfu/svg-inject";
import {
  Modal,
  TableApproval,
  Table,
  BadgeSelect,
  AssignerCategory,
} from "components";
import { Button, Heading } from "shared";
import {
  FloatingMenu,
  Selected,
  ButtonMenu,
  IconContainer,
  Icon,
  AssignCategoryClose,
  AssignCategoryHeader,
  AssignCategoryTitle,
  AssignCategoryDesc,
  AssignCategoryContentContainer,
  AssignCategoryButtonContainer,
  AssignCategoryListContainer,
  AssignCategoryTableSelected,
  DetailClose,
  DetailContentContainer,
  DetailHeader,
  DetailHeaderTitle,
  DetailTableContainer,
  DetailButtonContainer,
  FloatingMenuContainer,
  ModalContainer,
  ModalHeaderWithoutBadge,
  ListContainer,
  ListItem,
  ModalClose,
  ModalButtonContainer,
  PaginationArrow,
  PaginationText,
  PaginationContainer,
} from "pages/table-management/floating-menu/style";
import { Scrollbars } from "react-custom-scrollbars";
import FloatingMenuCloseSVG from "assets/icons/floating-menu-close.svg";
import ExitSVG from "assets/icons/exit.svg";
import AssignCategorySVG from "assets/icons/assign-category.svg";
import AssignCategory2SVG from "assets/icons/assign-category-2.svg";
import DeleteTableSVG from "assets/icons/delete-table.svg";
import GuestDetailSVG from "assets/icons/guest-detail.svg";
import TableDetailSVG from "assets/icons/table-detail.svg";
import DeleteConfirmSVG from "assets/images/delete-confirm.svg";
import BackPaginationSVG from "assets/icons/back-pagination.svg";
import NextPaginationSVG from "assets/icons/next-pagination.svg";
import DeleteTableFailSVG from "assets/icons/delete-table-fail.svg";

const AssignCategory = ({
  isOpen,
  onRequestClose,
  availableCategories,
  data,
  assignCategory,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const selectCategory = (i) => {
    const tempCategories = [];
    selectedCategories.map((item, index) => {
      if (index === i) {
        tempCategories.push({ ...item, select: !item.select });
      } else {
        tempCategories.push(item);
      }
      return true;
    });
    setSelectedCategories(tempCategories);
  };

  const removeItem = (row) => {
    // delete selectedTable[row];
    const tempList = [];
    selectedTable.map((item) => {
      tempList.push(item);
      return true;
    });
    tempList.splice(row, 1);
    if (!tempList.length) {
      onRequestClose();
      setSelectedTable(tempList);
    } else {
      setSelectedTable(tempList);
    }
  };

  useEffect(() => {
    const tempList = [];
    data.map((item) => {
      if (item.checked) {
        tempList.push(item);
      }
      return true;
    });
    setSelectedTable(tempList);
    setSelectedCategories(availableCategories);
  }, [isOpen, availableCategories, data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <>
        <AssignCategoryClose src={ExitSVG} onClick={onRequestClose} />
        <AssignCategoryHeader>
          <Icon src={AssignCategory2SVG} />
          <AssignCategoryTitle>Assign Category</AssignCategoryTitle>
        </AssignCategoryHeader>
        <AssignCategoryContentContainer>
          <AssignCategoryListContainer>
            <AssignCategoryDesc>
              Choose a category below to define the table / tables
            </AssignCategoryDesc>
            <BadgeSelect
              data={selectedCategories}
              setSelected={selectCategory}
              useIcon
            />
          </AssignCategoryListContainer>
          <AssignCategoryTableSelected>
            {selectedTable.length} Table Selected
          </AssignCategoryTableSelected>
          {/* <Assigner data={DUMMY_ASSIGNEE} /> */}
          <AssignerCategory
            data={selectedTable}
            removeItem={removeItem}
            name="name"
            label="Table "
          />
          <AssignCategoryButtonContainer>
            <Button
              kind="primary"
              type="button"
              text="Assign"
              onClick={() => assignCategory(selectedCategories)}
            />
          </AssignCategoryButtonContainer>
        </AssignCategoryContentContainer>
      </>
    </Modal>
  );
};

const GuestDetail = ({ isOpen, onRequestClose, data }) => {
  const cols = [
    {
      title: "No",
      position: "",
      render: (rowData, index) => (
        <span style={{ width: "100%" }}>{index + 1}</span>
      ),
    },
    {
      title: "Invitation Name",
      position: "",
      render: (rowData) => <span>{rowData.invitationName}</span>,
    },
    {
      title: "Guest Name",
      position: "",
      render: (rowData) => <span>{rowData.name}</span>,
    },
    {
      title: "Attending",
      position: "center",
      render: (rowData) => <TableApproval status />,
    },
    {
      title: "Arrival",
      position: "center",
      render: (rowData) => <TableApproval status />,
    },
  ];

  const [selectedTable, setSelectedTable] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const doNext = () => {
    if (pageNumber === selectedTable.length - 1) {
      setPageNumber(0);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  const doBack = () => {
    if (pageNumber === 0) {
      setPageNumber(selectedTable.length - 1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    const list = data.reduce((acc, curr) => {
      if (curr.checked) {
        acc.push(curr.guests);
      }

      return acc;
    }, []);

    setSelectedTable(list);
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <>
        <DetailClose src={ExitSVG} onClick={onRequestClose} />
        <DetailHeader>
          <Icon src={TableDetailSVG} />
          <DetailHeaderTitle>Guest Detail - Table 01</DetailHeaderTitle>
        </DetailHeader>
        <DetailContentContainer>
          {selectedTable.length > 1 && (
            <PaginationContainer>
              <PaginationArrow src={BackPaginationSVG} onClick={doBack} />
              <PaginationText>
                {pageNumber + 1} of {selectedTable.length} Tables
              </PaginationText>
              <PaginationArrow src={NextPaginationSVG} onClick={doNext} />
            </PaginationContainer>
          )}
          <DetailTableContainer>
            {selectedTable[pageNumber] && selectedTable[pageNumber].length ? (
              <Table readOnly cols={cols} data={selectedTable[pageNumber]} />
            ) : (
              ""
            )}
          </DetailTableContainer>
          <DetailButtonContainer>
            <Button
              kind="primary"
              type="button"
              text="Close"
              onClick={onRequestClose}
            />
          </DetailButtonContainer>
        </DetailContentContainer>
      </>
    </Modal>
  );
};

const DeleteConfirm = ({
  isOpen,
  onRequestClose,
  data,
  deleteTables,
  setShowDeleteFail,
  setDeleteDataFail,
}) => {
  const printDeleted = data.filter((d) => !!d.checked);
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <>
        <ModalClose src={ExitSVG} onClick={onRequestClose} />
        <ModalContainer>
          <ModalHeaderWithoutBadge>
            <Icon
              src={DeleteConfirmSVG}
              style={{ width: "100px", height: "100px", margin: "auto" }}
            />
            <Heading
              kind="subtitle"
              text="Some tables can’t be deleted since there are guests already assigned to these tables."
              marginBottom="0.5rem"
            />
          </ModalHeaderWithoutBadge>
          <ListContainer>
            <Scrollbars autoHide style={{ minHeight: "139px", width: "343px" }}>
              {printDeleted.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {item.name}
                </ListItem>
              ))}
            </Scrollbars>
          </ListContainer>
          <ModalButtonContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Back"
              onClick={onRequestClose}
            />
            <Button
              small
              kind="warning"
              type="button"
              text="Delete"
              onClick={() =>
                deleteTables(
                  setShowDeleteFail,
                  setDeleteDataFail,
                  onRequestClose
                )
              }
            />
          </ModalButtonContainer>
        </ModalContainer>
      </>
    </Modal>
  );
};

const DeleteFail = ({ isOpen, onRequestClose, failData }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    <>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon
            src={DeleteTableFailSVG}
            style={{ width: "100px", height: "100px", margin: "auto" }}
          />
          <Heading
            kind="subtitle"
            text=" Some tables can’t be deleted since there are guests already assigned to these tables."
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>
        <ListContainer>
          <Scrollbars autoHide style={{ minHeight: "139px", width: "343px" }}>
            {failData.map((item, index) => (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {item.name}
              </ListItem>
            ))}
          </Scrollbars>
        </ListContainer>
        <ModalButtonContainer>
          <Button
            small
            kind="secondary"
            type="button"
            text="Back"
            onClick={onRequestClose}
          />
        </ModalButtonContainer>
      </ModalContainer>
    </>
  </Modal>
);

const FloatingMenuComponent = ({
  data,
  tempData,
  searchTerm,
  setData,
  setShowFloatingMenu,
  setIsSelectedAll,
  availableCategories,
  assignCategoryIntialize,
  assignCategory,
  deleteTables,
}) => {
  const [isAssignCategoryOpen, setIsAssignCategoryOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const closeAssignCategory = () => setIsAssignCategoryOpen(false);
  const closeDetail = () => setIsDetailOpen(false);
  const [showDeleteFail, setShowDeleteFail] = useState(false);
  const [deleteDataFail, setDeleteDataFail] = useState([]);

  const unSelectAll = () => {
    setShowFloatingMenu(false);
    setIsSelectedAll(false);
    const tempList = [];
    data.forEach((item) => {
      tempList.push({ ...item, checked: false });
    });
    tempList.forEach((item) => {
      item.checked = false;
    });
    setData(tempList);
  };

  useEffect(() => {
    let tempCount = 0;
    if (!searchTerm) {
      data.forEach((item) => {
        if (item.checked === true) {
          tempCount += 1;
        }
      });
    } else {
      tempData.forEach((item) => {
        if (item.checked === true) {
          tempCount += 1;
        }
      });
    }
    setNumSelected(tempCount);
  }, [data]);

  useEffect(() => {
    SVGInject(document.querySelectorAll("img.injectable"));
  }, []);

  return (
    <>
      <AssignCategory
        isOpen={isAssignCategoryOpen}
        onRequestClose={closeAssignCategory}
        assignCategory={assignCategory}
        availableCategories={availableCategories}
        data={data}
      />
      <GuestDetail
        isOpen={isDetailOpen}
        data={data}
        onRequestClose={closeDetail}
      />
      <DeleteConfirm
        isOpen={showDeleteConfirm}
        data={data}
        deleteTables={deleteTables}
        setShowDeleteFail={setShowDeleteFail}
        setDeleteDataFail={setDeleteDataFail}
        onRequestClose={() => {
          setShowDeleteConfirm(false);
        }}
      />
      <DeleteFail
        isOpen={showDeleteFail}
        onRequestClose={() => {
          setShowDeleteFail(false);
        }}
        failData={deleteDataFail}
      />
      <FloatingMenuContainer>
        <FloatingMenu>
          <Selected>
            <IconContainer onClick={unSelectAll}>
              <Icon className="injectable" src={FloatingMenuCloseSVG} />
            </IconContainer>
            {`${numSelected} Table Selected`}
          </Selected>

          <ButtonMenu success onClick={() => setIsDetailOpen(true)}>
            <Icon className="injectable" src={GuestDetailSVG} />
            Guest Detail
          </ButtonMenu>

          <ButtonMenu
            success
            onClick={() => {
              setIsAssignCategoryOpen(true);
              assignCategoryIntialize();
            }}
          >
            <Icon className="injectable" src={AssignCategorySVG} />
            Assign Category
          </ButtonMenu>

          <ButtonMenu
            danger
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          >
            <Icon className="injectable" src={DeleteTableSVG} />
            Delete Table
          </ButtonMenu>
        </FloatingMenu>
      </FloatingMenuContainer>
    </>
  );
};

const { bool, number, func, arrayOf, shape, string } = PropTypes;

GuestDetail.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf(shape({})),
};

GuestDetail.defaultProps = {
  data: [],
};

AssignCategory.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

FloatingMenuComponent.propTypes = {
  numSelected: number,
  unSelectAll: func,
  tempData: arrayOf(shape({})),
  searchTerm: string,
};

FloatingMenuComponent.defaultProps = {
  numSelected: 0,
  unSelectAll: () => {},
  tempData: [],
  searchTerm: "",
};

export default FloatingMenuComponent;
