import React from "react";
import PropTypes from "prop-types";
import { Container } from "components/food-pie-chart/style";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
const FoodPieChart = ({ data }) => {
  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    animation: false,
    cutout: "45%",
  };

  const datas = {
    datasets: [
      {
        data: data,
        backgroundColor: [
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
        ],
        borderColor: [
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
        ],
        borderWidth: 0,
        hoverBackgroundColor: [
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
        ],
        hoverBorderColor: [
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
          "#C1934D",
          "#096B81",
          "#438FD4",
          "#9B558C",
          "#6D78DB",
          "#40A790",
          "#7CC9DA",
          "#B3936D",
          "#13934D",
          "#FCC9DA",
        ],
        hoverBorderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <Doughnut data={datas} options={options} />
    </Container>
  );
};

const { oneOfType, arrayOf, shape, array } = PropTypes;

FoodPieChart.propTypes = {
  data: array,
};

FoodPieChart.defaultProps = {
  data: [],
};

export default FoodPieChart;
