/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalContainer,
  ModalClose,
  ModalHeaderWithoutBadge,
} from 'pages/admin/dashboard-admin/invoices/modal/invoice-sent/style';
import EmailSentSVG from 'assets/images/email-sent.svg';
import ExitSVG from 'assets/icons/exit.svg';

const InvoiceSent = ({
  isOpen,
  onRequestClose,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon src={EmailSentSVG} />
          <Heading
            kind="title"
            text="Invoice Sent!"
            marginBottom="0.5rem"
          />
          <Heading
            kind="subtitle"
            text="Invoice has been sent to client. Back to this page to change the payment status."
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>
      </ModalContainer>
    </>
  </Modal>
);

const { bool, func } = PropTypes;

InvoiceSent.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

export default InvoiceSent;
