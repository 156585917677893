/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from "react";
import { store } from "store";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { Button } from "shared";
import {
  Modal,
  Summary,
  BadgeSelect,
  TableButton,
  Tabgroups,
} from "components";
import {
  Icon,
  ContentContainer,
  SummaryContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalButtonContainer,
  TableButtonContainer,
  IconClose,
  PaginationArrow,
  PaginationText,
  PaginationContainer,
  HealthCheckText,
  ConfirmationContainer,
  ConfirmationDetail,
  FlightContainer,
  FlightContent,
  FlightItems,
} from "pages/guest-list/floating-menu/style";
import InfoTableSVG from "assets/icons/info-table.svg";
import WhatsappSmallSVG from "assets/icons/whatsapp-small.svg";
import EmailSmallSVG from "assets/icons/email-small.svg";
import ExitSVG from "assets/icons/exit.svg";
import BackPaginationSVG from "assets/icons/back-pagination.svg";
import NextPaginationSVG from "assets/icons/next-pagination.svg";
import moment from "moment";
import { axiosApiInstance } from "helpers/authentication";
import ENDPOINTSV2 from "helpers/endpoints-v2";
import Loading from "pages/loading";

const InfoModal = ({
  isOpen,
  onRequestClose,
  data,
  openConfirmDetail,
  openInvitationStatus,
  openQrCodeStatus,
}) => {
  const {
    state: { eventDetail },
  } = useContext(store);
  // const [categories, setCategories] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [summaryData, setSummaryData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const tabData = [
    "Invitation Status",
    "Invitation Detail",
    "Guest Detail",
    eventDetail.accommodation_status && "Accommodation",
    "Guest Category",
  ];

  const doNext = () => {
    if (pageNumber === selectedTable.length - 1) {
      setPageNumber(0);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  const doBack = () => {
    if (pageNumber === 0) {
      setPageNumber(selectedTable.length - 1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    // setLoading(true);
    const tableSelected = data.reduce((acc, curr) => {
      if (curr.checked) {
        acc.push({ ...curr });
      }

      return acc;
    }, []);

    axiosApiInstance
      .post(`${ENDPOINTSV2("customer.invitation.invitation")}/detail`, {
        invitationIds: tableSelected.map((item) => item._id),
      })
      .then((res) => {
        // setLoading(false);
        setSelectedTable(res.data?.value?.invitations);
      })
      .catch((error) => {
        // error message
        console.log(error);
      });
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [data]);

  useEffect(() => {
    if (selectedTable.length) {
      const {
        _id: id,
        address,
        category: categories,
        email,
        email_invitation_status: emailInvitationStatus,
        guestNames,
        invitation_status: invitationStatus,
        name,
        notes,
        pax,
        origin_pax,
        phone,
        phone_code: phoneCode,
        wa_invitation_status: waInvitationStatus,
        order,
        health_check_status,
        confirmation_address,
        confirmation_attending_status,
        wa_blast_status,
        qr_blast_status,
        url_qr_code,
        accommodation_check_in_date_string,
        accommodation_check_out_date_string,
        accommodation_room_number,
        guests,
      } = selectedTable[pageNumber] || {};

      let invitation_status_color;
      let qr_code_status_color;
      switch ((waStats(selectedTable[pageNumber]) || "").toLowerCase()) {
        case "pending":
          invitation_status_color = "#F2994A";
          break;
        case "sent":
          invitation_status_color = "#438FD4";
          break;
        case "delivered":
          invitation_status_color = "#BDBDBD";
          break;
        case "read":
          invitation_status_color = "#40A790";
          break;
        case "rejected":
          invitation_status_color = "#E76E6E";
          break;
        default:
          invitation_status_color = "#E76E6E";
      }

      switch ((qrCodeStats(selectedTable[pageNumber]) || "").toLowerCase()) {
        case "pending":
          qr_code_status_color = "#F2994A";
          break;
        case "sent":
          qr_code_status_color = "#438FD4";
          break;
        case "delivered":
          qr_code_status_color = "#BDBDBD";
          break;
        case "read":
          qr_code_status_color = "#40A790";
          break;
        case "rejected":
          qr_code_status_color = "#E76E6E";
          break;
        default:
          qr_code_status_color = "#E76E6E";
      }

      // eslint-disable-next-line no-shadow
      let summaryData;
      if (eventDetail.health_check_status) {
        if (selectedTab === 0) {
          summaryData = [
            {
              fieldName: "Invitation Status",
            },
            {
              fieldName: "Sent Via",
              value: invitationStatus ? (
                <TableButtonContainer>
                  {waInvitationStatus && (
                    <TableButton text="Whatsapp" icon={WhatsappSmallSVG} />
                  )}
                  {emailInvitationStatus && (
                    <TableButton text="Email" icon={EmailSmallSVG} />
                  )}
                </TableButtonContainer>
              ) : (
                "-"
              ),
            },
            {
              fieldName: "Health Check",
              value: health_check_status ? (
                <HealthCheckText>Yes</HealthCheckText>
              ) : (
                "No"
              ),
            },
            {
              fieldName: "Invitation Status",
              value: (
                <ConfirmationContainer>
                  <div
                    style={{
                      textTransform: "capitalize",
                      color: invitation_status_color,
                    }}
                  >
                    {wa_blast_status || "-"}
                  </div>
                  <ConfirmationDetail
                    onClick={() => {
                      openInvitationStatus(selectedTable[pageNumber]);
                    }}
                  >
                    {wa_blast_status ? "See Detail" : " "}
                  </ConfirmationDetail>
                </ConfirmationContainer>
              ),
            },
            {
              fieldName: "QR Code Status",
              value: (
                <ConfirmationContainer>
                  <div
                    style={{
                      textTransform: "capitalize",
                      color: qr_code_status_color,
                    }}
                  >
                    {qrCodeStats(selectedTable[pageNumber]) || "-"}
                  </div>
                  <ConfirmationDetail
                    onClick={() => {
                      openQrCodeStatus(selectedTable[pageNumber]);
                    }}
                  >
                    {qr_blast_status ? "See Detail" : ""}
                  </ConfirmationDetail>
                </ConfirmationContainer>
              ),
            },
            {
              fieldName: "Confirmation",
              value: (
                <ConfirmationContainer>
                  {confirmation_attending_status}
                  <ConfirmationDetail
                    onClick={() => {
                      openConfirmDetail(selectedTable[pageNumber]);
                    }}
                  >
                    {confirmation_attending_status ? "See Detail" : ""}
                  </ConfirmationDetail>
                </ConfirmationContainer>
              ),
            },
          ];
        } else if (selectedTab === 1) {
          summaryData = [
            {
              fieldName: "Invitation Detail",
            },
            {
              fieldName: "Guest ID",
              value: order,
            },
            {
              fieldName: "Invitation Name",
              value: name,
            },
            {
              fieldName: "Phone Number",
              value: `${phoneCode}${phone}`,
            },
            {
              fieldName: "Email",
              value: email || "-",
            },
            {
              fieldName: "Address",
              value: address || "-",
            },
            {
              fieldName: "Notes",
              value: notes || "-",
            },
            {
              fieldName: "QR Code Link",
              value:
                (
                  <a href={url_qr_code} target="_blank" rel="noreferrer">
                    {url_qr_code}
                  </a>
                ) || "-",
            },
            {
              fieldName: "Typeform Link",
              value:
                (
                  <a
                    href={`https://eventco.id/form/${id}?name=${name.replace(
                      / /g,
                      "-"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://eventco.id/form/${id}?name=${name.replace(
                      / /g,
                      "-"
                    )}`}
                  </a>
                ) || "-",
            },
          ];
        } else if (selectedTab === 2) {
          summaryData = [
            {
              fieldName: "Guest Detail",
            },
            {
              fieldName: "Origin Pax",
              value: origin_pax ? origin_pax : pax,
            },
            {
              fieldName: "Total Pax",
              value: pax,
            },
            ...guests.reduce(
              (acc, curr, index) => {
                acc.push(
                  {
                    fieldName: `Guest Name ${index + 1}`,
                    value: curr.name,
                  },
                  {
                    fieldName: "Food Preference",
                    value: curr.notes || "-",
                  },
                  {
                    fieldName: "Table Number",
                    value: !curr.table?.name ? "-" : curr.table?.name,
                  },
                  eventDetail.flight_details_status && {
                    fieldName: "Flight Information",
                    value: (
                      <FlightContainer>
                        <FlightContent>
                          <div></div>
                          <h3>ARRIVAL</h3>
                          <FlightItems>
                            <p>Flight Number</p>
                            <span>{curr.arrival_flight_number || "-"}</span>
                          </FlightItems>
                          <FlightItems>
                            <p>Date & Time</p>
                            <span>
                              {curr.arrival_flight_date_string
                                ? moment(
                                    curr.arrival_flight_date_string
                                  ).format("DD MMMM YYYY - HH:mm A")
                                : "-"}
                            </span>
                          </FlightItems>
                        </FlightContent>
                        <FlightContent
                          style={{
                            borderBottom: "none",
                          }}
                        >
                          <div></div>
                          <h3>DEPARTURE</h3>
                          <FlightItems>
                            <p>Flight Number</p>
                            <span>{curr.departure_flight_number || "-"}</span>
                          </FlightItems>
                          <FlightItems>
                            <p>Date & Time</p>
                            <span>
                              {curr.departure_flight_date_string
                                ? moment(
                                    curr.departure_flight_date_string
                                  ).format("DD MMMM YYYY - HH:mm A")
                                : "-"}
                            </span>
                          </FlightItems>
                        </FlightContent>
                      </FlightContainer>
                    ),
                  }
                );

                return acc;
              },
              [selectedTable]
            ),
          ];
        } else if (selectedTab === 3 && eventDetail.accommodation_status) {
          summaryData = [
            {
              fieldName: "Accommodation",
              // value: (
              //   <FlightContainer>
              //     <FlightContent
              //       style={{
              //         borderBottom: "none",
              //       }}
              //     >
              //       <div></div>
              //       <FlightItems>
              //         <p style={{ width: "107px" }}>Check-In Date</p>
              //         <span>
              //           {accommodation_check_in_date_string
              //             ? moment(accommodation_check_in_date_string).format(
              //                 "MM/DD/YYY"
              //               )
              //             : "-"}
              //         </span>
              //       </FlightItems>
              //       <FlightItems>
              //         <p style={{ width: "107px" }}>Check-Out Date</p>
              //         <span>
              //           {accommodation_check_out_date_string
              //             ? moment(accommodation_check_out_date_string).format(
              //                 "MM/DD/YYY"
              //               )
              //             : "-"}
              //         </span>
              //       </FlightItems>
              //       <FlightItems>
              //         <p style={{ width: "107px" }}>Room Number</p>
              //         <span>{accommodation_room_number || "-"}</span>
              //       </FlightItems>
              //     </FlightContent>
              //   </FlightContainer>
              // ),
            },
            {
              fieldName: "Check-In Date",
              value: accommodation_check_in_date_string
                ? moment(accommodation_check_in_date_string).format(
                    "DD MMMM YYYY"
                  )
                : "-",
            },
            {
              fieldName: "Check-Out Date",
              value: accommodation_check_out_date_string
                ? moment(accommodation_check_out_date_string).format(
                    "DD MMMM YYYY"
                  )
                : "-",
            },
            {
              fieldName: "Room Numbe",
              value: accommodation_room_number || "-",
            },
          ];
        } else {
          summaryData = [
            {
              fieldName: "Guest Category",
            },
            {
              value:
                categories.length >= 1 ? (
                  <BadgeSelect data={categories} />
                ) : (
                  "-"
                ),
            },
          ];
        }
      } else {
        if (selectedTab === 0) {
          summaryData = [
            {
              fieldName: "Invitation Status",
            },
            {
              fieldName: "Sent Via",
              value: invitationStatus ? (
                <TableButtonContainer>
                  {waInvitationStatus && (
                    <TableButton text="Whatsapp" icon={WhatsappSmallSVG} />
                  )}
                  {emailInvitationStatus && (
                    <TableButton text="Email" icon={EmailSmallSVG} />
                  )}
                </TableButtonContainer>
              ) : (
                "-"
              ),
            },
            {
              fieldName: "Invitation Status",
              value: (
                <ConfirmationContainer>
                  <div
                    style={{
                      textTransform: "capitalize",
                      color: invitation_status_color,
                    }}
                  >
                    {waStats(selectedTable[pageNumber]) || "-"}
                  </div>
                  <ConfirmationDetail
                    onClick={() => {
                      openInvitationStatus(selectedTable[pageNumber]);
                    }}
                  >
                    {wa_blast_status ? "See Detail" : ""}
                  </ConfirmationDetail>
                </ConfirmationContainer>
              ),
            },
            {
              fieldName: "QR Code Status",
              value: (
                <ConfirmationContainer>
                  <div
                    style={{
                      textTransform: "capitalize",
                      color: qr_code_status_color,
                    }}
                  >
                    {qrCodeStats(selectedTable[pageNumber]) || "-"}
                  </div>
                  <ConfirmationDetail
                    onClick={() => {
                      openQrCodeStatus(selectedTable[pageNumber]);
                    }}
                  >
                    {qr_blast_status ? "See Detail" : ""}
                  </ConfirmationDetail>
                </ConfirmationContainer>
              ),
            },
            {
              fieldName: "Confirmation",
              value: (
                <ConfirmationContainer>
                  {confirmation_attending_status || "-"}
                  <ConfirmationDetail
                    onClick={() => {
                      openConfirmDetail(selectedTable[pageNumber]);
                    }}
                  >
                    {confirmation_attending_status ? "See Detail" : " "}
                  </ConfirmationDetail>
                </ConfirmationContainer>
              ),
            },
          ];
        } else if (selectedTab === 1) {
          summaryData = [
            {
              fieldName: "Invitation Detail",
            },
            {
              fieldName: "Guest ID",
              value: order,
            },
            {
              fieldName: "Invitation Name",
              value: name,
            },
            {
              fieldName: "Phone Number",
              value: `${phoneCode}${phone}`,
            },
            {
              fieldName: "Email",
              value: email || "-",
            },
            {
              fieldName: "Address",
              value: address || "-",
            },
            {
              fieldName: "Notes",
              value: notes || "-",
            },
            {
              fieldName: "QR Code Link",
              value:
                (
                  <a href={url_qr_code} target="_blank" rel="noreferrer">
                    {url_qr_code}
                  </a>
                ) || "-",
            },
            {
              fieldName: "Typeform Link",
              value:
                (
                  <a
                    href={`https://eventco.id/form/${id}?name=${name.replace(
                      / /g,
                      "-"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://eventco.id/form/${id}?name=${name.replace(
                      / /g,
                      "-"
                    )}`}
                  </a>
                ) || "-",
            },
          ];
        } else if (selectedTab === 2) {
          summaryData = [
            {
              fieldName: "Guest Detail",
            },
            {
              fieldName: "Origin Pax",
              value: origin_pax ? origin_pax : pax,
            },
            {
              fieldName: "Total Pax",
              value: pax,
            },
            ...guests.reduce(
              (acc, curr, index) => {
                acc.push(
                  {
                    fieldName: `Guest Name ${index + 1}`,
                    value: curr.name,
                  },
                  {
                    fieldName: "Food Preference",
                    value: curr.notes || "-",
                  },
                  {
                    fieldName: "Table Number",
                    value: !curr.table?.name ? "-" : curr.table?.name,
                  },
                  eventDetail.flight_details_status && {
                    fieldName: "Flight Information",
                    value: (
                      <FlightContainer>
                        <FlightContent>
                          <div></div>
                          <h3>ARRIVAL</h3>
                          <FlightItems>
                            <p>Flight Number</p>
                            <span>{curr.arrival_flight_number || "-"}</span>
                          </FlightItems>
                          <FlightItems>
                            <p>Date & Time</p>
                            <span>
                              {curr.arrival_flight_date_string
                                ? moment(
                                    curr.arrival_flight_date_string
                                  ).format("DD MMMM YYYY - HH:mm A")
                                : "-"}
                            </span>
                          </FlightItems>
                        </FlightContent>
                        <FlightContent
                          style={{
                            borderBottom: "none",
                          }}
                        >
                          <div></div>
                          <h3>DEPARTURE</h3>
                          <FlightItems>
                            <p>Flight Number</p>
                            <span>{curr.departure_flight_number || "-"}</span>
                          </FlightItems>
                          <FlightItems>
                            <p>Date & Time</p>
                            <span>
                              {curr.departure_flight_date_string
                                ? moment(
                                    curr.departure_flight_date_string
                                  ).format("DD MMMM YYYY - HH:mm A")
                                : "-"}
                            </span>
                          </FlightItems>
                        </FlightContent>
                      </FlightContainer>
                    ),
                  }
                );

                return acc;
              },
              [selectedTable]
            ),
          ];
        } else if (selectedTab === 3 && eventDetail.accommodation_status) {
          summaryData = [
            {
              fieldName: "Accommodation",
            },
            {
              fieldName: "Check-In Date",
              value: accommodation_check_in_date_string
                ? moment(accommodation_check_in_date_string).format(
                    "DD MMMM YYYY"
                  )
                : "-",
            },
            {
              fieldName: "Check-Out Date",
              value: accommodation_check_out_date_string
                ? moment(accommodation_check_out_date_string).format(
                    "DD MMMM YYYY"
                  )
                : "-",
            },
            {
              fieldName: "Room Numbe",
              value: accommodation_room_number || "-",
            },
          ];
        } else {
          summaryData = [
            {
              fieldName: "Guest Category",
            },
            {
              value:
                categories.length >= 1 ? (
                  <BadgeSelect data={categories} />
                ) : (
                  "-"
                ),
            },
          ];
        }
      }

      setSummaryData(summaryData);
    }
  }, [selectedTable, pageNumber, selectedTab]);

  const waStats = (data) => {
    const {
      wa_read_time,
      wa_sent_time,
      wa_delivered_time,
      wa_pending_time,
      wa_rejected_time,
    } = data;

    if (wa_read_time && wa_read_time > wa_delivered_time) {
      return "read";
    } else if (wa_delivered_time && wa_delivered_time > wa_sent_time) {
      return "delivered";
    } else if (wa_sent_time && wa_sent_time > wa_pending_time) {
      return "sent";
    } else if (wa_pending_time) {
      return "pending";
    } else if (wa_rejected_time) {
      return "rejected";
    } else {
      return "not sent";
    }
  };
  const qrCodeStats = (data) => {
    const {
      qr_read_time,
      qr_sent_time,
      qr_delivered_time,
      qr_pending_time,
      qr_rejected_time,
    } = data;

    if (qr_read_time && qr_read_time > qr_delivered_time) {
      return "read";
    } else if (qr_delivered_time && qr_delivered_time > qr_sent_time) {
      return "delivered";
    } else if (qr_sent_time && qr_sent_time > qr_pending_time) {
      return "sent";
    } else if (qr_pending_time) {
      return "pending";
    } else if (qr_rejected_time) {
      return "rejected";
    } else {
      return "not sent";
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      {loading ? (
        <Loading secondary />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <IconClose src={ExitSVG} onClick={onRequestClose} />
          <ModalHeader>
            <Icon src={InfoTableSVG} />
            <ModalHeaderTitle>Guest Info</ModalHeaderTitle>
          </ModalHeader>
          <Tabgroups
            data={tabData}
            active={selectedTab}
            onClick={(index) => {
              setSelectedTab(index);
            }}
          />
          <Scrollbars
            autoHide
            style={{ minHeight: "70vh", backgroundColor: "#F4F4F4" }}
          >
            <ContentContainer>
              {selectedTable.length > 1 && (
                <PaginationContainer>
                  <PaginationArrow src={BackPaginationSVG} onClick={doBack} />
                  <PaginationText>
                    {pageNumber + 1} of {selectedTable.length} guests
                  </PaginationText>
                  <PaginationArrow src={NextPaginationSVG} onClick={doNext} />
                </PaginationContainer>
              )}
              <SummaryContainer>
                <Summary data={summaryData} />
              </SummaryContainer>
              {/* <ModalButtonContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Close"
              onClick={onRequestClose}
            />
          </ModalButtonContainer> */}
            </ContentContainer>
          </Scrollbars>
        </div>
      )}
    </Modal>
  );
};

const { bool, func, shape, arrayOf, string } = PropTypes;

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf(shape({})),
};

InfoModal.defaultProps = {
  data: [],
};

export default InfoModal;
