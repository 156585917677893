import React, { useState, useEffect } from 'react';
import { Loader } from "@googlemaps/js-api-loader";

const MapLeaflet = ({
	longLat,
	setLng = () => { },
	setLat = () => { },
	setLngLat = () => { },
	setEventCity = () => { },
	setSearchMap = () => { },
	setAddress = () => { },
	setAll = () => { },
}) => {
	const { REACT_APP_GAPI_KEY } = process.env;
	let map;
	let marker;

	useEffect(() => {
		console.log(longLat)
		const additionalOptions = {};
		const loader = new Loader({
			apiKey: REACT_APP_GAPI_KEY,
			version: "weekly",
			...additionalOptions,
		});
		loader.load().then(() => {
			const google = window.google;
			map = new google.maps.Map(document.getElementById("map"), {
				center: { lat: Number(longLat[1]), lng: Number(longLat[0]) },
				zoom: 16,
				zoomControl: false,
				rotateControl: false,
				mapTypeControl: false,
				// scrollwheel: false,
				navigationControl: false,
				scaleControl: false,
				streetViewControl: false,
				draggable: false,
				// gestureHandling: 'none',
				restriction: {
					latLngBounds: {
						south: -11.29236331165547,
						west: 85.511204063525607,
						north: 30.177565813044403,
						east: 140.86700652331092,
					},
					strictBounds: false,
				},
			});
			addMarker({ lat: Number(longLat[1]), lng: Number(longLat[0]) })
		});
	}, [longLat])

	const addMarker = (position) => {
		const google = window.google;
		if (marker) {
			marker.setMap(null)
		}
		marker = new google.maps.Marker({
			position,
			map: map,
			draggable: true,
			animation: google.maps.Animation.DROP,
		});
		map.setCenter(position);
		google.maps.event.addListener(marker, 'dragend', function () {
			geocodePosition(marker.getPosition());
			map.setCenter(marker.getPosition());
		});
	}

	const geocodePosition = (pos) => {
		let geocoder;
		const google = window.google;
		geocoder = new google.maps.Geocoder();
		geocoder.geocode
			({
				latLng: pos
			},
				function (results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						setLng(pos?.lng())
						setLat(pos?.lat())
						setLngLat([pos?.lng(), pos?.lat()])
						setEventCity(results?.[0]?.address_components.find(item => item.types.includes("administrative_area_level_2"))?.long_name
							|| results?.[0]?.address_components.find(item => item.types.includes("administrative_area_level_1"))?.long_name
							|| '');
						setSearchMap(results?.[0]?.formatted_address)
						setAddress(results?.[0]?.formatted_address)
						setAll(
							{
								address: results?.[0]?.formatted_address,
								lng: pos?.lng(),
								lat: pos?.lat(),
								region: results?.[0]?.address_components.find(item => item.types.includes("administrative_area_level_2"))?.long_name
									|| results?.[0]?.address_components.find(item => item.types.includes("administrative_area_level_1"))?.long_name
									|| ''
							}
						)
					}
					else {
						console.log('cek fail')
					}
				}
			);
	}

	return (
		<div id='map' style={{ height: '250px', outline: 'none' }} />
	);
};

export default MapLeaflet;
