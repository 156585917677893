import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Icon = styled.img``;

export const HeadingContainer = styled.div`
  margin-bottom: 2.75rem;

  ${Heading.Heading} {
    text-align: left;
    font-family: 'GT Walsheim Pro', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: 0.03125rem;
    color: ${(props) => props.theme.navbar.background};
  }
`;

export const Body = styled.div`
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;

  ${Button.Button} {
    padding: 0.5rem 2.90625rem;
  }
`;

export const ButtonContainerUpload = styled.div`
  margin-top:20px;
  display: flex;
  justify-content: flex-end;
  ${Button.Button} {
    padding: 0.5rem 2.90625rem;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
`;

export const UserManagementContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
`;

export const DetailHeader = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.modal.header.background};
  color: ${(props) => props.theme.modal.header.text};
  padding: 2rem;
  border-radius: 0.625rem;
  margin-bottom: 2.75rem;

  img {
    position: absolute;
    right: 0;
    width: 8.125rem;
  }
`;

export const DetailHeaderDesc = styled.div``;

export const DetailHeaderDescContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${DetailHeaderDesc} {
    margin-right: 2rem;
    border-right: 0.0625rem solid ${(props) => props.theme.modal.header.text};

    &:last-child {
      border: 0;
    }
  }

`;

export const DetailHeaderDescTitle = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

export const DetailHeaderDescValue = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
  text-transform: capitalize;
`;

export const Label = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.heading.subtitle};
`;

export const UploadInvitation = styled.div`
  box-sizing: content-box;
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.heading.subtitle};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  border: 0.09375rem dashed ${(props) => props.theme.border.light};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 200px;

  b {
    font-weight: 500;
  }

  &:hover {
    border: 0.09375rem dashed ${(props) => props.theme.border.primary};
  }
`;

export const UploadIcon = styled.img`
 &:hover{
   cursor: pointer;
 }
`;

export const UploadPreviewContainer = styled.div`
  position: relative;
  border-radius: .625rem;
  overflow: hidden;
`;

export const UploadPreviewImage = styled.img`
  object-fit: scale-down;
  object-position: 50% 50%;
  height: 200px;
`;

export const UploadPreviewVideo = styled.video`
  object-fit: scale-down;
  object-position: 50% 50%;
  height: 200px;
`;

export const UploadPreviewOverlay = styled.div`
  position: absolute; 
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(71, 71, 71,0.7);
`;

export const UploadPreviewIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap:10px;
`;

export const UploadContainer = styled.div`
`;

export const UploadLabel = styled.label``;

export const UploadInput = styled.input`
  display: none;
`;

export const MapContainer = styled.div`
  display: grid;
  height: 200px;
  z-index:2;
  border-radius: 10px;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeaderTitle = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 0.5rem 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const ModalButtonInviteUserContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Button.Button} {
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const ModalImage = styled.img``;
export const ModalVideo = styled.video``;

export const ModalBody = styled.div`
  padding: 24px 53px;
  background: #F3F2F5;
`;

export const ModalBody2 = styled.div`
  padding: 28px 40px;
  background: #F3F2F5;
`;

export const EventDetailContainer = styled.div`
  width: 48.75rem;
  margin: 0 auto;
`;

export const UploadProgressBar = styled.div`
  margin-top: 15px;
  width: 250px;
  height: 14px;
  background: #FFFFFF;
  border-radius: 20px;
  color: #474747;
  padding: 3px;
`;

export const UploadProgressLoad = styled.div`
  background-color: #096B81;
  width: ${(props) => `${props.progress || 0}%`};
  height: 100%;
  border-radius: 20px;
`;

export const UploadProgressContainer = styled.div`
  display:flex;
  flex-direction:column;
`;

export const UploadProgressLabel = styled.div`
font-family: 'GT Walsheim Pro', sans-serif;  
text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
`;

export const ToolTipText = styled.span`
  visibility: hidden;
  width: 22.5rem;
  background-color: white;
  color: #00000;
  text-align: left;
  border-radius: 0.375rem;
  padding: 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-weight: normal;
  margin-left: ${(props) => ((props.isLeft && '-21.875rem') || '-2.1875rem')};
  box-shadow:0.3125rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: ${(props) => (props.isLeft ? 'auto' : '50%')};
    right:${(props) => (props.isLeft ? '10%' : 'auto')};
    margin-left: -10.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

export const ToolTip = styled.span`
  padding-left: 4px;
  position: relative;
  display: inline-block;
  ${Icon} {
    width:18px;
    height: 18px;
    bottom: 0;
  }
  &:hover {
    ${ToolTipText}{
      visibility: visible;
    }
  }
`;

export const AddUseerManagementCard = styled.div`
  height: 138px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1.5px dashed #BDBDBD;
  border-radius: 10px;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  box-sizing: border-box;
  &:hover{
    cursor: pointer;
  }
  ${Icon}{
    height:42.67px;
    width:42.67px;
  }
`

export const AddUseerManagementText = styled.div`
  margin-top:10px;
`
export const UserManagementCard = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  height: 138px;
  padding: 16px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const UserManagementTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UserManagementTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
`;

export const UserManagementTitleText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color:${(props) => props.isOwner ? '#438FD4' : '#0A0A0A'};
`;

export const UserManagementName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserPending = styled.div`
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 26px;
letter-spacing: 0.3px;
color: #E76E6E;
`;


export const UserManagementEmail = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #727272;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
