import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonIcon,
  Title,
  InputContainers,
  Input,
  Icon,
  SubTitleDashboard,
} from 'components/icon-card/style';

const ButtonIconComponent = ({
  isActive,
  infographic,
  editable,
  text,
  icon,
  info,
  onChange,
  isDashboard,
  subTitle,
  onClick,
  placeholder,
}) => {
  const [focus, setFocus] = useState(false);
  const inputEl = useRef(null);
  return (
    <ButtonIcon
      isActive={isActive}
      infographic={infographic}
      editable={editable}
      isDashboard
      onClick={infographic ? () => { inputEl.current.focus(); } : onClick}
    >
      <Icon
        src={icon}
      />
      <Title isDashboard={isDashboard}>{text}</Title>
      {isDashboard && (
        <SubTitleDashboard>{subTitle}</SubTitleDashboard>
      )}
    </ButtonIcon>
  );
};

const {
  oneOfType,
  bool,
  string,
  number,
  object,
  func,
} = PropTypes;

ButtonIconComponent.propTypes = {
  isActive: bool,
  infographic: bool,
  editable: bool,
  text: oneOfType([string, object, number]),
  icon: string,
  info: oneOfType([string, number]),
  onChange: func,
  isDashboard: bool,
  subTitle: string,
  onClick: func,
};

ButtonIconComponent.defaultProps = {
  isActive: false,
  infographic: false,
  editable: false,
  text: '',
  icon: '',
  info: 0,
  onChange: () => { },
  isDashboard: false,
  subTitle: '',
  onClick: () => { },
};

export default ButtonIconComponent;
