/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVGInject from '@iconfu/svg-inject';
import Joi from 'joi';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstanceAdmin } from 'helpers/authenticationAdmin';
import forms from 'data/static/form';
import {
  Heading,
  Input,
  Button,
} from 'shared';
import {
  Table,
  SideMenu,
  TableButton,
  Checkbox,
  Modal,
  MessageBox,
} from 'components';
import {
  Icon,
  ModalContainer,
  ModalClose,
  ModalHeaderWithoutBadge,
  Body,
  TopContainer,
  TopRightContainer,
  ContentContainer,
  EmptyGuestImageContainer,
  EmptyGuestImage,
  EmptyGuestText,
  StatusContainer,
  SelectionContainer,
  SelectionItem,
  SelectionLabel,
  ModalButtonContainer,
  MessageBoxContainer,
} from 'pages/admin/dashboard-admin/operators/style';
import SearchSVG from 'assets/icons/search.svg';
import AddSVG from 'assets/icons/add.svg';
import EmptyGuestSVG from 'assets/images/empty-guest.svg';
import AddGuestSVG from 'assets/icons/add-guest.svg';
import EditSVG from 'assets/icons/edit.svg';
import DeleteSVG from 'assets/icons/delete-table.svg';
import DeleteConfirmSVG from 'assets/images/delete-confirm.svg';
import ExitSVG from 'assets/icons/exit.svg';

const EmptyOperatorList = () => (
  <EmptyGuestImageContainer>
    <EmptyGuestImage src={EmptyGuestSVG} style={{height:'130px',width:'130px',marginLeft:'auto',marginRight:'auto'}}/>
    <EmptyGuestText>
      Looks like you have not invited any guest to the event.
      <br />
      Lets start adding your guests!
    </EmptyGuestText>
  </EmptyGuestImageContainer>
);

const DeleteConfirmModal = ({
  isOpen,
  onRequestClose,
  data,
  deleteOperator,
  isSubmiting,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon src={DeleteConfirmSVG} />

          <Heading
            kind="subtitle"
            text={(
              <div>
                Sure you want to remove
                {' '}
                <span style={{ fontWeight: 'bold' }}>{data.fullname}</span>
                {' '}
                from EventCo’s operator?
              </div>
              )}
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>
        <ModalButtonContainer>
          <Button
            small

            kind="secondary"
            type="button"
            text="Cancel"
            onClick={onRequestClose}
          />
          <Button
            small
            kind="warning"
            type="button"
            text="Delete"
            onClick={deleteOperator}
            isLoading={isSubmiting}
            disabled={isSubmiting}
          />

        </ModalButtonContainer>
      </ModalContainer>

    </>
  </Modal>
);

const GuestList = ({ eventTokenCommited, setIsLoading }) => {
  const cols = [
    {
      title: 'Name',
      position: '',
      render: (rowData) => (<span>{rowData.fullname}</span>),
    },
    {
      title: 'Email',
      position: '',
      render: (rowData) => (<span>{rowData.email}</span>),
    },
    {
      title: 'Phone No',
      position: '',
      render: (rowData) => (
        <span>
          {rowData.extension}
          {rowData.phone}
        </span>
      ),
    },
    {
      title: 'Role',
      position: '',
      render: (rowData) => (<span>{rowData.role}</span>),
    },
    {
      title: '',
      position: 'center',
      render: (rowData) => (
        <div style={{ display: 'flex', width: '163px', justifyContent: 'space-between' }}>
          <TableButton
            text="Edit"
            icon={EditSVG}
            onClick={() => openEdit(rowData)}
          />
          <TableButton
            text="Remove"
            icon={DeleteSVG}
            onClick={() => openDelete(rowData)}
          />
        </div>
      ),
    },
  ];

  const [showDeletedModalOpen, setShowDeletedModalOpen] = useState(false);
  const [openedSideMenuId, setOpenedSideMenuId] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  // data
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [createOperatorsData, setCreateOperatorsData] = useState(forms.admin.operators.AddOperators);
  const [editOperatorData, setEditOperatorData] = useState({});
  const [deleteOperatorData, setDeleteOperatorData] = useState({});
  const [operatorId, setOperatorId] = useState('');

  // error
  const [addOperatorError, setAddOperatorError] = useState({
    status: false,
    title: '',
    text: '',
  });

  const closeSideMenu = () => {
    setOpenedSideMenuId(0);
  };

  // functions
  const getOperatorList = async () => {
    axiosApiInstanceAdmin.get(ENDPOINTS('admin.operator.operator'))
      .then(({ data: { value: { admins } } }) => {
        setIsLoading(false);
        setData(JSON.parse(JSON.stringify(admins)));
        setTempData(JSON.parse(JSON.stringify(admins)));
      })
      .catch(() => {
        // error message
      });
  };

  const addOperators = async () => {
    setIsSubmiting(true);
    try {
      const form = createOperatorsData;
      const schema = Joi.object({
        fullname: Joi.string().trim().allow(''),
        phone: Joi.string().default('').required().error((err) => {
          if (err[0].code === 'string.empty') {
            return new Error('Please input your phone number');
          }
          return new Error('Your phone number format not valid');
        }),
        extension: Joi.string().default('').allow(''),
        email: Joi.string().default('').required().error((err) => {
          if (err[0].code === 'string.empty') {
            return new Error('Please input your email');
          }
          return new Error('Your email format not valid');
        }),
        role: Joi.string().trim().required(),
      });

      const value = await schema.validateAsync(form);

      axiosApiInstanceAdmin.post(ENDPOINTS('admin.operator.operator'), value)
        .then(() => {
          setIsSubmiting(false);
          setCreateOperatorsData(forms.admin.operators.AddOperators);
          setOpenedSideMenuId(0);
          getOperatorList();
        })
        .catch(() => {
          setIsSubmiting(false);
          // error message
        });
    } catch (err) {
      setIsSubmiting(false);
      console.log(err.message);
      setAddOperatorError({
        type: '',
        status: true,
        title: 'Can\'t Add Operator',
        message: err.message,
      });
    }
  };

  const editOperator = async () => {
    setIsSubmiting(true);
    const form = editOperatorData;
    const schema = Joi.object({
      fullname: Joi.string().trim().required(),
      phone: Joi.string().default('').allow(''),
      extension: Joi.string().default('').allow(''),
      email: Joi.string().default('').allow(''),
      role: Joi.string().trim().required(),
    });

    const value = await schema.validateAsync(form);

    axiosApiInstanceAdmin.put(`${ENDPOINTS('admin.operator.operator')}/${operatorId}`, value)
      .then(() => {
        setIsSubmiting(false);
        setEditOperatorData({});
        setOpenedSideMenuId(0);
        getOperatorList();
      })
      .catch(() => {
        // error message
      });
  };

  const deleteOperator = async () => {
    setIsSubmiting(true);
    axiosApiInstanceAdmin.delete(`${ENDPOINTS('admin.operator.operator')}/${deleteOperatorData.id}`)
      .then(() => {
        setIsSubmiting(false);
        setDeleteOperatorData({});
        setShowDeletedModalOpen(false);
        getOperatorList();
      })
      .catch(() => {
        // error message
      });
  };

  const openEdit = (value) => {
    setOperatorId(value._id);
    setEditOperatorData({
      fullname: value.fullname,
      email: value.email,
      extension: value.extension,
      phone: value.phone,
      role: value.role,
    });

    setOpenedSideMenuId(2);
  };

  const openDelete = (value) => {
    setDeleteOperatorData({
      fullname: value.fullname,
      id: value._id,
    });
    setShowDeletedModalOpen(true);
  };

  useEffect(() => {
    SVGInject(document.querySelectorAll('img.injectable'));
    getOperatorList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(tempData.filter((item) => item.fullname.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      || item.role.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <>
      <DeleteConfirmModal
        isOpen={showDeletedModalOpen}
        onRequestClose={() => setShowDeletedModalOpen(false)}
        data={deleteOperatorData}
        deleteOperator={deleteOperator}
        isSubmiting={isSubmiting}
      />
      <SideMenu
        isOpen={openedSideMenuId === 1}
        onRequestClose={closeSideMenu}
        finishFunc={addOperators}
        title="Add Operator"
        buttonText="Add Operator"
        icon={AddGuestSVG}
        isLoading={isSubmiting}
        disabled={isSubmiting}
      >
        {addOperatorError.status && (
        <MessageBoxContainer>
          <MessageBox
            type="warning"
            title={addOperatorError.title}
            text={addOperatorError.message}
            onRequestClose={() => {
              setAddOperatorError({
                type: '',
                status: false,
                title: '',
                message: '',
              });
            }}
          />
        </MessageBoxContainer>
        )}

        <Input
          type="text"
          label="Name"
          name="operatorName"
          placeholder="Placeholder"
          value={createOperatorsData.fullname}
          onChange={(value) => setCreateOperatorsData({ ...createOperatorsData, fullname: value })}
        />

        <Input
          type="tel"
          label="Phone Number"
          name="phoneNumber"
          placeholder="Placeholder"
          value={[createOperatorsData.extension, createOperatorsData.phone]}
          onChange={(extension, phone) => { setCreateOperatorsData({ ...createOperatorsData, extension, phone }); }}
        />

        <Input
          type="email"
          label="Email"
          name="email"
          placeholder="Placeholder"
          value={createOperatorsData.email}
          onChange={(value) => setCreateOperatorsData({ ...createOperatorsData, email: value })}
        />

        <StatusContainer>
          Role
          <SelectionContainer>
            <SelectionItem>
              <Checkbox
                checked={createOperatorsData.role === 'Management'}
                onChange={() => { setCreateOperatorsData({ ...createOperatorsData, role: 'Management' }); }}
              />
              <SelectionLabel>
                Management
              </SelectionLabel>
            </SelectionItem>
            <SelectionItem>
              <Checkbox
                checked={createOperatorsData.role === 'Staff'}
                onChange={() => { setCreateOperatorsData({ ...createOperatorsData, role: 'Staff' }); }}
              />
              <SelectionLabel>
                Staff
              </SelectionLabel>
            </SelectionItem>
          </SelectionContainer>
        </StatusContainer>
      </SideMenu>
      <SideMenu
        isOpen={openedSideMenuId === 2}
        onRequestClose={closeSideMenu}
        finishFunc={editOperator}
        title="Edit Operator"
        buttonText="Save Changes"
        icon={AddGuestSVG}
      >

        <Input
          type="text"
          label="Name"
          name="operatorName"
          placeholder="Placeholder"
          value={editOperatorData.fullname}
          onChange={(value) => setEditOperatorData({ ...editOperatorData, fullname: value })}
        />

        <Input
          type="tel"
          label="Phone Number"
          name="phoneNumber"
          placeholder="Placeholder"
          value={[editOperatorData.extension, editOperatorData.phone]}
          onChange={(extension, phone) => { setEditOperatorData({ ...editOperatorData, extension, phone }); }}
        />

        <Input
          type="email"
          label="Email"
          name="email"
          placeholder="Placeholder"
          value={editOperatorData.email}
          onChange={(value) => setEditOperatorData({ ...editOperatorData, email: value })}
        />

        <StatusContainer>
          Role
          <SelectionContainer>
            <SelectionItem>
              <Checkbox
                checked={editOperatorData.role === 'Management'}
                onChange={() => { setEditOperatorData({ ...editOperatorData, role: 'Management' }); }}
              />
              <SelectionLabel>
                Management
              </SelectionLabel>
            </SelectionItem>
            <SelectionItem>
              <Checkbox
                checked={editOperatorData.role === 'Staff'}
                onChange={() => { setEditOperatorData({ ...editOperatorData, role: 'Staff' }); }}
              />
              <SelectionLabel>
                Staff
              </SelectionLabel>
            </SelectionItem>
          </SelectionContainer>
        </StatusContainer>
      </SideMenu>

      <Body>
        <Heading
          kind="title"
          text="Operators"
        />
        <TopContainer>
          <Input
            isSearch
            type="text"
            name="searchCategory"
            value={searchTerm}
            icon={SearchSVG}
            onChange={setSearchTerm}
            placeholder="Search by name or role"
          />
          <TopRightContainer>

            <Button
              small
              kind="primary"
              type="button"
              text="Add Operator"
              icon={AddSVG}
              onClick={() => setOpenedSideMenuId(1)}
            />
          </TopRightContainer>
        </TopContainer>
        <ContentContainer data={data}>
          {data.length ? (
            <Table
              readOnly
              cols={cols}
              data={data}
              setData={setData}
              isSelectedAll={isSelectedAll}
              setIsSelectedAll={setIsSelectedAll}
            />
          ) : (
            <EmptyOperatorList />
          )}
          {/* {showFloatingMenu
            && (
              <GuestListFloatingMenu
                data={data}
                setData={setData}
                setShowFloatingMenu={setShowFloatingMenu}
                setIsSelectedAll={setIsSelectedAll}
                availableCategories={selectedCategories}
                assignCategory={assignCategory}
                deleteInvitations={deleteInvitations}
                assignCategoryIntialize={assignCategoryIntialize}
                invitationInfo={invitationInfo}
                sendEmailInvitation={sendEmailInvitation}
                sendWAInvitation={sendWAInvitation}
                invitationFailType={invitationFailType}
              />
            )} */}
        </ContentContainer>
      </Body>
    </>
  );
};

const { bool, func } = PropTypes;

GuestList.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default GuestList;
