import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Icon = styled.img``;
export const IconVideo = styled.video`
  width:360px;
  height:240px;
`;

export const HeadingContainer = styled.div`
  margin-bottom: 2.75rem;

  ${Heading.Heading} {
    text-align: left;
    font-family: 'GT Walsheim Pro', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: 0.03125rem;
    color: ${(props) => props.theme.navbar.background};
  }
`;

export const Body = styled.div`
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;
`;

export const Label = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.heading.subtitle};
`;

export const InvitationLanguageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;

  * {
    z-index: 0;
  }
`;

export const CheckboxContainer = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.card.background};
  padding: 1.5rem 1.25rem;
  border-radius: 0.625rem;
`;

export const UploadInvitation = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.heading.subtitle};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  border: 0.09375rem dashed ${(props) => props.theme.border.light};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1.5rem;

  ${Icon} {
    margin-top: 2.3125rem;
    margin-bottom: 1.3125rem;
  }

  b {
    font-weight: 500;
  }

  &:hover {
    border: 0.09375rem dashed ${(props) => props.theme.border.primary};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;

  ${Button.Button} {
    padding: 0.5rem 2.90625rem;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
`;

export const UploadIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const UploadPreviewContainer = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`;

export const UploadPreviewImage = styled.img`
  object-fit: scale-down;
  object-position: 50% 50%;
  height: 370px;
`;

export const UploadPreviewVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadPreviewVideo = styled.video`
  object-fit: scale-down;
  object-position: 50% 50%;
  height: 320px;
  width: 320px;
`;

export const UploadPreviewIconContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%
`;

export const UploadPreviewOverlay = styled.div`
  position: absolute; 
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  &:hover{
      background-color: rgba(71, 71, 71,0.7);

    ${UploadPreviewIconContainer}{
      display:flex;
    }
  }
`;

export const UploadContainer = styled.div``;

export const UploadLabel = styled.label``;

export const UploadInput = styled.input`
  display: none;
`;

export const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeaderTitle = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 0.5rem 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const ModalImage = styled.img``;

export const ModalVideo = styled.video`
  width: 400px;
  height: 240px;
`;

export const ModalBody = styled.div`
  padding: 24px 53px;
  background: #F3F2F5;
`;

export const InvitationTab = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  text-align: center;
  align-items: center;
  padding: 22px 0;
`;

export const InvitationTabItem = styled.div`
  flex: 1;
  color: ${(props) => (props.active && '#096B81') || '#BDBDBD'};
  &:hover{
    cursor: pointer;
  }
`;

export const InvitationContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 2rem 6.5625rem;
`;

export const EmailContainerHead = styled.div`
  box-sizing: border-box;
  width:100%;
  position: relative;
  padding: 3.125rem 2.5rem;
  background: #096B81;
  border-radius: 10px 10px 0 0;

  ${Heading.Heading} {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    text-align: left;
    margin: 0;
  }
`;

export const SubTitle = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #B5E0EA;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

export const EmailContainerBody = styled.div`
  box-sizing: border-box;
  width:100%;
  position: relative;
  padding: 28px 40px;
  background: #FFFFFF;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0A0A0A;
  border-radius: 0 0 10px 10px;
`;

export const EmailGreeting = styled.div`
  margin-bottom: 12px;
`;

export const EmailContent = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #DDDDDD;
`;

export const EmailInformation = styled.div`
  padding-top: 24px;
`;

export const EmailItem = styled.div`
  margin-bottom: 28px;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

export const EmailLabelInformation = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 122.4%;
  letter-spacing: 1.5px;
  color: #096B81;
  align-items: center;
  vertical-align: middle;
  text-transform: uppercase;

  ${Icon} {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;

export const EmailInfo = styled.div`
  margin-top: 8px;
`;

export const ViewMap = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #438FD4;
  margin-top: 4px;
`;

export const WhatsappContainer = styled.div`
  box-sizing: border-box;
  width:100%;
  position: relative;
  padding: 2rem 2.5rem;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  font-family: 'GT Walsheim Pro', sans-serif;
  ${Icon} {
    max-width: 360px;
    height: auto;
  }
`;

export const WhatsappBody = styled.div`
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0A0A0A;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
`;

export const InvitationContainer = styled.div`
  width: 48.75rem;
  margin: 0 auto;
`;

export const ToolTipText = styled.span`
  visibility: hidden;
  width: 22.5rem;
  background-color: white;
  color: #00000;
  text-align: left;
  border-radius: 0.375rem;
  padding: 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-weight: normal;
  margin-left: ${(props) => ((props.isLeft && '-21.875rem') || '-2.1875rem')};
  box-shadow:0.3125rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: ${(props) => (props.isLeft ? 'auto' : '50%')};
    right:${(props) => (props.isLeft ? '10%' : 'auto')};
    margin-left: -10.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;
export const ToolTip = styled.span`
  padding-left: 4px;
  position: relative;
  display: inline-block;
  ${Icon} {
    width:18px;
    height: 18px;
    bottom: 0;
  }
  &:hover {
    ${ToolTipText}{
      visibility: visible;
    }
  }
`;
