import styled, { css } from 'styled-components';

export const Button = styled.div`
  box-sizing: border-box;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  cursor: pointer;
  padding: 0 0.75rem;
  height: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border: 0.0625rem solid ${(props) => props.theme.table.button.border};
  color: ${(props) => props.theme.table.button.text};

  ${(props) => props.text === 'Whatsapp' && css`
    background-color: ${props.theme.form.button.background.whatsapp};
    border-color: ${props.theme.form.button.background.whatsapp};
    color: ${props.theme.form.button.text.whatsapp};
  `}

  ${(props) => props.text === 'Email' && css`
    background-color: ${props.theme.form.button.background.email};
    border-color: ${props.theme.form.button.background.email};
    color: ${props.theme.form.button.text.email};
  `}

  ${(props) => props.text === 'Edit' && css`
    width: 63px;
  `}
  ${(props) => props.text === 'Remove' && css`
    color: #E76E6E;
  `}

  ${(props) => props.text === 'Event Details' && css`
    width: 95px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 6px;
    color: #438FD4;
    padding: 0;
    &:hover {
      border-color: #438FD4;
    }
`}
`;

export const Icon = styled.img`
  position: relative;
  margin: 0;
  margin-right: 0.395625rem;
`;
