import { Player } from "@lottiefiles/react-lottie-player";
import {
  LeftTab,
  Icon,
  Session1Mobile,
  Title,
  CaptionContainer,
  VideoContainerMobile,
  SubTitle,
} from "components/page-animation/style";
import React from "react";

const MobileSection = ({ title, icon, caption, subCaption, video, color }) => {
  return (
    <Session1Mobile color={color}>
      <LeftTab color={color}>
        {title} <Icon src={icon} />
      </LeftTab>

      <CaptionContainer>
        <Title>{caption}</Title>
        <SubTitle>{subCaption}</SubTitle>
      </CaptionContainer>
      <VideoContainerMobile>
        <Player
          autoplay
          loop
          src={video}
          style={{ height: "auto", width: "500px" }}
        />
      </VideoContainerMobile>
    </Session1Mobile>
  );
};

export default MobileSection;
