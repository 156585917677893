import styled from 'styled-components';

export const Icon = styled.img`
`;

export const IconBack = styled.img`
  position: absolute;
  top: 2rem;
  left: 2rem;
  &:hover {
    cursor: pointer;
  }
`;

export const IconNext = styled.img`
  position: absolute;
  transform: scaleX(-1);
  top: 2rem;
  right: 2rem;
  &:hover {
    cursor: pointer;
  }
`;

export const NotFoundContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  background:  ${(props) => props.theme.checkin.color.gray7};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  ${Icon}{
    width:auto;
    height:100%;
  }
`;

export const NotFoundText = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.75rem;
  text-align: center;
  letter-spacing: 0.03125rem;
  color: ${(props) => props.theme.checkin.color.text2};
`;
