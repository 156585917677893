import React from "react";
import { Container, Icon } from "pages/loading/style";
import LoaderGif from "assets/images/dual-ball.gif";

const Loading = ({ secondary = false }) => (
  <Container secondary={secondary}>
    <Icon src={LoaderGif} />
  </Container>
);

export default Loading;
