/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { store } from "store";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Heading } from "shared";
import { Modal } from "components";
import {
  Icon,
  ModalHeader,
  ModalHeaderTitle,
  ModalButtonContainer,
  InvitationTab,
  InvitationTabItem,
  InvitationContentContainer,
  EmailContainerHead,
  SubTitle,
  EmailContainerBody,
  EmailGreeting,
  EmailContent,
  EmailInformation,
  EmailLabelInformation,
  EmailItem,
  EmailInfo,
  ViewMap,
  WhatsappContainer,
  WhatsappBody,
  IconClose,
  PaginationArrow,
  PaginationText,
  PaginationContainer,
  NameLabel,
} from "pages/guest-list/floating-menu/style";
import InvitationPreviewSVG from "assets/icons/invitation-preview.svg";
import CalendarSVG from "assets/icons/calendar.svg";
import VenueSVG from "assets/icons/venue.svg";
import LocationSVG from "assets/icons/location.svg";
import ExitSVG from "assets/icons/exit.svg";
import BackPaginationSVG from "assets/icons/back-pagination.svg";
import NextPaginationSVG from "assets/icons/next-pagination.svg";
import { Document, Page, pdfjs } from "react-pdf";

const InvitationModal = ({
  isOpen,
  onRequestClose,
  invitationInfo,
  sendEmailInvitation,
  sendWAInvitation,
  setShowInvitationFail,
  data,
  isSubmiting,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [onTab, setOnTab] = useState("whatsapp");
  const [dataUser, setDataUser] = useState([]);
  const {
    state: { eventDetail },
  } = useContext(store);
  const [pageNumber, setPageNumber] = useState(0);
  const [numPages, setNumPagesPDF] = useState(null);
  const [pageNumberPDF, setPageNumberPDF] = useState(1);
  const [invitationSettings, setInvitationSettings] = useState({});

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPagesPDF(numPages);
  };
  const {
    email_body,
    email_salutation,
    email_subject,
    hash_tag,
    wa_last_confirmation_string,
    wa_signature,
  } = invitationInfo;
  const {
    address,
    event_date_string,
    event_venue,
    latitude,
    longitude,
    name: eventName,
    url_image,
    document_file_type,
    bride_name,
    bride_father,
    bride_mother,
    groom_name,
    groom_father,
    groom_mother,
  } = eventDetail;
  useEffect(() => {
    setDataUser(data.filter((item) => item.checked));
  }, [data]);
  const doSend = () => {
    if (onTab === "email") {
      sendEmailInvitation(onTab, setShowInvitationFail, onRequestClose);
    } else {
      sendWAInvitation(onTab, setShowInvitationFail, onRequestClose);
    }
  };
  const doNext = () => {
    if (pageNumber === dataUser.length - 1) {
      setPageNumber(0);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };
  const doBack = () => {
    if (pageNumber === 0) {
      setPageNumber(dataUser.length - 1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <Scrollbars autoHide style={{ minHeight: "80vh" }}>
        <IconClose src={ExitSVG} onClick={onRequestClose} />
        <ModalHeader>
          <Icon src={InvitationPreviewSVG} />
          <ModalHeaderTitle>Invitation Preview</ModalHeaderTitle>
        </ModalHeader>
        <InvitationTab>
          <InvitationTabItem
            active={onTab === "whatsapp"}
            onClick={() => {
              setOnTab("whatsapp");
            }}
          >
            Whatsapp
          </InvitationTabItem>
          <InvitationTabItem
            active={onTab === "email"}
            onClick={() => {
              setOnTab("email");
            }}
          >
            Email
          </InvitationTabItem>
        </InvitationTab>
        <InvitationContentContainer>
          {dataUser.length > 1 && (
            <PaginationContainer>
              <PaginationArrow src={BackPaginationSVG} onClick={doBack} />
              <PaginationText>
                {pageNumber + 1} of {dataUser.length} guests
              </PaginationText>
              <PaginationArrow src={NextPaginationSVG} onClick={doNext} />
            </PaginationContainer>
          )}
          {onTab === "email" && (
            <>
              <EmailContainerHead>
                <SubTitle>YOU’RE INVITED TO</SubTitle>
                <Heading kind="title" text={email_subject} />
              </EmailContainerHead>
              <EmailContainerBody>
                <EmailGreeting>
                  {email_salutation}{" "}
                  {dataUser.length && (
                    <NameLabel>{dataUser[pageNumber].name}</NameLabel>
                  )}
                  ,
                </EmailGreeting>
                <EmailContent>{email_body}</EmailContent>
                <EmailInformation>
                  <EmailItem>
                    <EmailLabelInformation>
                      <Icon src={CalendarSVG} />
                      DATE & TIME
                    </EmailLabelInformation>
                    <EmailInfo>
                      {moment
                        .utc(event_date_string)
                        .format("dddd, MMM D, YYYY - hh.mm A")}
                    </EmailInfo>
                  </EmailItem>
                  <EmailItem>
                    <EmailLabelInformation>
                      <Icon src={VenueSVG} />
                      venue
                    </EmailLabelInformation>
                    <EmailInfo>{event_venue}</EmailInfo>
                  </EmailItem>
                  <EmailItem>
                    <EmailLabelInformation>
                      <Icon src={LocationSVG} />
                      Address
                    </EmailLabelInformation>
                    <EmailInfo>{address}</EmailInfo>
                    <ViewMap
                      href={`https://www.google.com/maps/search/?api=1&query=${latitude.replace(
                        ",",
                        "."
                      )},${longitude.replace(",", ".")}`}
                      target="_blank"
                    >
                      View Map
                    </ViewMap>
                  </EmailItem>
                </EmailInformation>
              </EmailContainerBody>
              <ModalButtonContainer>
                <Button
                  small
                  kind="primary"
                  type="button"
                  text="Send invitation"
                  onClick={doSend}
                  disabled={isSubmiting}
                  isLoading={isSubmiting}
                />
              </ModalButtonContainer>
            </>
          )}
          {onTab === "whatsapp" && (
            <>
              <WhatsappContainer>
                {url_image ? (
                  document_file_type == "application/pdf" ? (
                    <div
                      style={{
                        width: "100%",
                        height: 370,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Document
                        file={url_image}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading=""
                      >
                        <Page pageNumber={pageNumberPDF} />
                      </Document>
                    </div>
                  ) : (
                    <Icon src={url_image} />
                  )
                ) : (
                  ""
                )}
                <WhatsappBody>
                  {invitationInfo.language === "English" ? (
                    <>
                      Dear Honored guest(s),
                      <br />
                      <b>
                        {dataUser.length && (
                          <NameLabel>{dataUser[pageNumber].name}</NameLabel>
                        )}
                      </b>
                      <br />
                      <br />
                      <div
                        style={{
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        With great pleasure, Eventco requests the confirmation
                        of your attendance at the Wedding reception of:
                        <br />
                        <br />
                        <b>{groom_name}</b>
                        <br />
                        Son of {groom_father} & {groom_mother}
                        <br />
                        <br />
                        and
                        <br />
                        <br />
                        <b>{bride_name}</b>
                        <br />
                        Daughter of {bride_father} & {bride_mother}
                      </div>
                      <br />
                      On{" "}
                      <b>
                        {moment(event_date_string)
                          .locale("en")
                          .format("dddd, MMMM D, YYYY")}
                      </b>
                      <br />
                      At{" "}
                      <b>
                        {moment(event_date_string).locale("en").format("LT")} -
                        onwards
                      </b>
                      <br />
                      <br />
                      Location:
                      <br />
                      {address}
                    </>
                  ) : (
                    <>
                      Kepada Yang Terhormat,
                      <br />
                      <b>
                        {dataUser.length && (
                          <NameLabel>{dataUser[pageNumber].name}</NameLabel>
                        )}
                      </b>
                      <br />
                      <br />
                      <div
                        style={{
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        Kami dari Eventco telah ditugaskan oleh pasangan:
                        <br />
                        <br />
                        Groom: <b>{groom_name}</b>, Putra dari pasangan{" "}
                        <b>{groom_father}</b> dan <b>{groom_mother}</b>
                        <br />
                        <br />
                        Bride: <b>{bride_name}</b>, Putri dari pasangan{" "}
                        <b>{bride_father}</b> dan <b>{bride_mother}</b>
                        <br />
                        <br />
                        Untuk mendapatkan konfirmasi atas kehadiran Bapak/Ibu
                        pada:
                        <br />
                        <br />
                        Nama Acara :<br />
                        <b>{eventName}</b>
                        <br />
                        Hari/Tanggal :<br />
                        <b>
                          {moment(event_date_string)
                            .locale("id")
                            .format("dddd, D MMMM YYYY")}
                        </b>
                        <br />
                        Pukul :<br />
                        <b>
                          {moment(event_date_string)
                            .locale("id")
                            .format("hh.mm")}{" "}
                          - Selesai
                        </b>
                        <br />
                        Lokasi Acara :<br />
                        {event_venue}
                        <br />
                        <br />
                        Alamat :<br />
                        {address}
                      </div>
                      <br />
                      <i>Hormat Kami,</i>
                      <br />
                      {wa_signature}
                    </>
                  )}
                </WhatsappBody>
              </WhatsappContainer>
              <ModalButtonContainer>
                <Button
                  small
                  kind="primary"
                  type="button"
                  text={`Send Invitation (${dataUser.length})`}
                  onClick={doSend}
                  disabled={isSubmiting}
                  isLoading={isSubmiting}
                />
              </ModalButtonContainer>
            </>
          )}
        </InvitationContentContainer>
      </Scrollbars>
    </Modal>
  );
};
const { bool, func, shape, arrayOf, string } = PropTypes;
InvitationModal.propTypes = {
  isOpen: func.isRequired,
  onRequestClose: func.isRequired,
  invitationInfo: shape({}),
  sendEmailInvitation: func.isRequired,
  sendWAInvitation: func.isRequired,
  setShowInvitationFail: func.isRequired,
  data: arrayOf([shape({})]),
  isSubmiting: bool.isRequired,
};
InvitationModal.defaultProps = {
  invitationInfo: {},
  data: [],
};
export default InvitationModal;
