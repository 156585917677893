import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';
import ENDPOINTSV2 from 'helpers/endpoints-v2';
import moment from 'moment';
import {
  Heading,
  Input,
  Button,
} from 'shared';
import {
  GuestContainer,
  GuestContent,
  Card,
  CardText,
  SearchContainer,
  LogoContainer,
  Icon,
  ModalContent,
  ModalClose,
  ModalHeader,
} from 'pages/check-in/guest-search/style';
import { Modal } from 'components';
import ListContainer from 'pages/check-in/guest-search/list';
import LogoFooterSVG from 'assets/images/logo-footer.svg';
import ExitSVG from 'assets/icons/exit.svg';
import NotFoundSVG from 'assets/images/not-found.svg';

const ModalNotFound = ({
  isOpen,
  onRequestClose,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <ModalContent>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalHeader>
        <Icon src={NotFoundSVG} style={{width:'100px',height:'100px',margin:'0 auto'}}/>
        <Heading
          kind="title"
          text="Oops! Invitation Not Found"
        />
      </ModalHeader>

    </ModalContent>

  </Modal>
);

const GuestSearch = ({
  eventName, setGuestDetail, withVideo, eventType,
}) => {
  const [guestName, setGuestName] = useState('');
  const [guestList, setGuestList] = useState([]);
  const [showNotFound, setShowNotFound] = useState(false);
  const [onSearch, setOnSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setShowNotFound(false);
    document.getElementById('guestSearch').focus();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      getList();
    }
  };

  useEffect(() => {
    if (guestList.length) {
      setShowNotFound(false);
    } else if (onSearch) {
      setShowNotFound(true);
    }
  }, [guestList, onSearch]);

  useEffect(() => {
    document.getElementById('guestSearch').focus();
  }, []);

  const getList = () => {
    setIsLoading(true)
    axiosApiInstance.get(`${ENDPOINTS('customer.tab.findInvitation')}?name=${guestName}`)
      .then((res) => {
        setIsLoading(false)
        setOnSearch(true);
        setGuestList(res.data.value.invitations);
        document.getElementById('guestSearch').focus();
        setGuestName('');
      })
      .catch(() => {
        setIsLoading(false)
        setGuestList([]);
        setOnSearch(true);
        document.getElementById('guestSearch').focus();
        setGuestName('');
      });
  };

  const searchGuest = () => {
    if (guestName && guestName.length !== 0) {
      getList();
    }
  };
  const history = useHistory();
  const selectName = (item) => {
    console.log(item)
    const guestPack = item?.guests?.reduce((acc, curr) => {
      if (!curr?.arrival_status) {
        console.log(curr)
        return acc = acc + 1
      } 

      return acc;
    }, 0);
    
    setGuestDetail(item);
    if (guestPack) {
      history.push('/check-in/guest-arrival');
    } else {
      history.push('/check-in/guest-thankyou');
    }
  };


  return (
    <>
      <ModalNotFound
        isOpen={showNotFound}
        onRequestClose={closeModal}
      />
      {onSearch
        ? (
          <GuestContainer withVideo={withVideo}>
            <GuestContent>
              <Heading
                kind="subtitle"
                text={(
                  <>
                    <p>Choose invitation</p>
                    name below
                  </>
                )}
              />
              <Card>
                {guestList.length !== 0
                  && (
                    <ListContainer
                      data={guestList}
                      selectName={selectName}
                    />
                  )}
                <CardText>
                  <p>enter your booking code</p>
                  OR search other name
                </CardText>
                <SearchContainer>
                  <Input
                    type="text"
                    name="guestSearch"
                    placeholder="Type name here"
                    value={guestName}
                    onChange={setGuestName}
                    onKeyDown={onKeyDown}
                  />
                  <Button
                    kind="primary"
                    type="button"
                    text="Search"
                    onClick={searchGuest}
                    isLoading={isLoading}
                  />
                </SearchContainer>
              </Card>
            </GuestContent>
          </GuestContainer>
        )
        : (
          <GuestContainer withVideo={withVideo}>
            <GuestContent>
              <Heading
                kind="subtitle"
                text={eventName}
              />
              <Card>
                <CardText>
                  type your name below
                </CardText>
                <SearchContainer>
                  <Input
                    type="text"
                    name="guestSearch"
                    placeholder="Type name here"
                    value={guestName}
                    onChange={setGuestName}
                    onKeyDown={onKeyDown}
                  />
                  <Button
                    kind="primary"
                    type="button"
                    text="Search"
                    onClick={searchGuest}
                    disabled={isLoading}
                    isLoading={isLoading}
                  />
                </SearchContainer>
              </Card>
            </GuestContent>
          </GuestContainer>
        )}
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};

const { bool, func, string } = PropTypes;

ModalNotFound.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

GuestSearch.propTypes = {
  eventName: string.isRequired,
  setGuestDetail: func.isRequired,
};

export default GuestSearch;
