/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalContainer,
  ModalClose,
  ModalHeaderWithoutBadge,
} from 'pages/sign-in/modal/no-internet/style';
import NoInternet from 'assets/images/no-internet.svg';
import ExitSVG from 'assets/icons/exit.svg';

const InvoiceSent = ({
  isOpen,
  onRequestClose,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon src={NoInternet} style={{width:'100px',height:'100px',margin:'0 auto'}}/>
          <Heading
            kind="title"
            text="Oops!"
            marginBottom="0.5rem"
          />
          <Heading
            kind="subtitle"
            text="Seems like you're not connected to the internet! Check your connection and refresh the page."
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>
      </ModalContainer>
    </>
  </Modal>
);

const { bool, func } = PropTypes;

InvoiceSent.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

export default InvoiceSent;
