import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const LoadingBar = styled.div`
  border-top: 0.1875rem solid ${(props) => props.theme.loading.primary};
  position: absolute;
  top: -0.1875rem;
  left: 0;
  width: ${(props) => props.percent}%;
`;
