import styled, { css } from "styled-components";
import { Button, Heading } from "shared/styles";

export const FloatingMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0 1.25rem;
  border-radius: 0.75rem;
  background-color: ${(props) =>
    props.theme.table.floatingMenu.background.dark};
  color: ${(props) => props.theme.table.floatingMenu.text.dark};
`;

export const Icon = styled.img`
  position: relative;
  top: 0.15rem;
  margin-right: 0.4375rem;
`;

export const Selected = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  padding: 1rem 0.75rem;

  ${Icon} {
    cursor: pointer;
  }
`;

export const ButtonMenu = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.theme.table.floatingMenu.button.default.background};
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  padding: 0.3125rem 0.75rem;
  border-radius: 0.3125rem;
  margin: 1rem 0;

  ${(props) =>
    props.danger &&
    css`
      background-color: ${props.theme.table.floatingMenu.button.danger
        .background};
    `}
`;

export const ContentContainer = styled.div`
  min-height: 100%;
  background-color: ${(props) => props.theme.modal.background};
  padding: 2rem 2.5rem 1.75rem;
`;

export const HealthCheckContainer = styled.div`
  display: flex;
  padding: 28px 40px;
  flex-direction: column;
  background-color: #f4f4f4;
`;
export const HealthCheckButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const HCInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const HCInputGridContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const SummaryContainer = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  border-radius: 0.625rem;
  padding: 2.25rem 1.5rem;
`;

export const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const ModalHeaderTitle = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 0.5rem 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const TableButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const FloatingMenuContainer = styled.div`
  position: fixed;
  bottom: 1.5rem;
  width: fit-content;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-30%);
`;

export const InvitationTab = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  text-align: center;
  align-items: center;
  padding: 24px 0;
`;

export const InvitationTabItem = styled.div`
  flex: 1;
  color: ${(props) => (props.active && "#096B81") || "#BDBDBD"};
  &:hover {
    cursor: pointer;
  }
`;

export const InvitationContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 2rem 6.5625rem;
`;

export const EmailContainerHead = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 3.125rem 2.5rem;
  background: #096b81;
  border-radius: 10px 10px 0 0;

  ${Heading.Heading} {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #ffffff;
    text-align: left;
    margin: 0;
  }
`;

export const SubTitle = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #b5e0ea;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

export const EmailContainerBody = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 28px 40px;
  background: #ffffff;
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0a0a0a;
  border-radius: 0 0 10px 10px;
`;

export const EmailGreeting = styled.div`
  margin-bottom: 12px;
`;

export const EmailContent = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #dddddd;
`;

export const EmailInformation = styled.div`
  padding-top: 24px;
`;

export const EmailItem = styled.div`
  margin-bottom: 28px;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

export const EmailLabelInformation = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 122.4%;
  letter-spacing: 1.5px;
  color: #096b81;
  align-items: center;
  vertical-align: middle;
  text-transform: uppercase;

  ${Icon} {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;

export const EmailInfo = styled.div`
  margin-top: 8px;
`;

export const ViewMap = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #438fd4;
  margin-top: 4px;
`;

export const WhatsappContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 2rem 2.5rem;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  font-family: "GT Walsheim Pro", sans-serif;
  ${Icon} {
    max-width: 360px;
    height: auto;
  }
`;

export const WhatsappBody = styled.div`
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0a0a0a;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  padding: 32px 40px;
  background-color: #f4f4f4;
`;

export const ModalHeaderWithoutBadge = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.125rem;
    }
  }
`;

export const ListContainer = styled.div`
  background-color: white;
  padding: 0 1rem;
  border-radius: 0.625rem;
`;

export const ListItem = styled.div`
  padding: 1rem 0;
  border-bottom: 0.697397px solid #dddddd;
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.checkin.color.black};

  &:last-child {
    border-bottom-width: 0;
  }
`;

export const IconClose = styled.img`
  position: absolute;
  right: 1.8125rem;
  top: 1.8125rem;
  cursor: pointer;
`;

export const AssignCategoryDesc = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.heading.subtitle};
  margin-bottom: 1.5rem;
`;

export const AssignCategoryButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Button.Button} {
    margin: 1.25rem 1rem;
    margin-right: 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const AssignCategoryContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1.75rem 2.5rem;
  padding-bottom: 0;
  max-height: 40rem;
`;

export const AssignCategoryListContainer = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.assigner.border};
  margin-bottom: 1.5rem;
`;

export const AssignCategoryTableSelected = styled.p`
  font-family: "GT Walsheim Pro";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.sideMenu.title};
  margin-bottom: 1.5rem;
`;

export const PaginationArrow = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const PaginationText = styled.div`
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #096b81;
  margin: 0 45px;
`;

export const PaginationContainer = styled.div`
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const NameLabel = styled.span`
  text-transform: capitalize;
`;

export const HealthCheckText = styled.div`
  font-family: GT Walsheim;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #40a790;
`;

export const MapContainer = styled.div`
  display: grid;
  height: 200px;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ConfirmationDetail = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #438fd4;
`;

export const ConfirmationContent = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  border-radius: 0.625rem;
  padding: 20px;
`;

export const ConfirmationContentTile = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #0a0a0a;
`;

export const ConfirmationList = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

export const ConfirmationItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  &:nth-last-child(1) {
    border-bottom: 1px solid transparent;
  }
`;

export const ConfirmationItemRight = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0a0a0a;
`;
export const ConfirmationItemLeft = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #727272;
`;

export const CategoryBody = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  overflow-y: auto;
`;

export const FlightContent = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;

  h3 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #096b81;
  }
`;

export const FlightItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  p {
    width: 92px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #727272;
  }
  span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #0a0a0a;
  }
`;

export const FlightContainer = styled.div`
  margin-top: 20px;
`;
