import styled from 'styled-components';
import { Heading, Link } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
`;

export const HeadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  margin 0 auto;
  ${Link.Link} {
    position: absolute;
    bottom: 3.75rem;
  }
`;

export const Section = styled.div`
  flex:1;
  ${Heading.Heading} {
    width: 100%;
    text-align: left;

    &[kind="title"] {
      font-size: 3.25rem;
      line-height: 4.125rem;
      letter-spacing: 0.03125rem;
      margin-bottom: 1rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.00125rem;
      margin-bottom: 0;
    }
  }
`;

export const ContactsContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`;

export const Temp = styled.img`
  height: 100vh;
  right: 0;
`;

export const ContactContent = styled.div``;
