import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ENDPOINTS from "helpers/endpoints";
import { axiosApiInstance } from "helpers/authentication";
import { exportExcel } from "helpers/helpers";
import {
  Container,
  ContainerRight,
  Body,
  Icon,
  CloseButton,
  Content,
  ContentTitle,
  Label,
  Title,
  ChartContent,
  ChartTop,
  ChartBottom,
  ChartBottomLeft,
  ChartBottomRight,
  ChartLegend,
  ChartLegendNumber,
  ChartLegendText,
  ConfettiDecoration,
  BallDecoration,
  TopContainer,
  NextButton,
  NextButtonRight,
  ButtonContainer,
  ButtonContainer2,
  GridDashboard,
  Menu,
  OverflowTableControl,
  Category,
} from "pages/dashboard/fullreport/style";
import BackSVG from "assets/icons/left-arrow-white.svg";
import NextSVG from "assets/icons/right-arrow-white.svg";
import GiftsSVG from "assets/icons/gifts.svg";
import Back2SVG from "assets/icons/back-black.svg";
import UploadSVG from "assets/icons/import-white.svg";

import {
  PieChart,
  BoothCard,
  IconCard,
  BoothDetail,
  Table,
  TableApproval,
} from "components";
import { set } from "lodash";

const FullReportShow = ({ closeReport, redirect, data, eventDetail }) => {
  const { guestCheckedInCount, guestNotYetCheckedInCount, countAngpao } = data;

  const { name: eventName } = eventDetail;
  const percentChart =
    (guestCheckedInCount / (guestCheckedInCount + guestNotYetCheckedInCount)) *
    100;
  // const percentChart = 1 / (1 + 10)*100;

  const [invitationData, setInvitationData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checkInModal, setOpenCheckInModal] = useState(false);
  const [haventArrive, setOpenModalHaventArrive] = useState(false);
  const [invitation, setInvitation] = useState(true);
  const [checkInDatas, setCheckInDatas] = useState([]);
  const [notArriveData, setNotArriveData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [onDownload, setOnDownload] = useState(false);

  useEffect(() => {
    const getGuestCheckin = () => {
      axiosApiInstance
        .get(`${ENDPOINTS("customer.dashboard.guestCheckIn")}`)
        .then((res) => {
          setCheckInDatas(res.data.value);
        });
    };

    const guestHasNotArrived = () => {
      axiosApiInstance
        .get(`${ENDPOINTS("customer.dashboard.guestHasNotArrived")}`)
        .then((res) => {
          setNotArriveData(res.data.value);
        });
    };

    getGuestCheckin();
    guestHasNotArrived();
  }, []);

  const cols = [
    {
      title: "No",
      position: "center",
      width: "100px",
      render: (rowData, idx) => <span>{idx + 1}</span>,
    },
    {
      title: "Invitation Name",
      position: "",
      width: "120px",
      render: (rowData) => <span>{rowData.name}</span>,
    },
    {
      title: "Invitation Pax",
      position: "center",
      width: "100px",
      render: (rowData) => (
        <div style={{ textAlign: "center", width: "100%" }}>{rowData.pax}</div>
      ),
    },
    {
      title: "Arrived Pax",
      position: "center",
      width: "100px",
      render: (rowData) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          {rowData.arrivedPax}
        </div>
      ),
    },
    {
      title: "Arrived Time",
      position: "center",
      width: "100px",
      render: (rowData) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          {moment(rowData.arrival_time).format("HH:mm A")}
        </div>
      ),
    },
    {
      title: "Angpao",
      position: "center",
      width: "100px",
      render: (rowData) => <TableApproval status={rowData.angpao} />,
    },
    {
      title: "QRIS",
      position: "center",
      width: "100px",
      render: (rowData) => <TableApproval status={rowData.qris_gift} />,
    },
  ];
  const columns = [
    {
      checkIn: [
        {
          title: "No",
          position: "",
          width: "50px",
          render: (rowData, index) => {
            return <span>{index + 1}</span>;
          },
        },
        {
          title: "No",
          position: "center",
          width: "100px",
          render: (rowData, idx) => <span>1</span>,
        },
        {
          title: "Guest Name",
          position: "",
          width: "200px",
          render: (rowData) => <span>{rowData.name}</span>,
        },
        {
          title: "Invitation Name",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.invitationName || "-"}
            </div>
          ),
        },
        {
          title: "Invitation Category",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <Category
              category={
                rowData.invitationCategory
                  ? rowData?.invitationCategory?.length !== 0
                    ? rowData.invitationCategory[0]
                    : "-"
                  : "-"
              }
            >
              {rowData.invitationCategory
                ? rowData?.invitationCategory?.length !== 0
                  ? rowData.invitationCategory
                  : "-"
                : "-"}
            </Category>
          ),
        },
        {
          title: "Arrived Time",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {moment(rowData.arrival_time).format("HH:mm A")}
            </div>
          ),
        },
        {
          title: "Table",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.tables || rowData.table || "-"}
            </div>
          ),
        },
      ],
      checkInInvitation: [
        {
          title: "No",
          position: "",
          width: "50px",
          render: (rowData, index) => {
            return <span>{index + 1}</span>;
          },
        },
        {
          title: "Invitation Name",
          position: "",
          width: "200px",
          render: (rowData) => <span>{rowData.name}</span>,
        },
        {
          title: "Invitation Category",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <Category
              category={
                rowData.category
                  ? rowData?.category?.length !== 0
                    ? rowData.category[0]
                    : "-"
                  : "-"
              }
            >
              {rowData.category
                ? rowData?.category?.length !== 0
                  ? rowData.category
                  : "-"
                : "-"}
            </Category>
          ),
        },
        {
          title: "Invitation Pax",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.pax || "-"}
            </div>
          ),
        },
        {
          title: "Arrived Pax",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.arrivedPax || "-"}
            </div>
          ),
        },
        {
          title: "Arrived Time",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {moment(rowData.arrival_time).format("HH:mm A")}
            </div>
          ),
        },
        {
          title: "Table",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.tables || rowData.table || "-"}
            </div>
          ),
        },
      ],
      haventArrive: [
        {
          title: "No",
          position: "",
          width: "50px",
          render: (rowData, index) => {
            return <span>{index + 1}</span>;
          },
        },
        {
          title: invitation ? "Invitation Name" : "Guest Name",
          position: "",
          width: "200px",
          render: (rowData) => <span>{rowData.name}</span>,
        },
        {
          title: "Invitation Name",
          position: "",
          width: "100px",
          render: (rowData) => (
            <div style={{ width: "100%" }}>{rowData.invitationName || "-"}</div>
          ),
        },
        {
          title: "Invitation Category",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <Category
              category={
                rowData.invitationCategory
                  ? rowData?.invitationCategory?.length !== 0
                    ? rowData.invitationCategory[0]
                    : "-"
                  : "-"
              }
            >
              {rowData.invitationCategory
                ? rowData?.invitationCategory?.length !== 0
                  ? rowData.invitationCategory
                  : "-"
                : "-"}
            </Category>
          ),
        },
        {
          title: "Table",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.tables || rowData.table || "-"}
            </div>
          ),
        },
      ],
      haventArriveInvitation: [
        {
          title: "No",
          position: "",
          width: "50px",
          render: (rowData, index) => {
            return <span>{index + 1}</span>;
          },
        },
        {
          title: "Invitation Name",
          position: "",
          width: "200px",
          render: (rowData) => <span>{rowData.name}</span>,
        },
        {
          title: "Invitation Pax",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.pax || "-"}
            </div>
          ),
        },
        {
          title: "Invitation Category",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <Category
              category={
                rowData.category
                  ? rowData?.category?.length !== 0
                    ? rowData.category[0]
                    : "-"
                  : "-"
              }
            >
              {rowData.category
                ? rowData?.category?.length !== 0
                  ? rowData.category
                  : "-"
                : "-"}
            </Category>
          ),
        },
        {
          title: "Table",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.tables || rowData.table || "-"}
            </div>
          ),
        },
      ],
    },
  ];

  const dataTableBooth = invitationData.filter((item) => {
    if (searchTerm) {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return item;
  });
  const dataTableCheckInInvitation = checkInDatas.invitationCheckIn?.filter(
    (item) => {
      if (searchTerm) {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return item;
    }
  );
  const dataTableHasNotArriveInvitation =
    notArriveData.invitationHasNotArrived?.filter((item) => {
      if (searchTerm) {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return item;
    });
  const dataTableCheckInGuest = checkInDatas.guestCheckIn?.filter((item) => {
    if (searchTerm) {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return item;
  });

  const dataTableHasNotArriveGuest = notArriveData.guestHasNotArrived?.filter(
    (item) => {
      if (searchTerm) {
        return (
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.invitationName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      return item;
    }
  );

  const downloadReport = () => {
    setOnDownload(true);
    axiosApiInstance
      .get(`${ENDPOINTS("customer.dashboard.dashboardReport")}`)
      .then(
        ({
          data: {
            value: { attending, notAttending },
          },
        }) => {
          setOnDownload(false);
          const parsedDataAttending = parsedAttending(attending.data);
          const fileNameAttending = attending.sheetName;
          const parsedDataNotAttending = parsedNotAttending(notAttending.data);
          const fileNameNotAttending = notAttending.sheetName;
          const parsedData = [
            {
              data: parsedDataAttending,
              sheetName: fileNameAttending,
            },
            {
              data: parsedDataNotAttending,
              sheetName: fileNameNotAttending,
            },
          ];
          exportExcel(parsedData, "Event Report", true);
        }
      )
      .catch((err) => {
        setOnDownload(false);
        console.error(err);
      });
  };

  const parsedAttending = (data) => {
    const parsed = [
      [
        "Invitation name",
        "Guest Name",
        "Phone Number",
        "Pax",
        "Arrival Status",
        "Arrival Time",
        "Guest Number",
        "Gift / Angpao",
        "Souvenir",
        "Leaving Time",
        "Notes",
      ],
    ];
    data.map((item) => {
      const arr = [
        item["Invitation name"],
        item["Guest Name"],
        item["Phone Number"],
        item.Pax,
        item["Arrival Status"],
        item["Arrival Time"],
        item["Guest Number"],
        item["Gift / Angpao"],
        item.Souvenir,
        item["Leaving Time"],
        item.Notes,
      ];
      parsed.push(arr);
    });
    return parsed;
  };

  const parsedNotAttending = (data) => {
    const parsed = [["Invitation name", "Guest Name", "Phone Number", "Pax"]];
    data.map((item) => {
      const arr = [
        item["Invitation name"],
        item["Guest Name"],
        item["Phone Number"],
        item.Pax,
      ];
      parsed.push(arr);
    });
    return parsed;
  };

  return (
    <>
      <BoothDetail
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
          setSearchTerm("");
        }}
        title="Booth Detail"
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      >
        {invitationData ? (
          <OverflowTableControl>
            <Table readOnly={true} cols={cols} data={dataTableBooth} />{" "}
          </OverflowTableControl>
        ) : null}
      </BoothDetail>
      <BoothDetail
        button
        isOpen={checkInModal || haventArrive}
        onRequestClose={() => {
          setOpenCheckInModal(false) || setOpenModalHaventArrive(false);
          setSearchTerm("");
        }}
        title={checkInModal ? "Guest Check-in" : "Guest Haven't Arrive"}
        buttonText="Invitation"
        buttonSecondaryText="Guest"
        onClickInvitation={() => setInvitation(true)}
        onClickGuest={() => setInvitation((prev) => !prev)}
        invitationActive={invitation}
        guestActive={!invitation}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      >
        {checkInDatas || notArriveData ? (
          <OverflowTableControl>
            {invitation ? (
              <Table
                readOnly={true}
                cols={
                  checkInModal
                    ? columns[0].checkInInvitation
                    : columns[0].haventArriveInvitation
                }
                data={
                  checkInModal
                    ? dataTableCheckInInvitation
                    : dataTableHasNotArriveInvitation
                }
              />
            ) : (
              <Table
                readOnly={true}
                cols={
                  checkInModal ? columns[0].checkIn : columns[0].haventArrive
                }
                data={
                  checkInModal
                    ? dataTableCheckInGuest
                    : dataTableHasNotArriveGuest
                }
              />
            )}
          </OverflowTableControl>
        ) : null}
      </BoothDetail>
      <Menu>
        <Container>
          {/* <BallDecoration src={BallSVG} />
          <ConfettiDecoration src={ConfettiSVG} /> */}
          {/* <Body> */}
          <TopContainer>
            <ButtonContainer2>
              <NextButton onClick={() => redirect("/check-in/guest-welcome")}>
                check-in page
                <Icon src={NextSVG} />
              </NextButton>
            </ButtonContainer2>
            <ButtonContainer2>
              <NextButtonRight
                onClick={onDownload ? () => {} : downloadReport}
                end
              >
                <Icon src={UploadSVG} />
                Reports
              </NextButtonRight>
            </ButtonContainer2>
          </TopContainer>
          <GridDashboard>
            <IconCard
              text={countAngpao ? countAngpao.totalAngpao : ""}
              isDashboard
              icon={GiftsSVG}
              subTitle="Total Angpao"
            />
            {countAngpao
              ? countAngpao.booths.map((item, index) => (
                  <BoothCard
                    key={index}
                    title={item.name}
                    subTitle={item.totalAngpao}
                    text={item.qris}
                    totalInvities={item.invitation}
                    onClick={() => {
                      setInvitationData(item.invitationData);
                      setOpenModal(true);
                    }}
                  />
                ))
              : null}
          </GridDashboard>
        </Container>
        <ContainerRight>
          <ButtonContainer>
            <CloseButton onClick={closeReport}>
              <Icon src={Back2SVG} />
              Dashboard
            </CloseButton>
          </ButtonContainer>
          <Content>
            <ContentTitle>
              <Label>Check-In Report</Label>
              <Title>{eventName}</Title>
            </ContentTitle>
            <ChartContent>
              <ChartTop>
                <PieChart
                  size="260"
                  width="82"
                  number={percentChart.toFixed(0)}
                  fontSize="22"
                  fontWeight="bold"
                  backgroundColor="#ffffff"
                  isBlack={true}
                />
              </ChartTop>
              <ChartBottom>
                <ChartBottomLeft>
                  <ChartLegendNumber>
                    {/* <ChartLegend isFill /> */}
                    <Title isCheckIn onClick={() => setOpenCheckInModal(true)}>
                      {guestCheckedInCount}
                    </Title>
                  </ChartLegendNumber>
                  <ChartLegendText>Guests Checked-In</ChartLegendText>
                </ChartBottomLeft>
                <ChartBottomRight>
                  <ChartLegendNumber>
                    {/* <ChartLegend /> */}
                    <Title
                      isHaventArrived
                      onClick={() => setOpenModalHaventArrive(true)}
                    >
                      {guestNotYetCheckedInCount}
                    </Title>
                  </ChartLegendNumber>
                  <ChartLegendText>Guests Haven’t Arrived</ChartLegendText>
                </ChartBottomRight>
              </ChartBottom>
            </ChartContent>
          </Content>
        </ContainerRight>
      </Menu>
    </>
  );
};

const { func } = PropTypes;

FullReportShow.propTypes = {
  closeReport: func,
  redirect: func.isRequired,
};

FullReportShow.defaultProps = {
  closeReport: () => {},
};

export default FullReportShow;
