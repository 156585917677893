import React from 'react';
import PropTypes from 'prop-types';
import { IconContainer, Icon } from 'components/table/approval/style';
import ApprovedSVG from 'assets/icons/approved.svg';
import UnapprovedSVG from 'assets/icons/unapproved.svg';

const ApprovalComponent = ({ status }) => (
  <IconContainer>
    {status ? (
      <Icon src={ApprovedSVG} />
    ) : (
      <Icon src={UnapprovedSVG} />
    )}
  </IconContainer>
);

const { bool } = PropTypes;

ApprovalComponent.propTypes = {
  status: bool.isRequired,
};

export default ApprovalComponent;
