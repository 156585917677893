import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonIcon,
  Title,
  InputContainers,
  Input,
  Icon,
  SubTitleDashboard,
} from 'components/button-icon/style';

const ButtonIconComponent = ({
  isActive,
  infographic,
  editable,
  text,
  icon,
  info,
  onChange,
  isDashboard,
  subTitle,
  onClick,
  placeholder,
}) => {
  const [focus, setFocus] = useState(false);
  const inputEl = useRef(null);
  return (
    <ButtonIcon
      isActive={isActive}
      infographic={infographic}
      editable={editable}
      isDashboard
      onClick={infographic ? () => { inputEl.current.focus(); } : onClick}
    >
      <Title isDashboard={isDashboard}>{text}</Title>
      {isDashboard && (
        <SubTitleDashboard>{subTitle}</SubTitleDashboard>
      )}
      {!infographic ? (
        <Icon
          src={icon}
        />
      ) : (
        <InputContainers>
          <Icon
            src={icon}
          />
          <Input
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            type="number"
            min="0"
            onKeyDown={((e) => {

              if (!((e.keyCode > 95 && e.keyCode < 106)
                  || (e.keyCode > 47 && e.keyCode < 58)
                  || e.keyCode == 8)
                  || (!info && e.keyCode==48)
                  ) {
                e.preventDefault();
              }
            })}
            disabled={!editable}
            value={info}
            placeholder={focus ? '' : (placeholder || '500')}
            onClick={(event) => { event.target.select(); }}
            onChange={(e) => onChange(e.target.value)}
            ref={inputEl}
          />
        </InputContainers>
      )}
    </ButtonIcon>
  );
};

const {
  oneOfType,
  bool,
  string,
  number,
  object,
  func,
} = PropTypes;

ButtonIconComponent.propTypes = {
  isActive: bool,
  infographic: bool,
  editable: bool,
  text: oneOfType([string, object, number]),
  icon: string,
  info: oneOfType([string, number]),
  onChange: func,
  isDashboard: bool,
  subTitle: oneOfType([object, string]),
  onClick: func,
};

ButtonIconComponent.defaultProps = {
  isActive: false,
  infographic: false,
  editable: false,
  text: '',
  icon: '',
  info: 0,
  onChange: () => { },
  isDashboard: false,
  subTitle: '',
  onClick: () => { },
};

export default ButtonIconComponent;
