import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ENDPOINTS from "helpers/endpoints";
import { axiosApiInstance } from "helpers/authentication";
import Joi from "joi";
import { Input, Button, TextArea } from "shared";
import {
  Modal,
  Combobox,
  ComboboxWhite,
  SideMenu,
  MessageBox,
} from "components";
import { useHistory } from "react-router-dom";
import BackSVG from "assets/icons/left-arrow.svg";
import NoNotifSVG from "assets/images/no-notif.svg";
import MessageSVG from "assets/icons/message.svg";
import {
  Body,
  Icon,
  ModalHeaderTitle,
  ContentContainer,
  ModalHeader,
  Title,
  SubTitle,
  BackButton,
  Top,
  RightMenu,
  Bottom,
  EmptyListContainer,
  EmptyListContent,
  EmptyListMessage,
  ListContainer,
  GridDashboard,
  Label,
  CardContainer,
  Card,
  CardDate,
  CardName,
  CardDetail,
  CardItem,
  CardItemNumber,
  CardItemText,
  ButtonContainer,
  CardWrapper,
  MessageBoxContainer,
} from "pages/guest-request/style";

const InfoModal = ({
  isOpen,
  onRequestClose,
  list,
  setPaxApproveInput,
  paxApproveInput,
  updateGuestRequest,
  isSubmiting,
}) => {
  const {
    invitationName,
    before_pax: beforePax,
    asking_pax: askingPax,
    _id,
  } = list;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <>
        <ModalHeader>
          <Icon src={MessageSVG} />
          <ModalHeaderTitle>Guest Info</ModalHeaderTitle>
        </ModalHeader>
        <ContentContainer>
          <Input
            type="text"
            label="Requested by"
            name="requestedby"
            value={invitationName}
            disabled
          />
          <GridDashboard gridTemplateColumn="1fr 1fr">
            <Input
              type="number"
              label="Pax Provided"
              name="beforePax"
              value={beforePax || ""}
              disabled
            />
            <Input
              type="number"
              label="Pax Requested"
              name="askingPax"
              value={askingPax || ""}
              disabled
            />
          </GridDashboard>
          <Input
            type="number"
            label="Pax Approved"
            name="paxapprove"
            value={paxApproveInput}
            onChange={setPaxApproveInput}
          />
          <ButtonContainer>
            <Button
              kind="primary"
              type="button"
              text="Confirm"
              onClick={() => updateGuestRequest(_id)}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </ContentContainer>
      </>
    </Modal>
  );
};

const SendRequestModal = ({
  isOpen,
  list,
  onRequestClose,
  isSubmiting,
  guestValue,
  noteValue,
  beforePaxValue,
  askingPaxValue,
  onChangeGuests,
  onChangeRequestPax,
  onChangeNote,
  onClickConfirm,
  sendRequestError,
  closeSendRequestError,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "27.625rem",
          background: "#fff",
        },
      }}
    >
      <>
        <ModalHeader>
          <Icon src={MessageSVG} />
          <ModalHeaderTitle>Send Request</ModalHeaderTitle>
        </ModalHeader>
        <ContentContainer>
          {sendRequestError.status && (
            <MessageBoxContainer>
              <MessageBox
                type={sendRequestError.type}
                title={sendRequestError.title}
                text={sendRequestError.text}
                onRequestClose={closeSendRequestError}
              />
            </MessageBoxContainer>
          )}
          <Input
            type="text"
            label="Select Guest"
            name="guest"
            placeholder="Select Guest"
            withSearchFilter
            value={guestValue}
            guestsList={list}
            onChange={onChangeGuests}
            readonly
          />
          <GridDashboard gridTemplateColumn="1fr 1fr">
            <Input
              type="number"
              label="Current Pax"
              name="currentPax"
              value={beforePaxValue || 0}
              disabled
            />
            <Input
              type="number"
              label="Request Pax"
              name="requestPax"
              value={askingPaxValue}
              onChange={onChangeRequestPax}
            />
          </GridDashboard>
          <TextArea
            name="notes"
            label="Notes"
            rows="4"
            cols="50"
            placeholder="Write Your Notes Here"
            value={noteValue}
            onChange={onChangeNote}
          />
          <ButtonContainer>
            <Button
              kind="primary"
              type="button"
              text="Confirm"
              onClick={onClickConfirm}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </ContentContainer>
      </>
    </Modal>
  );
};

const SideMenuBar = ({
  isOpen,
  onRequestClose,
  finishFunc,
  editFunc,
  cancelFunc,
  guestValue,
  noteValue,
  beforePaxValue,
  askingPaxValue,
  paxApproveInput,
  onChangeRequestPax,
  onChangePaxApprove,
  onChangeNote,
  disabled,
  closeSendRequestError,
  sendRequestError,
  cancelButton,
  buttonText,
  readOnly,
}) => {
  return (
    <SideMenu
      cancelButton={cancelButton}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      finishFunc={finishFunc}
      editFunc={editFunc}
      cancelFunc={cancelFunc}
      title="Sent Request"
      buttonText={buttonText}
      icon={MessageSVG}
      disabled={false}
      isLoading={false}
    >
      {sendRequestError.status && (
        <MessageBoxContainer>
          <MessageBox
            type={sendRequestError.type}
            title={sendRequestError.title}
            text={sendRequestError.text}
            onRequestClose={closeSendRequestError}
          />
        </MessageBoxContainer>
      )}
      <Input
        type="text"
        label="Guest Name"
        name="name"
        placeholder="Kevin Himawan"
        value={guestValue}
        disabled
      />
      <GridDashboard gridTemplateColumn="1fr 1fr">
        <Input
          type="number"
          label="Current Pax"
          name="currentPax"
          value={beforePaxValue || 0}
          disabled
        />
        <Input
          type="number"
          label="Request Pax"
          name="requestPax"
          value={askingPaxValue}
          disabled={disabled}
          onChange={onChangeRequestPax}
        />
      </GridDashboard>
      {disabled && (
        <Input
          type="text"
          label="Approval Pax"
          name="approvalPax"
          placeholder="Approval Pax"
          value={paxApproveInput}
          onChange={onChangePaxApprove}
          disabled={readOnly}
        />
      )}
      <TextArea
        name="notes"
        label="Notes"
        rows="4"
        cols="50"
        value={noteValue}
        placeholder="Notes.."
        onChange={onChangeNote}
        disabled={disabled}
      />
    </SideMenu>
  );
};

const GuestRequest = ({ setIsLoading }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [status, setStatus] = useState("Sent Request");
  const [selectedId, setSelectedId] = useState(0);
  const [paxApproveInput, setPaxApproveInput] = useState("");
  const [list, setList] = useState([]);
  const [listInvitation, setListInvitaiton] = useState([]);
  const [guestList, setGuestList] = useState([]);
  const [value, setValue] = useState({
    invitation: "",
    before_pax: 0,
    asking_pax: 0,
    notes: "",
  });
  const [request, setRequest] = useState([]);
  const openModal = (id) => {
    setShowMenu(true);
    setRequest(id);
    setSelectedId(id);
  };
  const [edit, setEdit] = useState(false);

  const [sendRequestError, setSendRequestError] = useState({
    type: "",
    status: false,
    title: "",
    text: "",
  });

  const closeModal = () => setShowModal(false);
  const closeSendRequestError = () => {
    setSendRequestError({
      type: "",
      status: false,
      title: "",
      message: "",
    });
  };
  const statuSelected = (value) => setStatus(value);
  const updateGuestRequest = (id) => {
    setIsSubmiting(true);
    const reRenderList = () => {
      axiosApiInstance
        .get(
          `${ENDPOINTS(
            "customer.guestRequest.guestRequest"
          )}?is_resolve=${status}`
        )
        .then((res) => {
          setList(res.data.value.requests);
        });
    };

    axiosApiInstance
      .put(`${ENDPOINTS("customer.guestRequest.updateGuestRequest")}/${id}`, {
        after_pax: paxApproveInput,
      })
      .then(() => {
        setIsSubmiting(false);
        reRenderList();
        setShowModal(false);
      })
      .catch(() => {
        setIsSubmiting(false);
      });
  };

  useEffect(() => {
    const getList = () => {
      axiosApiInstance
        .get(
          `${ENDPOINTS(
            "customer.guestRequest.guestRequest"
          )}?is_resolve=${status}`
        )
        .then((res) => {
          setList(res.data.value.requests);
          setIsLoading(false);
        });
    };

    const getInvitationList = () => {
      axiosApiInstance
        .get(`${ENDPOINTS("customer.guestRequest.guestRequest")}/invitation`)
        .then((res) => {
          setListInvitaiton(res.data.value.invitations);
          let tempData = [];
          res.data.value.invitations.map((item) => {
            tempData.push({
              name: item.name,
              before_pax: item.pax,
              _id: item._id,
            });
          });
          setGuestList(tempData);
          setIsLoading(false);
        });
    };

    getInvitationList();
    if (status) {
      getList();
    }
    // eslint-disable-next-line
  }, [status]);

  const EmptyList = () => (
    <EmptyListContainer>
      <EmptyListContent>
        <Icon src={NoNotifSVG} />
        <EmptyListMessage>You have no {status}</EmptyListMessage>
      </EmptyListContent>
    </EmptyListContainer>
  );

  const dummy = [
    {
      id: 1,
      date: "29 Mar",
      name: "Benny Girsang",
      currentPax: 2,
      requestPax: 4,
    },
    {
      id: 2,
      date: "29 Mar",
      name: "Yeremias Nata",
      currentPax: 2,
      requestPax: 4,
    },
    {
      id: 3,
      date: "29 Mar",
      name: "Annisa Jasmine",
      currentPax: 2,
      requestPax: 4,
    },
  ];

  const SentRequest = () => (
    <CardContainer>
      <Label>SENT REQUEST (3)</Label>
      <CardWrapper>
        {dummy.map((item) => (
          <Card onClick={() => setShowMenu(true)} key={item.id}>
            <CardDate>{item.date}</CardDate>
            <SubTitle>SENT REQUEST TO</SubTitle>
            <CardName>{item.name}</CardName>
            <CardDetail>
              <CardItem>
                <CardItemNumber>{item.currentPax}</CardItemNumber>
                <CardItemText>Current Pax</CardItemText>
              </CardItem>
              <CardItem>
                <CardItemNumber>{item.requestPax}</CardItemNumber>
                <CardItemText>Request Pax</CardItemText>
              </CardItem>
            </CardDetail>
          </Card>
        ))}
      </CardWrapper>
    </CardContainer>
  );

  const GuestRequestComboboxList = [
    { value: "All", label: "All" },
    { value: "New Request", label: "New Request" },
    { value: "Completed Request", label: "Completed Request" },
  ];

  const GuestNameComboBoxList = listInvitation.map((item) => ({
    value: item._id,
    label: item.name,
    pax: item.pax,
  }));

  const onSendRequest = async () => {
    const form = value;
    try {
      const schema = Joi.object({
        asking_pax: Joi.number()
          .required()
          .error((err) => {
            return new Error("Please enter a valid number of Request Pax");
          }),
        before_pax: Joi.number().required(),
        invitation: Joi.string()
          .trim()
          .required()
          .error((err) => {
            if (err[0].code === "string.empty") {
              return new Error("Please choose guest name");
            }
            return new Error("Your name format not valid");
          }),
        notes: Joi.string().default("").allow(""),
        // event: Joi.number().trim(),
      });

      const values = await schema.validateAsync(form);
      axiosApiInstance
        .post(ENDPOINTS("customer.guestRequest.guestRequest"), values)
        .then((res) => {
          setShowSendModal(false);
          window.location.reload();
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.m) {
            setSendRequestError({
              type: "warning",
              status: true,
              title: "Can't add Request",
              text: err.response.data.m,
            });
          }
          setIsSubmiting(false);
          // error message
        });
    } catch (err) {
      setSendRequestError({
        type: "warning",
        status: true,
        title: "Can't add Request",
        text: err.message,
      });
    }
  };

  const onClickConfirm = async () => {
    const form = request;
    !edit && (form.after_pax = paxApproveInput);
    try {
      const schema = Joi.object({
        asking_pax: Joi.number()
          .required()
          .error((err) => {
            return new Error("Please enter a valid number of Request Pax");
          }),
        before_pax: Joi.number().required(),
        after_pax:
          !edit &&
          Joi.number()
            .required()
            .error((err) => {
              return new Error("Please enter a valid number of Approval Pax");
            }),
        invitationId: Joi.string().trim().required(),
        invitationName: Joi.string().trim(),
        is_resolve: Joi.number().required(),
        notes: Joi.string().default("").allow(""),
        _id: Joi.string().trim().required(),
        // event: Joi.number().trim(),
      });

      const values = await schema.validateAsync(form);
      axiosApiInstance
        .put(
          `${ENDPOINTS("customer.guestRequest.guestRequest")}/${form._id}${
            !edit ? "/approve" : "/"
          }`,
          values
        )
        .then((res) => {
          if (res.data) {
            setShowMenu(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.m) {
            setSendRequestError({
              type: "warning",
              status: true,
              title: "Can't send Request",
              text: err.response.data.m,
            });
          }
          setIsSubmiting(false);
          // error message
        });
    } catch (err) {
      setSendRequestError({
        type: "warning",
        status: true,
        title: "Can't send Request",
        text: err.message,
      });
    }
  };

  const onClickCancel = async () => {
    setEdit(false);
    const form = { notes: request.notes };
    try {
      const schema = Joi.object({
        notes: Joi.string().default("").allow(""),
        // _id: Joi.string().trim().required(),
        // event: Joi.number().trim(),
      });

      const values = await schema.validateAsync(form);
      axiosApiInstance
        .put(
          `${ENDPOINTS("customer.guestRequest.guestRequest")}/${
            request._id
          }/cancel`,
          values
        )
        .then((res) => {
          if (res.data) {
            setShowMenu(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.m) {
            setSendRequestError({
              type: "warning",
              status: true,
              title: "Can't cancel Request",
              text: err.response.data.m,
            });
          }
          setIsSubmiting(false);
          // error message
        });
    } catch (err) {
      setSendRequestError({
        type: "warning",
        status: true,
        title: "Can't cancel Request",
        text: err.message,
      });
    }
  };

  return (
    <>
      <InfoModal
        setPaxApproveInput={setPaxApproveInput}
        paxApproveInput={paxApproveInput}
        isOpen={showModal}
        onRequestClose={closeModal}
        list={
          (status === "New Request" || status === "All") && list[selectedId]
        }
        updateGuestRequest={updateGuestRequest}
        isSubmiting={isSubmiting}
      />

      <SendRequestModal
        isOpen={showSendModal}
        list={guestList}
        onRequestClose={() => setShowSendModal(false)}
        isSubmiting={isSubmiting}
        guestValue={value.name}
        beforePaxValue={value.before_pax}
        askingPaxValue={value.asking_pax}
        noteValue={value.notes}
        onChangeGuests={(values) =>
          setValue({
            ...value,
            invitation: values?._id,
            before_pax: values?.before_pax,
          })
        }
        onChangeRequestPax={(v) => setValue({ ...value, asking_pax: v })}
        onChangeNote={(v) => setValue({ ...value, notes: v })}
        onClickConfirm={onSendRequest}
        sendRequestError={sendRequestError}
        closeSendRequestError={closeSendRequestError}
      />

      <SideMenuBar
        isOpen={showMenu}
        onRequestClose={() => setShowMenu(false)}
        guestValue={request.invitationName}
        beforePaxValue={request.before_pax}
        askingPaxValue={request.asking_pax}
        noteValue={request.notes}
        paxApproveInput={
          request?.after_pax ? request?.after_pax : paxApproveInput
        }
        onChangeRequestPax={(v) => setRequest({ ...request, asking_pax: v })}
        onChangePaxApprove={(v) => setPaxApproveInput(v)}
        onChangeNote={(v) => setRequest({ ...request, notes: v })}
        finishFunc={onClickConfirm}
        editFunc={() => setEdit(!edit)}
        cancelFunc={onClickCancel}
        disabled={edit ? false : true}
        readOnly={request?.is_resolve ? true : false}
        sendRequestError={sendRequestError}
        closeSendRequestError={closeSendRequestError}
        cancelButton={request?.is_resolve ? false : true}
        buttonText={request?.is_resolve ? "" : "Confirm"}
      />

      <Body>
        <Top>
          <BackButton onClick={() => history.push("/event/dashboard")}>
            <Icon src={BackSVG} />
            <Title>Guest Request</Title>
          </BackButton>
          <RightMenu>
            <Combobox
              placeholder="New Request"
              list={GuestRequestComboboxList}
              value={status}
              onChange={(value) => {
                statuSelected(value.value);
              }}
              skipChange={false}
            />
            <Button
              small
              kind="primary"
              type="button"
              text="Send Request"
              onClick={() => setShowSendModal(true)}
            />
          </RightMenu>
        </Top>
        <Bottom>
          {list.length && status !== "Sent Request" ? (
            <ListContainer>
              <SubTitle>
                {(status === "New Request" && `New Request (${list.length})`) ||
                  (status === "Completed Request" &&
                    `Completed Request (${list.length})`) ||
                  "All"}
              </SubTitle>
              <GridDashboard>
                {list.map((item, index) => (
                  <Card
                    isCompleted={item?.is_resolve}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={() => {
                      openModal(item);
                    }}
                  >
                    <CardDate>{item.date}</CardDate>
                    <SubTitle>Requested By</SubTitle>
                    <CardName>{item.invitationName}</CardName>
                    <CardDetail>
                      <CardItem>
                        <CardItemNumber>{item.before_pax}</CardItemNumber>
                        <CardItemText>Pax Provided</CardItemText>
                      </CardItem>
                      <CardItem>
                        <CardItemNumber>{item.asking_pax}</CardItemNumber>
                        <CardItemText>Pax Requested</CardItemText>
                      </CardItem>
                      {item?.is_resolve ? (
                        <CardItem>
                          <CardItemNumber>
                            {item?.after_pax || 0}
                          </CardItemNumber>
                          <CardItemText>Pax Approved</CardItemText>
                        </CardItem>
                      ) : null}
                    </CardDetail>
                  </Card>
                ))}
              </GridDashboard>
            </ListContainer>
          ) : (
            <EmptyList />
          )}
        </Bottom>
      </Body>
    </>
  );
};

const { bool, func, arrayOf, number, shape, oneOfType, string } = PropTypes;

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  list: oneOfType([shape({}), arrayOf(shape({}))]),
  setPaxApproveInput: func,
  paxApproveInput: oneOfType([number, string]),
  updateGuestRequest: func.isRequired,
};

InfoModal.defaultProps = {
  list: [],
  setPaxApproveInput: () => {},
  paxApproveInput: 0,
};

SendRequestModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  list: oneOfType([shape({}), arrayOf(shape({}))]),
  sendRequestError: oneOfType([shape({}), arrayOf(shape({}))]),
  closeSendRequestError: func.isRequired,
  noteValue: string,
  onChangeNote: func,
};

SendRequestModal.defaultProps = {
  list: [],
  onRequestClose: () => {},
  closeSendRequestError: () => {},
};

SideMenuBar.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  finishFunc: func.isRequired,
  editFunc: func.isRequired,
  cancelFunc: func.isRequired,
  sendRequestError: oneOfType([shape({}), arrayOf(shape({}))]),
  closeSendRequestError: func.isRequired,
  cancelButton: bool,
  buttonText: string,
  readOnly: bool,
};

SideMenuBar.defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  finishFunc: () => {},
  editFunc: () => {},
  cancelFunc: () => {},
  closeSendRequestError: () => {},
  cancelButton: true,
  readOnly: false,
};

export default GuestRequest;
