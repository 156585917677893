import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Heading,
  Button,
} from 'shared';
import {
  GuestContainer,
  GuestContent,
  Card,
  CardText,
  ButtonContainer,
  LogoContainer,
  Icon,
} from 'pages/check-in/guest-welcome/style';
import LogoFooterSVG from 'assets/images/logo-footer.svg';

const GuestWelcome = ({ eventName, withVideo }) => {
  const history = useHistory();

  return (
    <>
      <GuestContainer alignItems="center" withVideo={withVideo}>
        <Heading
          kind="title"
          text={eventName}
        />
      </GuestContainer>
      <GuestContainer>
        <GuestContent>
          <Heading
            kind="subtitle"
            text={eventName}
          />
          <Card>
            <CardText>
              Do you bring your QR CODE?
            </CardText>
            <ButtonContainer>
              <Button
                kind="secondary"
                type="button"
                text="No"
                onClick={() => { history.push('/check-in/guest-search'); }}
              />
              <Button
                kind="primary"
                type="button"
                text="Yes"
                onClick={() => { history.push('/check-in/guest-scan-qr'); }}
              />
            </ButtonContainer>
          </Card>
        </GuestContent>
      </GuestContainer>
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};

const { string } = PropTypes;

GuestWelcome.propTypes = {
  eventName: string.isRequired,
};

export default GuestWelcome;
