import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { Modal } from "components";
import { Heading, Button } from "shared";
import {
  Container,
  Title,
  Icon,
  SearchIcon,
  Input,
  SectionWrapper,
  Section,
  ButtonContainer,
  Content,
} from "components/booth-details/style";
import ExitSVG from "assets/icons/exit.svg";
import SearchSVG from "assets/icons/search.svg";

const BoothDetails = ({
  isOpen,
  onRequestClose,
  title,
  button,
  buttonText,
  buttonSecondaryText,
  onClickInvitation,
  onClickGuest,
  icon,
  children,
  onChange,
  invitationActive,
  guestActive,
}) => {
  const totalCollapsibles = children.length;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          border: 0,
          width: "80vw",
          minHeight: "87vh",
          padding: 0,
          marginBottom: "3rem",
          borderRadius: "18px",
          overflow: "hidden",
        },
      }}
    >
      <Container>
        <Scrollbars autoHide style={{ minHeight: "100vh" }}>
          <Title>
            <SectionWrapper>
              <Section icon>
                {icon && <Icon src={icon} />}
                <Heading kind="title" text={title} />
              </Section>
              <Section>
                <Section>
                  <Input type="text" placeholder="Search" onChange={onChange} />
                  <SearchIcon src={SearchSVG} />
                </Section>
                <Icon src={ExitSVG} onClick={onRequestClose} />
              </Section>
            </SectionWrapper>
            {button && (
              <ButtonContainer>
                <Button
                  small
                  kind="secondary"
                  type="button"
                  text={buttonText}
                  onClick={onClickInvitation}
                  active={invitationActive}
                />
                <Button
                  small
                  kind="secondary"
                  type="button"
                  text={buttonSecondaryText}
                  onClick={onClickGuest}
                  active={guestActive}
                />
              </ButtonContainer>
            )}
          </Title>
          <Content>{children}</Content>
        </Scrollbars>
      </Container>
    </Modal>
  );
};

const { oneOfType, arrayOf, bool, number, func, string, element } = PropTypes;

BoothDetails.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  title: string.isRequired,
  buttonText: oneOfType([bool, string]),
  buttonSecondaryText: oneOfType([bool, string]),
  onClickInvitation: func.isRequired,
  onClickGuest: func.isRequired,
  onChange: func.isRequired,
  button: bool,
  icon: string,
  children: oneOfType([element, arrayOf(element)]),
  invitationActive: bool,
  guestActive: bool,
};

BoothDetails.defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  title: "",
  button: false,
  buttonText: "",
  onClickInvitation: () => {},
  onClickGuest: () => {},
  onChange: () => {},
  buttonSecondaryText: "",
  children: <></>,
  invitationActive: false,
  guestActive: false,
};

export default BoothDetails;
