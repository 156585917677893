import styled, { keyframes } from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Icon = styled.img`
`;

export const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
`;

export const SourceVideo = styled.source`
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const GuestContainer = styled.div`
  position: absolute;
  background-color: ${(props) => (props.withVideo && 'rgba(0,0,0,0.5)') || 'transparent'};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items ${(props) => props.alignItems || 'stretch'};

  ${Heading.Heading} {
    &[kind="title"] {
      animation: ${fadeOut} 2s ease-out;
      -webkit-animation-fill-mode: forwards;
      width: 47.1875rem;
      font-weight: bold;
      font-size: 3.25rem;
      line-height: 4.125rem;
      text-align: center;
      letter-spacing: 0.03125rem;
      color: #FFFFFF;
    }
  }
`;

export const GuestContent = styled.div`
  position: absolute;
  top: 4.375rem;
  opacity: 0;
  animation: ${fadeIn} 2s ease-out;
  -webkit-animation-fill-mode: forwards;
  ${Heading.Heading} {
    &[kind="subtitle"] {
      width: 25rem;
      margin: 0 auto;
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      line-height: 2.75rem;
      text-align: center;
      letter-spacing: 0.03125rem;
      color: #FFFFFF;
      margin-bottom: 1.75rem;
    }
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  margin: auto;
  width: 30rem;
  background: rgba(243, 242, 245, 0.5);
  border-radius: 0.625rem;
  padding: 2.5rem;
`;

export const CardText = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.625rem;
  text-align: center;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 1.25rem;
`;

export const ButtonContainer = styled.div`
  height: 3rem;
  display:flex;
  justify-content: space-between;
  ${Button.Button}{
    width: 12rem;
    &[kind="secondary"]{
      border: 0.09375rem solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 0.625rem;
      background-color: transparent;
      color: #ffffff;
    }
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 3.9375rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
