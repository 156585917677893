import React from 'react';
import PropTypes from 'prop-types';
import { Contact, Icon } from 'components/contact/style';

const ContactComponent = ({ icon }) => (
  <Contact>
    <Icon src={icon} />
  </Contact>
);

const { string } = PropTypes;

ContactComponent.propTypes = {
  icon: string,
};

ContactComponent.defaultProps = {
  icon: '',
};

export default ContactComponent;
