import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { store, ACTIONS } from "store";
import {
  DatePicker,
  Map,
  SideMenuCollapsible as Collapsible,
  TimePicker,
  Modal,
  Confetti,
  ToastBox,
} from "components";
import { Heading, Button, Input } from "shared";
import ENDPOINTS from "helpers/endpoints";
import Joi from "joi";
import { axiosApiInstance } from "helpers/authentication";
import forms from "data/static/form";
import {
  EventDetailContainer,
  Body,
  HeadingContainer,
  ButtonContainer,
  InputContainer,
  UserManagementContainer,
  AddUseerManagementCard,
  AddUseerManagementText,
  UserManagementCard,
  UserManagementTop,
  UserManagementTitleContainer,
  UserManagementTitleText,
  UserManagementName,
  UserManagementEmail,
  ModalButtonInviteUserContainer,
  DetailHeader,
  DetailHeaderDesc,
  DetailHeaderDescContainer,
  DetailHeaderDescTitle,
  DetailHeaderDescValue,
  Label,
  UploadInvitation,
  Icon,
  UploadIcon,
  UploadPreviewContainer,
  UploadPreviewImage,
  UploadPreviewVideo,
  UploadPreviewOverlay,
  UploadPreviewIconContainer,
  UploadContainer,
  UploadLabel,
  UploadInput,
  MapContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalButtonContainer,
  ModalImage,
  ModalBody,
  ModalBody2,
  ModalVideo,
  ModalClose,
  ButtonContainerUpload,
  UploadProgressBar,
  UploadProgressLoad,
  UploadProgressLabel,
  UploadProgressContainer,
  ToolTip,
  ToolTipText,
  UserPending,
} from "pages/event-details/style";
import UploadSVG from "assets/icons/upload.svg";
import DeleteUploadSVG from "assets/icons/delete-upload.svg";
import ZoomUploadSVG from "assets/icons/zoom-upload.svg";
import PlaySVG from "assets/icons/play.svg";
import InvitationPreviewSVG from "assets/icons/invitation-preview.svg";
import ExitSVG from "assets/icons/exit.svg";
import DeleteSVG from "assets/icons/delete.svg";
import InviteUserSVG from "assets/icons/invite-user.svg";
import InfoSVG from "assets/icons/info.svg";
import PlusCircledSVG from "assets/icons/plus-circled.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { getAutoComplete, geoCode } from "helpers/dataProvider";

const QrisModal = ({ isOpen, onRequestClose, image }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        width: "500px",
      },
    }}
  >
    <Scrollbars autoHide style={{ minHeight: "80vh" }}>
      <ModalHeader>
        <ModalClose src={ExitSVG} onClick={onRequestClose} />
        <Icon src={InvitationPreviewSVG} />
        <ModalHeaderTitle>Preview</ModalHeaderTitle>
      </ModalHeader>
      <ModalBody>
        <ModalImage src={image} width="100%" height="auto" />
        <ModalButtonContainer>
          <Button
            small
            kind="primary"
            type="button"
            text="Close"
            onClick={onRequestClose}
          />
        </ModalButtonContainer>
      </ModalBody>
    </Scrollbars>
  </Modal>
);

const VideoModal = ({ isOpen, onRequestClose, video }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        width: "500px",
      },
    }}
  >
    <ModalHeader>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      <Icon src={InvitationPreviewSVG} />
      <ModalHeaderTitle>Preview</ModalHeaderTitle>
    </ModalHeader>
    <ModalBody>
      <ModalVideo src={video} width="100%" height="auto" controls />
      <ModalButtonContainer>
        <Button
          small
          kind="primary"
          type="button"
          text="Close"
          onClick={onRequestClose}
        />
      </ModalButtonContainer>
    </ModalBody>
  </Modal>
);

const InviteUserModaal = ({
  isOpen = false,
  onRequestClose = () => {},
  fnUpdate = () => {},
  isLoading,
}) => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail("");
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "440px",
        },
      }}
    >
      <ModalHeader>
        <ModalClose src={ExitSVG} onClick={onRequestClose} />
        <Icon src={InviteUserSVG} />
        <ModalHeaderTitle>Invite User</ModalHeaderTitle>
      </ModalHeader>
      <ModalBody2>
        <Input
          type="text"
          label="User Email"
          name="userEmail"
          placeholder="Type user email here"
          value={email}
          onChange={(value) => setEmail(value)}
        />
        <ModalButtonInviteUserContainer>
          <Button
            small
            kind="primary"
            type="button"
            text="Invite"
            onClick={() => fnUpdate(email)}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </ModalButtonInviteUserContainer>
      </ModalBody2>
    </Modal>
  );
};

const EventDetails = ({ eventTokenCommited, setIsLoading }) => {
  const {
    state: { eventDetail, user },
    dispatch,
  } = useContext(store);

  const [openedCollapsibleId, setOpenedCollapsibleId] = useState(Infinity);
  const [showQrisModal, setShowQrisModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  // trigger proceed
  const [isEditBasicInfoProceed, setIsEditBasicInfoProceed] = useState(false);
  const [isEditLocationInfoProceed, setIsEditLocationInfoProceed] =
    useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isInvitationLoading, setIsInvitationLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  // data
  // const [eventDetailData, setEventDetailData] = useState({});
  const [eventEditBasicWedding, setEventEditBasicWedding] = useState(
    forms.event.EditBasicWedding
  );
  const [eventEditBasicGeneral, setEventEditBasicGeneral] = useState(
    forms.event.EditBasicGeneral
  );
  const [eventEditLocation, setEventEditLocation] = useState(
    forms.event.EditLocation
  );
  const [searchMap, setSearchMap] = useState("");

  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [timerOnchange, setTImerOnchange] = useState();
  const [lngLat, setLngLat] = useState([]);
  const [videoUpload, setVideoUpload] = useState({
    preview: "",
    value: "",
    file: "",
  });
  const [QRISUpload, setQRISUpload] = useState({
    preview: "",
    value: "",
    file: "",
  });
  const [uploadToastQRIS, setUploadToastQRIS] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [uploadToastVideo, setUploadToastVideo] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [basicToast, setBasicToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [locationToast, setLocationToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [uploadInterval, setUploadInterval] = useState();
  const { category, maximum_capacity, type } = eventDetail;

  const [userManagementList, setUserManagementList] = useState([]);

  const startUploading = () => {
    setUploadingVideo(true);
    let time = 0;
    var timer = setInterval(() => {
      time += 1;
      setUploadingPercent(time * 20);
      if (time == 4) {
        clearInterval(timer);
      }
    }, 1000);
    setUploadInterval(timer);
  };

  const uploadVideo = (value) => {
    if (value.size / 1024 / 1024 > 50) {
      setUploadToastVideo({
        status: true,
        title: "Upload Video Failed",
        text: "Video exceeds the maximum upload size allowed.",
        type: "warning",
      });
    } else {
      setVideoUpload({
        preview: URL.createObjectURL(value),
        value: value.name,
        file: value,
      });
    }
  };
  const uploadImage = (value) => {
    if (value.size / 1024 / 1024 > 3.5) {
      setUploadToastQRIS({
        status: true,
        title: "Upload Image Failed",
        text: "Image exceeds the maximum upload size allowed.",
        type: "warning",
      });
    } else {
      setQRISUpload({
        preview: URL.createObjectURL(value),
        value: value.name,
        file: value,
      });
    }
  };

  const deleteUploadVideo = () => {
    setVideoUpload({
      preview: "",
      value: "",
      file: "",
    });
  };
  const deleteUploadImage = () => {
    setQRISUpload({
      preview: "",
      value: "",
      file: "",
    });
  };

  const getEventDetail = async () => {
    axiosApiInstance
      .get(ENDPOINTS("customer.event.detail"))
      .then(
        ({
          data: {
            value: { event },
          },
        }) => {
          dispatch({ type: ACTIONS.getEventDetail, payload: event });
          setIsLoading(false);
          // setEventDetailData(event);
        }
      )
      .catch(() => {
        // error message
      });
  };

  const editBasicInfoWedding = async () => {
    const form = eventEditBasicWedding;
    form.event_date_string = moment
      .utc(form.event_date_string)
      .format("YYYY-MM-DD HH:mm");
    const schema = Joi.object({
      name: Joi.string().required(),
      groom_name: Joi.string().required(),
      bride_name: Joi.string().required(),
      event_date_string: Joi.string().required(),
      groom_father: Joi.string().required(),
      groom_mother: Joi.string().required(),
      bride_father: Joi.string().required(),
      bride_mother: Joi.string().required(),
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.event.editBasicInfo"), value)
      .then(() => {
        setBasicToast({
          status: true,
          type: "success",
          title: "Successfully updated the basic information of your event",
          text: "",
        });
        setIsEditBasicInfoProceed(true);
        setIsSubmiting(false);
      })
      .catch(() => {
        setBasicToast({
          status: true,
          type: "warning",
          title: "Update Failed",
          text: "Please try again",
        });
        setIsSubmiting(false);
        // error message
      });
  };

  const editBasicInfoGeneral = async () => {
    const form = eventEditBasicGeneral;
    const schema = Joi.object({
      name: Joi.string().required(),
      event_date_string: Joi.string().required(),
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.event.editBasicInfo"), value)
      .then(() => {
        setBasicToast({
          status: true,
          type: "success",
          title: "Successfully updated the basic information of your event",
          text: "",
        });
        setIsEditBasicInfoProceed(true);
        setIsSubmiting(false);
      })
      .catch(() => {
        setBasicToast({
          status: true,
          type: "warning",
          title: "Update Failed",
          text: "Please try again",
        });
        setIsSubmiting(false);
        // error message
      });
  };

  const editLocation = async () => {
    const form = {
      ...eventEditLocation,
      latitude: String(eventEditLocation.latitude),
      longitude: String(eventEditLocation.longitude),
    };
    const schema = Joi.object({
      event_venue: Joi.string().required(),
      event_city: Joi.string().required(),
      address: Joi.string().required(),
      latitude: Joi.string().required(),
      longitude: Joi.string().required(),
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.event.editLocation"), value)
      .then(() => {
        setIsEditLocationInfoProceed(true);
        setIsSubmiting(false);
        setBasicToast({
          status: true,
          type: "success",
          title: "Successfully updated the location of your event",
          text: "",
        });
      })
      .catch(() => {
        setIsSubmiting(false);
        setBasicToast({
          status: true,
          type: "warning",
          title: "Update Failed",
          text: "Please try again",
        });
        // error message
      });
  };

  const setValue = (value) => {
    setSearchMap(value);
    clearTimeout(timerOnchange);
    const timer = setTimeout(async () => {
      if (searchMap) {
        const list = await getAutoComplete(value);
        setAutoCompleteList(list);
      }
    }, 500);
    setTImerOnchange(timer);
  };

  const selectedList = async (item) => {
    const results = await geoCode(item.label);
    const location = results?.geometry?.location;
    setEventEditLocation({
      ...eventEditLocation,
      address: item.label,
      latitude: location?.lat,
      longitude: location?.lng,
      event_city:
        results?.address_components.find((item) =>
          item.types.includes("administrative_area_level_2")
        )?.long_name ||
        results?.address_components.find((item) =>
          item.types.includes("administrative_area_level_1")
        )?.long_name ||
        "",
    });
    setSearchMap(item.label);
    setLngLat([location?.lng, location?.lat]);
  };

  const doRemoveVideo = () => {
    axiosApiInstance
      .put(ENDPOINTS("customer.event.removeVideo"))
      .then(() => {
        deleteUploadVideo();
        getEventDetail();
        setUploadToastVideo({
          status: true,
          type: "success",
          title: "Video Deleted",
          text: "",
        });
      })
      .catch(() => {
        setUploadToastVideo({
          status: true,
          type: "warning",
          title: "Fail Delete Video",
          text: "Upload Video Fail",
        });
        // error message
      });
  };

  const doUploadVideo = () => {
    startUploading();
    const formData2 = new FormData();
    if (videoUpload.file !== "") {
      formData2.append("video", videoUpload.file);
    }
    axiosApiInstance
      .put(ENDPOINTS("customer.event.video"), formData2)
      .then(() => {
        setUploadingPercent(100);
        setTimeout(() => {
          setUploadingPercent(0);
          setUploadingVideo(false);
        }, 500);
        clearInterval(uploadInterval);
        setVideoUpload({
          preview: "",
          value: "",
          file: "",
        });
        getEventDetail();
        setIsSubmiting(false);

        setUploadToastVideo({
          status: true,
          type: "success",
          title: "Successfully updated check-in page materials",
          text: "",
        });
      })
      .catch(() => {
        clearInterval(uploadInterval);
        setUploadingPercent(0);
        setUploadingVideo(false);
        setIsSubmiting(false);
        setUploadToastVideo({
          status: true,
          type: "warning",
          title: "Upload Video",
          text: "Upload Video Fail",
        });
        // error message
      });
  };

  const doRemoveQRIS = () => {
    axiosApiInstance
      .put(ENDPOINTS("customer.event.removeQris"))
      .then(() => {
        deleteUploadImage();
        getEventDetail();
        setUploadToastQRIS({
          status: true,
          type: "success",
          title: "Qris Deleted",
          text: "",
        });
      })
      .catch(() => {
        setUploadToastQRIS({
          status: true,
          type: "warning",
          title: "Qris Delete Fail",
          text: "",
        });
        // error message
      });
  };

  const doUploadQRIS = () => {
    const formData = new FormData();
    setIsSubmiting(true);
    if (QRISUpload.file !== "") {
      formData.append("qris", QRISUpload.file);
    }

    axiosApiInstance
      .put(ENDPOINTS("customer.event.qris"), formData)
      .then(() => {
        setQRISUpload({
          preview: "",
          value: "",
          file: "",
        });
        getEventDetail();
        setIsSubmiting(false);
        setUploadToastQRIS({
          status: true,
          type: "success",
          title: "Successfully updated check-in page materials",
          text: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsSubmiting(false);
        setUploadToastQRIS({
          status: true,
          type: "warning",
          title: "Upload QRIS",
          text: "Upload QRIS Fail",
        });
        // error message
      });
  };

  useEffect(() => {
    if (isEditBasicInfoProceed === true) {
      getEventDetail();
      setIsEditBasicInfoProceed(false);
    }

    if (isEditLocationInfoProceed === true) {
      getEventDetail();
      setIsEditLocationInfoProceed(false);
    }
    // eslint-disable-next-line
  }, [isEditBasicInfoProceed, isEditLocationInfoProceed]);

  useEffect(() => {
    if (eventTokenCommited) {
      setIsLoading(false);
      getInvitationList();
      setEventEditLocation({
        event_venue: eventDetail.event_venue,
        event_city: eventDetail.event_city,
        address: eventDetail.address,
        latitude: eventDetail.latitude,
        longitude: eventDetail.longitude,
      });
      setLngLat([eventDetail.longitude, eventDetail.latitude]);
      setSearchMap(eventDetail.address);
      if (
        eventDetail.category === "Wedding" ||
        eventDetail.category === "Holy Matrimony"
      ) {
        setEventEditBasicWedding({
          name: eventDetail.name,
          groom_name: eventDetail.groom_name,
          groom_father: eventDetail.groom_father,
          groom_mother: eventDetail.groom_mother,
          bride_name: eventDetail.bride_name,
          bride_father: eventDetail.bride_father,
          bride_mother: eventDetail.bride_mother,
          event_date_string: eventDetail.event_date_string,
        });
      } else {
        setEventEditBasicGeneral({
          name: eventDetail.name,
          event_date_string: eventDetail.event_date_string,
        });
      }

      setVideoUpload({
        preview: eventDetail.video_dday,
        value: "",
        file: "",
      });
      setQRISUpload({
        preview: eventDetail.qris_bca,
        value: "",
        file: "",
      });
    }
    // eslint-disable-next-line
  }, [eventTokenCommited, eventDetail]);

  const getInvitationList = async () => {
    setIsLoading(true);
    axiosApiInstance
      .get(`${ENDPOINTS("customer.userManagement.userList")}`)
      .then(
        ({
          data: {
            value: { users },
          },
        }) => {
          setUserManagementList(JSON.parse(JSON.stringify(users)));
          setIsLoading(false);
        }
      )
      .catch(() => {
        setIsLoading(false);
        setUserManagementList([]);
        // error message
      });
  };

  const sendInvitation = async (email) => {
    setIsInvitationLoading(true);
    axiosApiInstance
      .post(`${ENDPOINTS("customer.userManagement.sendInvitation")}`, { email })
      .then((_) => {
        // setData(JSON.parse(JSON.stringify(invitations)));
        // setTempData(JSON.parse(JSON.stringify(invitations)));
        setShowInviteUserModal(false);
        setIsInvitationLoading(false);
        getInvitationList();
      })
      .catch((err) => {
        setShowInviteUserModal(false);
        setIsInvitationLoading(false);
        // error message
      });
  };

  const deleteInvitation = async (id) => {
    setIsDeleteLoading(true);
    axiosApiInstance
      .delete(`${ENDPOINTS("customer.userManagement.deleteInvitation")}${id}`)
      .then((_) => {
        setIsDeleteLoading(false);
        getInvitationList();
      })
      .catch(() => {
        setIsDeleteLoading(false);
      });
  };

  // console.log("eventEditBasicWedding", eventEditBasicWedding);

  return (
    <>
      {uploadToastQRIS.status && (
        <ToastBox
          isOpen={uploadToastQRIS}
          type={uploadToastQRIS.type}
          title={uploadToastQRIS.title}
          text={uploadToastQRIS.text}
          onRequestClose={() => {
            setUploadToastQRIS({ ...uploadToastQRIS, status: false });
          }}
        />
      )}
      {uploadToastVideo.status && (
        <ToastBox
          isOpen={uploadToastVideo}
          type={uploadToastVideo.type}
          title={uploadToastVideo.title}
          text={uploadToastVideo.text}
          onRequestClose={() => {
            setUploadToastVideo({ ...uploadToastVideo, status: false });
          }}
        />
      )}
      {basicToast.status && (
        <ToastBox
          isOpen={basicToast}
          type={basicToast.type}
          title={basicToast.title}
          text={basicToast.text}
          onRequestClose={() => {
            setBasicToast({ ...basicToast, status: false });
          }}
        />
      )}
      <EventDetailContainer>
        <QrisModal
          isOpen={showQrisModal}
          onRequestClose={() => {
            setShowQrisModal(false);
          }}
          image={QRISUpload.preview}
        />
        <VideoModal
          isOpen={showVideoModal}
          onRequestClose={() => {
            setShowVideoModal(false);
          }}
          video={videoUpload.preview}
        />
        <InviteUserModaal
          isOpen={showInviteUserModal}
          onRequestClose={() => {
            setShowInviteUserModal(false);
          }}
          fnUpdate={sendInvitation}
          isLoading={isInvitationLoading}
        />
        <Body>
          <HeadingContainer>
            <Heading kind="title" text="Event Details" />
          </HeadingContainer>
          <DetailHeader>
            <DetailHeaderDescContainer>
              <DetailHeaderDesc>
                <DetailHeaderDescTitle>Category</DetailHeaderDescTitle>
                <DetailHeaderDescValue>{category}</DetailHeaderDescValue>
              </DetailHeaderDesc>
              <DetailHeaderDesc>
                <DetailHeaderDescTitle>Event Type</DetailHeaderDescTitle>
                <DetailHeaderDescValue>{type}</DetailHeaderDescValue>
              </DetailHeaderDesc>
              <DetailHeaderDesc>
                <DetailHeaderDescTitle>Total Guest</DetailHeaderDescTitle>
                <DetailHeaderDescValue>
                  {maximum_capacity} pax
                </DetailHeaderDescValue>
              </DetailHeaderDesc>
            </DetailHeaderDescContainer>
            <Confetti />
          </DetailHeader>
          <Collapsible
            isNotSideMenu
            id={0}
            openedId={openedCollapsibleId}
            openId={setOpenedCollapsibleId}
            title="Basic Info"
          >
            {eventDetail.category &&
            (eventDetail.category === "Wedding" ||
              eventDetail.category === "Holy Matrimony") ? (
              <>
                <Input
                  type="text"
                  label="Event's Name"
                  name="eventName"
                  placeholder="Event's Name"
                  value={eventEditBasicWedding.name}
                  onChange={(value) =>
                    setEventEditBasicWedding({
                      ...eventEditBasicWedding,
                      name: value,
                    })
                  }
                />
                <InputContainer>
                  <Input
                    type="text"
                    label="Groom's Full Name"
                    name="groomsFullName"
                    placeholder="Groom's Full Name"
                    value={eventEditBasicWedding.groom_name}
                    onChange={(value) =>
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        groom_name: value,
                      })
                    }
                  />
                  <Input
                    type="text"
                    label="Bride's Full Name"
                    name="bridesFullName"
                    placeholder="Bride's Full Name"
                    value={eventEditBasicWedding.bride_name}
                    onChange={(value) =>
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        bride_name: value,
                      })
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <DatePicker
                    label="Event Date"
                    value={eventEditBasicWedding.event_date_string}
                    onChange={(newDate) => {
                      const dateTemp =
                        eventEditBasicWedding.event_date_string.split(" ");
                      const value = `${newDate} ${dateTemp[1]}`;
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        event_date_string: value,
                      });
                    }}
                  />
                  <TimePicker
                    isEmpty
                    value={moment
                      .utc(eventEditBasicWedding.event_date_string)
                      .format("HH:mm")}
                    label="Event Time"
                    onChange={(newTime) => {
                      const dateTemp =
                        eventEditBasicWedding.event_date_string.split(" ");
                      const value = `${dateTemp[0]} ${newTime}`;

                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        event_date_string: value,
                      });
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Groom Father’s Name"
                    name="groomFathersName"
                    placeholder="Groom Father's Name"
                    value={eventEditBasicWedding.groom_father}
                    onChange={(value) =>
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        groom_father: value,
                      })
                    }
                  />
                  <Input
                    type="text"
                    label="Groom Mother’s Name"
                    name="groomMothersFullName"
                    placeholder="Groom Mother's Name"
                    value={eventEditBasicWedding.groom_mother}
                    onChange={(value) =>
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        groom_mother: value,
                      })
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Bride Father’s Name"
                    name="brideFathersName"
                    placeholder="Groom Father's Name"
                    value={eventEditBasicWedding.bride_father}
                    onChange={(value) =>
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        bride_father: value,
                      })
                    }
                  />
                  <Input
                    type="text"
                    label="Bride Mother’s Name"
                    name="brideMothersName"
                    placeholder="Bride Mother's Name"
                    value={eventEditBasicWedding.bride_mother}
                    onChange={(value) =>
                      setEventEditBasicWedding({
                        ...eventEditBasicWedding,
                        bride_mother: value,
                      })
                    }
                  />
                </InputContainer>
              </>
            ) : (
              <>
                <Input
                  type="text"
                  label="Event's Name"
                  name="eventName"
                  placeholder="Event's Name"
                  value={eventEditBasicGeneral.name}
                  onChange={(value) =>
                    setEventEditBasicGeneral({
                      ...eventEditBasicGeneral,
                      name: value,
                    })
                  }
                />
                <InputContainer>
                  <DatePicker
                    label="Event Date"
                    value={eventEditBasicGeneral.event_date_string}
                    onChange={(value) => {
                      setEventEditBasicGeneral({
                        ...eventEditBasicGeneral,
                        event_date_string: value,
                      });
                    }}
                  />
                  <TimePicker
                    isEmpty
                    value={moment(
                      eventEditBasicGeneral.event_date_string
                    ).format("hh:mm")}
                    label="Event Time"
                    onChange={(value) => {
                      setEventEditBasicGeneral({
                        ...eventEditBasicGeneral,
                        event_date_string: new Date(
                          new Date(
                            eventEditBasicGeneral.event_date_string
                          ).setHours(value.split(":")[0])
                        ).setMinutes(value.split(":")[1]),
                      });
                    }}
                  />
                </InputContainer>
              </>
            )}
            <ButtonContainer>
              <Button
                small
                kind="primary"
                type="button"
                text="Update"
                onClick={
                  eventDetail.category &&
                  (eventDetail.category === "Wedding" ||
                    eventDetail.category === "Holy Matrimony")
                    ? editBasicInfoWedding
                    : editBasicInfoGeneral
                }
                disabled={isSubmiting}
                isLoading={isSubmiting}
              />
            </ButtonContainer>
          </Collapsible>
          <Collapsible
            isNotSideMenu
            id={1}
            openedId={openedCollapsibleId}
            openId={setOpenedCollapsibleId}
            title="Location"
          >
            <Input
              type="text"
              label={
                <>
                  Venue Detail
                  <ToolTip>
                    <Icon src={InfoSVG} className="injectable" />
                    <ToolTipText>
                      The exact function room detail where the event held
                      <br />
                      e.g. Main Ballroom, Grand Ballroom
                    </ToolTipText>
                  </ToolTip>
                </>
              }
              name="venueDetail"
              placeholder="Grand Ballroom Hotel Mulia"
              value={eventEditLocation.event_venue}
              onChange={(value) =>
                setEventEditLocation({
                  ...eventEditLocation,
                  event_venue: value,
                })
              }
            />
            <Input
              type="text"
              label="Address"
              name="address"
              value={searchMap}
              onChange={setValue}
              placeholder="Search venue address"
              isAutoComplete
              autoCompleteList={autoCompleteList}
              selectedList={selectedList}
            />
            <MapContainer>
              <Map
                longLat={lngLat}
                setAll={(value) => {
                  setEventEditLocation((prevState) => {
                    return {
                      ...prevState,
                      address: value?.address,
                      latitude: value?.lat,
                      longitude: value?.lng,
                      event_city: value?.region,
                    };
                  });
                }}
                setSearchMap={setSearchMap}
                setLngLat={setLngLat}
              />
            </MapContainer>
            <ButtonContainer>
              <Button
                small
                kind="primary"
                type="button"
                text="Update"
                onClick={editLocation}
                disabled={isSubmiting}
                isLoading={isSubmiting}
              />
            </ButtonContainer>
          </Collapsible>
          <Collapsible
            isNotSideMenu
            id={3}
            openedId={openedCollapsibleId}
            openId={setOpenedCollapsibleId}
            title="USER MANAGEMENT"
          >
            <UserManagementContainer>
              {user.type == "admin" ||
              (user.type == "user" &&
                user.fullname == eventDetail?.owner?.fullname) ? (
                <AddUseerManagementCard
                  onClick={() => {
                    setShowInviteUserModal(true);
                  }}
                >
                  <Icon src={PlusCircledSVG} />
                  <AddUseerManagementText>INVITE USER</AddUseerManagementText>
                </AddUseerManagementCard>
              ) : null}

              <UserManagementCard>
                <UserManagementTop>
                  <UserManagementTitleContainer>
                    <UserManagementTitleText isOwner>
                      OWNER
                    </UserManagementTitleText>
                    {/* <Icon src={DeleteSVG} /> */}
                  </UserManagementTitleContainer>
                  <UserManagementName>
                    {eventDetail?.owner?.fullname || ""}
                  </UserManagementName>
                </UserManagementTop>
                <UserManagementEmail>
                  {eventDetail?.owner?.email || ""}
                </UserManagementEmail>
              </UserManagementCard>

              {userManagementList.map((item, index) => {
                return (
                  <UserManagementCard key={index}>
                    <UserManagementTop>
                      <UserManagementTitleContainer>
                        <UserManagementTitleText>USER</UserManagementTitleText>
                        {user.type == "admin" ||
                        (user.type == "user" &&
                          user.fullname == eventDetail?.owner?.fullname) ? (
                          <Icon
                            src={DeleteSVG}
                            onClick={
                              isDeleteLoading
                                ? () => {}
                                : () => deleteInvitation(item?._id)
                            }
                          />
                        ) : null}
                      </UserManagementTitleContainer>
                      {item?.isPending ? (
                        <UserPending>Pending</UserPending>
                      ) : (
                        <UserManagementName>
                          {item?.user?.fullname || ""}
                        </UserManagementName>
                      )}
                    </UserManagementTop>
                    <UserManagementEmail>
                      {item?.user?.email || ""}
                    </UserManagementEmail>
                  </UserManagementCard>
                );
              })}
            </UserManagementContainer>
          </Collapsible>

          <Collapsible
            isNotSideMenu
            id={4}
            openedId={openedCollapsibleId}
            openId={setOpenedCollapsibleId}
            title="D-DAY CHECK-IN PAGE"
          >
            <InputContainer>
              <div>
                <Label>Video</Label>
                {videoUpload.preview ? (
                  <UploadPreviewContainer>
                    <UploadPreviewVideo
                      src={videoUpload.preview}
                      width="100%"
                      height="100%"
                    />
                    <UploadPreviewOverlay>
                      <UploadPreviewIconContainer>
                        {uploadingVideo ? (
                          <UploadProgressContainer>
                            <UploadProgressLabel>
                              Uploading Video
                            </UploadProgressLabel>
                            <UploadProgressBar>
                              <UploadProgressLoad progress={uploadingPercent} />
                            </UploadProgressBar>
                          </UploadProgressContainer>
                        ) : (
                          <>
                            <UploadIcon
                              src={PlaySVG}
                              onClick={() => {
                                setShowVideoModal(true);
                              }}
                            />
                            <UploadIcon
                              src={DeleteUploadSVG}
                              onClick={doRemoveVideo}
                            />
                          </>
                        )}
                      </UploadPreviewIconContainer>
                    </UploadPreviewOverlay>
                  </UploadPreviewContainer>
                ) : (
                  <UploadContainer>
                    <UploadLabel htmlFor="video-upload">
                      <UploadInvitation>
                        <Icon src={UploadSVG} />
                        <b>Cick to add video</b>
                        Format should be MP4 no larger than 50MB
                      </UploadInvitation>
                    </UploadLabel>
                    <UploadInput
                      id="video-upload"
                      value={videoUpload.value}
                      type="file"
                      accept="video/mp4"
                      onChange={(event) => {
                        uploadVideo(event.target.files[0]);
                      }}
                    />
                  </UploadContainer>
                )}
                {videoUpload &&
                videoUpload.preview &&
                videoUpload.preview.includes("blob:") &&
                !uploadingVideo ? (
                  <ButtonContainerUpload>
                    <Button
                      small
                      kind="primary"
                      type="button"
                      text="Upload Video"
                      onClick={() => {
                        startUploading();
                      }}
                      // onClick={doUploadVideo}
                      disabled={isSubmiting}
                      isLoading={isSubmiting}
                    />
                  </ButtonContainerUpload>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <Label>QRIS BCA</Label>
                {QRISUpload.preview ? (
                  <UploadPreviewContainer>
                    <UploadPreviewImage
                      src={QRISUpload.preview}
                      width="100%"
                      height="100%"
                    />
                    <UploadPreviewOverlay>
                      <UploadPreviewIconContainer>
                        <UploadIcon
                          src={ZoomUploadSVG}
                          onClick={() => {
                            setShowQrisModal(true);
                          }}
                        />
                        <UploadIcon
                          src={DeleteUploadSVG}
                          onClick={doRemoveQRIS}
                        />
                      </UploadPreviewIconContainer>
                    </UploadPreviewOverlay>
                  </UploadPreviewContainer>
                ) : (
                  <UploadContainer>
                    <UploadLabel htmlFor="qris-upload">
                      <UploadInvitation>
                        <Icon src={UploadSVG} />
                        <b>Cick to add QRIS BCA</b>
                        JPEG or PNG, no larger than 3.5MB
                      </UploadInvitation>
                    </UploadLabel>
                    <UploadInput
                      id="qris-upload"
                      value={QRISUpload.value}
                      type="file"
                      accept="image/x-png,image/jpeg"
                      onChange={(event) => {
                        uploadImage(event.target.files[0]);
                      }}
                    />
                  </UploadContainer>
                )}
                {QRISUpload &&
                QRISUpload.preview &&
                QRISUpload.preview.includes("blob:") ? (
                  <ButtonContainerUpload>
                    <Button
                      small
                      kind="primary"
                      type="button"
                      text="Upload QRIS"
                      onClick={doUploadQRIS}
                      disabled={isSubmiting}
                      isLoading={isSubmiting}
                    />
                  </ButtonContainerUpload>
                ) : (
                  <></>
                )}
              </div>
            </InputContainer>
          </Collapsible>
        </Body>
      </EventDetailContainer>
    </>
  );
};

const { bool, func, string } = PropTypes;

EventDetails.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

QrisModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  image: string,
};

QrisModal.defaultProps = {
  image: "",
};

export default EventDetails;
