import styled, { css } from "styled-components";

export const TableScroll = styled.div`
  margin-top: 1.9rem;
  ${(props) =>
    props.horizontalScroll &&
    css`
      white-space: nowrap;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #dddddd;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #bdbdbd;
      }
    `}
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0rem 0.5rem;
  width: 100%;
  ${(props) =>
    props.horizontalScroll &&
    css`
      width: 500px;
      min-width: 100%;
      table-layout: fixed;
    `}
  box-sizing: content-box;
  * {
    z-index: 0;
  }
`;

export const TD = styled.td`
  text-align: ${(props) => props.position || "left"};
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`;

export const TR = styled.tr`
  background-color: ${(props) => props.theme.table.background};
  cursor: ${(props) => (props.rowClickable && "pointer") || "auto"};

  ${(props) =>
    props.checked &&
    css`
      box-shadow: 0.125rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
      ${TD} {
        &:first-child {
          box-shadow: inset 6.25rem 0 0 -5.875rem ${props.theme.table.selected};
        }
      }
    `}
`;

export const THead = styled.thead`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 122.4%;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.table.text.header};

  ${TR} {
    background-color: transparent;
  }

  ${TD} {
    padding: 0 0.625rem 0 1.9375rem;
    padding-bottom: 0.6875rem;
  }
`;

export const TBody = styled.tbody`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 122.4%;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.table.text.body};

  ${TR} {
    height: 3.5rem;
  }

  ${TD} {
    vertical-align: middle;
    padding: 0 0.625rem 0 1.9375rem;

    &:first-child {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }

    &:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
  }
`;
