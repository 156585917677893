import styled from 'styled-components';
import { Heading, Button, Input } from 'shared/styles';
import { ButtonIcon } from 'components/button-icon/style';

export const HeadingContainer = styled.div`
  width: 50%;

  ${Heading.Heading} {
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
  
    &[kind="title"] {
      margin-top: 6.25rem;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      margin-bottom: 2.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
    }
  }
`;

export const HeadingContainer2 = styled.div`
  width: 50%;

  ${Heading.Heading} {
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
  
    &[kind="title"] {
      margin-top: 44px;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      margin-bottom: 2.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
    }
  }
`;
export const Section = styled.div`
  width: 50%;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  ${ButtonIcon} {
    font-size: 1.5rem;
    line-height: 2rem;
    align-items: stretch;
  }
`;

export const Section2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const InfoContainer = styled.div`
  width: 50%;
`;

export const Info = styled.div`
  border: 0.09375rem solid ${(props) => props.theme.border.primary};
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  display:flex;
  align-items: center;
  height: 4rem;
`;

export const Icon = styled.img`
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.625rem;
  position: relative;
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
  ${HeadingContainer} {
    &:nth-child(3) {
      ${Heading.Heading} {
        margin-top: 0;
      }
    }
  }

  ${Section} {
    &:nth-child(2) {
      margin-bottom: 3rem;
    }
  }
`;

export const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-top: 0.1875rem solid ${(props) => props.theme.loading.empty};
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 72px;
 
  }
`;

export const FooterContent = styled.div`
  flex:1;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  ${Button.Button} {
    position: absolute;
    top: 16px;
    &:first-child{
      left: 36px;
    }
    &:last-child{
      right: 36px;
    }
`;

export const StepIcon = styled.img``;

export const ListContainer = styled.div`
  width: 50%;
  margin-top:16px;
`;


export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1.75rem 2.5rem;
  padding-bottom: 0;
`;

export const SummaryContainer = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  border-radius: 0.625rem;
  padding: 2.25rem 1.5rem;
`;

export const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.modal.header.background};
  color: ${(props) => props.theme.modal.header.text};
  padding: 1.6875rem 2rem;
`;

export const ModalHeaderTitle = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.75rem;
  letter-spacing: 0.03125rem;
  margin: 0 0 2.9375rem 0;
`;

export const ModalHeaderDesc = styled.div`
  margin-bottom: 0rem;
`;

export const ModalHeaderDescContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${ModalHeaderDesc} {
    margin-right: 2rem;
    border-right: 0.0625rem solid ${(props) => props.theme.modal.header.text};

    &:last-child {
      border: 0;
    }
  }

`;

export const ModalHeaderDescTitle = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

export const ModalHeaderDescValue = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 0.5rem;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;