import React from 'react';
import { useHistory } from 'react-router-dom';
import {
} from 'components';
import {
  TopImage,
  Navbar,
  Brand,
  Container,
  Login,
  TopImageContent,
  TopImageTitle,
  TopImageText,
  Footer,
  FooterRight,
  FooterAddress,
  FooterCopyright,
  FooterLeft,
  FooterLeftTitle,
  FooterLeftList,
  TextIcon,
  FooterPrivacyPolicy,
} from 'pages/privacy-policy/style';
import LogoWhiteSVG from 'assets/images/logo-white.svg';

const PrivacyPolicy = () => {
  const history = useHistory();
  const {
    REACT_APP_WA_NUMBER,
    REACT_APP_WA_NUMBER_PREVIEW,
    REACT_APP_EMAIL,
  } = process.env;
  return (
    <>
      <TopImage>
        <Container>
          <Navbar>
            <Brand
              src={LogoWhiteSVG}
              onClick={() => history.push('/')}
            />
            <Login to="/sign-in">
              Login
            </Login>
          </Navbar>
          <TopImageContent>
            <TopImageTitle>
              Privacy Policy
            </TopImageTitle>
            <TopImageText>
              This privacy policy applies to the use of the Site and the Service,
              {' '}
              and its terms are a part of this Agreement. Additionally, by using the Site and
              {' '}
              the Service, you acknowledge and agree that internet transmissions are
              {' '}
              never completely private or secure. You understand that any message or
              {' '}
              information you send to the Site may be read or intercepted by others,
              {' '}
              even if there is a special notice that a particular transmission
              {' '}
              (for example, credit card information) is encrypted.
              <br />
              <br />
              The following statement explains our policy regarding
              {' '}
              the personal information we collect about you.
              {' '}
              Eventco takes your privacy very seriously.
              {' '}
              Eventco does not sell or rent your contact information to other people.
              {' '}
              At times we may be required by law or
              {' '}
              litigation to disclose your personal information.
              {' '}
              We may also disclose information about you if
              {' '}
              we determine that for law enforcement, or other issues of public importance,
              {' '}
              disclosure is necessary. You can help us by also taking precautions to
              {' '}
              protect your personal data when you are on the internet.
              {' '}
              Change your passwords often using a combination of letters and numbers,
              {' '}
              and make sure you use a secure web browser.
              <br />
              <br />
              We may retain your Personal Data on the type of data and
              {' '}
              the purpose for which we process the data.
              {' '}
              We will retain your Personal Information for the period necessary to fulfill
              {' '}
              the purposes outlined in this Privacy Notice unless
              {' '}
              a longer retention period is required or permitted by law.
              <br />
              <br />
              If at any time you feel Eventco acts in violation of this privacy policy,
              {' '}
              please contact us immediately.
              <br />
              <br />
              Eventco explicitly agrees not to share any of
              {' '}
              your uploaded data, names, email addresses and telephone numbers with any third-party!
              <br />
              <br />
              If at any time you feel Eventco acts in violation of this privacy policy,
              {' '}
              please contact us.
            </TopImageText>
          </TopImageContent>
        </Container>
      </TopImage>
      <Footer>
        <Container>
          <FooterLeft>
            <FooterLeftTitle>
              Contact Us
            </FooterLeftTitle>
            <FooterLeftList onClick={() => { window.open(`mailto:${REACT_APP_EMAIL}`); }}>
              <TextIcon>e</TextIcon>
              {' '}
              {REACT_APP_EMAIL}
            </FooterLeftList>
            <FooterLeftList onClick={() => { window.open(`https://wa.me/${REACT_APP_WA_NUMBER}?text=`, '_blank'); }}>
              <TextIcon>p</TextIcon>
              {' '}
              {REACT_APP_WA_NUMBER_PREVIEW}
            </FooterLeftList>
            <FooterAddress>
              Spaces - WTC 3
              <p>
                Jl. Jend. Sudirman Kav. 29-31
                Kuningan, Jakarta, 12920
              </p>
            </FooterAddress>
            {/* <Icon src={WAHomeSVG}/>
            <Icon src={EmailHomeSVG}/> */}
          </FooterLeft>

          <FooterRight>
            <FooterPrivacyPolicy onClick={() => history.push('/privacy-policy')}>
              Privacy Policy
            </FooterPrivacyPolicy>
            <FooterCopyright>
              © 2020 PT. Integrasi Inti Sinergi
            </FooterCopyright>
          </FooterRight>

        </Container>
      </Footer>

    </>
  );
};

export default PrivacyPolicy;
