import styled from 'styled-components';



export const ListItem = styled.div`
  padding:1rem 0;
  display:flex;
  flex-direction:row;
  gap: 10px;
  align-items: center;
  border-bottom: 0.0435873125rem solid ${(props) => props.theme.checkin.color.gray5};
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.checkin.color.black};
  
  &:hover {
    cursor: pointer;
  }
`;

export const ListContainer = styled.div`
  background-color:white;
  padding: 0.25rem 1rem;
  border-radius:0.625rem;
  margin-bottom:1.25rem;
  ${ListItem}{
    &:nth-last-child(1){
      border-bottom: 0.0435873125rem solid transparent;
    }
  }
`;