import styled, { css } from 'styled-components';

export default {
  Heading: styled.h1`
    ${(props) => props.kind === 'brand' && css`
      position: absolute;
      font-family: 'GT Walsheim Pro', sans-serif;
      font-size: 1.7rem;
      font-weight: bold;
      color: ${props.theme.brand.primary};
      margin: 1.4375rem 0 0 2.1325rem;
      margin-bottom: ${props.marginBottom || '1.4375rem'};
      text-align: left;
    `};

    ${(props) => props.kind === 'title' && css`
      font-family: 'GT Walsheim Pro', sans-serif;
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 3.25rem;
      letter-spacing: 0.03125rem;
      color: ${props.theme.heading.title};
      margin: 1rem 0;
      margin-bottom: ${props.marginBottom || '1rem'};
      text-align: center;
    `};

    ${(props) => props.kind === 'subtitle' && css`
      font-family: 'GT Walsheim Pro', sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
      color: ${props.theme.heading.subtitle};
      margin-bottom: ${props.marginBottom || '2rem'};
      text-align: center;
    `};
  `,
};
