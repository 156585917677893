import styled, { css } from 'styled-components';
import { Heading } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.isModalOpen && css`
    height: 100vh;
    overflow-y: hidden;
  `}
`;

export const HeadingContainer = styled.div`
  width: 80%;

  ${Heading.Heading} {
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
  }
`;

export const Section = styled.div`
  width: 80%;
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
`;

export const Icon = styled.img``;

export const AddNewEventButton = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.heading.subtitle};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.09375rem;
  border: 0.09375rem dashed ${(props) => props.theme.border.light};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  min-height: 16.875rem;

  ${Icon} {
    margin-bottom: 1rem;
  }

  &:hover {
    border: 0.09375rem dashed ${(props) => props.theme.border.primary};
  }
`;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1rem 2.5rem;
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.0.125rem;
    }
  }
`;

export const ModalContact = styled.div`
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.card.background};
  padding: 0.875rem 1.5rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;

  div {
    cursor: auto;
  }
`;

export const ModalDescContainer = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  &:hover{
    cursor: pointer;
  }
`;

export const ModalContactTitle = styled.p`
  text-transform: uppercase;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  color: ${(props) => props.theme.section.detail.fieldName};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.09375rem;
  margin-bottom: 0.25rem;
`;

export const ModalContactDesc = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.section.detail.value};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  margin-bottom: 0.25rem;
`;
