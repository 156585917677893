import styled, { css } from 'styled-components';

export const Icon = styled.img``;

export const AvailabilityContainer = styled.div`
  font-family: 'GT Walsheim Pro';
  font-size: 0.75rem:
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  color: ${(props) => (props.isFull && props.theme.table.label.error.text) || props.theme.heading.title};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.460625rem;

  svg {
    position: relative;
    top: -0.08rem;
    path {
      ${(props) => props.isFull && css`
        fill: ${props.theme.table.label.error.text};
      `}
    }
  }
`;
