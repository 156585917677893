import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import SVGInject from "@iconfu/svg-inject";
import { store } from "store";
import { Heading } from "shared";
import { Brand } from "components";
import {
  NavbarRelative,
  Navbar,
  NavbarContainer,
  ToggleContainer,
  ToggleButton,
  NavbarEventTime,
  NavbarMenu,
  NavbarMenuBody,
  NavbarMenuRow,
  NavbarMenuIcon,
  Icon,
  NavbarMenuOption,
  BrandContainer,
} from "components/navbar/style";
import LeftArrowSVG from "assets/icons/left-arrow.svg";
import DashboardSVG from "assets/icons/dashboard.svg";
import GuestListSVG from "assets/icons/guest-list.svg";
import TableManagementSVG from "assets/icons/table-management.svg";
import InvitationSVG from "assets/icons/invitation.svg";
import EventDetailsSVG from "assets/icons/event-details.svg";

const NavbarComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { state } = useContext(store);
  const { eventDetail } = state;
  const [menuOptions, setMenuOptions] = useState([]);
  useEffect(() => {
    SVGInject(document.querySelectorAll("img.injectable"));
  }, []);

  useEffect(() => {
    const today = new Date();
    const dday = new Date(eventDetail.event_date_string).setHours(0, 0, 0, 0);
    const post = new Date(eventDetail.event_date_string).setHours(
      23,
      59,
      59,
      0
    );

    let eventTime = "";
    if (today < dday) {
      eventTime = "before";
    } else if (today > dday && today < post) {
      eventTime = "dday";
    } else if (today > dday && today > post) {
      eventTime = "post";
    }
    if (eventTime == "post") {
      setMenuOptions([
        {
          id: "dashboard",
          option: "Dashboard",
          icon: DashboardSVG,
        },
      ]);
    } else {
      if (eventDetail.type === "Standing" || eventDetail.type === "standing") {
        setMenuOptions([
          {
            id: "dashboard",
            option: "Dashboard",
            icon: DashboardSVG,
          },
          {
            id: "guest-list",
            option: "Guest List",
            icon: GuestListSVG,
          },
          {
            id: "invitation",
            option: "Invitation",
            icon: InvitationSVG,
          },
          {
            id: "event-details",
            option: "Event Details",
            icon: EventDetailsSVG,
          },
        ]);
      } else {
        setMenuOptions([
          {
            id: "dashboard",
            option: "Dashboard",
            icon: DashboardSVG,
          },
          {
            id: "guest-list",
            option: "Guest List",
            icon: GuestListSVG,
          },
          {
            id: "table-management",
            option: "Table Management",
            icon: TableManagementSVG,
          },
          {
            id: "invitation",
            option: "Invitation",
            icon: InvitationSVG,
          },
          {
            id: "event-details",
            option: "Event Details",
            icon: EventDetailsSVG,
          },
        ]);
      }
    }
  }, [eventDetail]);

  const limitTextEventName =
    eventDetail && eventDetail.name.length > 54
      ? `${eventDetail.name.slice(0, 54)}...`
      : eventDetail.name;

  return (
    <NavbarRelative>
      <Navbar>
        <NavbarContainer>
          <ToggleContainer onClick={() => history.push("/events")}>
            <ToggleButton className="injectable" src={LeftArrowSVG} />
          </ToggleContainer>
          <Heading kind="title" text={limitTextEventName} />
          <NavbarEventTime>
            {moment
              .utc(eventDetail.event_date_string)
              .format("MMM D, YYYY, hh.mm")}
          </NavbarEventTime>
          <NavbarMenu>
            <NavbarMenuBody>
              {menuOptions.map((option) => (
                <NavbarMenuRow
                  key={option.id}
                  active={
                    `/event/${option.id}` === location.pathname ? "active" : ""
                  }
                  // eslint-disable-next-line no-return-assign
                  to={`/event/${option.id}`}
                >
                  <NavbarMenuIcon>
                    <Icon className="injectable" src={option.icon} />
                  </NavbarMenuIcon>
                  <NavbarMenuOption>{option.option}</NavbarMenuOption>
                </NavbarMenuRow>
              ))}
            </NavbarMenuBody>
          </NavbarMenu>
        </NavbarContainer>
        <BrandContainer onClick={() => history.push("/")}>
          <Brand navbar className="injectable" />
        </BrandContainer>
      </Navbar>
    </NavbarRelative>
  );
};

export default NavbarComponent;
