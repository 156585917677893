import React from "react";
import PropTypes from "prop-types";
import {
  Summary,
  SummaryBody,
  SummaryRow,
  SummaryFieldName,
  SummaryValue,
  SummaryHeader,
  SummaryTD,
} from "components/summary/style";

const SummaryComponent = ({ data }) => (
  <Summary>
    <SummaryBody>
      {data.map((row, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <SummaryRow key={i}>
          {
            // eslint-disable-next-line no-nested-ternary
            row.fieldName && row.value ? (
              <>
                <SummaryFieldName>{row.fieldName}</SummaryFieldName>
                <SummaryValue>{row.value}</SummaryValue>
              </>
            ) : !row.fieldName ? (
              <SummaryTD colSpan="2">{row.value}</SummaryTD>
            ) : (
              <SummaryHeader>{row.fieldName}</SummaryHeader>
            )
          }
        </SummaryRow>
      ))}
    </SummaryBody>
  </Summary>
);

const { arrayOf, shape } = PropTypes;

SummaryComponent.propTypes = {
  data: arrayOf(shape({})),
};

SummaryComponent.defaultProps = {
  data: [],
};

export default SummaryComponent;
