import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import {
  Button,
  Heading,
  Input,
  Link,
} from 'shared';
import {
  Brand,

  // Decoratives
  Stick,
  HalfBall2,
  Cone,
} from 'components';
import {
  Container,
  Form,
  SubtitleContainer,
} from 'pages/sign-up/style';

const SignUp = () => {
  const history = useHistory();
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const errorStatusDefault = {
    fullname: false,
    email: false,
    password: false,
    confirmPassword: false,
  };
  const [errorStatus, setErrorStatus] = useState(errorStatusDefault);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    try {
      const schema = Joi.object({
        fullname: Joi.string().required().error((err) => {
          setErrorStatus({ ...errorStatusDefault, fullname: true });
          if (err[0].code === 'string.empty') {
            return new Error('Please input your fullname');
          }
          return new Error('Your fullname format not valid');
        }),
        email: Joi.string().required().email({ tlds: { allow: false } }).error((err) => {
          setErrorStatus({ ...errorStatusDefault, email: true });
          if (err[0].code === 'string.empty') {
            return new Error('Please input your email');
          }
          return new Error('Your email format not valid');
        }),
        password: Joi.string().required().error((err) => {
          setErrorStatus({ ...errorStatusDefault, password: true });
          if (err[0].code === 'string.empty') {
            return new Error('Please input your password');
          }
          return new Error('Your password format not valid');
        }),
        confirmPassword: Joi.ref('password'),
      });

      const value = await schema.validateAsync({
        fullname,
        email,
        password,
        confirmPassword,
      });
      delete value.confirmPassword;
      axiosApiInstance.post(ENDPOINTS('customer.authentication.signup'), value)
        .then(() => history.push('/verify-email', { email }))
        .catch((err) => {
          setIsSubmitting(false);
          setErrorMessage(err.response.data.m);
        });
    } catch (err) {
      setIsSubmitting(false);

      if (err.message == '"confirmPassword" must be [ref:password]') {
        setErrorMessage(err.message);
        setErrorStatus({ ...errorStatusDefault, confirmPassword: true });
      } else {

      }
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <Brand />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Heading
            kind="title"
            text="Sign up"
          />
          <SubtitleContainer>
            <Heading
              kind="subtitle"
              text="Already have an account?"
            />
            <Link
              to="/sign-in"
              text="Sign in"
            />
          </SubtitleContainer>
          <Input
            type="text"
            label="Full Name"
            name="fullname"
            value={fullname}
            onChange={setFullname}
            placeholder="Hi, what's your name?"
            errorMessage={errorMessage && ' '}
            errorStatus={errorStatus.fullname}
            disabled={isSubmitting}
          />
          <Input
            type="text"
            label="Email"
            name="email"
            value={email}
            onChange={setEmail}
            placeholder="6+ characters"
            errorMessage={errorMessage && ' '}
            errorStatus={errorStatus.email}
            disabled={isSubmitting}
          />
          <Input
            type="password"
            label="Password"
            name="password"
            value={password}
            onChange={setPassword}
            placeholder="Type your password"
            errorMessage={errorMessage && ' '}
            errorStatus={errorStatus.password}
            disabled={isSubmitting}
          />
          <Input
            type="password"
            label="Retype Password"
            name="retype-password"
            value={confirmPassword}
            onChange={setConfirmPassword}
            placeholder="Retype your password"
            errorMessage={errorMessage}
            errorStatus={errorStatus.confirmPassword}
            disabled={isSubmitting}
          />
          <Button
            kind="primary"
            type="submit"
            text="Sign Up"
            disabled={isSubmitting}
          />
        </Form>
      </Container>

      {/* Decoratives *************************************** */}
      <Stick />
      <HalfBall2 />
      <Cone />
      {/* *************************************************** */}
    </>
  );
};

export default SignUp;
