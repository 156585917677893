import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Combobox,
  Select,
  Options,
  Item,
  Icon,
  Placeholder,
  ComboboxLabel,
} from 'components/combobox-white/style';
import ArrowDownSVG from 'assets/icons/down-arrow.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import { Scrollbars } from 'react-custom-scrollbars';

const ComboboxWhite = ({
  placeholder,
  onChange,
  list,
  label,
}) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState({});
  const selectItem = (item) => {
    setSelected({ value: item.value, label: item.label });
    if (onChange !== undefined) {
      onChange(item.value);
    }
  };

  const toggleList = () => setShowList(!showList);
  const closeList = () => setShowList(false);
  const spaceOpenList = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      toggleList();
    }
  };
  const enterSelected = (event, item) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      selectItem(item);
      closeList();
    }
  };

  return (
    <>
      {label && <ComboboxLabel>{label}</ComboboxLabel>}
      <OutsideClickHandler onOutsideClick={closeList}>
        <Combobox onClick={toggleList} tabIndex="0" onKeyUp={(event) => spaceOpenList(event)}>
          {showList ? <Icon src={ArrowDownSVG} up /> : <Icon src={ArrowDownSVG} />}
          <Select>
            {(selected && selected.label) || <Placeholder>{placeholder}</Placeholder>}
          </Select>
          {showList && (
          <Options>
            <Scrollbars
              autoHide
              style={{
                minHeight: list.length === 1 ? '64px'
                  : list.length === 2 ? '112px'
                    : list.length > 2 ? '160px'
                      : 0,
              }}
            >
              {list.map((item, index) => (
                <Item
              // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  tabIndex="0"
                  onKeyUp={(event) => enterSelected(event, item)}
                  onClick={() => selectItem(item)}
                >
                  {item.label}
                </Item>
              ))}
            </Scrollbars>

          </Options>
          )}
        </Combobox>
      </OutsideClickHandler>
    </>

  );
};
const {
  string,
  func,
  arrayOf,
  shape,
} = PropTypes;

ComboboxWhite.propTypes = {
  placeholder: string,
  onChange: func,
  list: arrayOf(shape({})),
};

ComboboxWhite.defaultProps = {
  placeholder: 'Select',
  onChange: () => {},
  list: [],
};

export default ComboboxWhite;
