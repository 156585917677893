/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  TableContainer,
  TableHeader,
  TableItem,
  TableBody,
} from 'pages/check-in/guest-table/table/style';
import PropTypes from 'prop-types';

const Table = ({ cols, list }) => (
  <TableContainer>
    <TableHeader>
      <TableItem>Table Number</TableItem>
      <TableItem>Pax Number</TableItem>
    </TableHeader>
    {list.map((item, index) => (
      <TableBody key={index}>
        {cols.map((col, index2) => (
          <TableItem key={index2}>
            {col.render(item)}
          </TableItem>
        ))}
      </TableBody>
    ))}
  </TableContainer>
);

const {
  arrayOf,
  object,
  oneOfType,
  string,
} = PropTypes;

Table.propTypes = {
  cols: arrayOf(oneOfType([object, string])),
  list: arrayOf(oneOfType([object, string])),
};

Table.defaultProps = {
  cols: [],
  list: [],
};

export default Table;
