import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { Modal } from "components";
import { Heading, Button } from "shared";
import {
  Container,
  Title,
  Icon,
  Content,
  ButtonContainer,
  ButtonWrapper,
} from "components/side-menu/style";
import ExitSVG from "assets/icons/exit.svg";
import EditSVG from "assets/icons/edit-pencil.svg";

const SideMenuComponent = ({
  expanded,
  isOpen,
  onRequestClose,
  finishFunc,
  cancelFunc,
  editFunc,
  isCollapsible,
  openedId,
  openId,
  title,
  buttonText,
  icon,
  children,
  isLoading,
  disabled,
  cancelButton,
  isEditDrawer,
  collapsibleContent,
}) => {
  const totalCollapsibles = isEditDrawer ? collapsibleContent : children.length;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          border: 0,
          width: expanded ? "65vw" : "27.5rem",
          minHeight: "100vh",
          top: "50%",
          left: "auto",
          right: "0",
          marginRight: "0",
          marginLeft: "0",
          transform: "translate(0%, -50%)",
          padding: 0,
          borderRadius: "0.9375rem 0 0 0.9375rem",
        },
      }}
    >
      <Container>
        <Scrollbars autoHide style={{ minHeight: "100vh" }}>
          <Title>
            <Icon src={icon} />
            <Heading kind="title" text={title} />
            <Icon src={ExitSVG} onClick={onRequestClose} />
          </Title>
          <Content>{children}</Content>
          {(isCollapsible || buttonText) && (
            <ButtonContainer cancelButton={cancelButton}>
              {cancelButton ? (
                <>
                  <Button
                    small
                    kind="secondary"
                    type="button"
                    isLoading={isLoading}
                    disabled={disabled}
                    text="Cancel"
                    onClick={() => {
                      cancelFunc();
                    }}
                  />
                  <ButtonWrapper>
                    <Button
                      small
                      kind="secondary"
                      type="button"
                      icon={EditSVG}
                      isLoading={isLoading}
                      disabled={disabled}
                      text={(() => {
                        if (isCollapsible) {
                          if (
                            openedId === totalCollapsibles - 1 ||
                            openedId === Infinity
                          ) {
                            return "Edit";
                          }
                          return "Next";
                        }
                        return "Edit";
                      })()}
                      onClick={() => {
                        if (isCollapsible) {
                          if (
                            openedId === totalCollapsibles - 1 ||
                            openedId === Infinity
                          ) {
                            editFunc();
                          } else {
                            openId(openedId + 1);
                          }
                        } else {
                          editFunc();
                        }
                      }}
                    />
                    <Button
                      small
                      kind="primary"
                      type="button"
                      isLoading={isLoading}
                      disabled={disabled}
                      text={(() => {
                        if (isCollapsible) {
                          if (
                            openedId === totalCollapsibles - 1 ||
                            openedId === Infinity
                          ) {
                            return buttonText;
                          }
                          return "Next";
                        }
                        return buttonText;
                      })()}
                      onClick={() => {
                        if (isCollapsible) {
                          if (
                            openedId === totalCollapsibles - 1 ||
                            openedId === Infinity
                          ) {
                            finishFunc();
                          } else {
                            openId(openedId + 1);
                          }
                        } else {
                          finishFunc();
                        }
                      }}
                    />
                  </ButtonWrapper>
                </>
              ) : (
                <Button
                  small
                  kind="primary"
                  type="button"
                  isLoading={isLoading}
                  disabled={disabled}
                  text={(() => {
                    if (isCollapsible) {
                      if (
                        openedId === totalCollapsibles - 1 ||
                        openedId === Infinity
                      ) {
                        return buttonText;
                      }
                      return "Next";
                    }
                    return buttonText;
                  })()}
                  onClick={() => {
                    if (isCollapsible) {
                      if (
                        openedId === totalCollapsibles - 1 ||
                        openedId === Infinity
                      ) {
                        finishFunc();
                      } else {
                        openId(openedId + 1);
                      }
                    } else {
                      finishFunc();
                    }
                  }}
                />
              )}
            </ButtonContainer>
          )}
        </Scrollbars>
      </Container>
    </Modal>
  );
};

const { oneOfType, arrayOf, bool, number, func, string, element, any } =
  PropTypes;

SideMenuComponent.propTypes = {
  expanded: bool,
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  finishFunc: func,
  cancelFunc: func,
  editFunc: func,
  isCollapsible: bool,
  openedId: number,
  openId: func,
  title: string.isRequired,
  buttonText: oneOfType([bool, string]),
  icon: string.isRequired,
  children: oneOfType([element, arrayOf(element), any]),
  isLoading: bool,
  disabled: bool,
  cancelButton: bool,
  isEditDrawer: bool,
  collapsibleContent: number,
};

SideMenuComponent.defaultProps = {
  expanded: false,
  finishFunc: () => {},
  cancelFunc: () => {},
  editFunc: () => {},
  isCollapsible: false,
  openedId: 0,
  openId: () => {},
  buttonText: "",
  children: <></>,
  isLoading: false,
  disabled: false,
  cancelButton: false,
  isEditDrawer: false,
  collapsibleContent: 0,
};

export default SideMenuComponent;
