import styled, { css } from 'styled-components';

export const Icon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.heading.title};
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.01875rem;
  border: 0;
  display: inline-block;
  height: 2.5rem;
  &::placeholder {
    color: #BDBDBD;
  }
  ${(props) => props.disabled && css`
    background-color: ${props.theme.section.detail.background};
    cursor: pointer;
  `}
`;

export const InputContainers = styled.div`
  border-width:0;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 2.75rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Title = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  color: ${(props) => ((props.isDashboard && props.theme.section.detail.title) || props.theme.section.detail.header)};
  font-size:${(props) => ((props.isDashboard && '2rem') || '1.25rem')};
  line-height: ${(props) => ((props.isDashboard && '2.75rem') || '1.625rem')};
  letter-spacing:${(props) => ((props.isDashboard && '0.03125rem') || '0.01875rem')};
`;

export const SubTitleDashboard = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: normal;
  color: ${(props) => props.theme.section.detail.subtitle};
  font-size:1rem;
  line-height: 1.25rem;
  letter-spacing:0.0125rem;
`;

export const ButtonIcon = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow:hidden;
  height:${(props) => ((props.isDashboard && '100%') || '8.75rem')};
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.section.detail.background};
  padding: 1.25rem 1.5rem 1rem;
  display: flex;
  align-items:${(props) => ((props.isDashboard && 'top') || 'center')};
  cursor: pointer;
  flex-direction:${(props) => ((props.isDashbaord && 'row') || 'column')};
  ${(props) => props.infographic && css`
    display: block;

    ${Icon} {
      position: relative;
      top: 0.2rem;
    }

    ${Title} {
      color: ${props.theme.heading.subtitle};
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.09375rem;
      text-transform: uppercase;
      height: fit-content;
    }
  `}

  ${(props) => props.editable && css`
    cursor: auto;
  `}

  &:hover {
    box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);
  }

  ${(props) => props.isActive && css`
    border: 0.25rem solid ${props.theme.form.button.background.icon.normal};
    border-radius: 0.625rem;
    padding: calc(1.25rem - 0.25rem) calc(1.5rem - 0.25rem) calc(1rem - 0.25rem);

    ${Icon}{
      right: -0.25rem;
      bottom: -0.25rem;
    }
  `}
`;
