import styled, { css } from 'styled-components';
import { Heading, Input, TextArea } from 'shared/styles';

export const Icon = styled.img``;

export const Collapsible = styled.div`
  margin-bottom: 1.5rem;
  ${css`
    ${(props) => props.theme.noselect};
  `}

  ${(props) => props.isNotSideMenu && css`
    border-bottom: 0.0625rem solid ${props.theme.sideMenu.border};
  `}
`;

export const CollapsibleTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(props) => (props.opened && '0') || '1.5rem'};

  ${Heading.Heading} {
    text-align: left;
    font-family: 'GT Walsheim Pro', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    letter-spacing: 0.09375rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const CollapsibleContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 2rem;

  ${Input.Input}, ${TextArea.TextArea} {
    margin-bottom: 0;
  }
`;
