import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

// const { NODE_ENV } = process.env;

const initialState = {};

export const store = createContext(initialState);
const { Provider } = store;

export const ACTIONS = {
  addUser: 'ADD_USER',
  createEvent: 'CREATE_EVENT',
  deleteEvent: 'DELETE_EVENT',
  getEventDetail: 'GET_EVENT_DETAIL',
};

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((iState, action) => {
    // eslint-disable-next-line
    // if (NODE_ENV === 'development')
    switch (action.type) {
      case ACTIONS.addUser:
        return {
          ...iState,
          user: action.payload,
        };
      case ACTIONS.createEvent:
        return {
          ...iState,
          eventForm: {
            ...iState.eventForm,
            ...action.payload,
          },
        };
      case ACTIONS.deleteEvent:
        return {
          ...iState,
          eventForm:{}
        }
        
      case ACTIONS.getEventDetail:
        return {
          ...iState,
          eventDetail: action.payload,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const { element } = PropTypes;

StateProvider.propTypes = {
  children: element.isRequired,
};
