import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 50%;

  ${Heading.Heading} {
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
  }
`;

export const Section = styled.div`
  width: 50%;
  margin-bottom: 8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
`;

export const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-top: 0.1875rem solid ${(props) => props.theme.loading.empty};
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 72px;
 
  }
`;

export const FooterContent = styled.div`
  flex:1;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  ${Button.Button} {
    position: absolute;
    top: 16px;
    &:first-child{
      left: 36px;
    }
    &:last-child{
      right: 36px;
    }
`;

export const Icon = styled.img``;