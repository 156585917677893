import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Brand = styled.img`
  position: absolute;
  cursor: pointer;

  ${(props) => !props.navbar && css`
    top: 1.3125rem;
    left: 2rem;
  `}

  ${(props) => props.navbar && css`
    bottom: 2.625rem;
  `}
`;
