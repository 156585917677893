import React, { useState, useEffect } from 'react';
import {
  Switch,
  useHistory,
  Route,
} from 'react-router-dom';

import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';

import GuestNumber from 'pages/check-in/guest-number';
import GuestRedEnvelop from 'pages/check-in/guest-redenvelop';
import GuestScanQR from 'pages/check-in/guest-scan-qr';
import GuestSearch from 'pages/check-in/guest-search';
import GuestArrival from 'pages/check-in/guest-arrival';
import GuestTable from 'pages/check-in/guest-table';
import GuestWelcome from 'pages/check-in/guest-welcome';
import GuestThankYou from 'pages/check-in/guest-thankyou';
import GuestQRIS from 'pages/check-in/guest-qris';
import {
  Loading,
} from 'pages';
import {
  Video,
  SourceVideo,
  DefaultImage,
  Icon,
} from 'pages/check-in/style';
import DecorationImage from 'assets/images/default-checkin.png';
import { useDevice, checkDevice, print } from 'helpers/print';
import { ComboboxPrinter } from 'components';
import {
  Button,
} from 'shared';

const Event = () => {
  //printer
  const [deviceList] = useDevice();
  const [devicesList, setDevicesList] = useState([]);
  const [deviceSelected, setDeviceSelected] = useState({});

  const history = useHistory();
  const [eventDetail, setEventDetail] = useState({});
  const [eventTokenCommited, setEventCommited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [guestDetail, setGuestDetail] = useState({});
  const [guestList, setGuestList] = useState([]);

  useEffect(() => {
    setDevicesList(deviceList.reduce((acc, curr, index) => {
      acc.push({ value: { detail: curr, id: index }, isConnected: false, label: curr?.name })
      return acc;
    }, []));
  }, [deviceList]);

  const onDeviceChange = (device) => {
    checkDevice(device?.value?.detail, (resp) => {
      if (resp == 500) {
        console.log('failed to connect')
        setDeviceSelected({ value: '', label: '' })
      } else if (resp == "Ready to Print") {

        setDevicesList(devicesList.reduce((acc, curr, index) => {
          if (curr.value.id == device?.value?.id) {
            acc.push({ ...curr, isConnected: true })
          } else {
            acc.push({ ...curr, isConnected: false })
          }
          return acc
        }, []))

        setDeviceSelected(device);

      } else {
        console.log('contact admin')
        setDeviceSelected({ value: '', label: '' })
      }
    });
  }

  useEffect(() => {
    const getDetail = () => {
      axiosApiInstance.get(ENDPOINTS('customer.event.detail'))
        .then(({ data: { value: { event } } }) => {
          setEventCommited(true);
          setEventDetail(event);
          setIsLoading(false);
        })
        .catch(() => history.push('/events'));
    };

    if (localStorage.getItem('event_token')) {
      getDetail();
    } else {
      history.push('/events');
    }
  }, [history]);


  const countDeviceListConnected = devicesList.reduce((acc, curr) => {
    if (curr.isConnected) {
      acc = acc + 1;
    }

    return acc;
  }, 0)
  return (
    <>
      {isLoading ? <Loading /> : (
        <>
          {eventDetail.video_dday ? (
            <Video autoPlay muted loop>
              <SourceVideo
                src={eventDetail.video_dday}
                type="video/mp4"
              />
            </Video>
          ) : (
            <DefaultImage>
              <Icon src={DecorationImage} />
            </DefaultImage>
          )}
          <div style={{ position: 'fixed', right: 0, width: '250px', zIndex: 1 }}>
            {
              !!countDeviceListConnected ?
                null :
                <ComboboxPrinter
                  skipChange
                  placeholder="Selected Device"
                  list={devicesList}
                  value={deviceSelected?.value}
                  onChange={(value) => { onDeviceChange(value) }}
                  skipChange={false}
                />
            }
            {/* {deviceSelected?.value?.detail ? < Button
              kind="primary"
              type="button"
              text="Print"
              onClick={() => print(deviceSelected?.value?.detail,'seated' ,1,'louis', 1, '12, 13, 14, 12, 13, 14, 10')} /> : null} */}


          </div>
          <Switch>
            <Route
              path="/check-in/guest-number"
              render={() => (
                <GuestNumber
                  eventName={eventDetail.name}
                  guestDetail={guestDetail}
                  withVideo={eventDetail.video_dday}
                  eventType={eventDetail.type}
                  guestList={guestList}
                  device={deviceSelected?.value?.detail}
                />
              )}
            />
            <Route
              path="/check-in/guest-redenvelop"
              render={() => (
                <GuestRedEnvelop
                  eventName={eventDetail.name}
                  guestDetail={guestDetail}
                  withVideo={eventDetail.video_dday}
                  eventType={eventDetail.type}
                  guestList={guestList}
                  device={deviceSelected?.value?.detail}
                />
              )}
            />
            <Route
              path="/check-in/guest-scan-qr"
              render={() => (
                <GuestScanQR
                  eventTokenCommited={eventTokenCommited}
                  eventName={eventDetail.name}
                  eventType={eventDetail.type}
                  withVideo={eventDetail.video_dday}
                  setGuestDetail={setGuestDetail}
                />
              )}
            />
            <Route
              path="/check-in/guest-search"
              render={() => (
                <GuestSearch
                  eventTokenCommited={eventTokenCommited}
                  eventName={eventDetail.name}
                  eventType={eventDetail.type}
                  setGuestDetail={setGuestDetail}
                  withVideo={eventDetail.video_dday}
                />
              )}
            />
            <Route
              path="/check-in/guest-arrival"
              render={() => (
                <GuestArrival
                  eventTokenCommited={eventTokenCommited}
                  eventName={eventDetail.name}
                  eventType={eventDetail.type}
                  guestDetail={guestDetail}
                  setGuestDetail={setGuestDetail}
                  withVideo={eventDetail.video_dday}
                  guestList={guestList}
                  setGuestList={setGuestList}
                />
              )}
            />
            <Route
              path="/check-in/guest-table"
              render={() => (
                <GuestTable
                  eventName={eventDetail.name}
                  guestDetail={guestDetail}
                  withVideo={eventDetail.video_dday}
                  setGuestDetail={setGuestDetail}
                  guestList={guestList}
                />
              )}
            />
            <Route
              path="/check-in/guest-welcome"
              render={() => (
                <GuestWelcome
                  eventName={eventDetail.name}
                  withVideo={eventDetail.video_dday}
                />
              )}
            />
            <Route
              path="/check-in/guest-thankyou"
              render={() => (
                <GuestThankYou
                  eventName={eventDetail.name}
                  withVideo={eventDetail.video_dday}
                />
              )}
            />
            <Route
              path="/check-in/guest-qris"
              render={() => (
                <GuestQRIS
                  eventTokenCommited={eventTokenCommited}
                  eventName={eventDetail.name}
                  eventType={eventDetail.type}
                  qrisBca={eventDetail.qris_bca}
                />
              )}
            />
          </Switch>
        </>
      )}
    </>
  );
};

export default Event;
