import styled, { css } from "styled-components";
import { Heading, Input } from "shared/styles";
import { ButtonIcon, InputContainers } from "components/button-icon/style";

export const Icon = styled.img``;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1rem 2.5rem;
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    margin-bottom: 1.5rem;
    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.125rem;
    }
  }
`;

export const ModalSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.75rem;
  margin-bottom: 1.5rem;

  ${ButtonIcon} {
    font-size: 1.5rem;
    line-height: 2rem;
    align-items: stretch;
    height: 7rem;
    padding: 1rem 1.25rem 0.75rem;
    ${InputContainers} {
      margin-top: 2rem;
    }
  }
`;

export const ModalInfo = styled.div`
  border: 0.09375rem solid ${(props) => props.theme.border.primary};
  border-radius: 0.5rem;
  padding: 1.25rem 1.125rem;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;

  ${Heading.Heading} {
    text-align: left;
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: 0.03125rem;
    color: ${(props) => props.theme.navbar.background};
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    &:first-child {
      flex: 0.7;
    }
  }

  ${Input.Input} {
    margin: 0;
  }
`;

export const TopRightContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const EmptyGuestImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: 0.0125rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.empty.text};
`;
export const EmptyGuestImage = styled.img`
  margin-bottom: 1.5rem;
`;
export const EmptyGuestText = styled.p``;

export const ContentContainer = styled.div`
  height: 100%;
  ${(props) =>
    !props.data &&
    css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

  ${(props) =>
    props.data &&
    css`
      margin-top: 2.375rem;
      margin-bottom: 2.75rem;
    `}
`;

export const AssignGuestContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const AssignGuestSection = styled.div`
  width: 50%;
`;

export const AssignGuestSectionTitle = styled.p`
  font-family: "GT Walsheim Pro";
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.sideMenu.title};
  margin-bottom: 0.25rem;
`;

export const AssignGuestSectionSubtitle = styled.p`
  font-family: "GT Walsheim Pro";
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.sideMenu.subtitle};
  margin-bottom: 1.5rem;
`;

export const TableCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.125rem 0;
  align-items: center;
`;

export const CategoryMore = styled.div`
  margin-left: 4px;
  color: #727272;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  max-width: 22.5rem;
`;

export const MessageBoxContainer = styled.div`
  margin-bottom: 24px;
`;
