import React from 'react';
import PropTypes from 'prop-types';
import { LoadingBar } from 'components/loading-bar/style';

const LoadingBarComponent = ({ percent }) => <LoadingBar percent={percent} />;

const { string } = PropTypes;

LoadingBarComponent.propTypes = {
  percent: string,
};

LoadingBarComponent.defaultProps = {
  percent: 0,
};

export default LoadingBarComponent;
