import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Heading,
  Button,
} from 'shared';
import {
  GuestContainer,
  GuestContent,
  Card,
  CardText,
  ButtonContainer,
  LogoContainer,
  Icon,
  CardNumber,
} from 'pages/check-in/guest-number/style';
import LogoFooterSVG from 'assets/images/logo-footer.svg';
import { print } from 'helpers/print';

const GuestNumber = ({ eventName, guestDetail, withVideo, eventType, guestList, device }) => {
  const history = useHistory();
  const { name: guestName, order } = guestDetail;
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  useEffect(() => {
    if (!Object.keys(guestDetail).length) {
      history.push('/check-in/guest-search');
    }
  }, [guestDetail, history]);

  const printSetup = () => {
    setIsPrintLoading(true)
    const selectedList = guestList.reduce((acc, curr) => {
      if (curr?.selected) {
        acc.push(curr)
      }
      return acc;
    }, [])
    if (eventType.toLowerCase() === 'seated') {
      let tableFinal = selectedList.reduce((acc, curr) => {
        let checkDoubleTable = acc.findIndex(a => a.tableName === curr?.table?.name);
        if (curr?.table?.name) {
          if (checkDoubleTable === -1) {
            acc.push({
              tableName: curr.table.name,
              pax: 1
            })
          } else {
            acc[checkDoubleTable].pax++
          }
        }

        return acc
      }, [])
      let tableAndPax = tableFinal.reduce((acc, curr) => {
        acc.pax += curr.pax
        acc.table.push(curr?.tableName)
        return acc;

      }, { table: [], pax: 0 })
      const joinTable = tableAndPax?.table.join(', ')
      print(device, eventType.toLowerCase(), order, guestName, tableAndPax.pax, joinTable);
      setTimeout(() => {
        setIsPrintLoading(false);
      }, 3000)
    } else {
      print(device, eventType.toLowerCase(), order, guestName, selectedList.length || 0)
      setTimeout(() => {
        setIsPrintLoading(false);
      }, 3000)
    }
  }

  return (
    <>
      <GuestContainer withVideo={withVideo}>
        <GuestContent>
          <Heading
            kind="subtitle"
            text={eventName}
          />
          <Card>
            <CardText>
              Your guest number ID
            </CardText>
            <CardNumber>
              {order}
            </CardNumber>
            <ButtonContainer>
              <Button
                kind="secondary"
                type="button"
                text="Reprint"
                disabled={isPrintLoading || !device}
                isLoading={isPrintLoading}
                onClick={printSetup}
              />
              <Button
                kind="secondary"
                type="button"
                text="Show QRIS"
                onClick={() => { history.push({
                  pathname: '/check-in/guest-qris',
                  state: { invId: guestDetail._id }
                }); }}
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                kind="primary"
                type="button"
                text="Done"
                onClick={() => { history.push('/check-in/guest-search'); }}
              />
            </ButtonContainer>
          </Card>
        </GuestContent>
      </GuestContainer>
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};

const { string, shape } = PropTypes;

GuestNumber.propTypes = {
  eventName: string.isRequired,
  guestDetail: shape({}),
};

GuestNumber.defaultProps = {
  guestDetail: {},
};

export default GuestNumber;
