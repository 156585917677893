import { useState, useEffect } from 'react';

const parsingLine = (nameSplit, maxLength) => {
  let line = ''
  return nameSplit.reduce((acc, curr, index) => {
    if ((line.length + curr.length + 1) <= maxLength) {
      if (line.length === 0) {
        line += curr
      } else {
        line += ' '
        line += curr
      }
      if (index === nameSplit.length - 1) {
        acc.push(line)
        return acc
      }

      return acc
    } else {
      acc.push(line)
      line = curr
      if (index === nameSplit.length - 1) {
        acc.push(line)
      }

      return acc
    }
  }, [])
}

const parsingLineTable = (table, maxLength) => {
  let line = '';
  let tableSplit = table.split(' ');
  return tableSplit.reduce((acc, curr, index) => {
    if ((line.length + curr.length + 1) <= maxLength) {
      if (line.length === 0) {
        line += curr
      } else {
        line += ' '
        line += curr
      }
      if (index === tableSplit.length - 1) {
        acc.push(line)
        return acc
      }

      return acc
    } else {
      acc.push(line)
      line = curr
      if (index === tableSplit.length - 1) {
        acc.push(line)
      }

      return acc
    }
  }, [])
}
export const parsingText = (name) => {
  let nameSplit = name.split(' ')
  let resultSmall = parsingLine(nameSplit, 21)
  if (resultSmall.length <= 2) {
    return {
      value: resultSmall,
      fontSize: 'big',
      maxOneLine: 21
    }
  }

  let resultMedium = parsingLine(nameSplit, 28)
  if (resultMedium.length > 2) {
    return {
      value: resultMedium.slice(0, 3),
      fontSize: 'medium',
      maxOneLine: 42
    }
  }

}

export const checkDevice = (deviceSelected, callback) => {

  deviceSelected.sendThenRead("~HQES", function (text) {
    let statuses = new Array()
    let ok = false
    let is_error = text.charAt(70)
    let media = text.charAt(88)
    let head = text.charAt(87)
    let pause = text.charAt(84)
    // check each flag that prevents printing
    if (is_error == '0') {
      ok = true
      statuses.push("Ready to Print")
    }
    if (media == '1')
      statuses.push("Paper out")
    if (media == '2')
      statuses.push("Ribbon Out")
    if (media == '4')
      statuses.push("Media Door Open")
    if (media == '8')
      statuses.push("Cutter Fault")
    if (head == '1')
      statuses.push("Printhead Overheating")
    if (head == '2')
      statuses.push("Motor Overheating")
    if (head == '4')
      statuses.push("Printhead Fault")
    if (head == '8')
      statuses.push("Incorrect Printhead")
    if (pause == '1')
      statuses.push("Printer Paused")
    if ((!ok) && (statuses.Count == 0))
      statuses.push("Error: Unknown Error")

    callback(statuses.join())
  }, (err) => { if (err) callback(500) })
}



export const print = (deviceSelected, eventType, order, guest, pax_total, table_list) => {
  let format_end,
    guest_name = '';


  // ^FO297,25^A@N,40,40,E:SIMSUN.FNT^FDKODE : 320 ^FS
  // ^FO317,85^A@N,40,40,E:SIMSUN.FNT^FDPAX : 320 ^FS
  const kode_text = `KODE : ${order}`
  const kode_position = ((500 - ((kode_text).length * (500 / 21))) / 2) + 165;
  const kode = `^FO${kode_position},40^A@N,40,40,E:SIMSUN.FNT^FD${kode_text}^FS`;

  const devider_horizontal = `^FO165,${25 + (2 * 45)}^GB500,0,2^FS`

  const parsing_text = parsingText(guest);
  if (parsing_text.fontSize === 'big') {
    for (let i = 0; i < parsing_text.value.length; i++) {
      let guestName = parsing_text.value[i];
      if (i == 2 && parsing_text.value.length > 3) {
        guestName += '...'
      }
      let guestNameLength = guestName.length;
      const countCenterPosition = ((500 - (guestNameLength * (500 / parsing_text?.maxOneLine))) / 2) + 165;

      guest_name += `^FO${countCenterPosition},${(parsing_text.value.length > 1 ? 137 : 160) + (i * 45)}^A@N,40,40,E:SIMSUN.FNT^FD${guestName}^FS`
    }
  } else {
    for (let i = 0; i < parsing_text.value.length; i++) {
      let guestName = parsing_text.value[i];
      if (i == 2 && parsing_text.value.length > 3) {
        guestName += '...'
      }
      let guestNameLength = guestName.length;
      const countCenterPosition = ((500 - (guestNameLength * (500 / parsing_text?.maxOneLine))) / 2) + 165;
      if (i < 3) {
        guest_name += `^FO${countCenterPosition},${120 + (i * 38)}^A@N,38,35,E:SIMSUN.FNT^FD${guestName}^FS`
      }
    }
  }

  const devider_horizontal_2 = `^FO165,${25 + (5 * 45)}^GB500,0,2^FS`
  // const logo_position = ((500 - (('eventco').length * (500 / 21))) / 2) + 165;
  // const logo = `^FO${logo_position},${25 + (6 * 45)}^A@N,40,40,E:SIMSUN.FNT^FDeventco^FS`


  const pax_text = `PAX : ${pax_total}`
  const pax_position = ((500 - ((pax_text).length * (500 / 21))) / 2) + 165;


  let pax = '', table = '';
  if (eventType === 'seated') {
    pax = `^FO${pax_position},${25 + (5 * 45) + 10}^A@N,40,40,E:SIMSUN.FNT^FD${pax_text}^FS`;
    const table_text = `TABLE : ${table_list}`
    const table_position = ((500 - ((table_text).length * (500 / 21))) / 2) + 165;
    // console.log(table_text)
    // console.log(parsingLineTable(table_text, 21))
    const table_text_parsing = parsingLineTable(table_text, 21);
    for (let i = 0; i < table_text_parsing.length; i++) {
      let tableName = table_text_parsing[i];
      let tableNameLength = tableName.length;
      const countCenterPosition = ((500 - (tableNameLength * (500 / 21))) / 2) + 165;

      table += `^FO${countCenterPosition},${305 + (i * 45)}^A@N,40,40,E:SIMSUN.FNT^FD${tableName}^FS`
    }
  } else {
    pax = `^FO${pax_position},${25 + (5 * 45) + 45}^A@N,40,40,E:SIMSUN.FNT^FD${pax_text}^FS`;
  }

  const format_start = `
    ^XA
    ${kode}
   
    ${devider_horizontal}
    ${guest_name}
    ${devider_horizontal_2}
    ${pax}
    ${table}
  `
  // dynamic height
  let height = 260
  format_end = `
    ^XZ
    ^FS^XZ
  `
  deviceSelected.send(format_start + format_end, (res) => { console.log(res) }, null)
}

export const backupPrint = (deviceSelected) => {
  let format_start,
    format_end
  let minus = 10
  const header = `^FO165,${182 - minus}^GB500,50,2^FS`
  const tableName = `^FO165,${198 - minus}^A@N,25,10,E:SIMSUN.FNT^FD  TABLE NAME                   TOTAL PAX^FS`
  const separator = `^FO500,${185 - minus}^GB0,205,2^FS`
  const tableBody = `^FO165,${230 - minus}^GB500,160,2^FS`
  const format_start_header = `
    ^XA
    ^FO165,25^A@N,20,30,E:SIMSUN.FNT^FDWELCOME ^FS
    ^FO165,45^A@N,25,10,E:SIMSUN.FNT^FD
    ${`^FO165,${25 + (1 * 45)}^A@N,40,40,E:SIMSUN.FNT^FD${'Louis '}^FS`}^FS

    ${header}
    ${separator}
    ${tableName}
    ${tableBody}
  `
  // dynamic height
  let height = 260
  let countPrint = 0
  format_start += `
  ^FO180,${height - minus}^A@N,25,10,E:SIMSUN.FNT^FD ${'1'}^FS
  ^FO500,${height - minus}^A@N,25,15,E:SIMSUN.FNT^FD   ${'2'}^FS
`

  format_end = `
    ^XZ
    ^FS^XZ
  `
  deviceSelected.send((format_start_header + format_start) + format_end, (res) => { console.log(res) }, null)
}

export const useDevice = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [deviceSelected, setDeviceSelected] = useState('');

  const setup = () => {
    var selected_device;
    var devices = [];
    var BrowserPrint = window.BrowserPrint;
    BrowserPrint.getDefaultDevice("printer", function (device) {
      selected_device = device;
      devices.push(device);
      BrowserPrint.getLocalDevices(function (device_list) {
        for (var i = 0; i < device_list.length; i++) {
          //Add device to list of devices and to html select element
          selected_device = device_list[i];
          if (!selected_device || device.uid != selected_device.uid) {
            devices.push(selected_device);
            // var option = document.createElement("option");
            // option.text = device.name;
            // option.value = device.uid;
            // html_select.add(option);
          }
        }
        console.log(devices)
        setDeviceList(devices);
        setDeviceSelected(selected_device);
      }, function () { alert("Error getting local devices") }, "printer");

    }, function (error) {
      alert('No Devices', 'Printer');
    })
  }
  useEffect(() => {
    setup();
  }, []);

  return [deviceList, deviceSelected];
};


