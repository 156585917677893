import React from 'react';
import { Heading } from 'shared';
import { Brand } from 'components';
import {
  Container,
  Section,
  Icon,
  HeadingContainer,
} from 'components/email-sent/style';
import PasswordUpdatedSVG from 'assets/images/password-updated.svg';

const PasswordUpdatedComponent = () => (
  <>
    <Brand />
    <Container>
      <Section>
        <Icon src={PasswordUpdatedSVG} />
        <HeadingContainer>
          <Heading
            kind="title"
            text="Password Updated!"
          />
          <Heading
            kind="subtitle"
            text="Your password has been updated. Use your new password to sign in."
          />
        </HeadingContainer>
      </Section>
    </Container>
  </>
);

export default PasswordUpdatedComponent;
