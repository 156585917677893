import React, { useState, useEffect } from 'react';
import {
  Icon,
  InputPage,
  PaginationContainer,
  PaginationNumberContainer,
  PaginationPageTotal
} from 'components/pagination/style';
import NextSVG from 'assets/icons/next-page.svg';
import PrevSVG from 'assets/icons/prev-page.svg';

const Pagination = ({
  setPage = () => { },
  page = 1,
  totalPage = 1
}) => {

  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    setPageNumber(page)
  }, [page])

  return (<PaginationContainer>
    <Icon src={PrevSVG}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setPage((prevState) => {
          if (prevState !== 1) {
            return prevState - 1
          }
          return prevState
        })
      }
      } />
    <PaginationNumberContainer>

      <InputPage
        type="number"
        value={pageNumber}
        max={totalPage}
        onChange={(event) => setPageNumber(event.target.value)}
        onKeyDown={(event) => {
          if (event.keyCode === 13 && (pageNumber <= totalPage && pageNumber > 0)) {
            setPage(pageNumber)
          }
        }}
        // onBlur={()=>{
        //   setPage(pageNumber)
        // }}
      />

      <PaginationPageTotal>/ {totalPage}</PaginationPageTotal>
    </PaginationNumberContainer>
    <Icon
      src={NextSVG}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setPage((prevState) => {
          if (prevState < totalPage) {
            return prevState + 1
          }

          return prevState
        })
      }
      } />
  </PaginationContainer>)
}

export default Pagination;