import styled from 'styled-components';

export const Icon = styled.img``;

export const TotalPax = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.modal.header.background};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
`;

export const TotalPaxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerInputAmount = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  box-sizing: border-box;
  position: relative;
  width: 360px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const InputAmount = styled.input`
  box-sizing: border-box;
  border: 0;
  height: 80px;
  padding: 20px 21px 20px 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.3px;
  color: #0A0A0A;
  width: 360px;
`;

export const InputAmountPrefix = styled.div`
  position: absolute;
  left: 21px;
  top: 28px;
  text-transform: uppercase;
  z-index: 10;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #727272;
`;

export const StatusContainer = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #727272;
  margin-bottom: 20px;
`;

export const SelectionContainer = styled.div`
  border-radius: 10px;
  padding: 12px 20px;
  margin-top: 8px;
  background-color: #ffffff;
`;

export const SelectionItem = styled.div`
  padding: 16px 0;
  border-bottom: 0.697397px solid #DDDDDD;
  display: flex;
  align-items: center;
`;

export const SelectionLabel = styled.div`
  margin-left: 8px;
  color: #0A0A0A;
`;
