import styled from "styled-components";

export const Icon = styled.img``;

export const CalenderContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #727272;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const CalendarLabel = styled.div`
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  line-height: 20px;
`;

export const DateInputContainer = styled.div`
  padding: 11px 16px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: 1px solid #7cc9da;
  }
`;

export const DateSelectedContainer = styled.div`
  position: relative;
`;

export const DateText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #0a0a0a;
`;

export const DatepickerPopupContainer = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 100%;
  margin-top: 8px;
  z-index: 4;
  padding: 28px 32px;
  width: 100%;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.12);
`;

export const DatepickerPopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const DatepickerPopupHeaderBack = styled.div`
  cursor: pointer;
`;

export const DatepickerPopupHeaderMonthYear = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #0a0a0a;
`;

export const DatepickerPopupHeaderNext = styled.div`
  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: red;
      }
    }
  }
`;

export const DatepickerPopupWeeks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const DatepickerPopupWeeksItem = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DatepickerPopupDays = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
`;

export const DatepickerPopupDaysItem = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    (props.daysInCurrentMonth && !props.disabled && "#0A0A0A") || "#BDBDBD"};
  border-radius: 50%;
  background-color: ${(props) =>
    (props.selectedDate && "#B5E0EA") || "#FFFFFF"};

  &:hover {
    background-color: ${(props) =>
      (props.daysInCurrentMonth && !props.disabled && "#B5E0EA") || "none"};
    cursor: ${(props) =>
      (props.daysInCurrentMonth && !props.disabled && "pointer") || "auto"};
  }
`;

export const CalendarIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const DatepickerPopupDaysContainer = styled.div``;

export const PlaceholderText = styled.div`
  color: #bdbdbd;
`;
