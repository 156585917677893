import React from 'react';
import PropTypes from 'prop-types';
import {
  DashboardCard,
  Container,
  Title,
  SubTitle,
  SubTitle2,
  HeaderSubTitle,
  HeaderSubTitle2,
  Divider,
  Divider2,
  SubCard,
  SubTitleMain,
  HeaderSubTitleMain
} from 'components/booth-card/style';

const DashboardCardComponent = ({
  text,
  totalInvities,
  title,
  subTitle,
  warning,
  isAdmin,
  onClick,
}) => (
  <DashboardCard warning={warning} isAdmin={isAdmin} onClick={onClick}>
    <Title>{title}</Title>
    <Container>
      <SubTitleMain>{totalInvities}</SubTitleMain>
      <HeaderSubTitleMain>Guests Checked-In</HeaderSubTitleMain>
      <SubCard>
        <HeaderSubTitle>Angpao:</HeaderSubTitle>
        <SubTitle>{subTitle}</SubTitle>
        <Divider></Divider>
        <HeaderSubTitle2>QRIS:</HeaderSubTitle2>
        <SubTitle2>{text}</SubTitle2>
      </SubCard>
    </Container>
  </DashboardCard>
);

const {
  oneOfType,
  string,
  object,
  bool,
  number,
  func,
} = PropTypes;

DashboardCardComponent.propTypes = {
  title: oneOfType([string, object, number]),
  text: oneOfType([number, string]),
  subTitle: oneOfType([object, string, number]),
  warning: bool,
  isAdmin: bool,
  onClick: func,
};

DashboardCardComponent.defaultProps = {
  title: '',
  text: '',
  subTitle: '',
  warning: false,
  isAdmin: false,
  onClick: () => {},
};

export default DashboardCardComponent;
