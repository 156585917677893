import styled,{ css } from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Icon = styled.img`
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #474747;
`;

export const Body = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;
  padding-bottom:2.5rem;
`;

export const HeadingContainer = styled.div`
  margin-bottom: 36px;
  position: relative;
  display: flex;
  align-items: center;
  ${Icon} {
    margin-right: 13px;
  }

  ${Heading.Heading} {
    text-align:left;
    display:inline;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.5px;
    color: #0A0A0A;
  }

`;

export const EventDetailContainer = styled.div`
  margin-top: 40px;
  ${SubTitle} {
    margin-bottom:20px;
  }
`;

export const EventDetailContent = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
`;
export const ButtonContainer = styled.div`
  margin-bottom: 8px;
  ${Button.Button} {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
`;
export const Info = styled.div`
  padding: 1.5rem;
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.section.info.background[props.color]};
  color: ${(props) => props.theme.section.info.text};
  height: auto;
`;

export const InfoTitle = styled(Heading.Heading)`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.01875rem;
  padding-bottom: 2.75rem;
  margin-bottom: 2.75rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.section.info.border};
`;

export const InfoFieldName = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

export const InfoValue = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
  margin-bottom: 1.25rem;
`;

export const RightSection = styled.div`
`;

export const Detail = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  border-radius: 0.625rem;
  padding: 1.25rem;
  position: relative;
`;

export const DetailHeader = styled.p`
  
  color: ${(props) => props.theme.section.detail.header};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.09375rem;
  margin-bottom: 1.75rem;
  text-transform: uppercase;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:  ${(props) => props.gridTemplateColumn || '1fr'}} ;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-top: ${(props) => props.marginTop || 0};
`;

export const EventCardLabel = styled.div`

`;

export const EventCardText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${(props) => (props.type === 'success' && '#40A790')
  || (props.type === 'error' && '#E76E6E')
  || '#0A0A0A'};`;
export const EventCardBadge = styled.div`
  padding: 5px 12px;
  color:${(props) => (props.type === 'success' && '#51B182') || (props.type === 'error' && '#E76E6E')};
  background-color:${(props) => (props.type === 'success' && '#D9F1F6') || (props.type === 'error' && '#FFE3E3')};
  border-radius: 6px;
`;

export const EventCard = styled.div`
  padding: 16px 28px;
  display:flex;
  flex-direction: column;
  justify-content:center;
  background: ${(props) => (props.disabled && '#DDDDDD') || '#FFFFFF'};
  cursor: ${(props) => (props.disabled && 'auto') || 'pointer'};
  border-radius: 10px;
  text-align: left;
  ${EventCardLabel} {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #727272;
  }

  ${EventCardBadge}{
    margin-bottom: 28px;
    width:fit-content;
  }
`;

export const HeaderCardCancel = styled.div`
  padding: 16px 28px;
  background: #FFFFFF;
  border-radius: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const InvoiceContainer = styled.div`
  margin-top: 46px;
  ${SubTitle} {
    margin-bottom: 26px;
  }
`;

export const InvoiceCard = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
`;

export const EventCardContent = styled.div`
`;

export const DownloadFileContainer = styled.div`
  margin-top: 24px;
`;

export const AddInvoiceContainer = styled.div`
  min-height:133px;
  width: 100%;
  border: 1.5px dashed #BDBDBD;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  :hover{
    cursor:pointer;
  }
`;

export const AddInvoiceText = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  color: #474747;
`;

export const DetailAction = styled.div`
  position: absolute;
  right: 1.25rem;
  display: flex;
  flex-direction: row;
  gap:12px;
`;


export const ActionButton = styled.div`
  box-sizing: border-box;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  cursor: pointer;
  padding: 0 0.75rem;
  height: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border: 0.0625rem solid ${(props) => props.theme.table.button.border};
  color: ${(props) => props.theme.table.button.text};

  ${(props) => props.type === 'primary' && css`
    color: #438FD4;
  `}
  ${(props) => props.type === 'warning' && css`
    color: #E76E6E;
  `}

`;

export const IconAction = styled.img`
  position: relative;
  margin: 0;
  margin-right: 0.395625rem;
`;
