import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { store, ACTIONS } from "store";
import { Heading, Button, Input } from "shared";
import ENDPOINTS from "helpers/endpoints";
import Joi from "joi";
import { axiosApiInstance } from "helpers/authentication";
import forms from "data/static/form";

import {
  SideMenuCollapsible as Collapsible,
  Checkbox,
  DatePicker,
  Modal,
  ToastBox,
} from "components";
import {
  Icon,
  IconVideo,
  Body,
  HeadingContainer,
  Label,
  InvitationLanguageContainer,
  CheckboxContainer,
  UploadInvitation,
  ButtonContainer,
  InputContainer,
  UploadIcon,
  UploadPreviewContainer,
  UploadPreviewImage,
  UploadPreviewVideo,
  UploadPreviewVideoContainer,
  UploadPreviewOverlay,
  UploadPreviewIconContainer,
  UploadContainer,
  UploadLabel,
  UploadInput,
  ModalHeader,
  ModalClose,
  ModalHeaderTitle,
  ModalButtonContainer,
  ModalImage,
  ModalVideo,
  ModalBody,
  InvitationTab,
  InvitationTabItem,
  InvitationContentContainer,
  EmailContainerHead,
  SubTitle,
  EmailContainerBody,
  EmailGreeting,
  EmailContent,
  EmailInformation,
  EmailLabelInformation,
  EmailItem,
  EmailInfo,
  ViewMap,
  WhatsappContainer,
  WhatsappBody,
  InvitationContainer,
  ToolTip,
  ToolTipText,
} from "pages/invitation/style";

import SVGInject from "@iconfu/svg-inject";
import UploadSVG from "assets/icons/upload.svg";
import DeleteUploadSVG from "assets/icons/delete-upload.svg";
import ZoomUploadSVG from "assets/icons/zoom-upload.svg";
import InvitationPreviewSVG from "assets/icons/invitation-preview.svg";
import CalendarSVG from "assets/icons/calendar.svg";
import VenueSVG from "assets/icons/venue.svg";
import LocationSVG from "assets/icons/location.svg";
import ExitSVG from "assets/icons/exit.svg";
import InfoSVG from "assets/icons/info.svg";
import { Document, Page, pdfjs } from "react-pdf";

const PreviewModal = ({ isOpen, onRequestClose, image, type }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        width: "500px",
      },
    }}
  >
    <ModalHeader>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      <Icon src={InvitationPreviewSVG} />
      <ModalHeaderTitle>Preview</ModalHeaderTitle>
    </ModalHeader>
    <ModalBody>
      {type == "video/mp4" ? (
        <ModalVideo controls>
          <source src={image} type="video/mp4" />
        </ModalVideo>
      ) : (
        <ModalImage src={image} width="100%" height="auto" />
      )}
      <ModalButtonContainer>
        <Button
          small
          kind="primary"
          type="button"
          text="Close"
          onClick={onRequestClose}
        />
      </ModalButtonContainer>
    </ModalBody>
  </Modal>
);

const SendInvitation = ({
  isOpen,
  onRequestClose,
  invitationInfo,
  url_image,
  type,
  tabsOpen = "email",
}) => {
  const [onTab, setOnTab] = useState("");
  useEffect(() => {
    setOnTab(tabsOpen);
  }, [tabsOpen]);
  const {
    state: { eventDetail },
  } = useContext(store);
  const {
    email_body,
    email_salutation,
    email_subject,
    wa_last_confirmation,
    wa_signature,
    hash_tag,
  } = invitationInfo;
  const {
    address,
    event_date_string,
    event_venue,
    latitude,
    longitude,
    name: eventName,
    document_file_type,
    bride_name,
    bride_father,
    bride_mother,
    groom_name,
    groom_father,
    groom_mother,
  } = eventDetail;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <Scrollbars autoHide style={{ minHeight: "80vh" }}>
        <ModalHeader>
          <ModalClose src={ExitSVG} onClick={onRequestClose} />
          <Icon src={InvitationPreviewSVG} />
          <ModalHeaderTitle>Invitation Preview</ModalHeaderTitle>
        </ModalHeader>
        <InvitationTab>
          <InvitationTabItem
            active={onTab === "whatsapp"}
            onClick={() => {
              setOnTab("whatsapp");
            }}
          >
            Whatsapp
          </InvitationTabItem>
          <InvitationTabItem
            active={onTab === "email"}
            onClick={() => {
              setOnTab("email");
            }}
          >
            Email
          </InvitationTabItem>
        </InvitationTab>

        {onTab === "email" && (
          <InvitationContentContainer>
            <EmailContainerHead>
              <SubTitle>YOU’RE INVITED TO</SubTitle>
              <Heading kind="title" text={email_subject} />
            </EmailContainerHead>
            <EmailContainerBody>
              <EmailGreeting>
                {email_salutation} [Invitation Name]
              </EmailGreeting>
              <EmailContent>{email_body}</EmailContent>
              <EmailInformation>
                <EmailItem>
                  <EmailLabelInformation>
                    <Icon src={CalendarSVG} />
                    DATE & TIME
                  </EmailLabelInformation>
                  <EmailInfo>
                    {moment(event_date_string).format(
                      "dddd, MMM D, YYYY - hh.mm A"
                    )}
                  </EmailInfo>
                </EmailItem>
                <EmailItem>
                  <EmailLabelInformation>
                    <Icon src={VenueSVG} />
                    venue
                  </EmailLabelInformation>
                  <EmailInfo>{event_venue}</EmailInfo>
                </EmailItem>
                <EmailItem>
                  <EmailLabelInformation>
                    <Icon src={LocationSVG} />
                    Address
                  </EmailLabelInformation>
                  <EmailInfo>{address}</EmailInfo>
                  <ViewMap
                    href={`https://www.google.com/maps/search/?api=1&query=${String(
                      latitude
                    ).replace(",", ".")},${String(longitude).replace(
                      ",",
                      "."
                    )}`}
                    target="_blank"
                  >
                    View Map
                  </ViewMap>
                </EmailItem>
              </EmailInformation>
            </EmailContainerBody>
            <ModalButtonContainer>
              <Button
                small
                kind="primary"
                type="button"
                text="Done"
                onClick={onRequestClose}
              />
            </ModalButtonContainer>
          </InvitationContentContainer>
        )}

        {onTab === "whatsapp" && (
          <InvitationContentContainer>
            <WhatsappContainer>
              {url_image ? (
                type == "application/pdf" ? (
                  <div
                    style={{
                      width: "100%",
                      height: 370,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Document
                      file={url_image}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading=""
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </div>
                ) : type == "video/mp4" ? (
                  <IconVideo controls>
                    <source src={url_image} type="video/mp4" />
                  </IconVideo>
                ) : (
                  <Icon src={url_image} />
                )
              ) : (
                ""
              )}
              <WhatsappBody>
                Kepada Yang Terhormat,
                <br />
                Guest Name
                <br />
                <br />
                <div
                  style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  Kami dari Eventco telah ditugaskan oleh pasangan:
                  <br />
                  <br />
                  Groom: <b>{groom_name}</b>, Putra dari pasangan{" "}
                  <b>{groom_father}</b> dan <b>{groom_mother}</b>
                  <br />
                  <br />
                  Bride: <b>{bride_name}</b>, Putri dari pasangan{" "}
                  <b>{bride_father}</b> dan <b>{bride_mother}</b>
                  <br />
                  <br />
                  Untuk mendapatkan konfirmasi atas kehadiran Bapak/Ibu pada:
                  Untuk mendapatkan konfirmasi atas kehadiran Bapak/Ibu pada:
                  <br />
                  <br />
                  Nama Acara :<br />
                  <b>{eventName}</b>
                  <br />
                  Hari/Tanggal :<br />
                  <b>
                    {moment(event_date_string)
                      .locale("id")
                      .format("dddd, D MMMM YYYY")}
                  </b>
                  <br />
                  Pukul :<br />
                  <b>
                    {moment(event_date_string).locale("id").format("hh.mm")} -
                    Selesai
                  </b>
                  <br />
                  Lokasi Acara :<br />
                  {event_venue}
                  <br />
                  <br />
                  Alamat :<br />
                  {address}
                </div>
                <br />
                <i>Hormat Kami,</i>
                <br />
                {wa_signature}
              </WhatsappBody>
            </WhatsappContainer>

            <ModalButtonContainer>
              <Button
                small
                kind="primary"
                type="button"
                text="Done"
                onClick={onRequestClose}
              />
            </ModalButtonContainer>
          </InvitationContentContainer>
        )}
      </Scrollbars>
    </Modal>
  );
};

const Invitation = ({ eventTokenCommited, setIsLoading }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  useEffect(() => {
    SVGInject(document.querySelectorAll("img.injectable"));
  }, []);
  const {
    state: { eventDetail },
    dispatch,
  } = useContext(store);
  const [openedCollapsibleId, setOpenedCollapsibleId] = useState(Infinity);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [invitationInfo, setInvitationInfo] = useState({});
  const [tabsOpen, setTabsOpen] = useState("");
  // trigger
  const [isEditHealthCheckProceed, setIsEditHealthCheckProceed] =
    useState(false);
  const [isEditHospitalityCheckProced, setIsEditHospitalityProceed] =
    useState(false);
  const [isEditInvitationProceed, setIsEditInvitationProceed] = useState(false);
  const [isEditWhatsappProceed, setIsEditWhatsappProceed] = useState(false);
  const [isEditEmailProceed, setIsEditEmailProceed] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [uploadImage, setUploadImage] = useState({
    preview: "",
    value: "",
    file: "",
    type: "",
  });
  const [uploadToast, setUploadToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [emailToast, setEmailToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });

  const [whatsappToast, setWhatsappToast] = useState({
    status: false,
    title: "",
    text: "",
    type: "",
  });
  // data

  const [editHealtCheckData, setEditHealtCheckData] = useState(
    forms.setting.HealtCheck
  );
  const [editHospitalityData, setEditHospitalityData] = useState(
    forms.setting.HospitalityCheck
  );
  const [editInvitationData, setEditInvitationData] = useState(
    forms.setting.EInvitation
  );
  const [editWhatsappData, setEditWhatsappData] = useState(
    forms.setting.Whatsapp
  );
  const [editEmailData, setEditEmailData] = useState(forms.setting.Email);
  // const [hastag, setHashtag] = useState(false);
  // functions
  const editHealthCheckStatus = async () => {
    const form = editHealtCheckData;
    const schema = Joi.object({
      health_check_status: Joi.boolean().required(),
    });
    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.invitationSetting.healtCheck"), value)
      .then((res) => {
        dispatch({
          type: ACTIONS.getEventDetail,
          payload: {
            ...eventDetail,
            health_check_status: value?.health_check_status,
          },
        });

        // setWhatsappToast({
        //   status: true, type: 'success',
        //   title: 'WhatsApp Invitation Detail Has Been Updated.',
        //   text: 'Please note that the hashtag can not be changed once the invitation has been sent to guest.',
        // });
        setIsSubmiting(false);
        setIsEditWhatsappProceed(true);
      })
      .catch((err) => {
        err?.response?.data?.m
          ? setWhatsappToast({
              status: true,
              type: "warning",
              title: "Update Failed",
              text: err.response.data.m,
            })
          : setWhatsappToast({
              status: true,
              type: "warning",
              title: "Update Failed",
              text: "Please try again",
            });

        setIsSubmiting(false);
        // error message
      });
  };

  const editHospitalityStatus = async () => {
    const form = editHospitalityData;
    const schema = Joi.object({
      flight_details_status: Joi.boolean().required(),
      accommodation_status: Joi.boolean().required(),
    });
    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.invitationSetting.hospitalityCheck"), value)
      .then((res) => {
        dispatch({
          type: ACTIONS.getEventDetail,
          payload: {
            ...eventDetail,
            flight_details_status: value?.flight_details_status,
            accommodation_status: value?.accommodation_status,
          },
        });

        // setWhatsappToast({
        //   status: true, type: 'success',
        //   title: 'WhatsApp Invitation Detail Has Been Updated.',
        //   text: 'Please note that the hashtag can not be changed once the invitation has been sent to guest.',
        // });
        setIsSubmiting(false);
        setIsEditWhatsappProceed(true);
      })
      .catch((err) => {
        err?.response?.data?.m
          ? setWhatsappToast({
              status: true,
              type: "warning",
              title: "Update Failed",
              text: err.response.data.m,
            })
          : setWhatsappToast({
              status: true,
              type: "warning",
              title: "Update Failed",
              text: "Please try again",
            });

        setIsSubmiting(false);
        // error message
      });
  };

  const editEInvitation = async () => {
    const formData = new FormData();
    formData.append("language", editInvitationData.language);
    if (uploadImage.file !== "") {
      formData.append("image", uploadImage.file);
    }
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.invitationSetting.setting"), formData)
      .then(() => {
        setUploadToast({
          status: true,
          type: "success",
          title: "E-Invitation Settings Saved",
          text: "To change your E-Invitation click delete",
        });
        setIsEditInvitationProceed(true);
        setIsSubmiting(false);
      })
      .catch(() => {
        setIsSubmiting(false);
        // error message
      });
  };

  const editWhatsapp = async () => {
    const form = editWhatsappData;
    form.wa_last_confirmation_string = moment
      .utc(form.wa_last_confirmation_string)
      .format("YYYY-MM-DD");
    form.wa_reminder_date_string = moment
      .utc(form.wa_reminder_date_string)
      .format("YYYY-MM-DD");
    const schema = Joi.object({
      // hash_tag: Joi.string().required(),
      wa_signature: Joi.string(),
      wa_last_confirmation_string: Joi.string().required(),
      wa_reminder_date_string: Joi.string().required(),
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.invitationSetting.whatsapp"), value)
      .then(() => {
        setWhatsappToast({
          status: true,
          type: "success",
          title: "WhatsApp Invitation Detail Has Been Updated.",
          // text: 'Please note that the hashtag can not be changed once the invitation has been sent to guest.',
        });
        setIsSubmiting(false);
        setIsEditWhatsappProceed(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.m) {
          setWhatsappToast({
            status: true,
            type: "warning",
            title: "Update Failed",
            text: err.response.data.m,
          });
        } else {
          setWhatsappToast({
            status: true,
            type: "warning",
            title: "Update Failed",
            text: "Please try again",
          });
        }
        setIsSubmiting(false);
        // error message
      });
  };

  const editEmail = async () => {
    const form = editEmailData;
    form.email_reminder_date_string = moment
      .utc(form.email_reminder_date_string)
      .format("YYYY-MM-DD");
    const schema = Joi.object({
      email_subject: Joi.string().default(""),
      email_salutation: Joi.string().default(""),
      email_body: Joi.string().default(""),
      email_signature: Joi.string().default(""),
      email_reminder_date_string: Joi.string().required(),
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .put(ENDPOINTS("customer.invitationSetting.email"), value)
      .then(() => {
        setIsEditEmailProceed(true);
        setIsSubmiting(false);
        setEmailToast({
          status: true,
          type: "success",
          title: "Email Invitation Detail Has Been Updated.",
          text: "Please note that the signature can not be changed once the invitation has been sent to guest.",
        });
      })
      .catch(() => {
        // error message
        setIsSubmiting(false);
        setEmailToast({
          status: true,
          type: "warning",
          title: "Update Failed",
          text: "Please try again",
        });
      });
  };

  const getSettingData = () => {
    axiosApiInstance
      .get(ENDPOINTS("customer.invitationSetting.setting"))
      .then(
        ({
          data: {
            value: { event, hashTagUpdate },
          },
        }) => {
          setInvitationInfo(event);
          setIsLoading(false);
          setEditWhatsappData({
            // hash_tag: event.hash_tag,
            wa_signature: event.wa_signature,
            wa_last_confirmation_string: event.wa_last_confirmation_string,
            wa_reminder_date_string: event.wa_reminder_date_string,
          });
          setEditEmailData({
            email_salutation: event.email_salutation,
            email_body: event.email_body,
            email_reminder_date_string: event.email_reminder_date_string,
            email_signature: event.email_signature,
            email_subject: event.email_subject,
          });
          setEditInvitationData({
            language: event.language,
            removeImage: false,
            image: event.url_image ? event.url_image : "",
          });
          setEditHealtCheckData({
            health_check_status: event.health_check_status,
          });
          setEditHospitalityData({
            flight_details_status: event.flight_details_status,
            accommodation_status: event.accommodation_status,
          });
          // setHashtag(hashTagUpdate);
          if (event.url_image && event.url_image !== "") {
            setUploadImage({
              preview: event.url_image,
              value: "",
              file: "",
              type: event.document_file_type,
            });
          }
        }
      )
      .catch(() => {
        // error message
      });
  };

  const deleteUploadImage = () => {
    setUploadImage({
      preview: "",
      value: "",
      file: "",
      type: "",
    });
  };

  const doRemoveImage = () => {
    axiosApiInstance
      .put(ENDPOINTS("customer.invitationSetting.removeImage"))
      .then(() => {
        deleteUploadImage();
        getSettingData();
        setUploadToast({
          status: true,
          type: "success",
          title: "Image Deleted",
          text: "",
        });
      })
      .catch(() => {
        setUploadToast({
          status: true,
          type: "warning",
          title: "Fail Delete Image",
          text: "Upload Image Fail",
        });
        // error message
      });
  };

  const uploadingImage = (value) => {
    // if (value.size / 1024 / 1024 > 20) {
    //   setUploadToast({
    //     status: true,
    //     type: "warning",
    //     title: "Upload Failed",
    //     text: "maximum size 20 MB ",
    //   });
    // } else {
      setUploadImage({
        preview: URL.createObjectURL(value),
        value: value.name,
        file: value,
        type: value.type,
      });
    // }
  };

  useEffect(() => {
    if (isEditHealthCheckProceed == true) {
      setIsEditHealthCheckProceed(false);
      getSettingData();
    }
    if (isEditHospitalityCheckProced == true) {
      setIsEditHospitalityProceed(false);
      getSettingData();
    }
    if (isEditInvitationProceed === true) {
      setIsEditInvitationProceed(false);
      getSettingData();
    }

    if (isEditWhatsappProceed === true) {
      setIsEditWhatsappProceed(false);
      getSettingData();
    }

    if (isEditEmailProceed === true) {
      setIsEditEmailProceed(false);
      getSettingData();
    }

    // eslint-disable-next-line
  }, [isEditInvitationProceed, isEditWhatsappProceed, isEditEmailProceed]);

  useEffect(() => {
    if (eventTokenCommited) {
      getSettingData();
    }
    // eslint-disable-next-line
  }, [eventTokenCommited]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <InvitationContainer>
      {uploadToast.status && (
        <ToastBox
          isOpen={uploadToast}
          type={uploadToast.type}
          title={uploadToast.title}
          text={uploadToast.text}
          onRequestClose={() => {
            setUploadToast({ ...uploadToast, status: false });
          }}
        />
      )}

      {emailToast.status && (
        <ToastBox
          isOpen={emailToast}
          type={emailToast.type}
          title={emailToast.title}
          text={emailToast.text}
          onRequestClose={() => {
            setEmailToast({ ...emailToast, status: false });
          }}
        />
      )}

      {whatsappToast.status && (
        <ToastBox
          isOpen={whatsappToast}
          type={whatsappToast.type}
          title={whatsappToast.title}
          text={whatsappToast.text}
          onRequestClose={() => {
            setWhatsappToast({ ...whatsappToast, status: false });
          }}
        />
      )}
      <SendInvitation
        isOpen={showInvitationModal}
        onRequestClose={() => setShowInvitationModal(false)}
        invitationInfo={invitationInfo}
        url_image={uploadImage.preview}
        type={uploadImage.type}
        tabsOpen={tabsOpen}
      />
      <PreviewModal
        isOpen={showPreviewModal}
        onRequestClose={() => {
          setShowPreviewModal(false);
        }}
        image={uploadImage.preview}
        type={uploadImage.type}
      />
      <Body>
        <HeadingContainer>
          <Heading kind="title" text="Invitation Settings" />
        </HeadingContainer>
        <Collapsible
          isNotSideMenu
          id={0}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="health check invitation"
        >
          <Label>Send Health Check Invitation</Label>
          <InvitationLanguageContainer>
            <CheckboxContainer
              onClick={() =>
                setEditHealtCheckData({
                  ...editHealtCheckData,
                  health_check_status: true,
                })
              }
            >
              <Checkbox
                checked={editHealtCheckData.health_check_status === true}
                label="Yes"
              />
            </CheckboxContainer>
            <CheckboxContainer
              onClick={() =>
                setEditHealtCheckData({
                  ...editHealtCheckData,
                  health_check_status: false,
                })
              }
            >
              <Checkbox
                checked={editHealtCheckData.health_check_status === false}
                label="No"
              />
            </CheckboxContainer>
          </InvitationLanguageContainer>
          <ButtonContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Save"
              onClick={editHealthCheckStatus}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </Collapsible>
        <Collapsible
          isNotSideMenu
          id={4}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="HOSPITALITY"
        >
          <Label>Flight Details</Label>
          <InvitationLanguageContainer>
            <CheckboxContainer
              onClick={() =>
                setEditHospitalityData({
                  ...editHospitalityData,
                  flight_details_status: true,
                })
              }
            >
              <Checkbox
                checked={editHospitalityData.flight_details_status === true}
                label="Yes"
              />
            </CheckboxContainer>
            <CheckboxContainer
              onClick={() =>
                setEditHospitalityData({
                  ...editHospitalityData,
                  flight_details_status: false,
                })
              }
            >
              <Checkbox
                checked={editHospitalityData.flight_details_status === false}
                label="No"
              />
            </CheckboxContainer>
          </InvitationLanguageContainer>
          <Label>Accommodation</Label>
          <InvitationLanguageContainer>
            <CheckboxContainer
              onClick={() =>
                setEditHospitalityData({
                  ...editHospitalityData,
                  accommodation_status: true,
                })
              }
            >
              <Checkbox
                checked={editHospitalityData.accommodation_status === true}
                label="Yes"
              />
            </CheckboxContainer>
            <CheckboxContainer
              onClick={() =>
                setEditHospitalityData({
                  ...editHospitalityData,
                  accommodation_status: false,
                })
              }
            >
              <Checkbox
                checked={editHospitalityData.accommodation_status === false}
                label="No"
              />
            </CheckboxContainer>
          </InvitationLanguageContainer>
          <ButtonContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Save"
              onClick={editHospitalityStatus}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </Collapsible>
        <Collapsible
          isNotSideMenu
          id={1}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="E-Invitation"
        >
          <Label>Invitation Language</Label>
          <InvitationLanguageContainer>
            <CheckboxContainer
              onClick={() =>
                setEditInvitationData({
                  ...editInvitationData,
                  language: "Bahasa Indonesia",
                })
              }
            >
              <Checkbox
                checked={editInvitationData.language === "Bahasa Indonesia"}
                label="Bahasa Indonesia"
              />
            </CheckboxContainer>
            <CheckboxContainer
              onClick={() =>
                setEditInvitationData({
                  ...editInvitationData,
                  language: "English",
                })
              }
            >
              <Checkbox
                checked={editInvitationData.language === "English"}
                label="English"
              />
            </CheckboxContainer>
          </InvitationLanguageContainer>

          <Label>Invitation Upload</Label>
          {uploadImage.preview ? (
            uploadImage.type == "application/pdf" ? (
              <UploadPreviewContainer>
                <div
                  style={{
                    width: "100%",
                    height: 370,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Document
                    file={uploadImage.preview}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading=""
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>

                <UploadPreviewOverlay>
                  <UploadPreviewIconContainer>
                    <UploadIcon
                      src={ZoomUploadSVG}
                      style={{ marginLeft: "28px" }}
                      onClick={() => {
                        window.open(uploadImage.preview, "_blank");
                      }}
                    />
                    <UploadIcon src={DeleteUploadSVG} onClick={doRemoveImage} />
                  </UploadPreviewIconContainer>
                </UploadPreviewOverlay>
              </UploadPreviewContainer>
            ) : uploadImage.type == "video/mp4" ? (
              <UploadPreviewContainer>
                <UploadPreviewVideoContainer>
                  <UploadPreviewVideo>
                    <source src={uploadImage.preview} type="video/mp4" />
                  </UploadPreviewVideo>
                </UploadPreviewVideoContainer>
                <UploadPreviewOverlay>
                  <UploadPreviewIconContainer>
                    <UploadIcon
                      src={ZoomUploadSVG}
                      style={{ marginLeft: "28px" }}
                      onClick={() => {
                        setShowPreviewModal(true);
                      }}
                    />
                    <UploadIcon src={DeleteUploadSVG} onClick={doRemoveImage} />
                  </UploadPreviewIconContainer>
                </UploadPreviewOverlay>
              </UploadPreviewContainer>
            ) : (
              <UploadPreviewContainer>
                <UploadPreviewImage
                  src={uploadImage.preview}
                  width="100%"
                  height="100%"
                />
                <UploadPreviewOverlay>
                  <UploadPreviewIconContainer>
                    <UploadIcon
                      src={ZoomUploadSVG}
                      style={{ marginLeft: "28px" }}
                      onClick={() => {
                        setShowPreviewModal(true);
                      }}
                    />
                    <UploadIcon src={DeleteUploadSVG} onClick={doRemoveImage} />
                  </UploadPreviewIconContainer>
                </UploadPreviewOverlay>
              </UploadPreviewContainer>
            )
          ) : (
            <UploadContainer>
              <UploadLabel htmlFor="invitation-upload">
                <UploadInvitation>
                  <Icon src={UploadSVG} />
                  <b>Cick to add E-Invitation Image/Video</b>
                  Use a high quality image/video: 1080x540px (2:1 ratio). JPEG
                  or PNG or Video, no larger than 20MB
                </UploadInvitation>
              </UploadLabel>
              <UploadInput
                id="invitation-upload"
                value={uploadImage.value}
                type="file"
                onChange={(event) => {
                  let file = event.target.files[0];
                  uploadingImage(file);
                }}
                accept="image/x-png,image/jpeg,application/pdf,video/mp4"
              />
            </UploadContainer>
          )}

          <ButtonContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Save"
              onClick={editEInvitation}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </Collapsible>
        <Collapsible
          isNotSideMenu
          id={2}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="Whatsapp"
        >
          <InputContainer>
            <DatePicker
              label="Last Confirmation"
              value={moment
                .utc(editWhatsappData.wa_last_confirmation_string)
                .format("MM-DD-YYYY")}
              onChange={(value) =>
                setEditWhatsappData({
                  ...editWhatsappData,
                  wa_last_confirmation_string: value,
                })
              }
            />
            <DatePicker
              label="Reminder Date"
              value={moment
                .utc(editWhatsappData.wa_reminder_date_string)
                .format("MM-DD-YYYY")}
              onChange={(value) =>
                setEditWhatsappData({
                  ...editWhatsappData,
                  wa_reminder_date_string: value,
                })
              }
            />
          </InputContainer>

          <Input
            type="text"
            label={
              <>
                Signature
                <ToolTip>
                  <Icon src={InfoSVG} className="injectable" />
                  <ToolTipText>
                    Contains the name of the event owner. Make sure it is
                    written correctly since it will be displayed on Whatsapp and
                    Email Invitation. Once the invitation has been sent, the
                    signature can't be changed anymore.
                  </ToolTipText>
                </ToolTip>
              </>
            }
            name="signature"
            placeholder="Budi & Rizka"
            value={editWhatsappData.wa_signature}
            onChange={(value) =>
              setEditWhatsappData({ ...editWhatsappData, wa_signature: value })
            }
          />

          {/* <Input // readonly
            disabled={!hastag}
            value={editWhatsappData.hash_tag}
            onKeyDown={((e) => {
              if (e.keyCode == 51 || e.keyCode == 32) {
                e.preventDefault();
              }
            })}
            onChange={(value) => setEditWhatsappData({ ...editWhatsappData, hash_tag: value })}
            type="text"
            label={(
              <>
                Hashtag
                <ToolTip>
                  <Icon src={InfoSVG} className="injectable" />
                  <ToolTipText>
                    Make sure it is written correctly since it will be displayed on Whatsapp and Email Invitation. Once the invitation has been sent, the hashtag can't be changed anymore.
                    {' '}
                  </ToolTipText>
                </ToolTip>
              </>
            )}
            name="hashtag"
            placeholder="TheWeddingofBudiIsmanandRizka"

          /> */}
          <ButtonContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Preview"
              onClick={() => {
                setTabsOpen("whatsapp");
                setShowInvitationModal(true);
              }}
            />
            <Button
              small
              kind="primary"
              type="button"
              text="Save"
              onClick={editWhatsapp}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </Collapsible>
        <Collapsible
          isNotSideMenu
          id={3}
          openedId={openedCollapsibleId}
          openId={setOpenedCollapsibleId}
          title="Email"
        >
          <InputContainer>
            <DatePicker
              label="Reminder Date"
              value={moment
                .utc(editEmailData.email_reminder_date_string)
                .format("MM-DD-YYYY")}
              onChange={(value) =>
                setEditEmailData({
                  ...editEmailData,
                  email_reminder_date_string: value,
                })
              }
            />
            <Input
              type="text"
              label="Salutation"
              name="salutation"
              value={editEmailData.email_salutation}
              onChange={(value) =>
                setEditEmailData({ ...editEmailData, email_salutation: value })
              }
            />
          </InputContainer>
          <Input
            type="text"
            label="Email Subject"
            name="emailSubject"
            placeholder="The Wedding of Budi Isman and Rizka"
            value={editEmailData.email_subject}
            onChange={(value) =>
              setEditEmailData({ ...editEmailData, email_subject: value })
            }
          />
          <Input
            type="text"
            label="Body Message"
            name="bodyMessage"
            placeholder="It's a great pleasure to invite you to our wedding!"
            value={editEmailData.email_body}
            onChange={(value) =>
              setEditEmailData({ ...editEmailData, email_body: value })
            }
          />
          <Input
            type="text"
            label={
              <>
                Signature
                <ToolTip>
                  <Icon src={InfoSVG} />
                  <ToolTipText>
                    Contains the name of the event owner. Make sure it is
                    written correctly since it will be displayed on Whatsapp and
                    Email Invitation. Once the invitation has been sent, the
                    signature can't be changed anymore.
                  </ToolTipText>
                </ToolTip>
              </>
            }
            name="signature"
            placeholder="Budi & Rizka"
            value={editEmailData.email_signature}
            onChange={(value) =>
              setEditEmailData({ ...editEmailData, email_signature: value })
            }
          />
          <ButtonContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Preview"
              onClick={() => {
                setTabsOpen("email");
                setShowInvitationModal(true);
              }}
            />
            <Button
              small
              kind="primary"
              type="button"
              text="Save"
              onClick={editEmail}
              disabled={isSubmiting}
              isLoading={isSubmiting}
            />
          </ButtonContainer>
        </Collapsible>
      </Body>
    </InvitationContainer>
  );
};

const { bool, func } = PropTypes;

Invitation.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default Invitation;
