import styled from 'styled-components';

export const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
`;

export const Icon = styled.img``;

export const DefaultImage = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  background-color: #096B81;
  ${Icon} {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position:absolute;
  }
`;

export const SourceVideo = styled.source`
`;
