import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'shared/styles';

const LinkComponent = ({ to, text, onClick }) => (
  <Link.Link
    to={to}
    onClick={onClick}
  >
    {text}
  </Link.Link>
);

const { string, func } = PropTypes;

LinkComponent.propTypes = {
  to: string,
  text: string,
  onClick: func,
};

LinkComponent.defaultProps = {
  to: '',
  text: '',
  onClick: () => {},
};

export default LinkComponent;
