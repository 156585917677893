import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { store } from "store";
import PropTypes from "prop-types";
import moment from "moment";
import ENDPOINTS from "helpers/endpoints";
import { axiosApiInstance } from "helpers/authentication";
import {
  Container,
  ContainerRight,
  Icon,
  CloseButton,
  Content,
  ContentTitle,
  Label,
  Title,
  ChartContent,
  ButtonContainer,
  GridDashboard,
  Menu,
  OverflowTableControl,
  Category,
} from "pages/food-information/style";
import CircleGoldSVG from "assets/icons/circle-gold.svg";
import CircleDarkGreenSVG from "assets/icons/circle-dark-green.svg";
import CircleBlueSVG from "assets/icons/circle-blue.svg";
import CirclePurpleSVG from "assets/icons/circle-purple.svg";
import CircleVioletSVG from "assets/icons/circle-violet.svg";
import CircleGreenSVG from "assets/icons/circle-green.svg";
import CircleYellowLowSVG from "assets/icons/circle-yellow-low.svg";
import CircleGreenStandardSVG from "assets/icons/circle-greeen-standard.svg";
import CirclePinkSVG from "assets/icons/circle-pink.svg";
import CircleToscaBlueSVG from "assets/icons/circle-tosca-blue.svg";
import Back2SVG from "assets/icons/back-black.svg";

import { FoodPieChart, FoodCard, BoothDetail, Table } from "components";
import { map } from "lodash";
import { object } from "joi";

const FoodInformation = ({ setIsLoading }) => {
  const history = useHistory();
  const { state } = useContext(store);
  const { eventDetail } = state;
  const [openModal, setOpenModal] = useState(false);
  const [foodInformation, setFoodInformation] = useState([]);
  const [foodTitle, setFoodTitle] = useState([]);
  const [title, setTitle] = useState("");
  const [foodData, setFoodData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [cardCount, setCardCount] = useState([]);
  const openModalFood = (value, index) => {
    const temp = [];
    value.data.forEach((item) => {
      temp.push({
        guests: item.name,
        category: item.invitation.category[0]?.name,
        invitation: item.invitation.name,
        table: item.table?.name,
      });
    });

    setOpenModal(true);
    setFoodData(temp);
    setTitle(index);
  };

  const { name: eventName } = eventDetail;

  useEffect(() => {
    setIsLoading(true);
    const getFoodInformation = () => {
      axiosApiInstance
        .get(`${ENDPOINTS("customer.dashboard.foodCompiling")}`)
        .then((res) => {
          setIsLoading(false);
          const titles = Object.keys(res.data.value.foodCompile);
          titles[0] = "Belum Konfirmasi";
          setFoodTitle(titles);

          let food = [];
          let count = [];

          for (
            let i = 0;
            i < Object.keys(res.data.value.foodCompile).length;
            i++
          ) {
            food.push(
              res.data.value.foodCompile[
                Object.keys(res.data.value.foodCompile)[i]
              ]
            );
            count.push(
              res.data.value.foodCompile[
                Object.keys(res.data.value.foodCompile)[i]
              ]?.count
            );
          }
          setFoodInformation(food);
          setCardCount(count);
        });
    };
    getFoodInformation();
  }, []);

  const dataTable = foodData.filter((item) => {
    if (searchData) {
      return (
        Object.values(item).join("").toLowerCase().indexOf(searchData) > -1
      );
    }
    return item;
  });

  const columns = [
    {
      food: [
        {
          title: "No",
          position: "",
          width: "50px",
          render: (rowData, index) => <span>{index + 1}</span>,
        },
        {
          title: "Invitation Name",
          position: "",
          width: "200px",
          render: (rowData) => <span>{rowData.invitation}</span>,
        },
        {
          title: "Guest Name",
          position: "",
          width: "100px",
          render: (rowData) => <span>{rowData.guests}</span>,
        },
        {
          title: "Invitation Category",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <Category category={rowData.category || "-"}>
              {rowData.category || "-"}
            </Category>
          ),
        },
        {
          title: "Table",
          position: "center",
          width: "100px",
          render: (rowData) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              {rowData.table || "-"}
            </div>
          ),
        },
      ],
    },
  ];

  const cardData = [
    CircleGoldSVG,
    CircleDarkGreenSVG,
    CircleBlueSVG,
    CirclePurpleSVG,
    CircleVioletSVG,
    CircleGreenSVG,
    CircleToscaBlueSVG,
    CirclePinkSVG,
    CircleGreenStandardSVG,
    CircleYellowLowSVG,
    CircleGoldSVG,
    CircleDarkGreenSVG,
    CircleBlueSVG,
    CirclePurpleSVG,
    CircleVioletSVG,
    CircleGreenSVG,
    CircleToscaBlueSVG,
    CirclePinkSVG,
    CircleGreenStandardSVG,
    CircleYellowLowSVG,
    CircleGoldSVG,
    CircleDarkGreenSVG,
    CircleBlueSVG,
    CirclePurpleSVG,
    CircleVioletSVG,
    CircleGreenSVG,
    CircleToscaBlueSVG,
    CirclePinkSVG,
    CircleGreenStandardSVG,
    CircleYellowLowSVG,
  ];

  return (
    <>
      <BoothDetail
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
          setSearchData("");
        }}
        icon={cardData[title]?.icon}
        title={foodTitle[title]}
        onChange={(e) => {
          setSearchData(e.target.value);
        }}
      >
        {dataTable ? (
          <OverflowTableControl>
            <Table readOnly={true} cols={columns[0].food} data={dataTable} />{" "}
          </OverflowTableControl>
        ) : null}
      </BoothDetail>

      <Menu>
        <Container>
          <GridDashboard gridTemplateColumn={"4fr 4fr"}>
            {foodInformation.map((item, index) => {
              return (
                <FoodCard
                  key={index}
                  text={item.count}
                  isDashboard
                  icon={cardData[index]}
                  subTitle={foodTitle[index]}
                  onClick={() => openModalFood(item, index)}
                />
              );
            })}
          </GridDashboard>
        </Container>
        <ContainerRight>
          <ButtonContainer>
            <CloseButton onClick={() => history.push("/event/dashboard")}>
              <Icon src={Back2SVG} />
              Dashboard
            </CloseButton>
          </ButtonContainer>
          <Content>
            <ContentTitle>
              <Label>Guest Food Detail</Label>
              <Title>{eventName}</Title>
            </ContentTitle>
            <ChartContent>
              <FoodPieChart data={cardCount} />
            </ChartContent>
          </Content>
        </ContainerRight>
      </Menu>
    </>
  );
};

const { func } = PropTypes;

FoodInformation.propTypes = {
  setIsLoading: func,
};

FoodInformation.defaultProps = {
  setIsLoading: () => {},
};

export default FoodInformation;
