import styled, { css } from 'styled-components';

export default {
  Button: styled.button`
    cursor: pointer;
    font-family: 'GT Walsheim Pro', sans-serif;
    font-weight: 500;
    font-size: ${((props) => (props.small && '0.875rem') || '1rem')};
    border: 0;
    border-radius: ${((props) => (props.small && '0.5rem') || '0.625rem')};
    padding: 0.75rem 2.375rem;
    line-height: ${((props) => (props.small && '1.25rem') || '1.5rem')};
    letter-spacing: 0.0125rem;
    height: ${((props) => (props.small && '2.25rem') || '3rem')};
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) => props.kind === 'primary' && css`
      background-color: ${props.theme.form.button.background.primary};
      color: ${props.theme.form.button.text.primary};
      
      &:hover {
        background-color: ${props.theme.form.button.background.hover};
      }

      &:disabled {
        background-color: ${(props.isLoading && props.theme.form.button.background.primary) || props.theme.form.button.background.disabled};
        color: ${(props.isLoading && props.theme.form.button.text.primary) || props.theme.form.button.text.disabled};
      }
    `}

    ${(props) => props.kind === 'warning' && css`
      background-color: #E76E6E;
      color: ${props.theme.form.button.text.primary};

      &:hover {
        background-color: #DA6161;
      }

      &:disabled {
        background-color: ${(props.isLoading && '#E76E6E') || props.theme.form.button.background.disabled};
        color: ${(props.isLoading && props.theme.form.button.text.primary) || props.theme.form.button.text.disabled};
      }
    `}

    ${(props) => props.kind === 'secondary' && css`
      background-color:${props.active ? '#B5E0EA' : 'transparent'};
      color: ${props.active ? props.theme.form.button.text.hover : props.theme.form.button.text.secondary};
      border: 0.09375rem solid ${props.active ? '#B5E0EA' : props.theme.form.button.text.secondary};

      &:hover {
        color: ${props.theme.form.button.text.hover};
      }

      &:disabled {
        background-color: transparent;
        border-color: ${props.theme.form.button.border.disabled};
      }
    `}

    ${(props) => props.icon && css`
      img {
        position: relative;
        margin-right: 0.375rem;
      }
    `}

    ${(props) => props.kind === 'icon' && css`
      background-color: ${props.theme.form.button.background.icon.normal};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 2.25rem;
      height: 2.25rem;

      img {
        margin: 0;
      }

      &:hover {
        background-color: ${props.theme.form.button.background.icon.hover};
      }
    `}

  `,
  Icon: styled.img``,
};
