/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { store, ACTIONS } from 'store';
import {
  Heading,
  Button,
  Input,
} from 'shared';
import {
  Brand,
  LoadingBar,
  ProfileButton,

  Map,
  DatePicker,
  TimePicker,
} from 'components';
import {
  Container,
  HeadingContainer,
  Group,
  Form,
  InputsContainer,
  InputsContainerGrid,
  InputsContainerLocation,
  InputContainer,
  FooterContainer,
  MapContainer,
  ToolTip,
  ToolTipText,
  FooterContent,
  Icon,
} from 'pages/events/steps/03/style';
import InfoSVG from 'assets/icons/info.svg';
import {
  getAutoComplete,
  geoCode
} from 'helpers/dataProvider';
import Step3of4SVG from 'assets/images/3-of-4.svg';

const Step3 = ({ setStep }) => {
  const { state, dispatch } = useContext(store);
  const [category, setCategory] = useState('');
  const [eventName, setEventName] = useState('');
  const [groomName, setGroomName] = useState('');
  const [groomMotherName, setGroomMotherName] = useState('');
  const [groomFatherName, setGroomFatherName] = useState('');
  const [brideName, setBrideName] = useState('');
  const [brideMotherName, setBrideMotherName] = useState('');
  const [brideFatherName, setBrideFatherName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [eventVenue, setEventVenue] = useState('');
  const [address, setAddress] = useState('');
  const [eventCity, setEventCity] = useState('');
  const [lat, setLat] = useState(-6.17540);
  const [lng, setLng] = useState(106.82719);
  const [lngLat, setLngLat] = useState([106.82719, -6.17540])
  const [autoCompleteList, setAutoCompleteList] = useState([]);

  const [searchMap, setSearchMap] = useState('');

  const [timerOnchange, setTImerOnchange] = useState();

  const setValue = (value) => {
    setSearchMap(value);
    clearTimeout(timerOnchange)
    const timer = setTimeout(async () => {
      if (searchMap) {
        const list = await getAutoComplete(value)
        setAutoCompleteList(list)
      }
    }, 500)
    setTImerOnchange(timer)
  }

  const selectedList = async (item) => {
    setSearchMap(item.label);
    setAddress(item.label);
    const results = await geoCode(item.label);
    const location = results?.geometry?.location
    setLng(location?.lng);
    setLat(location?.lat);
    setLngLat([location?.lng, location?.lat])
    setEventCity(results?.address_components.find(item => item.types.includes("administrative_area_level_2"))?.long_name ||
      results?.address_components.find(item => item.types.includes("administrative_area_level_1"))?.long_name || '')
  };



  useEffect(() => {
    if (_.has(state.eventForm, 'groom_name')) {
      setEventName(state.eventForm.name);
      setGroomName(state.eventForm.groom_name);
      setGroomMotherName(state.eventForm.groom_mother);
      setGroomFatherName(state.eventForm.groom_father);
      setBrideMotherName(state.eventForm.bride_mother);
      setBrideFatherName(state.eventForm.bride_father);
      setBrideName(state.eventForm.bride_name);
      setEventDate(state.eventForm.event_date_string);
      setEventTime(state.eventForm.event_time);
      setEventVenue(state.eventForm.event_venue);
      setAddress(state.eventForm.address);
      setEventCity(state.eventForm.event_city);
      setLat(state.eventForm.latitude);
      setLng(state.eventForm.longitude);
      setLngLat(state.eventForm.lngLat || [106.82719, -6.17540]);
      setCategory(state.eventForm.category);
    } else {
      setEventName(state.eventForm.name);
      setEventDate(state.eventForm.event_date_string);
      setEventTime(state.eventForm.event_time);
      setEventVenue(state.eventForm.event_venue);
      setAddress(state.eventForm.address);
      setEventCity(state.eventForm.event_city);
      setLat(state.eventForm.latitude);
      setLng(state.eventForm.longitude);
      setCategory(state.eventForm.category);
      setLngLat(state.eventForm.lngLat || [106.82719, -6.17540]);
    }
  }, [state]);

  const dispatchEventForm = () => {
    dispatch({
      type: ACTIONS.createEvent,
      payload: {
        groom_name: groomName,
        bride_name: brideName,
        event_date_string: eventDate,
        event_time: eventTime,
        event_venue: eventVenue,
        address:address,
        event_city: eventCity,
        latitude: lat,
        longitude: lng,
        lngLat: lngLat,
        name: eventName,
        groom_mother: groomMotherName,
        groom_father: groomFatherName,
        bride_mother: brideMotherName,
        bride_father: brideFatherName
      },
    });
  };

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <HeadingContainer>
          <Heading
            kind="title"
            text="Event Details"
          />
        </HeadingContainer>
        <Form>
          <Group>Basic Info</Group>
          <InputsContainerGrid>
            <InputContainer>
              <Input
                type="text"
                label="Event Name"
                name="eventname"
                value={eventName}
                onChange={setEventName}
                placeholder="Type event name"
              />
            </InputContainer>
          </InputsContainerGrid>
          {category === 'Wedding' || category === 'Holy Matrimony'
            ? (
              <InputsContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Groom's Full Name"
                    name="groomsfullname"
                    value={groomName}
                    onChange={setGroomName}
                    placeholder="Type groom's fullname"
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Bride's Full Name"
                    name="bridesfullname"
                    value={brideName}
                    onChange={setBrideName}
                    placeholder="Type bride's fullname"
                  />
                </InputContainer>
              </InputsContainer>
            ) : ''}

          <InputsContainer style={{ marginBottom: '20px' }}>
            <InputContainer>
              <DatePicker
                isEmpty
                label="Event Date"
                value={eventDate}
                onChange={setEventDate}
              />
            </InputContainer>
            <InputContainer>
              <TimePicker
                isEmpty
                value={eventTime}
                label="Event Time"
                onChange={setEventTime}
              />
            </InputContainer>
          </InputsContainer>

          {category === 'Wedding' || category === 'Holy Matrimony'
            ? (
              <InputsContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Groom Father's Name"
                    name="groomfathersname"
                    value={groomFatherName}
                    onChange={setGroomFatherName}
                    placeholder="Type groom father's name"
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Groom Mother's Name"
                    name="groommothersname"
                    value={groomMotherName}
                    onChange={setGroomMotherName}
                    placeholder="Type groom mother's name"
                  />
                </InputContainer>
              </InputsContainer>
            ) : ''}

          {category === 'Wedding' || category === 'Holy Matrimony'
            ? (
              <InputsContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Bride Father’s Name"
                    name="bridefathersname"
                    value={brideFatherName}
                    onChange={setBrideFatherName}
                    placeholder="Type bride father's name"
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Bride Mother’s Name"
                    name="bridemothersname"
                    value={brideMotherName}
                    onChange={setBrideMotherName}
                    placeholder="Type bride mother's name"
                  />
                </InputContainer>
              </InputsContainer>
            ) : ''}
          <Group style={{ marginTop: '2.5rem' }}>Location</Group>
          <InputsContainerLocation>
            <Input
              type="text"
              label={(
                <>
                  Venue Detail
                  <ToolTip>
                    <Icon src={InfoSVG} className="injectable" />
                    <ToolTipText>
                      The exact function room detail where the event held
                      <br />
                      e.g. Main Ballroom, Grand Ballroom
                    </ToolTipText>
                  </ToolTip>
                </>
              )}
              name="venuedetail"
              value={eventVenue}
              onChange={setEventVenue}
              placeholder="Type venue detail"
            />
            <Input
              type="text"
              label="Address"
              name="address"
              value={searchMap}
              onChange={setValue}
              placeholder="Search venue address"
              isAutoComplete
              autoCompleteList={autoCompleteList}
              selectedList={selectedList}
            />
            <MapContainer>
              <Map
                longLat={lngLat}
                setLng={setLng}
                setLat={setLat}
                setLngLat={setLngLat}
                setEventCity={setEventCity}
                setSearchMap={setSearchMap}
                setAddress={setAddress}
              />
            </MapContainer>
          </InputsContainerLocation>
        </Form>
        <FooterContainer>
          <FooterContent>
            <Button
              small
              kind="secondary"
              type="button"
              text="Back"
              onClick={() => {
                dispatchEventForm();
                setStep((prevState) => prevState - 1);
              }}
            />
            <Icon src={Step3of4SVG} style={{ width: '328px', height: '28px', margin: 'auto' }} />
            <Button
              small
              kind="primary"
              type="button"
              text="Next"
              onClick={() => {
                dispatchEventForm();
                setStep((prevState) => prevState + 1);
                // setIsModalOpen(true);
              }}
              disabled={
                ((category === 'Wedding' || category === 'Holy Matrimony') && !groomName) || ((category === 'Wedding' || category === 'Holy Matrimony') && !brideName) || (!eventName) || !eventDate || !eventTime
                || !eventVenue || !eventCity || !address || !lat || !lng
              }
            />
          </FooterContent>

        </FooterContainer>
      </Container>
    </>
  );
};

const { bool, func } = PropTypes;

Step3.propTypes = {
  setStep: func.isRequired,
};

export default Step3;
