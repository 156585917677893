/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'shared';
import {
  SideMenu,
  Checkbox,
} from 'components';
import {
  StatusContainer,
  SelectionContainer,
  SelectionItem,
  SelectionLabel,
} from 'pages/admin/dashboard-admin/event-details/sidemenu/edit-event/style';
import AddGuestSVG from 'assets/icons/add-guest.svg';

const EditEventSideMenu = ({
  isOpen,
  onRequestClose,
  eventDetail,
  editEvent,
  isSubmiting,
}) => {
  // const [openedCollapsibleId, setOpenedCollapsibleId] = useState(0);

  // data
  const [form, setForm] = useState({ type: '', maximum_capacity: 0 });

  // seated // standing
  useEffect(() => {
    // setOpenedCollapsibleId(0);
    const {
      type,
      maximum_capacity,
    } = eventDetail;
    setForm({ type: type && type.toLowerCase(), maximum_capacity });
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <SideMenu
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      finishFunc={() => editEvent(form)}
      title="Edit Event Details"
      buttonText="Edit"
      icon={AddGuestSVG}
      isLoading={isSubmiting}
      disabled={isSubmiting}
    >
      <StatusContainer>
        Event Type
        <SelectionContainer>
          <SelectionItem>
            <Checkbox
              checked={form.type === 'standing'}
              onChange={() => { setForm({ ...form, type: 'standing' }); }}
            />
            <SelectionLabel>
              Standing Party
            </SelectionLabel>
          </SelectionItem>
          <SelectionItem>
            <Checkbox
              checked={form.type === 'seated'}
              onChange={() => { setForm({ ...form, type: 'seated' }); }}
            />
            <SelectionLabel>
              Table Party
            </SelectionLabel>
          </SelectionItem>
        </SelectionContainer>
      </StatusContainer>
      <Input
        type="number"
        label="Event Capacity"
        name="eventCapacity"
        placeholder="Placeholder"
        value={form.maximum_capacity}
        onChange={(value) => setForm({ ...form, maximum_capacity: value })}
      />
    </SideMenu>
  );
};

const { bool, func } = PropTypes;

EditEventSideMenu.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default EditEventSideMenu;
