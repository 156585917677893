import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  ToastBoxPosition,
  ToastBoxContainer,
  ToastBoxTitle,
  ToastBoxText,
  ToastBoxContent,
  CloseIcon,
} from 'components/toast-box/style';
import OutsideClickHandler from 'react-outside-click-handler';
import UnapproveSVG from 'assets/icons/unapproved.svg';
import ApproveSVG from 'assets/icons/approve.svg';
import ExitSVG from 'assets/icons/exit-mbox.svg';

const ToastBox = ({
  title, text, onRequestClose, type, isOpen,
}) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        onRequestClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <OutsideClickHandler onOutsideClick={onRequestClose}>
      <ToastBoxPosition>
        <ToastBoxContainer
          onClick={onRequestClose}
          type={type}
        >
          <Icon src={(type === 'warning' && UnapproveSVG) || (type === 'success' && ApproveSVG)} />
          <ToastBoxContent>
            <ToastBoxTitle type={type}>
              {title}
            </ToastBoxTitle>
            <ToastBoxText>
              {text}
            </ToastBoxText>
          </ToastBoxContent>
          <CloseIcon>
            <Icon src={ExitSVG} />
          </CloseIcon>
        </ToastBoxContainer>
      </ToastBoxPosition>
    </OutsideClickHandler>
  );
};

const { func, string, bool } = PropTypes;

ToastBox.propTypes = {
  title: string,
  text: string,
  onRequestClose: func,
  type: string,
  isOpen: bool,
};

ToastBox.defaultProps = {
  title: '',
  text: '',
  onRequestClose: () => {},
  type: 'default',
  isOpen: false,
};

export default ToastBox;
