import React from 'react';
import { Confetti } from 'components/decoratives/confetti/style';
import ConfettiSVG from 'assets/images/confetti.svg';

const ConfettiComponent = () => (
  <Confetti
    src={ConfettiSVG}
    alt="Confetti"
  />
);

export default ConfettiComponent;
