/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Button,
  Input,
} from 'shared';
import {
  Modal,
  BadgeSelect,
  TableButton,
  DatePicker,
  TimePicker,
  Map
} from 'components';
import {
  Icon,
  ModalHeader,
  ModalHeaderTitle,
  TableButtonContainer,
  IconClose,
  HealthCheckContainer,
  HealthCheckButtonContainer,
  HCInputContainer,
  HCInputGridContainer,
  MapContainer,
} from 'pages/guest-list/floating-menu/style';
import InfoTableSVG from 'assets/icons/info-table.svg';
import WhatsappSmallSVG from 'assets/icons/whatsapp-small.svg';
import EmailSmallSVG from 'assets/icons/email-small.svg';
import ExitSVG from 'assets/icons/exit.svg';
import {
  getAutoComplete,
  geoCode
} from 'helpers/dataProvider';

const InfoModal = ({
  isOpen,
  onRequestClose,
  data,
  sendCovid19,
  isSubmiting
}) => {
  // const [categories, setCategories] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [summaryData, setSummaryData] = useState([]);
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [timerOnchange, setTImerOnchange] = useState();
  const [searchMap, setSearchMap] = useState('');
  const [lngLat, setLngLat] = useState([106.82719, -6.17540])
  const [formCovid, setFormCovid] = useState({
    test_time: '',
    test_date: '',
    address: '',
    latitude: -6.17540,
    longitude: 106.82719,
    event_city: '',
    address_detail: '',
  })
  const doNext = () => {
    if (pageNumber === selectedTable.length - 1) {
      setPageNumber(0);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  const doBack = () => {
    if (pageNumber === 0) {
      setPageNumber(selectedTable.length - 1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  };

  const setValue = (value) => {
    setSearchMap(value);
    clearTimeout(timerOnchange)
    const timer = setTimeout(async () => {
      if (searchMap) {
        const list = await getAutoComplete(value)
        setAutoCompleteList(list)
      }
    }, 500)
    setTImerOnchange(timer)
  }

  const selectedList = async (item) => {
    const results = await geoCode(item.label);
    const location = results?.geometry?.location;
    console.log(formCovid)
    setFormCovid({
      ...formCovid,
      address: item.label,
      latitude: location?.lat,
      longitude: location?.lng,
    });
    setSearchMap(item.label);
    setLngLat([location?.lng, location?.lat])
  };

  useEffect(() => {
    // setCategories(res.map((c) => ({
    //   ...c, select: true,
    // })));

    const tableSelected = data.reduce((acc, curr) => {
      if (curr.checked) {
        const categories = curr.categories.reduce((acc2, curr2) => {
          acc2.push({ name: curr2, select: true });

          return acc2;
        }, []);
        acc.push({ ...curr, categories });
      }

      return acc;
    }, []);

    setSelectedTable(tableSelected);
  }, [data]);

  useEffect(() => {
    if (selectedTable.length) {
      const {
        address = '',
        categories,
        email,
        email_invitation_status: emailInvitationStatus,
        guestNames,
        invitation_status: invitationStatus,
        name,
        notes,
        pax,
        phone,
        phone_code: phoneCode,
        wa_invitation_status: waInvitationStatus,
        order,
      } = selectedTable[pageNumber];

      // eslint-disable-next-line no-shadow
      const summaryData = [
        {
          fieldName: 'Invitation Status',
        },
        {
          fieldName: invitationStatus ? 'Sent Via' : '',
          value: invitationStatus
            ? (
              <TableButtonContainer>
                {waInvitationStatus && (
                  <TableButton
                    text="Whatsapp"
                    icon={WhatsappSmallSVG}
                  />
                )}
                {emailInvitationStatus && (
                  <TableButton
                    text="Email"
                    icon={EmailSmallSVG}
                  />
                )}
              </TableButtonContainer>
            ) : 'Hasn’t been sent',
        },
        {
          fieldName: 'Invitation Detail',
        },
        {
          fieldName: 'Guest ID',
          value: order,
        },
        {
          fieldName: 'Invitation Name',
          value: name,
        },
        {
          fieldName: 'Phone Number',
          value: `${phoneCode}${phone}`,
        },
        {
          fieldName: 'Email',
          value: email,
        },
        {
          fieldName: 'Address',
          value: address,
        },
        {
          fieldName: 'Notes',
          value: notes,
        },
        {
          fieldName: 'Guest Detail',
        },
        {
          fieldName: 'Total Pax',
          value: pax,
        },
        ...guestNames.reduce((acc, curr, index) => {
          acc.push({
            fieldName: `Guest Name ${index + 1}`,
            value: curr,
          });

          return acc;
        }, [selectedTable]),
        {
          fieldName: 'Guest Category',
        },
        {
          value: <BadgeSelect data={categories} />,
        },
      ];

      setSummaryData(summaryData);
    }
  }, [selectedTable, pageNumber]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: '820px',
        },
      }}
    >
      <IconClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalHeader>
        <Icon src={InfoTableSVG} />
        <ModalHeaderTitle>
          Health Check Invitation
        </ModalHeaderTitle>
      </ModalHeader>
      <HealthCheckContainer>
        <HCInputContainer>
          <HCInputGridContainer>
            <DatePicker
              label="Test Date"
              placeholder="Choose Date"
              isEmpty
              value={formCovid?.test_date}
              onChange={(value) => setFormCovid({ ...formCovid, test_date: value })}
            />

            <TimePicker
              isEmpty
              value={formCovid?.test_time}
              label="Test Time"
              onChange={(value) => setFormCovid({ ...formCovid, test_time: value })}
            />
          </HCInputGridContainer>
          <div>
            <Input
              type="text"
              label="Address Detail"
              name="addressdetail"
              value={formCovid?.address_detail}
              onChange={(value) => setFormCovid({ ...formCovid, address_detail: value })}
              placeholder="Search address detail"
            />

            <Input
              type="text"
              label="Address"
              name="address"
              value={searchMap}
              onChange={setValue}
              placeholder="Search address"
              isAutoComplete
              autoCompleteList={autoCompleteList}
              selectedList={selectedList}
            />
          </div>
          <MapContainer>
            <Map
              longLat={lngLat}
              setAll={(value) => {
                console.log(formCovid)
                setFormCovid((prevState) => {
                  return {
                    ...prevState,
                    address: value?.address,
                    latitude: value?.lat,
                    longitude: value?.lng,
                    event_city: value?.region
                  }
                })
              }}
              setSearchMap={setSearchMap}
              setLngLat={setLngLat}
            />
          </MapContainer>
        </HCInputContainer>
        <HealthCheckButtonContainer>
          <Button
            small
            kind="primary"
            type="button"
            text="Send Health Check Invitation"
            onClick={() => sendCovid19(selectedTable, formCovid)}
            disabled={isSubmiting}
            isLoading={isSubmiting}
          />
        </HealthCheckButtonContainer>
      </HealthCheckContainer>


    </Modal>
  );
};

const {
  bool,
  func,
  shape,
  arrayOf,
  string,
} = PropTypes;

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf(shape({})),
  isSubmiting: bool
};

InfoModal.defaultProps = {
  data: [],
  isSubmiting: false,
};

export default InfoModal;
