import styled from "styled-components";

export const Menu = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const BallDecoration = styled.img`
  position: absolute;
  bottom: 2.735625rem;
  left: 0;
`;

export const ConfettiDecoration = styled.img`
  position: absolute;
  bottom: 0;
  left: 7.5rem;
`;

export const Icon = styled.img``;

export const Container = styled.div`
  position: fixed;
  box-sizing: border-box;
  display: block;
  width: 45%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.fullreport.background};
  padding: 2.4rem 2.4rem;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ContainerRight = styled.div`
  position: fixed;
  box-sizing: border-box;
  display: block;
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  padding: 3rem 6.25rem;
  z-index: 2;
  overflow: hidden;
`;

export const Body = styled.div`
  position: relative;
  width: 67.5rem;
`;
export const CloseButton = styled.div`
  display: flex;
  width: fit-content;
  float: left;
  color: black;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.09375rem;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  ${Icon} {
    margin-right: 1rem;
  }
`;

export const NextButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.fullreport.font.white};
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.09375rem;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  ${Icon} {
    margin-left: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  clear: both;
  display: table;
`;

export const ButtonContainer2 = styled.div`
  width: 100%;
  clear: both;
  display: table;
`;

export const Content = styled.div`
  margin-top: 3.25rem;
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.div`
  width: 80%;
  margin-right: 10.125rem;
`;

export const Label = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.01875rem;
  color: black;
`;

export const Title = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.25rem;
  letter-spacing: 0.03125rem;
  cursor: pointer;
  color: ${(props) =>
    (props.isCheckIn && "#096B81") ||
    (props.isHaventArrived && "#C1934D") ||
    "black"};
`;

export const ChartContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 9rem 0 0;
`;

export const ChartTop = styled.div`
  padding-bottom: 4rem;
  border-bottom: 0.09375rem solid #bdbdbd;
  width: 33.9375rem;
`;

export const ChartBottom = styled.div`
  display: flex;
  align-content: strech;
  padding: 1.5rem 0.625rem;
  border-bottom: 0.09375rem solid #bdbdbd;
  width: 32.9375rem;
`;

export const ChartBottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  width: 50%;
`;

export const ChartBottomRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-left: 0.09375rem solid #bdbdbd;
  padding-left: 3.25rem;
`;

export const ChartLegend = styled.div`
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background: ${(props) =>
    (props.isFill &&
      "radial-gradient(161.32% 161.32% at 57.41% 18.68%, #EBDCD5 0%, #C9B9B2 17%, #75625B 61%, #675650 76%, #5A4B47 86%)") ||
    props.theme.fullreport.font.white};
  margin-right: 0.75rem;
  align-self: center;
`;

export const ChartLegendNumber = styled.div`
  display: flex;
`;

export const ChartLegendText = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
  color: black;
`;

export const GridDashboard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:  ${(props) => props.gridTemplateColumn || "1fr"}} ;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
`;

export const TableCategoryContainer = styled.div`
  display: flex;
  max-width: 12.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.125rem 0;
  align-items: center;
`;

export const CategoryMore = styled.div`
  margin-left: 4px;
  color: #727272;
`;

export const OverflowTableControl = styled.div`
  min-width: 100%;
  width: 300px;
  overflow-x: scroll;
`;

export const Category = styled.div`
  // background-color: ${(props) =>
    (props.category &&
      (props.category === "-"
        ? "#fff"
        : props.category === "Church"
        ? "#FDEDD4"
        : props.category === "College Friend"
        ? "#D7ECFF"
        : props.category === "Office"
        ? "#DBDFFD"
        : props.category === "Friend of Groom"
        ? "#EDDFB3"
        : props.category === "Kids"
        ? "#C6FFC6"
        : props.category === "Family"
        ? "#B3E8E5"
        : props.category === "School Gang"
        ? "#E7AB79"
        : props.category === "Friends"
        ? "#AEDBCE"
        : props.category === "Teachers"
        ? "#40DFEF"
        : props.category === "VIP Guests"
        ? "#F2D7D9"
        : "#FFF")) ||
    "#B5E0EA"} ;
  text-align: center;
  width: 55%;
  padding: 10px 4px;
  margin: 0px auto;
  border-radius: 12px;
  // color: ${(props) =>
    (props.category &&
      (props.category === "-"
        ? "#0a0a0a"
        : props.category === "Church"
        ? "#C1934D"
        : props.category === "College Friend"
        ? "#54BAB9"
        : props.category === "Office"
        ? "#646FD4"
        : props.category === "Friend of Groom"
        ? "#87805E"
        : props.category === "Kids"
        ? "#18978F"
        : props.category === "Family"
        ? "#2F8F9D"
        : props.category === "School Gang"
        ? "#4C3A51"
        : props.category === "Friends"
        ? "#635666"
        : props.category === "Teachers"
        ? "#143F6B"
        : props.category === "VIP Guests"
        ? "#748DA6"
        : "#0a0a0a")) ||
    "#096B81"}
`;
