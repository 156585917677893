/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Button,
  Heading,
} from 'shared';
import {
  Modal,
} from 'components';
import {
  Icon,
  ModalButtonContainer,
  ModalClose,
  ModalContainer,
  ModalHeaderWithoutBadge,
  ListContainer,
  ListItem,
} from 'pages/guest-list/floating-menu/style';
import ExitSVG from 'assets/icons/exit.svg';
import DeleteConfirmSVG from 'assets/images/delete-confirm.svg';

const DeleteFailModal = ({
  isOpen,
  onRequestClose,
  failData,
}) => {
  const [deletedUserFail, setDeletedUserFail] = useState([]);

  useEffect(() => {
    setDeletedUserFail(failData);
  }, [failData]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <>
        <ModalClose
          src={ExitSVG}
          onClick={onRequestClose}
        />
        <ModalContainer>
          <ModalHeaderWithoutBadge>
            <Icon src={DeleteConfirmSVG} style={{height:'100px',width:'100%'}} />
            <Heading
              kind="subtitle"
              text="Some guests can’t be deleted since they were already invited."
              marginBottom="0.5rem"
            />
          </ModalHeaderWithoutBadge>
          <ListContainer>
            <Scrollbars
              autoHide
              style={{ minHeight: deletedUserFail.length > 3 ? '165px' : deletedUserFail.length === 2 ? '110px' : deletedUserFail.length === 1 ? '55px' : '0', width: '343px' }}
            >
              {deletedUserFail.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {item.name}
                </ListItem>
              ))}
            </Scrollbars>
          </ListContainer>
          <ModalButtonContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Back"
              onClick={onRequestClose}
            />
          </ModalButtonContainer>
        </ModalContainer>
      </>

    </Modal>
  );
};

const {
  bool,
  func,
  shape,
  arrayOf,
  string,
} = PropTypes;

DeleteFailModal.propTypes = {
  isOpen: func.isRequired,
  onRequestClose: func.isRequired,
  failData: arrayOf([shape({})]),
};

DeleteFailModal.defaultProps = {
  failData: [],
};

export default DeleteFailModal;
