/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from "react";
import Joi from "joi";
import SVGInject from "@iconfu/svg-inject";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { queryRead } from "helpers/helpers";
import ENDPOINTS from "helpers/endpoints";
import { axiosApiInstance } from "helpers/authentication";
import forms from "data/static/form";
import { Heading, Input, Button } from "shared";
import {
  ButtonIcon,
  Assigner,
  Modal,
  Table,
  TableLabel,
  SideMenu,
  TableButton,
  TableAvailability,
  BadgeSelect,
  MessageBox,
} from "components";
import {
  Icon,
  ModalContent,
  ModalClose,
  ModalHeader,
  ModalSection,
  ModalInfo,
  ModalButtonContainer,
  Body,
  TopContainer,
  TopRightContainer,
  ContentContainer,
  EmptyGuestImageContainer,
  EmptyGuestImage,
  EmptyGuestText,
  AssignGuestContainer,
  AssignGuestSection,
  AssignGuestSectionTitle,
  AssignGuestSectionSubtitle,
  TableCategoryContainer,
  CategoryMore,
  ActionButtonContainer,
  SearchContainer,
  MessageBoxContainer,
  LoadingContainer,
} from "pages/table-management/style";
import TableManagementFloatingMenu from "pages/table-management/floating-menu";
import SearchSVG from "assets/icons/search.svg";
import PersonSVG from "assets/icons/person.svg";
import TableSVG from "assets/icons/table.svg";
import InfoSVG from "assets/icons/info.svg";
import AddSVG from "assets/icons/add.svg";
import EmptyTableSVG from "assets/images/empty-table.svg";
import ExitSVG from "assets/icons/exit.svg";
import TableNumberedSVG from "assets/icons/table-numbered.svg";
import AssignSVG from "assets/icons/assign.svg";
import AddTableSVG from "assets/icons/add-table.svg";
import EditTableSVG from "assets/icons/edit-table.svg";
import AssignGuestSVG from "assets/icons/assign-guest.svg";
import EditSVG from "assets/icons/edit.svg";
import Loading from "pages/loading";

const TablesNeeded = ({
  isOpen,
  onRequestClose,
  submitQuickSetup,
  setQuickSetupData,
  quickSetupData,
  tablesNeededLoading,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    <ModalContent>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      <ModalHeader>
        <Icon
          src={TableNumberedSVG}
          style={{ width: "100px", height: "100px", margin: "auto" }}
        />
        <Heading kind="subtitle" text="How many tables do you need?" />
      </ModalHeader>
      <ModalSection>
        <ButtonIcon
          infographic
          editable
          text="Table"
          icon={TableSVG}
          info={quickSetupData.totalTable}
          onChange={(value) =>
            setQuickSetupData({ ...quickSetupData, totalTable: value })
          }
        />
        <ButtonIcon
          infographic
          editable
          text="Capacity"
          icon={PersonSVG}
          info={quickSetupData.capacityPerTable}
          onChange={(value) =>
            setQuickSetupData({ ...quickSetupData, capacityPerTable: value })
          }
        />
      </ModalSection>
      <ModalInfo>
        <Icon src={InfoSVG} />
        {`You will have ${
          quickSetupData.totalTable || 0
        } tables in total, with a capacity of ${
          quickSetupData.capacityPerTable || 0
        } person on each table.`}
      </ModalInfo>
      <ModalButtonContainer>
        <Button
          small
          kind="primary"
          type="submit"
          text="Add Table"
          onClick={submitQuickSetup}
          disabled={tablesNeededLoading}
          isLoading={tablesNeededLoading}
        />
      </ModalButtonContainer>
    </ModalContent>
  </Modal>
);

const EmptyTableManagement = () => (
  <EmptyGuestImageContainer>
    <EmptyGuestImage
      src={EmptyTableSVG}
      style={{
        width: "130px",
        height: "130px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
    <EmptyGuestText>
      Looks like you don’t have any table yet.
      <br />
      Lets start adding your table!
    </EmptyGuestText>
  </EmptyGuestImageContainer>
);

const TableManagement = ({ eventTokenCommited, setIsLoading }) => {
  const { search } = useLocation();
  const [tableName, setTableName] = useState("");
  const [categoriesSelected, setCategoriesSelected] = useState("");

  const cols = [
    {
      title: "Table Name",
      position: "",
      render: (rowData) => (
        <span style={{ width: "100%" }}>Table {rowData.name}</span>
      ),
    },
    {
      title: "Category",
      position: "",
      render: (rowData) => (
        <span style={{ width: "100%" }}>
          <TableCategoryContainer>
            {!!rowData.categories.length && (
              <TableLabel type="default" text={rowData.categories[0]} />
            )}
            {rowData.categories.length > 1 && (
              <CategoryMore>+{rowData.categories.length - 1} More</CategoryMore>
            )}
          </TableCategoryContainer>
        </span>
      ),
    },
    {
      title: "Avability",
      position: "center",
      render: (rowData) => (
        <span style={{ width: "100%" }}>
          <TableAvailability
            text={`${rowData.totalGuest}/${rowData.capacity}`}
          />
        </span>
      ),
    },
    {
      title: "Arrival",
      position: "center",
      render: (rowData) => (
        <span>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {/* <TableLabel
              type={rowData.arrival === "Haven't Arrived" ? "error" : "success"}
              text={rowData.arrival}
            /> */}
            <TableAvailability
              primary
              text={`${rowData.totalGuest}/${rowData.capacity}`}
            />
          </div>
        </span>
      ),
    },
    {
      title: "",
      position: "center",
      render: (rowData) => (
        <span
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ActionButtonContainer>
            <TableButton
              text="Edit"
              icon={EditSVG}
              onClick={() => openEditTable(rowData)}
            />
            <TableButton
              text="Assign Table"
              icon={AssignSVG}
              onClick={() => {
                openAssignTable(rowData);
                setTableName(rowData.name);
                setCategoriesSelected(
                  rowData.categories.length === 0
                    ? ""
                    : "- " + rowData.categories
                );
              }}
            />
          </ActionButtonContainer>
        </span>
      ),
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(0);
  const [isQuickSetupProceed, setIsQuickSetupProceed] = useState(false);
  const [isSetIsAddTableProceed, setIsAddTableProceed] = useState(false);
  const [isSetIsEditTableProceed, setIsEditTableProceed] = useState(false);
  const [isAssignTableProceed, setIsAssignTableProceed] = useState(false);
  const [isAssignCategoryProceed, setIsAssignCategoryProceed] = useState(false);
  const [isDeleteProceed, setIsDeleteProceed] = useState(false);
  const [openedSideMenuId, setOpenedSideMenuId] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchAssignTable, setSearchAssignTable] = useState("");
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [loading, setLoading] = useState(false);

  // data
  const [data, setData] = useState([]);
  const [sort, setSort] = useState("");
  const [tempData, setTempData] = useState([]);
  const [categories, setCategory] = useState([]);
  const [dDayStatus, setDDayStatus] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [addTableError, setAddTableError] = useState({
    status: false,
    title: "",
    message: "",
  });

  const [editTableError, setEditTableError] = useState({
    status: false,
    title: "",
    message: "",
  });

  const [quickSetupData, setQuickSetupData] = useState(forms.table.QuickSetup);
  const [addTableData, setAddTableData] = useState(forms.table.AddTable);
  const [editTableData, setEditTableData] = useState(forms.table.EditTable);
  const [guestTableCondition, setGuestTableCondition] = useState({
    guestsAssignTable: [],
    guestsUnAssign: [],
  });

  const [tempGuestsUnassignTable, setTempGuestsUnassignTable] = useState([]);
  const [willEditTable, setWillEditTable] = useState({});
  const [tablesNeededLoading, setTablesNeededLoading] = useState(false);

  // functions
  const closeAddTableError = () => {
    setAddTableError({
      status: false,
      title: "",
      message: "",
    });
  };

  const closeEditTableError = () => {
    setEditTableError({
      status: false,
      title: "",
      message: "",
    });
  };
  // console.log(
  //   "data",
  //   guestTableCondition.guestsAssignTable.sort((a, b) => b.order - a.order)
  // );

  const closeModal = () => setIsModalOpen(0);

  const submitQuickSetup = async () => {
    setTablesNeededLoading(true);
    try {
      const form = quickSetupData;

      const schema = Joi.object({
        totalTable: Joi.number().required(),
        capacityPerTable: Joi.number().required(),
      });
      const value = await schema.validateAsync(form);

      axiosApiInstance
        .post(ENDPOINTS("customer.table.quickSetup"), value)
        .then(({ data: { m } }) => {
          setTablesNeededLoading(false);
          // TODO: LOADING
          setIsQuickSetupProceed(true);
          // TODO: LOADING FALSE
        })
        .catch(() => {
          setTablesNeededLoading(false);
          // error message
        });
    } catch (error) {
      setTablesNeededLoading(false);
      // error here
    }
  };

  const submitAddTable = async () => {
    setIsSubmiting(true);
    try {
      const form = addTableData;
      form.category = selectedCategories.reduce((acc, curr) => {
        if (curr.select) {
          acc.push(curr._id);
        }

        return acc;
      }, []);

      const schema = Joi.object({
        name: Joi.string().required(),
        capacity: Joi.number().required(),
        category: Joi.array().items(Joi.string().trim()),
      });

      const value = await schema.validateAsync(form);
      axiosApiInstance
        .post(ENDPOINTS("customer.table.table"), value)
        .then(({ data: { m } }) => {
          // TODO: LOADING
          setIsAddTableProceed(true);
          setIsSubmiting(false);
          // TODO: LOADING FALSE
        })
        .catch((err) => {
          setIsSubmiting(false);
          // error message
          if (err && err.response && err.response.data && err.response.data.m) {
            setAddTableError({
              status: true,
              title: "Can't be Added",
              text: err.response.data.m,
            });
          }
        });
    } catch (error) {
      // error here
    }
  };

  const submitEditTable = async () => {
    setIsSubmiting(true);
    try {
      const form = { ...editTableData, name: String(editTableData.name) };
      form.category = selectedCategories.reduce((acc, curr) => {
        if (curr.select) {
          acc.push(curr._id);
        }

        return acc;
      }, []);

      const schema = Joi.object({
        name: Joi.string().required(),
        capacity: Joi.number().required(),
        category: Joi.array().items(Joi.string().trim()),
      });
      const value = await schema.validateAsync(form);
      axiosApiInstance
        .put(`${ENDPOINTS("customer.table.table")}/${willEditTable._id}`, value)
        .then(({ data: { m } }) => {
          // TODO: LOADING
          setIsEditTableProceed(true);
          setIsSubmiting(false);
          // TODO: LOADING FALSE
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.m) {
            setEditTableError({
              status: true,
              title: "Can't be Edited",
              text: err.response.data.m,
            });
          }
          setIsSubmiting(false);
          // error message
        });
    } catch (error) {
      // error here
      setIsSubmiting(false);
      console.log(error);
    }
  };

  const deleteTables = async (
    setShowDeleteFail,
    setDeleteDataFail,
    closeModalConfirm
  ) => {
    const form = {
      tables: data.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
    };

    const schema = Joi.object({
      tables: Joi.array().items(Joi.string().trim()),
    });

    const value = await schema.validateAsync(form);
    axiosApiInstance
      .post(ENDPOINTS("customer.table.deleteTable"), value)
      .then(
        ({
          data: {
            value: { cannotDeleted },
          },
        }) => {
          // TODO: LOADING
          if (cannotDeleted.length) {
            setDeleteDataFail(cannotDeleted);
            closeModalConfirm();
            setShowDeleteFail(true);
          } else {
            setIsDeleteProceed(true);
          }
          // TODO: LOADING FALSE
        }
      )
      .catch(() => {
        // error message
      });
  };

  const assignTableCategories = async (categories) => {
    const form = {
      category: categories.reduce((acc, curr) => {
        if (curr.select) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
      tables: data.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push(curr._id);
        }

        return acc;
      }, []),
    };

    const schema = Joi.object({
      category: Joi.array().items(Joi.string()),
      tables: Joi.array().items(Joi.string()),
    });

    const value = await schema.validateAsync(form);
    axiosApiInstance
      .post(ENDPOINTS("customer.table.setCategory"), value)
      .then((_) => {
        // TODO: LOADING
        setIsAssignCategoryProceed(true);
        // TODO: LOADING FALSE
      })
      .catch(() => {
        // error message
      });
  };

  const assignTable = async () => {
    const assignTable = guestTableCondition.guestsAssignTable;

    let assignLength = assignTable.length + 1;
    const form = {
      guests: assignTable.map((guest, index) => {
        assignLength -= 1;
        return { _id: guest._id, order: assignLength || "0" };
      }),
    };

    const schema = Joi.object({
      guests: Joi.array().items(
        Joi.object().keys({
          order: Joi.number().required(),
          _id: Joi.string().required(),
        })
      ),
    });

    const value = await schema.validateAsync(form);
    setIsSubmiting(true);
    axiosApiInstance
      .post(
        `${ENDPOINTS("customer.assignTable.guestCondition")}/${
          willEditTable._id
        }`,
        value
      )
      .then(({ data: { m } }) => {
        // TODO: LOADING
        setIsSubmiting(false);
        setIsAssignTableProceed(true);
        // TODO: LOADING FALSE
      })
      .catch(() => {
        setIsSubmiting(false);
        // error message
      });
  };

  const getTableAssignCondition = async (tableId) => {
    setLoading(true);
    axiosApiInstance
      .get(`${ENDPOINTS("customer.assignTable.guestCondition")}/${tableId}`)
      .then(({ data: { value } }) => {
        setLoading(false);
        setTempGuestsUnassignTable(
          JSON.parse(JSON.stringify(value.guestsUnAssign))
        );
        setGuestTableCondition({
          guestsAssignTable: value.guestsAssignTable,
          guestsUnAssign: value.guestsUnAssign,
        });
        setDDayStatus(value.ddayStatus);
      })
      .catch(() => {
        // error message
        setLoading(false);
      });
  };

  const getTableList = (type = "") => {
    axiosApiInstance
      .get(`${ENDPOINTS("customer.table.table")}?type=${type}`)
      .then(
        ({
          data: {
            value: { tables },
          },
        }) => {
          setIsLoading(false);
          // TODO SETTING DATA and Loading
          if (tables.length === 0) {
            setIsModalOpen(1);
          }
          setIsSelectedAll(false);
          setData(JSON.parse(JSON.stringify(tables)));
          setTempData(JSON.parse(JSON.stringify(tables)));
        }
      )
      .catch(() => {
        // error message
      });
  };

  const getCategoryList = () => {
    axiosApiInstance
      .get(ENDPOINTS("customer.category.category"))
      .then(
        ({
          data: {
            value: { categories },
          },
        }) => {
          setCategory(categories);
          setSelectedCategories(
            categories.map((c) => ({
              ...c,
              select: false,
            }))
          );
        }
      )
      .catch(() => {
        // error message
      });
  };

  const openEditTable = (row) => {
    const form = {
      name: row.name,
      capacity: row.capacity,
      category: [],
    };
    const selectedCategories = categories.map((c) => {
      const findOldCategory = row.categories.findIndex(
        (current) => String(current) === String(c.name)
      );
      c.select = findOldCategory !== -1;

      return c;
    });

    setWillEditTable(row);
    setSelectedCategories(selectedCategories);
    setEditTableData(form);
    setOpenedSideMenuId(2);
  };

  const closeSideMenu = () => {
    setOpenedSideMenuId(0);
  };

  const openAssignTable = (data) => {
    setWillEditTable(data);
    getTableAssignCondition(data._id);
    setSearchAssignTable("");
    setOpenedSideMenuId(3);
  };

  const manageGuestAtTable = (data, index, hasAssigned) => {
    let { guestsAssignTable } = guestTableCondition;
    let { guestsUnAssign } = guestTableCondition;
    if (
      !hasAssigned &&
      guestsAssignTable.length + 1 <= willEditTable.capacity
    ) {
      guestsAssignTable = [data].concat(guestsAssignTable);

      setGuestTableCondition({
        guestsAssignTable,
        guestsUnAssign: guestsUnAssign.reduce((acc, curr) => {
          if (curr?._id !== data?._id) {
            acc.push(curr);
          }
          return acc;
        }, []),
      });
      setTempGuestsUnassignTable(JSON.parse(JSON.stringify(guestsUnAssign)));
    } else if (hasAssigned === true) {
      guestsUnAssign = [data].concat(guestsUnAssign);

      setGuestTableCondition({
        guestsAssignTable: guestsAssignTable.reduce((acc, curr) => {
          if (curr?._id !== data?._id) {
            acc.push(curr);
          }
          return acc;
        }, []),
        guestsUnAssign,
      });
      setTempGuestsUnassignTable(JSON.parse(JSON.stringify(guestsUnAssign)));
    }
  };

  const selectCategory = (i) => {
    const tempCategories = [];
    selectedCategories.map((item, index) => {
      if (index === i) {
        tempCategories.push({ ...item, select: !item.select });
      } else {
        tempCategories.push(item);
      }
      return true;
    });
    setSelectedCategories(tempCategories);
  };

  const assignCategoryIntialize = () => {
    const categoriesSelected = data.reduce((acc, curr) => {
      if (curr.checked) {
        acc = acc.concat(curr.categories);
      }

      return acc;
    }, []);

    const selectedCategory = selectedCategories.map((category) => {
      const findIndex = categoriesSelected.findIndex(
        (current) => String(current) === category.name
      );
      category.select = findIndex !== -1;

      return category;
    });

    setSelectedCategories(selectedCategory);
  };

  useEffect(() => {
    setIsLoading(true);
    SVGInject(document.querySelectorAll("img.injectable"));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSetIsAddTableProceed === true) {
      setIsAddTableProceed(false);
      setOpenedSideMenuId(0);
      setAddTableData(forms.table.AddTable);
      getTableList();
    }

    if (isSetIsEditTableProceed === true) {
      setIsEditTableProceed(false);
      setEditTableData(forms.table.EditTable);
      setOpenedSideMenuId(0);
      getTableList();
    }

    if (isAssignTableProceed === true) {
      setIsAssignTableProceed(false);
      setGuestTableCondition({ guestsAssignTable: [], guestsUnAssign: [] });
      setOpenedSideMenuId(0);
      getTableList();
    }
    // eslint-disable-next-line
  }, [isSetIsAddTableProceed, isSetIsEditTableProceed, isAssignTableProceed]);

  useEffect(() => {
    if (isQuickSetupProceed === true) {
      setIsQuickSetupProceed(false);
      setQuickSetupData(forms.table.QuickSetup);
      setIsModalOpen(0);
      getTableList();
    }

    if (isDeleteProceed === true) {
      setIsDeleteProceed(false);
      setShowFloatingMenu(false);
      getTableList();
    }

    if (isAssignCategoryProceed === true) {
      setIsAssignCategoryProceed(false);
      setShowFloatingMenu(false);
      getTableList();
    }
    // eslint-disable-next-line
  }, [isQuickSetupProceed, isDeleteProceed, isAssignCategoryProceed]);

  useEffect(() => {
    if (eventTokenCommited) {
      const { type } = queryRead(search);
      getTableList(type);
      getCategoryList();
    }
    // eslint-disable-next-line
  }, [eventTokenCommited, sort]);

  useEffect(() => {
    if (openedSideMenuId === 1) {
      setSelectedCategories(
        categories.map((c) => ({
          ...c,
          select: false,
        }))
      );
    }
    // eslint-disable-next-line
  }, [openedSideMenuId]);

  useEffect(() => {
    setData(
      tempData.filter(
        (item) =>
          String(`table ${item.name}`)
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.categories.findIndex(
            (item) =>
              item.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          ) !== -1 ||
          item.guests.findIndex(
            (item) =>
              item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              item.invitationName
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) !== -1
          ) !== -1
      )
    );
    // eslint-disable-next-line
  }, [searchTerm]);

  const filterGuestUnAssign = guestTableCondition?.guestsUnAssign?.filter(
    (item) =>
      item.name.toLowerCase().indexOf(searchAssignTable.toLowerCase()) !== -1 ||
      item?.invitationName
        .toLowerCase()
        .indexOf(searchAssignTable.toLowerCase()) !== -1 ||
      item?.categories?.findIndex(
        (item) =>
          item.toLowerCase().indexOf(searchAssignTable.toLowerCase()) !== -1
      ) !== -1
  );

  return (
    <>
      <TablesNeeded
        isOpen={isModalOpen === 1}
        onRequestClose={closeModal}
        submitQuickSetup={submitQuickSetup}
        quickSetupData={quickSetupData}
        setQuickSetupData={setQuickSetupData}
        tablesNeededLoading={tablesNeededLoading}
      />
      <SideMenu
        isOpen={openedSideMenuId === 1}
        onRequestClose={closeSideMenu}
        title="Add Table"
        buttonText="Add Table"
        finishFunc={submitAddTable}
        icon={AddTableSVG}
        disabled={isSubmiting}
        isLoading={isSubmiting}
      >
        {addTableError.status && (
          <MessageBoxContainer>
            <MessageBox
              type="warning"
              title={addTableError.title}
              text={addTableError.text}
              onRequestClose={closeAddTableError}
            />
          </MessageBoxContainer>
        )}
        <Input
          type="number"
          label="Table Name"
          name="tableName"
          placeholder="Type table name"
          value={addTableData.name}
          onChange={(value) =>
            setAddTableData({ ...addTableData, name: value })
          }
        />
        <Input
          type="text"
          label="Table Capacity"
          name="tableCapacity"
          placeholder="10"
          value={addTableData.capacity}
          onChange={(value) =>
            setAddTableData({ ...addTableData, capacity: value })
          }
        />
        <Input labelOnly label="Category" />
        <BadgeSelect
          data={selectedCategories}
          setSelected={selectCategory}
          useIcon
        />
      </SideMenu>
      <SideMenu
        isOpen={openedSideMenuId === 2}
        onRequestClose={closeSideMenu}
        title="Edit Table"
        buttonText="Confirm"
        finishFunc={submitEditTable}
        icon={EditTableSVG}
        disabled={isSubmiting}
        isLoading={isSubmiting}
      >
        {editTableError.status && (
          <MessageBoxContainer>
            <MessageBox
              type="warning"
              title={editTableError.title}
              text={editTableError.text}
              onRequestClose={closeEditTableError}
            />
          </MessageBoxContainer>
        )}
        <Input
          type="number"
          label="Table Name"
          name="tableName"
          placeholder="Type table name"
          value={editTableData.name}
          onChange={(value) =>
            setEditTableData({ ...editTableData, name: value })
          }
        />
        <Input
          type="text"
          label="Table Capacity"
          name="tableCapacity"
          placeholder="10"
          value={editTableData.capacity}
          onChange={(value) =>
            setEditTableData({ ...editTableData, capacity: value })
          }
        />
        <Input labelOnly label="Category" />
        <BadgeSelect
          data={selectedCategories}
          setSelected={selectCategory}
          useIcon
        />
      </SideMenu>
      <SideMenu
        expanded
        isOpen={openedSideMenuId === 3}
        onRequestClose={closeSideMenu}
        title={`Assign Guest Table ${tableName} ${categoriesSelected}`}
        buttonText="Assign Table"
        finishFunc={assignTable}
        icon={AssignGuestSVG}
        disabled={isSubmiting}
        isLoading={isSubmiting}
      >
        <AssignGuestContainer>
          {loading ? (
            <LoadingContainer>
              <Loading secondary />
            </LoadingContainer>
          ) : (
            <>
              <AssignGuestSection>
                <AssignGuestSectionTitle>
                  Unseated Guests
                </AssignGuestSectionTitle>
                <AssignGuestSectionSubtitle>
                  Choose guests to be assigned at this table
                </AssignGuestSectionSubtitle>
                <Input
                  type="text"
                  name="searchUnseatedGuests"
                  value={searchAssignTable}
                  icon={SearchSVG}
                  onChange={setSearchAssignTable}
                  placeholder="Search by name or category"
                />
                <Assigner
                  dDayStatus={dDayStatus}
                  title={`${guestTableCondition.guestsUnAssign.length} Guests`}
                  data={filterGuestUnAssign}
                  manageGuestAtTable={manageGuestAtTable}
                />
              </AssignGuestSection>
              <AssignGuestSection>
                <AssignGuestSectionTitle>
                  Selected Guests
                </AssignGuestSectionTitle>
                <AssignGuestSectionSubtitle>
                  List of guests assigned at this table.
                </AssignGuestSectionSubtitle>
                <Assigner
                  hasAssigned
                  dDayStatus={dDayStatus}
                  title={`${guestTableCondition.guestsAssignTable.length} Guests`}
                  data={guestTableCondition.guestsAssignTable
                    ?.slice()
                    .reverse()}
                  manageGuestAtTable={manageGuestAtTable}
                />
              </AssignGuestSection>
            </>
          )}
        </AssignGuestContainer>
      </SideMenu>
      <Body>
        <Heading kind="title" text="Table Management" />
        <TopContainer>
          <SearchContainer>
            <Input
              isSearch
              type="text"
              name="searchCategory"
              value={searchTerm}
              icon={SearchSVG}
              onChange={setSearchTerm}
              placeholder="Search by name or category"
            />
          </SearchContainer>
          <TopRightContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Add Table"
              icon={AddSVG}
              onClick={() => setOpenedSideMenuId(1)}
            />
          </TopRightContainer>
        </TopContainer>
        <ContentContainer data={data}>
          {data.length ? (
            <Table
              cols={cols}
              data={data}
              setData={setData}
              setShowFloatingMenu={setShowFloatingMenu}
              isSelectedAll={
                data.filter((item) => item.checked == true).length ==
                data.length
              }
              setIsSelectedAll={setIsSelectedAll}
              setTempData={setTempData}
            />
          ) : (
            <EmptyTableManagement />
          )}

          {showFloatingMenu && (
            <TableManagementFloatingMenu
              data={data}
              setData={setData}
              tempData={tempData}
              searchTerm={searchTerm}
              setShowFloatingMenu={setShowFloatingMenu}
              setIsSelectedAll={setIsSelectedAll}
              availableCategories={selectedCategories}
              assignCategoryIntialize={assignCategoryIntialize}
              assignCategory={assignTableCategories}
              deleteTables={deleteTables}
            />
          )}
        </ContentContainer>
      </Body>
    </>
  );
};

const { bool, func, shape } = PropTypes;

TablesNeeded.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  submitQuickSetup: func.isRequired,
  setQuickSetupData: func.isRequired,
  assignCategoryIntialize: func.isRequired,
  assignCategory: func.isRequired,
  deleteTables: func.isRequired,
  quickSetupData: shape({}),
};

TableManagement.propTypes = {
  eventTokenCommited: bool.isRequired,
  setIsLoading: func.isRequired,
};

export default TableManagement;
