import React from 'react';
import PropTypes from 'prop-types';
import {
  DashboardCard,
  Container,
  Title,
  SubTitle,
} from 'components/dashboard-card/style';

const DashboardCardComponent = ({
  text,
  title,
  subTitle,
  warning,
  isAdmin,
  onClick,
}) => (
  <DashboardCard warning={warning} isAdmin={isAdmin} onClick={onClick}>
    <Container>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </Container>
    <SubTitle>{text}</SubTitle>
  </DashboardCard>
);

const {
  oneOfType,
  string,
  object,
  bool,
  number,
  func,
} = PropTypes;

DashboardCardComponent.propTypes = {
  title: oneOfType([string, object, number]),
  text: string,
  subTitle: string,
  warning: bool,
  isAdmin: bool,
  onClick: func,
};

DashboardCardComponent.defaultProps = {
  title: '',
  text: '',
  subTitle: '',
  warning: false,
  isAdmin: false,
  onClick: () => {},
};

export default DashboardCardComponent;
