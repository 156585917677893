import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "shared/styles";
import EyeClosedSVG from "assets/icons/eye-closed.svg";
import EyeOpenSVG from "assets/icons/eye-opened.svg";
import DownArrowSVG from "assets/icons/down-arrow.svg";
import UpArrowSVG from "assets/icons/up-arrow.svg";
import ErrorSVG from "assets/icons/error.svg";
import { Scrollbars } from "react-custom-scrollbars";
import PhoneCodeListJSON from "data/static/calling-codes.json";

const InputComponent = ({
  labelOnly,
  type,
  label,
  name,
  value,
  icon,
  iconClick,
  onChange,
  onKeyPress,
  onKeyUp,
  onKeyDown,
  placeholder,
  errorMessage,
  disabled,
  isSearch,
  isAutoComplete,
  autoCompleteList,
  selectedList, // for auto complet list click
  isForm,
  errorStatus,
  withSearchFilter,
  guestsList,
  readonly,
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [telValue, setTelValue] = useState(value[0]);
  const [guestValue, setGuestValue] = useState(value[0]);
  const [phoneNumber, setPhoneNumber] = useState(value[1]);
  const [isTelModalOpen, setIsTelModalOpen] = useState(false);
  const [searchPhoneCode, setSearchPhoneCode] = useState("");
  const [phoneCodeList, setPhoneCodeList] = useState(PhoneCodeListJSON);
  const [guestList, setGuestList] = useState(guestsList);
  const [guestSearch, setGuestSearch] = useState("");
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [focus, setFocus] = useState(false);

  const selectNumber = (input) => {
    input.select();
  };
  const selecPhoneCode = (phoneCode) => {
    setTelValue(`+${phoneCode}`);
    setIsTelModalOpen(false);
    onChange(phoneCode, phoneNumber);
  };
  const selectGuestName = (guest) => {
    setGuestValue(guest?.name);
    setIsTelModalOpen(false);
    onChange(guest);
    setGuestList(guestsList);
  };
  const changeTelp = (val) => {
    onChange(telValue, val);
    setPhoneNumber(val);
  };

  useEffect(() => {
    if (searchPhoneCode) {
      const temp = PhoneCodeListJSON.filter((item) => {
        const tempName = item.name.toLowerCase();
        return tempName.includes(searchPhoneCode.toLowerCase());
      });
      setPhoneCodeList(temp);
    }
  }, [searchPhoneCode]);

  useEffect(() => {
    if (guestSearch) {
      const temp = guestList.filter((item) => {
        const tempName = item.name.toLowerCase();
        return tempName.includes(guestSearch.toLowerCase());
      });
      setGuestList(temp);
    } else {
      setGuestList(guestsList);
    }
  }, [guestSearch]);

  useEffect(() => {
    if (autoCompleteList.length !== 0) {
      setShowAutoComplete(true);
    }
  }, [autoCompleteList]);

  return (
    <Input.Container>
      {label && <Input.Label htmlFor={name}>{label}</Input.Label>}
      {!labelOnly && (
        <>
          {withSearchFilter ? (
            <Input.InputContainer>
              <>
                <Input.CodeContainer
                  onClick={() => setIsTelModalOpen((prevState) => !prevState)}
                >
                  <Input.Input
                    id={`${name}-guest`}
                    name={`${name}-guest`}
                    type="text"
                    value={guestValue}
                    placeholder={placeholder}
                    readOnly={readonly}
                  />
                  <Input.Icon
                    src={isTelModalOpen ? UpArrowSVG : DownArrowSVG}
                    expanded={isTelModalOpen}
                  />
                </Input.CodeContainer>
              </>

              {isTelModalOpen && (
                <Input.PhoneCodePouUpContainer withSearchFilter>
                  <Input.PhoneCodeSearchContainer>
                    {/* search */}
                    <Input.PhoneCodeSearchInput
                      type="text"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setGuestSearch(e.target.value);
                      }}
                    />
                  </Input.PhoneCodeSearchContainer>
                  <Input.PhoneCodeListContainer>
                    <Scrollbars
                      autoHide
                      style={{
                        minHeight:
                          (guestList.length > 3 && "139px") ||
                          (guestList.length === 2 && "100px") ||
                          (guestList.length === 1 && "40px") ||
                          "0",
                      }}
                    >
                      {guestList.map((item) => (
                        <Input.PhoneCodeList
                          onClick={() => {
                            selectGuestName(item);
                          }}
                        >
                          {item?.name}
                        </Input.PhoneCodeList>
                      ))}
                    </Scrollbars>
                  </Input.PhoneCodeListContainer>
                </Input.PhoneCodePouUpContainer>
              )}

              {showAutoComplete && isAutoComplete && autoCompleteList.length ? (
                <Input.AutoCompleteContainer>
                  <Input.AutoCompleteListContainer>
                    <Scrollbars
                      autoHide
                      style={{
                        minHeight:
                          (autoCompleteList.length > 3 && "139px") ||
                          (autoCompleteList.length === 2 && "100px") ||
                          (autoCompleteList.length === 1 && "40px") ||
                          "0",
                      }}
                    >
                      {autoCompleteList.map((item) => (
                        <Input.AutoCompleteList
                          onClick={() => {
                            selectedList(item);
                            setShowAutoComplete(false);
                          }}
                        >
                          {item.label}
                        </Input.AutoCompleteList>
                      ))}
                    </Scrollbars>
                  </Input.AutoCompleteListContainer>
                </Input.AutoCompleteContainer>
              ) : (
                ""
              )}
              {type === "password" && (
                <Input.InputIcon
                  src={isPasswordHidden ? EyeClosedSVG : EyeOpenSVG}
                  onClick={() => setIsPasswordHidden((prevState) => !prevState)}
                />
              )}
            </Input.InputContainer>
          ) : (
            <Input.InputContainer tel={type === "tel"}>
              {type === "tel" && (
                <>
                  <Input.CodeContainer
                    onClick={() => setIsTelModalOpen((prevState) => !prevState)}
                  >
                    <Input.Input
                      id={`${name}-telephone`}
                      name={`${name}-telephone`}
                      type="tel"
                      value={telValue}
                    />
                    <Input.Icon
                      src={isTelModalOpen ? UpArrowSVG : DownArrowSVG}
                      expanded={isTelModalOpen}
                    />
                  </Input.CodeContainer>
                </>
              )}

              {type === "tel" && isTelModalOpen && (
                <Input.PhoneCodePouUpContainer>
                  <Input.PhoneCodeSearchContainer>
                    {/* search */}
                    <Input.PhoneCodeSearchInput
                      type="text"
                      placeholder="Search Country or Code"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSearchPhoneCode(e.target.value);
                      }}
                    />
                  </Input.PhoneCodeSearchContainer>
                  <Input.PhoneCodeListContainer>
                    <Scrollbars
                      autoHide
                      style={{
                        minHeight:
                          (phoneCodeList.length > 3 && "139px") ||
                          (phoneCodeList.length === 2 && "120px") ||
                          (phoneCodeList.length === 1 && "60px") ||
                          "0",
                      }}
                    >
                      {phoneCodeList.map((item) => (
                        <Input.PhoneCodeList
                          onClick={() => {
                            selecPhoneCode(item.callingCodes[0]);
                          }}
                        >
                          {item.name} (+
                          {item.callingCodes[0]})
                        </Input.PhoneCodeList>
                      ))}
                    </Scrollbars>
                  </Input.PhoneCodeListContainer>
                </Input.PhoneCodePouUpContainer>
              )}
              {isForm ? (
                <Input.Input
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  icon={icon}
                  isSearch={isSearch}
                  disabled={disabled}
                  id={name}
                  name={name}
                  autoComplete="off"
                  type={isPasswordHidden ? type : "text"}
                  onClick={
                    type === "number"
                      ? (event) => selectNumber(event.target)
                      : () => {}
                  }
                  value={type === "tel" ? phoneNumber : value}
                  onChange={(e) => {
                    type === "tel"
                      ? changeTelp(e.target.value)
                      : onChange(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    onKeyPress(e);
                  }}
                  onKeyUp={(e) => onKeyUp(e)}
                  onKeyDown={(e) => onKeyDown(e)}
                  placeholder={focus ? "" : placeholder}
                  error={!!errorStatus}
                />
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  style={{ width: "100%" }}
                >
                  <Input.Input
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    icon={icon}
                    isSearch={isSearch}
                    disabled={disabled}
                    id={name}
                    name={name}
                    autoComplete="off"
                    type={isPasswordHidden ? type : "text"}
                    onClick={
                      type === "number"
                        ? (event) => selectNumber(event.target)
                        : () => {}
                    }
                    value={type === "tel" ? phoneNumber : value}
                    onChange={(e) =>
                      type === "tel"
                        ? changeTelp(e.target.value)
                        : onChange(e.target.value)
                    }
                    onKeyPress={(e) => onKeyPress(e)}
                    onKeyUp={(e) => onKeyUp(e)}
                    onKeyDown={(e) => onKeyDown(e)}
                    placeholder={focus ? "" : placeholder}
                    error={!!errorStatus}
                  />
                </form>
              )}

              <Input.Icon isSearch={isSearch} src={icon} onClick={iconClick} />
              {showAutoComplete && isAutoComplete && autoCompleteList.length ? (
                <Input.AutoCompleteContainer>
                  <Input.AutoCompleteListContainer>
                    <Scrollbars
                      autoHide
                      style={{
                        minHeight:
                          (autoCompleteList.length > 3 && "139px") ||
                          (autoCompleteList.length === 2 && "100px") ||
                          (autoCompleteList.length === 1 && "40px") ||
                          "0",
                      }}
                    >
                      {autoCompleteList.map((item) => (
                        <Input.AutoCompleteList
                          onClick={() => {
                            selectedList(item);
                            setShowAutoComplete(false);
                          }}
                        >
                          {item.label}
                        </Input.AutoCompleteList>
                      ))}
                    </Scrollbars>
                  </Input.AutoCompleteListContainer>
                </Input.AutoCompleteContainer>
              ) : (
                ""
              )}
              {type === "password" && (
                <Input.InputIcon
                  src={isPasswordHidden ? EyeClosedSVG : EyeOpenSVG}
                  onClick={() => setIsPasswordHidden((prevState) => !prevState)}
                />
              )}
            </Input.InputContainer>
          )}
          {!!errorMessage && (
            <Input.ErrorMessageContainer>
              <Input.ErrorMessage>
                {errorMessage !== " " && <Input.ErrorIcon src={ErrorSVG} />}
                {errorMessage}
              </Input.ErrorMessage>
            </Input.ErrorMessageContainer>
          )}
        </>
      )}
    </Input.Container>
  );
};

const { bool, string, func, oneOfType, number, arrayOf, shape } = PropTypes;

InputComponent.propTypes = {
  guestsList: arrayOf(shape({})),
  labelOnly: bool,
  withSearchFilter: bool,
  type: string,
  label: string,
  name: string,
  value: oneOfType([string, number, arrayOf(oneOfType([string, shape({})]))]),
  readonly: bool,
  icon: string,
  onChange: func,
  onKeyPress: func,
  onKeyUp: func,
  onKeyDown: func,
  placeholder: string,
  errorMessage: string,
  errorStatus: string,
  disabled: bool,
  isSearch: bool,
  iconClick: func,
  autoCompleteList: arrayOf(shape({})),
  isAutoComplete: bool,
  selectedList: arrayOf(shape({})),
};

InputComponent.defaultProps = {
  guestsList: [],
  labelOnly: false,
  withSearchFilter: false,
  type: "",
  label: "",
  name: "",
  value: "",
  readonly: false,
  icon: "",
  onChange: () => {},
  onKeyPress: () => {},
  onKeyUp: () => {},
  onKeyDown: () => {},
  iconClick: () => {},
  placeholder: "",
  errorMessage: "",
  errorStatus: "",
  disabled: false,
  isSearch: false,
  autoCompleteList: [],
  isAutoComplete: false,
  selectedList: [],
};

export default InputComponent;
