import styled from 'styled-components';
import { Link as LinkDOM } from 'react-router-dom';

export default {
  Link: styled(LinkDOM)`
    font-family: 'GT Walsheim Pro', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme.link.normal};
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;

    &:hover{
      color: ${(props) => props.theme.link.hover};
    }
  `,
};
