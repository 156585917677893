import styled, { css } from 'styled-components';
import { Heading } from 'shared/styles';
import { Link } from 'react-router-dom';

export const NavbarRelative = styled.div`
  position: relative;
  height: 100vh;
  width: 20vw;
`;

export const Navbar = styled.div`
  position: fixed;
  height: 100vh;
  width: 20vw;
  border-radius: 0 0.9375rem 0.9375rem 0;
  background-color: ${(props) => props.theme.navbar.background};

  ${Heading.Heading} {
    &[kind="title"] {
      margin: 0;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.01875rem;
      color: ${(props) => props.theme.navbar.text.primary};
      text-align: left;
      margin-bottom: 0.5rem;
    }
  }
`;

export const NavbarContainer = styled.div`
  margin-left: 2.375rem;
  padding-right: 2rem;
`;

export const NavbarMenu = styled.div`
  width: 100%;
`;

export const NavbarMenuOption = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0.125rem;
  color: ${(props) => props.theme.navbar.text.mute};
`;

export const NavbarMenuBody = styled.div``;

export const NavbarMenuRow = styled(Link)`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  align-items:center;
  padding: 0.875rem 0;

  svg {
    position: relative;
    path {
      fill: ${(props) => props.theme.navbar.text.mute};
    }
  }

  &:hover {
    ${NavbarMenuOption} {
      color: ${(props) => props.theme.navbar.text.primary};
    }

    svg {
      path {
        fill: ${(props) => props.theme.navbar.text.hover};
      }
    }
  }

  ${(props) => props.active && css`
      ${NavbarMenuOption} {
        color: ${props.theme.navbar.text.primary};
      }

      svg {
        path {
          fill: ${props.theme.navbar.text.hover};
        }
      }
  `}
`;
export const NavbarMenuIcon = styled.div`margin-right: 12px;`;

export const Icon = styled.img``;

export const NavbarLogo = styled.img`
  margin: 42px 0 60px;
`;
