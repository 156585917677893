import styled from 'styled-components';
import { Heading } from 'shared/styles';

export const GridDashboard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:  ${(props) => props.gridTemplateColumn || '1fr'}} ;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-top: ${(props) => props.marginTop || 0};
`;

export const FlexColumnDashboard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  text-align: left;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.315rem;
  letter-spacing: 0.01875rem;
  margin-bottom:${(props) => ((props.marginBottom && props.marginBottom) || 0)}
`;

export const SubTitle = styled.div`
  text-align: left;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  margin-bottom:${(props) => ((props.marginBottom && props.marginBottom) || 0)}
`;

export const Icon = styled.img``;

export const Text = styled.div``;
export const LinkText = styled.div`
  display: inline;
  text-decoration:underline;
  color:${(props) => props.theme.dashboardPost.color.white};

  &:hover {
    cursor: pointer;
  }
`;
export const Banner = styled.div`
  min-height:14.75rem;
  background-color:${(props) => props.theme.dashboardPost.background.green};
  border-radius:0.625rem;
  padding:1.75rem 2rem;
  position:relative;
  overflow:hidden;

  ${Heading.Heading} {
   max-width:21.875rem;
   text-align:left;
   color:${(props) => props.theme.dashboardPost.color.white};
   position:absolute;
   z-index:10;
   margin:0;
  }

  ${Text}{
    font-family: 'GT Walsheim Pro', sans-serif;
    position:absolute;
    bottom:1.75rem;
    left:2rem;
    color:${(props) => props.theme.dashboardPost.color.white};
    z-index:10;
  }

`;

export const BallDecoration = styled.img`
  position:absolute;
  top:0;
  right:0;
`;

export const StickDecoration = styled.img`
  position:absolute;
  top:0;
  right:16.875rem;
`;

export const ConfettiDecoration = styled.img`
  position:absolute;
  bottom:0;
  right:8.125rem;
`;

export const Card = styled.div`
  background-color:${(props) => props.theme.dashboardPost.color.white};
  padding:0.9375rem 1.5rem 1.25rem;
  min-height:14.8125rem;
  border-radius:0.625rem;
  position:relative;
  ${Icon}{
    position:absolute;
    bottom:1.5rem;
    left:1.5rem;
  }
  ${SubTitle}{
    font-weight:normal;
    color:${(props) => props.theme.dashboardPost.color.gray};
  }
`;
