import styled from 'styled-components';
import { Heading, Button } from 'shared/styles';

export const Icon = styled.img``;

export const ModalContainer = styled.div`
  padding: 32px 40px;
  background-color: #F4F4F4;
`;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1rem 2.5rem;
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.0.125rem;
    }
  }
`;

export const ModalHeaderWithoutBadge = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.0.125rem;
    }
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 0 0.5rem;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;
