import styled, { css } from "styled-components";
import { Heading } from "shared/styles";
import { Link } from "react-router-dom";

export const NavbarRelative = styled.div`
  position: relative;
  height: 100vh;
  width: 250px;
`;

export const Navbar = styled.div`
  position: fixed;
  height: 100vh;
  width: 250px;
  display: flex;
  border-radius: 0 0.9375rem 0.9375rem 0;
  background-color: ${(props) => props.theme.navbar.background};
  ${Heading.Heading} {
    &[kind="title"] {
      margin: 0 20px;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.01875rem;
      color: ${(props) => props.theme.navbar.text.primary};
      text-align: left;
      margin-bottom: 0.5rem;
    }
  }
`;

export const NavbarContainer = styled.div`
  // margin-left: 2.375rem;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 100px;
`;

export const ToggleContainer = styled.div`
  margin: 0 20px;
  cursor: pointer;

  svg {
    margin-top: 2.125rem;
    margin-bottom: 1.125rem;

    path {
      fill: ${(props) => props.theme.navbar.text.primary};
    }
  }
`;

export const ToggleButton = styled.img``;

export const NavbarEventTime = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.navbar.text.secondary};
  margin: 0 20px;
  margin-bottom: 3.875rem;
`;

export const NavbarMenu = styled.div`
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a3a3a;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #646464;
  }
`;

export const NavbarMenuOption = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.01875rem;
  color: ${(props) => props.theme.navbar.text.mute};
`;

export const NavbarMenuBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const NavbarMenuRow = styled(Link)`
  text-decoration: none;
  background-color: ${(props) => props.active && "#373737"};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 12px 32px;
  border-radius: 12px;
  svg {
    position: relative;
    path {
      fill: ${(props) => props.theme.navbar.text.mute};
    }
  }

  &:hover {
    background-color: #373737;
    ${NavbarMenuOption} {
      color: ${(props) => props.theme.navbar.text.primary};
    }

    svg {
      path {
        fill: ${(props) => props.theme.navbar.text.hover};
      }
    }
  }

  ${(props) =>
    props.active &&
    css`
      ${NavbarMenuOption} {
        color: ${props.theme.navbar.text.primary};
      }

      svg {
        path {
          fill: ${props.theme.navbar.text.hover};
        }
      }
    `}
`;
export const NavbarMenuIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;
export const Icon = styled.img``;

export const BrandContainer = styled.div`
  position: fixed;
  display: flex;
  cursor: pointer;
  bottom: 0;
  left: 80px;
  width: 250px;
  svg {
    path {
      fill: ${(props) => props.theme.navbar.text.primary};
    }
  }
`;
