import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Label = styled.div`
  padding: 0.3125rem 0.75rem; 
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  width: fit-content;
  border-radius: 0.375rem;
  background-color: ${(props) => props.theme.table.label[props.type].background};
  color: ${(props) => props.theme.table.label[props.type].text};
`;
