import styled from "styled-components";
import { Heading, Button } from "shared/styles";
export const Container = styled.div`
  background-color: ${(props) => props.theme.modal.background};
`;

export const Icon = styled.img``;

export const Title = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem;
  width: 100%;
  background-color: ${(props) => props.theme.modal.header.text};
  z-index: 9999;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Section = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${(props) => (props.icon && "0.4rem") || "1.2rem"};

  ${Icon} {
    &:last-child {
      cursor: pointer;
    }
  }

  ${Heading.Heading} {
    text-align: left;
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: 0.01875rem;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 1.25rem;
`;

export const Input = styled.input`
  width: 177px;
  heigth: auto;
  padding: 8px 16px;
  margin-bottom: 0rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
`;
export const Content = styled.div`
  margin: 2.5rem;
  margin-bottom: 8rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.688rem;
  width: 100%;
  background-color: ${(props) => props.theme.modal.header.text};

  ${Button.Button} {
    padding: 0.5rem 3.46875rem;
  }
`;
