import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SVGInject from '@iconfu/svg-inject';
import {
  Icon,
  Container,
  Title,
  Row,
  RowItem,
  Value,
  Status,
  StatusIcon,
  IconActionContainer
} from 'components/assigner/style';
import AddSVG from 'assets/icons/assign-table.svg';
import UnionSVG from 'assets/icons/union.svg';

const AssignerComponent = ({
  hasAssigned, title, data, manageGuestAtTable, dDayStatus,
}) => {
  
  useEffect(() => {
    SVGInject(document.querySelectorAll('img.injectable'));
  }, []);

  const titleArray = title && title.split(' ');
  return (
    <Container>
      {title && (
        <Title>
          <b>{titleArray[0]}</b>
          {' '}
          {titleArray.slice(1).join(' ')}
        </Title>
      )}
      {data.map((row, i) => (
        <Row
          hasAssigned={hasAssigned}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          onClick={() => manageGuestAtTable(row, i, hasAssigned)}
        >
          <RowItem>
            <IconActionContainer>
            {row.arrivalStatus !== undefined && (
              <>
                {hasAssigned ? (
                  <Icon src={UnionSVG} />
                ) : (
                  <Icon
                    className="injectable"
                    src={AddSVG}
                  />
                )}
              </>
            )}
            </IconActionContainer>
            <Value>
              {row.name}
              {/* {row?.invitationName} */}
            </Value>
            {row.arrivalStatus !== undefined ? (
              <Status>
               <StatusIcon success={dDayStatus === 'pre event' ? (!!row.confirmationStatus && !!row.attendingStatus ? true : false) : row.arrivalStatus} />
                {dDayStatus === 'pre event' ? ((!!row.confirmationStatus && !!row.attendingStatus) ? 'Attending' : 'Unconfirmed') : (row.arrivalStatus ? 'Arrived' : 'Hasn\'t arrived')}
              </Status>
            ) : (
              <Icon src={UnionSVG} />
            )}
          </RowItem>
        </Row>
      ))}
    </Container>
  );
};

const {
  bool,
  arrayOf,
  shape,
  string,
  func,
} = PropTypes;

AssignerComponent.propTypes = {
  hasAssigned: bool,
  title: string,
  dDayStatus: string,
  data: arrayOf(shape({})),
  manageGuestAtTable: func.isRequired,
};

AssignerComponent.defaultProps = {
  hasAssigned: false,
  title: '',
  data: [],
  dDayStatus: '',
};

export default AssignerComponent;
