import styled from 'styled-components';
import { Heading } from 'shared/styles';
import { ButtonIcon } from 'components/button-icon/style';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 60%;

  ${Heading.Heading} {
    position: relative;
    right: 1.5rem;
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  position: relative;
  right: 1.3125rem;
`;

export const Section = styled.div`
  width: 60%;
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
`;

export const Info = styled.div`
  padding: 1.5rem;
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.section.info.background[props.color]};
  color: ${(props) => props.theme.section.info.text};
  height: fit-content;
`;

export const InfoTitle = styled(Heading.Heading)`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.01875rem;
  padding-bottom: 2.75rem;
  margin-bottom: 2.75rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.section.info.border};
`;

export const InfoFieldName = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

export const InfoValue = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
  margin-bottom: 1.25rem;
`;

export const RightSection = styled.div``;

export const DownloadButtonContainers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  margin-bottom: 1.5rem;

  ${ButtonIcon} {
    height: 6.25rem;
    align-items: stretch;
  }
`;

export const Detail = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  border-radius: 0.625rem;
  padding: 1.25rem;
`;

export const DetailHeader = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.section.detail.header};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.09375rem;
  margin-bottom: 1.75rem;
  text-transform: uppercase;
`;
