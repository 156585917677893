import styled from "styled-components";
// import { Input } from "shared";

export const Icon = styled.img``;

export const TimeContainer = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #727272;
`;

export const TimeLabel = styled.div`
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  line-height: 20px;
`;

export const TimeInputContainer = styled.div`
  padding: 11px 16px;
  background: ${(props) => (props.disabled ? "#e5e5e5" : "#FFFFFF")};
  border-radius: 10px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: 1px solid #7cc9da;
  }
`;

export const TimeSelectedContainer = styled.div`
  position: relative;
`;

export const TimeText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #0a0a0a;
`;

export const SearchContainer = styled.input`
  font-family: "GT Walsheim Pro", sans-serif;
  box-sizing: border-box;
  width: 95%;
  padding 16px;
  margin: 0px 8px;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e5e5e5;
`;

export const TimepickerPopupContainer = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 100%;
  margin-top: 8px;
  z-index: 4;
  padding: 8px 0px;
  width: 100%;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.12);
`;

export const TimepickerItemPopupContainer = styled.div`
  padding: 12px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #0a0a0a;
  background: ${(props) => (props.isActive && "#B5E0EA") || "#FFFFFF"};
  &:hover {
    background: #b5e0ea;
  }
`;

export const TimeIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const PlaceholderText = styled.div`
  color: #bdbdbd;
`;
