import React from 'react';
import { SemiCircle } from 'components/decoratives/semi-circle/style';
import SemiCircleSVG from 'assets/images/semi-circle.svg';

const SemiCircleComponent = () => (
  <SemiCircle
    src={SemiCircleSVG}
    alt="Semi Circle"
  />
);

export default SemiCircleComponent;
