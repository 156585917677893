/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "shared";
import { Modal, BadgeSelect, AssignerCategory } from "components";
import {
  Icon,
  ModalHeader,
  ModalHeaderTitle,
  IconClose,
  AssignCategoryDesc,
  AssignCategoryContentContainer,
  AssignCategoryButtonContainer,
  AssignCategoryListContainer,
  AssignCategoryTableSelected,
  CategoryBody,
} from "pages/guest-list/floating-menu/style";
import ExitSVG from "assets/icons/exit.svg";
import AssignCategory2SVG from "assets/icons/assign-category-2.svg";

const AssignCategoryModal = ({
  isOpen,
  onRequestClose,
  availableCategories,
  data,
  assignCategory,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState([]);
  const selectCategory = (i) => {
    const tempCategories = [];
    selectedCategories.map((item, index) => {
      if (index === i) {
        tempCategories.push({ ...item, select: !item.select });
      } else {
        tempCategories.push(item);
      }
      return true;
    });
    setSelectedCategories(tempCategories);
  };

  const removeItem = (row) => {
    // delete selectedGuest[row];
    const tempList = [];
    selectedGuest.map((item) => {
      tempList.push(item);
      return true;
    });
    tempList.splice(row, 1);
    if (!tempList.length) {
      onRequestClose();
      setSelectedGuest(tempList);
    } else {
      setSelectedGuest(tempList);
    }
  };

  useEffect(() => {
    const tempList = [];
    data.map((item) => {
      if (item.checked) {
        tempList.push(item);
      }
      return true;
    });

    setSelectedGuest(tempList);
    setSelectedCategories(availableCategories);
  }, [isOpen, availableCategories, data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <>
        <IconClose src={ExitSVG} onClick={onRequestClose} />
        <ModalHeader>
          <Icon src={AssignCategory2SVG} />
          <ModalHeaderTitle>Assign Category</ModalHeaderTitle>
        </ModalHeader>
        <AssignCategoryContentContainer>
          <AssignCategoryListContainer>
            <AssignCategoryDesc>
              Choose a category for the selected guest / guests below:
            </AssignCategoryDesc>
            <CategoryBody>
              <BadgeSelect
                data={selectedCategories}
                setSelected={selectCategory}
                useIcon
              />
            </CategoryBody>
          </AssignCategoryListContainer>
          <AssignCategoryTableSelected>
            {selectedGuest.length} Guest Selected
          </AssignCategoryTableSelected>
          <AssignerCategory
            data={selectedGuest}
            removeItem={removeItem}
            name="name"
          />
          <AssignCategoryButtonContainer>
            <Button
              small
              kind="primary"
              type="button"
              text="Assign"
              onClick={() => assignCategory(selectedCategories)}
            />
          </AssignCategoryButtonContainer>
        </AssignCategoryContentContainer>
      </>
    </Modal>
  );
};

const { bool, func, shape, arrayOf, string } = PropTypes;

AssignCategoryModal.propTypes = {
  data: arrayOf(shape({})),
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  availableCategories: arrayOf(shape({})),
  assignCategory: func.isRequired,
};

AssignCategoryModal.defaultProps = {
  availableCategories: [],
  data: [],
};

export default AssignCategoryModal;
