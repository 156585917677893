import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { store, ACTIONS } from 'store';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import Joi from 'joi';
import _ from 'lodash';
import { Heading, Button, Input } from 'shared';
import {
  Brand,
  ButtonIcon,
  LoadingBar,
  ProfileButton,
  ToastBox,
  ListCategoryEventCreate,
  Modal,
  Summary,
} from 'components';
import {
  Container,
  HeadingContainer,
  HeadingContainer2,
  Section,
  Section2,
  InfoContainer,
  Info,
  FooterContainer,
  FooterContent,
  Icon,
  StepIcon,
  ListContainer,
  ContentContainer,
  SummaryContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalHeaderDesc,
  ModalHeaderDescContainer,
  ModalHeaderDescTitle,
  ModalHeaderDescValue,
  ModalFooterContainer,

} from 'pages/events/steps/04/style';
import PersonSVG from 'assets/icons/person.svg';
import TableSVG from 'assets/icons/table.svg';
import InfoSVG from 'assets/icons/info.svg';
import moment from 'moment';
import { EVENT_TYPES_EXPLICIT } from 'helpers/constants';
import Step4of4SVG from 'assets/images/4-of-4.svg';
import { Scrollbars } from 'react-custom-scrollbars';
const EventSummary = ({
  isOpen,
  onRequestClose,
  setStep,
  totalTable,
  capacityPerTable,
  setFailToast,
  categories
}) => {
  const { dispatch,
    state: {
      eventForm: {
        category,
        type,
        maximum_capacity: maximumCapacity,
        groom_name: groomName,
        bride_name: brideName,
        event_date_string: eventDate,
        event_time: eventTime,
        event_venue: eventVenue,
        name: eventName,
        address,
        event_city: eventCity,
        latitude,
        longitude,
        status = false,
        groom_mother,
        groom_father,
        bride_mother,
        bride_father
      },
    },
  } = useContext(store);

  // const category = 'Wedding' // hapus
  // const type = 'seated'
  // const maximumCapacity = ''
  // const groomName = ''
  // const brideName = ''
  // const eventDate = ''
  // const eventTime = ''
  // const eventVenue = ''
  // const eventName = ''
  // const address = ''
  // const eventCity = ''
  // const latitude = ''
  // const longitude = ''
  // const status = false
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (category === 'Wedding' || category === 'Holy Matrimony') {
      setData([
        {
          fieldName: 'Event Name',
          value: eventName,
        },
        {
          fieldName: 'Groom\'s Full Name',
          value: groomName,
        },
        {
          fieldName: 'Bride\'s Full Name',
          value: brideName,
        },
        {
          fieldName: 'Groom\'s Parents',
          value: `${groom_father} & ${groom_mother}`,
        },
        {
          fieldName: 'Bride\’s Parents',
          value: `${bride_father} & ${bride_mother}`,
        },
        {
          fieldName: 'Event Date',
          value: moment(eventDate).format('DD-MM-YYYY'),
        },
        {
          fieldName: 'Event Time',
          value: eventTime,
        },
        {
          fieldName: 'Event Summary',
          value: eventVenue,
        },
        {
          fieldName: 'Venue Address',
          value: address,
        },
      ]);
    } else if (category === 'Birthday') {
      setData([
        {
          fieldName: 'Event Name',
          value: eventName,
        },
        {
          fieldName: 'Event Date',
          value: moment(eventDate).format('DD-MM-YYYY'),
        },
        {
          fieldName: 'Event Time',
          value: eventTime,
        },
        {
          fieldName: 'Event Summary',
          value: eventVenue,
        },
        {
          fieldName: 'Venue Address',
          value: address,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (!status) {
        let value;
        const dateSplit = (eventDate).split('-');
        if (category === 'Wedding' || category === 'Holy Matrimony') {
          const schema = Joi.object({
            name: Joi.string().required(),
            category: Joi.string().required(),
            type: Joi.string().required(),
            maximum_capacity: Joi.string().required(),
            groom_name: Joi.string().required(),
            bride_name: Joi.string().required(),
            groom_mother: Joi.string().required(),
            groom_father: Joi.string().required(),
            bride_mother: Joi.string().required(),
            bride_father: Joi.string().required(),
            event_date_string: Joi.string().required(),
            event_venue: Joi.string().required(),
            event_city: Joi.string().required(),
            address: Joi.string().required(),
            latitude: Joi.string().required(),
            longitude: Joi.string().required(),
            guestCategory: Joi.array().items(Joi.string())
          });

          value = await schema.validateAsync({
            category,
            type,
            name: eventName,
            maximum_capacity: maximumCapacity.toString(),
            groom_name: groomName,
            bride_name: brideName,
            event_date_string: `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]} ${eventTime}`,
            event_venue: eventVenue,
            event_city: eventCity,
            address,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            guestCategory: categories,
            groom_mother,
            groom_father,
            bride_mother,
            bride_father,
          });
        } else if (category === 'Birthday') {
          const schema = Joi.object({
            category: Joi.string().required(),
            type: Joi.string().required(),
            maximum_capacity: Joi.string().required(),
            name: Joi.string().required(),
            event_date_string: Joi.string().required(),
            event_venue: Joi.string().required(),
            event_city: Joi.string().required(),
            address: Joi.string().required(),
            latitude: Joi.string().required(),
            longitude: Joi.string().required(),
            guestCategory: Joi.array().items(Joi.string())
          });

          value = await schema.validateAsync({
            category,
            type,
            name: eventName,
            maximum_capacity: maximumCapacity.toString(),
            event_date_string: `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]} ${eventTime}`,
            event_venue: eventVenue,
            event_city: eventCity,
            address,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            guestCategory: categories
          });
        }
        const response = await axiosApiInstance.post(ENDPOINTS('customer.event.event'), value)
        localStorage.setItem('event_token', response?.data?.value?.eventToken || '');
        dispatch({
          type: ACTIONS.createEvent,
          payload: {
            status: true,
          },
        });
      }
      if (type !== 'Standing') {
        const schema2 = Joi.object({
          totalTable: Joi.number().required(),
          capacityPerTable: Joi.number().required(),
        });

        const value2 = await schema2.validateAsync({
          totalTable,
          capacityPerTable,
        });

        const response2 = await axiosApiInstance.post(
          ENDPOINTS('customer.table.quickSetup'), value2,
          {
            headers: {
              bypass: true,
            },
          })

        setIsSubmitting(false);
        setStep((prevState) => prevState + 1);
        dispatch({ type: ACTIONS.deleteEvent })

      } else {
        setIsSubmitting(false);
        setStep((prevState) => prevState + 1);
        dispatch({ type: ACTIONS.deleteEvent })
      }

    } catch (err) {
      // eslint-disable-next-line no-console
      if (err
        && err.response
        && err.response.data
        && err.response.data.m) {
        setFailToast({
          status: true, title: 'Quick Setup Table Fail', text: err.response.data.m,
        });
      }
      setIsSubmitting(false);
      console.log(err.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: '40.625rem',
        },
      }}
    >
      <Scrollbars
        autoHide
        style={{ minHeight: '80vh' }}
      >
        <ModalHeader>
          <ModalHeaderTitle>Event Summary</ModalHeaderTitle>
          <ModalHeaderDescContainer>
            <ModalHeaderDesc>
              <ModalHeaderDescTitle>
                Category
              </ModalHeaderDescTitle>
              <ModalHeaderDescValue>
                {category}
              </ModalHeaderDescValue>
            </ModalHeaderDesc>
            <ModalHeaderDesc>
              <ModalHeaderDescTitle>
                Event Type
              </ModalHeaderDescTitle>
              <ModalHeaderDescValue>
                {EVENT_TYPES_EXPLICIT[type.toLowerCase()]}
              </ModalHeaderDescValue>
            </ModalHeaderDesc>
            <ModalHeaderDesc>
              <ModalHeaderDescTitle>
                Total Guest
              </ModalHeaderDescTitle>
              <ModalHeaderDescValue>
                {`${maximumCapacity} pax`}
              </ModalHeaderDescValue>
            </ModalHeaderDesc>
          </ModalHeaderDescContainer>
        </ModalHeader>
        <ContentContainer>
          <SummaryContainer>
            <Summary data={data} />
          </SummaryContainer>
          <ModalFooterContainer>
            <Button
              small
              kind="secondary"
              type="button"
              text="Edit"
              onClick={onRequestClose}
            />
            <Button
              small
              kind="primary"
              type="button"
              text="Confirm"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </ModalFooterContainer>
        </ContentContainer>
      </Scrollbars>
    </Modal>
  );
};

const Step4 = ({ setStep }) => {
  const { state, dispatch } = useContext(store);
  const [totalTable, setTotalTable] = useState('');
  const [capacityPerTable, setCapacityPerTable] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { eventForm: { type: eventType } } = state;
  // const eventType = 'Seated' // hapus
  const [failToast, setFailToast] = useState({
    status: false,
    title: '',
    text: '',
  });
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState(['Friends', 'Teachers', 'VIP Guests', 'Family', 'College Friend', 'Office', 'Church', 'School Gang', 'Kids']);
  const [failToastCategory, setFailToastCategory] = useState({
    status: false,
    title: '',
    text: '',
  });

  const closeModal = () => setIsModalOpen(false);

  const addCategory = async () => {
    try {
      setIsSubmitting(true);

      const schema = Joi.object({
        name: Joi.string().required(),
      });

      const value = await schema.validateAsync({ name: category });

      // add category here
      setCategories([category, ...categories])
      setIsSubmitting(false);
      setCategory('');

    } catch (err) {
      setIsSubmitting(false);
      console.log(err.message);
      if (err.message == '"name" is not allowed to be empty') {
        setFailToastCategory({
          status: true, title: 'Add Category Fail', text: 'Please input your category',
        });
      }
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addCategory();
    }
  };


  const dispatchEventForm = () => {
    dispatch({
      type: ACTIONS.createEvent,
      payload: {
        totalTable: totalTable,
        capacityPerTable: capacityPerTable,
      },
    });
  }

  useEffect(() => {
    if (_.has(state.quickTable, 'totalTable') && _.has(state.quickTable, 'capacityPerTable')) {
      setTotalTable(state.quickTable.totalTable);
      setCapacityPerTable(state.quickTable.capacityPerTable);
    }
  }, [state]);



  return (
    <>
      <Brand />
      <ProfileButton />
      <EventSummary
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        setStep={setStep}
        setFailToast={setFailToast}
        totalTable={totalTable}
        capacityPerTable={capacityPerTable}
        categories={categories}
      />
      <Container>
        {failToast.status
          && (
            <ToastBox
              isOpen={failToast}
              type="warning"
              title={failToast.title}
              text={failToast.text}
              onRequestClose={() => { setFailToast({ ...failToast, status: false }); }}
            />
          )}
        {failToastCategory.status
          && (
            <ToastBox
              isOpen={failToastCategory}
              type="warning"
              title={failToastCategory.title}
              text={failToastCategory.text}
              onRequestClose={() => { setFailToastCategory({ ...failToastCategory, status: false }); }}
            />
          )}
        {eventType !== 'Standing' ?
          <><HeadingContainer>
            <Heading
              kind="title"
              text="Quick Table Setup"
            />
            <Heading
              kind="subtitle"
              text="Fill in how many tables available and how many people sitting in each"
            />
          </HeadingContainer>
            <Section>
              <ButtonIcon
                infographic
                editable
                text="Total Table"
                icon={TableSVG}
                info={totalTable}
                onChange={setTotalTable}
              />
              <ButtonIcon
                infographic
                editable
                text="Capacity per Table"
                icon={PersonSVG}
                info={capacityPerTable}
                onChange={setCapacityPerTable}
              />
            </Section>
            {totalTable && capacityPerTable && <InfoContainer>
              <Info>
                <Icon src={InfoSVG} />
                {`You will have ${Number(totalTable || 0)} tables in total, with a capacity of ${capacityPerTable || 0} person on each table.`}
              </Info>
            </InfoContainer>}
          </> :
          <div style={{ height: '56px' }} />
        }

        <HeadingContainer2>
          <Heading
            kind="title"
            text="Guest Category"
          />
          <Heading
            kind="subtitle"
            text="Determine a category for each guest"
          />
        </HeadingContainer2>
        <Section2>
          <div style={{ width: '75%' }}>
            <Input
              type="text"
              name="category"
              value={category}
              onChange={setCategory}
              onKeyDown={handleKeyDown}
              placeholder="Type new category name"
            />
          </div>
          <div style={{ minWidth: '181px' }}>
            <Button
              kind="primary"
              type="button"
              text="Add Category"
              onClick={addCategory}
              disabled={isSubmitting}
            />
          </div>
        </Section2>
        <ListContainer>
          <ListCategoryEventCreate
            data={categories}
            setCategories={setCategories}
          />
        </ListContainer>
        <FooterContainer>
          <FooterContent>
            <Button
              small
              kind="secondary"
              type="button"
              text="Back"
              onClick={() => {
                dispatchEventForm();
                setStep((prevState) => prevState - 1);
              }}
            />
            <StepIcon src={Step4of4SVG} style={{ width: '328px', height: '28px', margin: 'auto' }} />
            <Button
              small
              kind="primary"
              type="button"
              text="Next"
              onClick={() => setIsModalOpen(true)}
              disabled={(eventType !== 'Standing' && !totalTable) || isSubmitting || (eventType !== 'Standing' && !capacityPerTable)}
            />
          </FooterContent>
        </FooterContainer>
      </Container>
    </>
  );
};

const { func } = PropTypes;

Step4.propTypes = {
  setStep: func.isRequired,
};

export default Step4;
