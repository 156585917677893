/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from "react";
import { store } from "store";
import PropTypes from "prop-types";
import { Button } from "shared";
import { Modal } from "components";
import {
  Icon,
  ContentContainer,
  ConfirmationContent,
  ModalHeader,
  ModalHeaderTitle,
  ModalButtonContainer,
  ConfirmationContentTile,
  IconClose,
  ConfirmationList,
  ConfirmationItem,
  ConfirmationItemRight,
  ConfirmationItemLeft,
} from "pages/guest-list/floating-menu/style";
import InfoTableSVG from "assets/icons/info-table.svg";
import ExitSVG from "assets/icons/exit.svg";
import moment from "moment";

const InfoModal = ({ isOpen, onRequestClose, data, confirmDetailIndex }) => {
  const {
    state: { eventDetail },
  } = useContext(store);
  const [selectedTable, setSelectedTable] = useState([]);
  // const [categories, setCategories] = useState([]);
  useEffect(() => {
    const tableSelected = data.reduce((acc, curr) => {
      if (curr.checked) {
        const categories = curr.categories.reduce((acc2, curr2) => {
          acc2.push({ name: curr2, select: true });

          return acc2;
        }, []);
        acc.push({ ...curr, categories });
      }

      return acc;
    }, []);
    setSelectedTable(tableSelected);
  }, [data]);

  const {
    qr_read_time,
    qr_rejected_time,
    qr_delivered_time,
    qr_sent_time,
    qr_pending_time,
  } = confirmDetailIndex || {};

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "40.625rem",
        },
      }}
    >
      <IconClose src={ExitSVG} onClick={onRequestClose} />
      <ModalHeader>
        <Icon src={InfoTableSVG} />
        <ModalHeaderTitle>Invitation Details</ModalHeaderTitle>
      </ModalHeader>
      <ContentContainer>
        {/* {console.log(selectedTable?.[confirmDetailIndex])} */}
        <ConfirmationContent>
          {/* case 'pending': invitation_status_color = '#F2994A'
          break;
        case 'sent': invitation_status_color = '#438FD4'
          break;
        case 'delivered': invitation_status_color = '#438FD4'
          break;
        case 'read': invitation_status_color = '#40A790'
          break;
        case 'rejected': invitation_status_color = '#E76E6E'
          break; */}
          <ConfirmationContentTile>
            Invitation status record
          </ConfirmationContentTile>
          <ConfirmationList>
            {qr_read_time ? (
              <ConfirmationItem>
                <ConfirmationItemRight>Read</ConfirmationItemRight>
                <ConfirmationItemLeft>
                  {moment(qr_read_time).format("DD/MM/YYYY - hh.mm A") || "-"}
                </ConfirmationItemLeft>
              </ConfirmationItem>
            ) : null}
            {qr_rejected_time ? (
              <ConfirmationItem>
                <ConfirmationItemRight>Rejected</ConfirmationItemRight>
                <ConfirmationItemLeft>
                  {moment(qr_rejected_time).format("DD/MM/YYYY - hh.mm A") ||
                    "-"}
                </ConfirmationItemLeft>
              </ConfirmationItem>
            ) : null}
            {qr_delivered_time ? (
              <ConfirmationItem>
                <ConfirmationItemRight>Delivered</ConfirmationItemRight>
                <ConfirmationItemLeft>
                  {moment(qr_delivered_time).format("DD/MM/YYYY - hh.mm A") ||
                    "-"}
                </ConfirmationItemLeft>
              </ConfirmationItem>
            ) : null}
            {qr_sent_time ? (
              <ConfirmationItem>
                <ConfirmationItemRight>Sent</ConfirmationItemRight>
                <ConfirmationItemLeft>
                  {moment(qr_sent_time).format("DD/MM/YYYY - hh.mm A") || "-"}
                </ConfirmationItemLeft>
              </ConfirmationItem>
            ) : null}
            {qr_pending_time ? (
              <ConfirmationItem>
                <ConfirmationItemRight>Pending</ConfirmationItemRight>
                <ConfirmationItemLeft>
                  {moment(qr_pending_time).format("DD/MM/YYYY - hh.mm A") ||
                    "-"}
                </ConfirmationItemLeft>
              </ConfirmationItem>
            ) : null}
          </ConfirmationList>
        </ConfirmationContent>
        <ModalButtonContainer>
          <Button
            small
            kind="primary"
            type="button"
            text="Back"
            onClick={onRequestClose}
          />
        </ModalButtonContainer>
      </ContentContainer>
    </Modal>
  );
};

const { bool, func, shape, arrayOf, string } = PropTypes;

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  data: arrayOf(shape({})),
};

InfoModal.defaultProps = {
  data: [],
};

export default InfoModal;
