/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ListContainer,
  List,
  IconCustomAdd,
  IconCustomRemove,
} from 'components/badge-select/style';
import PlusBagdeSVG from 'assets/icons/plus-badge.svg';
import RemoveBadgeSVG from 'assets/icons/remove-badge.svg';

const ListComponent = ({ data, setSelected, useIcon }) => (
  <>
    {data.length > 0 && (
      <ListContainer>
        {data.map((item, index) => (
          <List
            key={item._id}
            isActive={item.select}
            onClick={() => { setSelected && setSelected(index); }}
            isEdit={setSelected}
          >
            {useIcon && !item.select && <IconCustomAdd src={PlusBagdeSVG} />}
            {item.name}
            {useIcon && item.select && <IconCustomRemove src={RemoveBadgeSVG} />}
          </List>
        ))}
      </ListContainer>
    )}
  </>
);

const {
  arrayOf,
  shape,
  string,
  func,
  bool,
} = PropTypes;

ListComponent.propTypes = {
  data: arrayOf(shape({
    _id: string,
    name: string,
  })),
  fetchCategories: func,
  bypass: bool,
  useIcon: bool,
};

ListComponent.defaultProps = {
  data: [],
  fetchCategories: () => {},
  bypass: false,
  useIcon: false,
};

export default ListComponent;
