import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { store } from "store";
import ENDPOINTS from "helpers/endpoints";
import { axiosApiInstance } from "helpers/authentication";
import { FullReport } from "pages";
import { Heading, Button } from "shared";
import { Modal } from "components";
import ExitSVG from "assets/icons/exit.svg";
import DDaySVG from "assets/icons/d-day.svg";
import {
  Body,
  Icon,
  ModalContent,
  ModalClose,
  ModalHeader,
  Title,
} from "pages/dashboard/style";
import PreDDay from "pages/dashboard/pre-dday";
import Post from "pages/dashboard/post";

const ModalDday = ({
  isOpen,
  onRequestClose,
  openCheckInReport,
  redirect,
  userType,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    <ModalContent>
      <ModalClose src={ExitSVG} onClick={onRequestClose} />
      <ModalHeader>
        <Icon
          src={DDaySVG}
          style={{
            height: "100px",
            width: "100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Heading kind="title" text="It’s D-Day!" />
        <Heading
          kind="subtitle"
          text="Check your check-in report lorem ipsum dolor sit amet consectetur adipiscing elit"
          marginBottom="0.5rem"
        />
      </ModalHeader>
      <Button
        small
        kind="primary"
        type="button"
        text="Go to Check-In Report"
        onClick={openCheckInReport}
      />
      {userType === "admin" && (
        <Button
          small
          kind="secondary"
          type="button"
          text="Go to Check-In Page"
          onClick={() => redirect("/check-in/guest-welcome")}
        />
      )}
    </ModalContent>
  </Modal>
);

const Dashboard = ({ eventTokenCommited, redirect, setIsLoading }) => {
  const history = useHistory();
  const { state } = useContext(store);
  const { eventDetail, user } = state;
  const [showModal, setShowModal] = useState(false);
  const [showFullReport, setShowFullReport] = useState(false);
  const [data, setData] = useState({});
  const [eventTimeLine, setEventTimeLine] = useState("");
  const closeModal = () => setShowModal(false);
  const closeReport = () => {
    setShowFullReport(false);
    setShowModal(true);
  };
  const openReport = () => {
    setShowFullReport(true);
    setShowModal(false);
  };
  const { type: userType } = user;
  useEffect(() => {
    if (eventTokenCommited) {
      setIsLoading(true);
      const today = new Date();
      const dday = new Date(eventDetail.event_date_string).setHours(0, 0, 0, 0);
      const post = new Date(eventDetail.event_date_string).setHours(
        23,
        59,
        59,
        0
      );
      let eventTime = "";
      if (today < dday) {
        eventTime = "before";
      } else if (today > dday && today < post) {
        eventTime = "dday";
      } else if (today > dday && today > post) {
        eventTime = "post";
      }

      // eventTime = "post";
      setEventTimeLine(eventTime);
      if (eventTime === "dday") {
        setShowModal(true);
      }
      axiosApiInstance
        .get(`${ENDPOINTS("customer.dashboard.dashboard")}?type=${eventTime}`)
        .then((res) => {
          setIsLoading(false);
          if (eventTime === "post") {
            setData(res.data);
          } else {
            setData(res.data.value);
          }
        })
        .catch(() => {
          setIsLoading(false);
          history.push("/events");
        });
    }
    // eslint-disable-next-line
  }, [history, eventTokenCommited, eventDetail]);
  return (
    <>
      {showFullReport && (
        <FullReport
          closeReport={closeReport}
          redirect={redirect}
          data={data}
          eventDetail={eventDetail}
        />
      )}
      <Body>
        <Title>Dashboard</Title>
        <ModalDday
          isOpen={showModal}
          onRequestClose={closeModal}
          openCheckInReport={openReport}
          redirect={redirect}
          userType={userType}
        />
        {eventTimeLine === "post" ? (
          <Post openReport={openReport} data={data} />
        ) : (
          <PreDDay
            eventTimeLine={eventTimeLine}
            openReport={openReport}
            data={data}
            redirect={redirect}
          />
        )}
      </Body>
    </>
  );
};

const { bool, func, string } = PropTypes;

ModalDday.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  openCheckInReport: func,
  redirect: func.isRequired,
  userType: string.isRequired,
};

ModalDday.defaultProps = {
  openCheckInReport: () => {},
};

Dashboard.propTypes = {
  eventTokenCommited: bool.isRequired,
  redirect: func.isRequired,
  setIsLoading: func.isRequired,
};

export default Dashboard;
