import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Combobox,
  Select,
  Options,
  Item,
  Icon,
} from 'components/combobox-printer/style';
import ArrowDownSVG from 'assets/icons/down-arrow.svg';
import OutsideClickHandler from 'react-outside-click-handler';

const ComboboxComponent = ({
  placeholder,
  onChange,
  skipChange,
  list,
  value,
}) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState({});

  const selectItem = (item) => {
    setSelected({ value: item.value, label: item.label });
    
    if (onChange !== undefined && !skipChange) {
      onChange({ value: item.value, label: item.label });
    }
  };

  const toggleList = () => setShowList(!showList);
  const closeList = () => setShowList(false);
  const spaceOpenList = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      toggleList();
    }
  };
  const enterSelected = (event, item) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      selectItem(item);
      closeList();
    }
  };

  useEffect(() => {
    const selectedValue = list.filter((item) => item.value === value);

    if (selectedValue && selectedValue.length) {
      selectItem(selectedValue[0]);
    } else {
      setSelected({})
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <OutsideClickHandler onOutsideClick={closeList}>
      <Combobox tabIndex={0} onClick={toggleList} onKeyUp={(event) => spaceOpenList(event)}>
        {showList ? <Icon src={ArrowDownSVG} up /> : <Icon src={ArrowDownSVG} />}
        <Select>
          {(selected && selected.value && selected.label) || placeholder}
        </Select>
        {showList && (
        <Options>
          {list.map((item, index) => (
            <Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              tabIndex="0"
              onKeyUp={(event) => enterSelected(event, item)}
              onClick={() => selectItem(item)}
            >
              {item.label}
              {' '}{item?.isConnected?'connected':''}
            </Item>
          ))}
        </Options>
        )}
      </Combobox>
    </OutsideClickHandler>
  );
};
const {
  string,
  func,
  arrayOf,
  shape,
} = PropTypes;

ComboboxComponent.propTypes = {
  placeholder: string,
  onChange: func,
  list: arrayOf(shape({})),
};

ComboboxComponent.defaultProps = {
  placeholder: 'Select',
  onChange: () => {},
  list: [],
};

export default ComboboxComponent;
