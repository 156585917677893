import styled from 'styled-components';

export const Icon = styled.img`transform:${(props) => ((props.up && 'scaleY(-1)') || 'scaleY(1)')};`;

export const Combobox = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  width: 100%;
  position: relative;
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  min-height: 2rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${(props) => (props.value === 'paid' && '#40A790') || (props.value === 'unpaid' && '#E76E6E')};
  background: ${(props) => (props.value === 'paid' && '#D9F1F6') || (props.value === 'unpaid' && '#FFE3E3')};

  ${Icon}{
    position: absolute;
    right: 0.625rem;
    width: 1rem;

  }
 
`;

export const Select = styled.div`
  &::after{
    position: absolute;
    content: "";
    top: 0.875rem;
    right: 0.625rem;
    width: 0;
    height: 0;
  }
`;

export const Options = styled.div`
  position: absolute;
  background-color: white;
  top: 3rem;
  right: 0;
  z-index: 99;
  width: 12.5rem;
  border-radius: 0.625rem;
  padding: 0.5rem 0;
`;

export const Item = styled.div`
  color: black;
  padding: 0.75rem 1rem;
  border: 0.0625rem solid transparent;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem
  letter-spacing: 0.0125rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.combobox.color.text1};

  &:hover, &:focus{
    background-color:  ${(props) => props.theme.combobox.options.select};
  }
`;
