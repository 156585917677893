import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  min-height: ${(props) => (props.secondary ? "50vh" : "100vh")};
  max-width: ${(props) => (props.secondary ? "100%" : "100vw")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img``;
