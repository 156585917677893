const { REACT_APP_API_URL } = process.env;

const SEMVER = "/api/v2/";

const ENDPOINTS = {
  customer: {
    tab: {
      checkIn: "tab/invitation-checkin",
    },
    invitation: {
      invitation: "invitation",
    },
  },
};

// eslint-disable-next-line no-confusing-arrow, no-restricted-globals
const ENDPOINT_RESOLVER = (path, obj) =>
  path
    .split(".")
    .reduce((prev, curr) => (prev ? prev[curr] : null), obj || window.self);
const ENDPOINT_FACTORY = (path) =>
  `${REACT_APP_API_URL}${SEMVER}${ENDPOINT_RESOLVER(path, ENDPOINTS)}`;

export default ENDPOINT_FACTORY;
