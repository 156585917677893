import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Title = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.title === "Sort by" ? "#BDBDBD" : "#000000")};
`;

export const DropwdownItems = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  position: absolute;
  top: 120%;
  left: 0;
  width: ${(props) => (props.isFilter ? "100px" : "340px")};
  background-color: white;
  border-radius: 8px;
  z-index: 50;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

export const ItemsContainer = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #b5e0ea;
  }
  ${Title} {
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000000;
  }
`;
