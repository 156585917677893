import styled from 'styled-components';

export const TableContainer = styled.div`
  background-color: white;
  width: 25rem;
  padding: 1.25rem 2rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  margin-bottom: 1.25rem;
`;

export const TableHeader = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  display: flex;
  flex-direction: row;
  text-align: center;
  color: ${(props) => props.theme.checkin.color.gray3};
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
`;

export const TableItem = styled.div`
  width: 50%;
`;

export const TableBody = styled.div`
  font-family: 'GT Walsheim Pro', sans-serif;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 0.875rem 0 0.625rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.checkin.color.gray5};
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.01875rem;
  color: ${(props) => props.theme.checkin.color.text1};
  
  &:nth-last-child(1) {
    border-bottom-width: 0;
  }
`;
