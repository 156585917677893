import React from 'react';
import PropTypes from 'prop-types';
import { Stick } from 'components/decoratives/stick/style';
import StickSVG from 'assets/images/stick.svg';

const StickComponent = ({ v2 }) => (
  <Stick
    v2={v2}
    src={StickSVG}
    alt="Stick"
  />
);

const { bool } = PropTypes;

StickComponent.propTypes = {
  v2: bool,
};

StickComponent.defaultProps = {
  v2: false,
};

export default StickComponent;
