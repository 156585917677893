const colors = {
  black: {
    black: "#000000",
    brand: "#231F20",
    text1: "#0A0A0A",
    text2: "#474747",
  },
  white: {
    white: "#FFFFFF",
  },
  red: {
    light: "#FCF1F1",
    error: "#E76E6E",
    hover: "#DA6161",
  },
  blue: {
    link: "#438FD4",
    active: "#7CC9DA",
    select: "#B5E0EA",
    hover: "#2A76BB",
  },
  green: {
    primary: "#096B81",
    secondary: "#005268",
    tertiary: "#D9F1F6",
    success: "#40A790",
    available: "#6DB3A3",
  },
  gray: {
    gray1: "#333333",
    gray3: "#727272",
    gray4: "#BDBDBD",
    gray5: "#DDDDDD",
    gray6: "#F2F2F2",
    gray7: "#F4F4F4",
    gray8: "#C9B9B2",
    background: "#f4f4f4",
  },
  brown: {
    brown: "#A87A34",
  },
  violet: {
    violet: "#9B558C",
    secondary: "#CE8DC0",
  },
  gold: {
    gold: "#C1934D",
  },
  yellow: {
    //warning background
    yellow: "#FBF1D3",
    warning: "#FF7B54",
  },
};

export default colors;
