import React from "react";
import PropTypes from "prop-types";
import { Heading } from "shared";
import {
  Icon,
  Collapsible,
  CollapsibleTitle,
  CollapsibleContent,
} from "components/side-menu/collapsible/style";
import DownArrowSVG from "assets/icons/down-arrow.svg";
import UpArrowSVG from "assets/icons/up-arrow.svg";

const CollapsibleComponent = ({
  isNotSideMenu,
  children,
  id,
  openedId,
  openId,
  title,
}) => {
  const opened = openedId === id;

  return (
    <Collapsible isNotSideMenu={isNotSideMenu} opened={opened}>
      <CollapsibleTitle
        /* eslint-disable-next-line no-confusing-arrow */
        onClick={() =>
          openId((prevState) => (prevState === id ? Infinity : id))
        }
      >
        <Heading kind="title" text={title} />
        {opened ? <Icon src={UpArrowSVG} /> : <Icon src={DownArrowSVG} />}
      </CollapsibleTitle>
      {opened && <CollapsibleContent>{children}</CollapsibleContent>}
    </Collapsible>
  );
};

const { oneOfType, shape, arrayOf, element, func, string, number, bool, any } =
  PropTypes;

CollapsibleComponent.propTypes = {
  isNotSideMenu: bool,
  children: any,
  id: number,
  openedId: number,
  openId: func.isRequired,
  title: string,
};

CollapsibleComponent.defaultProps = {
  isNotSideMenu: false,
  children: <></>,
  id: 0,
  openedId: 0,
  title: "",
};

export default CollapsibleComponent;
