import React from 'react';
import { HalfBall } from 'components/decoratives/half-ball/style';
import HalfBallSVG from 'assets/images/half-ball.svg';

const HalfBallComponent = () => (
  <HalfBall
    src={HalfBallSVG}
    alt="Half Ball"
  />
);

export default HalfBallComponent;
