import styled, { css } from "styled-components";
import { Button, Heading } from "shared/styles";

export const FloatingMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0 1.25rem;
  border-radius: 0.75rem;
  background-color: ${(props) =>
    props.theme.table.floatingMenu.background.dark};
  color: ${(props) => props.theme.table.floatingMenu.text.dark};
`;

export const IconContainer = styled.span``;
export const Icon = styled.img`
  position: relative;
  top: 0.15rem;
  margin-right: 0.4375rem;
`;

export const Selected = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  padding: 1rem 0.75rem;

  svg {
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.table.button.border};
    }
  }
`;

export const Separator = styled.div`
  border-right: 1px solid ${(props) => props.theme.section.detail.border};
`;

export const ButtonMenu = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.theme.table.floatingMenu.button.default.background};
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  padding: 0.3125rem 0.75rem;
  border-radius: 0.3125rem;
  margin: 1rem 0;

  ${(props) =>
    props.danger &&
    css`
      background-color: ${props.theme.table.floatingMenu.button.danger
        .background};
    `}
`;

export const AssignCategoryClose = styled.img`
  position: absolute;
  right: 1.8125rem;
  top: 1.8125rem;
  cursor: pointer;
`;

export const AssignCategoryHeader = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const AssignCategoryTitle = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const AssignCategoryDesc = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.heading.subtitle};
  margin-bottom: 1.5rem;
`;

export const AssignCategoryButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Button.Button} {
    margin: 1.25rem 1rem;
    margin-right: 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const AssignCategoryContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1.75rem 2.5rem;
  padding-bottom: 0;
`;

export const AssignCategoryListContainer = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.assigner.border};
  margin-bottom: 1.5rem;
`;

export const AssignCategoryTableSelected = styled.p`
  font-family: "GT Walsheim Pro";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.sideMenu.title};
  margin-bottom: 1.5rem;
`;

export const DetailClose = styled.img`
  position: absolute;
  right: 1.8125rem;
  top: 1.8125rem;
  cursor: pointer;
`;

export const DetailHeader = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const DetailHeaderTitle = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const DetailTableContainer = styled.div``;

export const DetailButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 1rem;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const DetailContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1.75rem 2.5rem;
  padding-bottom: 0;
`;

export const FloatingMenuContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 1.5rem;
  width: 80vw;
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  padding: 32px 40px;
  background-color: #f4f4f4;
`;

export const ModalHeaderWithoutBadge = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Icon} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  ${Heading.Heading} {
    &[kind="title"] {
      font-family: "GT Walsheim Pro", sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      letter-spacing: 0.01875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &[kind="subtitle"] {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.125rem;
    }
  }
`;

export const ListContainer = styled.div`
  background-color: white;
  padding: 0 1rem;
  border-radius: 0.625rem;
`;

export const ListItem = styled.div`
  padding: 1rem 0;
  border-bottom: 0.697397px solid #dddddd;
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.checkin.color.black};
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${Button.Button} {
    margin: 1.25rem 0.5rem 0;
    padding: 0.5rem 2.71875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 8.75rem;
  }
`;

export const PaginationArrow = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const PaginationText = styled.div`
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #096b81;
  margin: 0 45px;
`;

export const PaginationContainer = styled.div`
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
