import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { store, ACTIONS } from 'store';
import { Loading } from 'pages';
import ENDPOINTS from 'helpers/endpoints';

export const logout = () => {
  localStorage.removeItem('access_token_admin');
  localStorage.removeItem('event_token_admin');
  window.location.href = '/admin/sign-in';
};

export const getUserToken = (item) => localStorage.getItem(item);

export const axiosApiInstanceAdmin = axios.create();

axiosApiInstanceAdmin.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      authorization: getUserToken('access_token_admin'),
      authorizationevent: getUserToken('event_token_admin'),
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosApiInstanceAdmin.interceptors.response.use((response) => response,
  async (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem('access_token_admin');
      localStorage.removeItem('event_token_admin');
      window.location.href = '/admin/sign-in';
    }
    return Promise.reject(error);
  });

const decodeToken = () => axiosApiInstanceAdmin.get(ENDPOINTS('admin.authentication.decodeToken'));

export const AdminGuestRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => (!getUserToken('access_token_admin')
      // eslint-disable-next-line react/jsx-props-no-spreading
      ? <Component {...props} />
      : <Redirect to="/admin/dashboard" />)}
  />
);

export const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useContext(store);
  useEffect(() => {
    let isMounted = true;
    decodeToken()
      .then((res) => {
        if (isMounted) {
          dispatch({ type: ACTIONS.addUser, payload: res.data.value.admin });
          setIsAuthenticated(true);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          localStorage.removeItem('access_token_admin');
          localStorage.removeItem('event_token_admin');
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Route
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          render={(props) => (
            isAuthenticated
            // eslint-disable-next-line react/jsx-props-no-spreading
              ? <Component {...props} />
              : <Redirect to="/admin/sign-in" />
          )}
        />
      )}
    </>
  );
};

const { func } = PropTypes;

AdminGuestRoute.propTypes = {
  component: func,
};

AdminProtectedRoute.propTypes = {
  component: func,
};

AdminGuestRoute.defaultProps = {
  component: () => {},
};

AdminProtectedRoute.defaultProps = {
  component: () => {},
};
