import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  MessageBoxContainer,
  MessageBoxTitle,
  MessageBoxText,
  MessageBoxContent,
  CloseIcon,
} from 'components/message-box/style';
import UnapproveSVG from 'assets/icons/unapproved.svg';
import ApproveSVG from 'assets/icons/approve.svg';
import ExitSVG from 'assets/icons/exit-mbox.svg';

const MessageBox = ({
  title, text, onRequestClose, type = 'warning',
}) => (
  <MessageBoxContainer
    onClick={onRequestClose}
    type={type}
  >
    <Icon src={(type === 'warning' && UnapproveSVG) || (type === 'success' && ApproveSVG)} />
    <MessageBoxContent>
      <MessageBoxTitle type={type}>
        {title}
      </MessageBoxTitle>
      <MessageBoxText>
        {text}
      </MessageBoxText>
    </MessageBoxContent>
    <CloseIcon>
      <Icon src={ExitSVG} />
    </CloseIcon>
  </MessageBoxContainer>
);

const { func, string } = PropTypes;

MessageBox.propTypes = {
  title: string,
  text: string,
  onRequestClose: func,
  type: string,
};

MessageBox.defaultProps = {
  title: '',
  text: '',
  onRequestClose: () => {},
  type: 'default',
};

export default MessageBox;
