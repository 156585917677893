import styled, { css } from 'styled-components';
import { Heading, Button, Input } from 'shared/styles';

export const Icon = styled.img``;
export const HeadingContainer = styled.div`
  width: 50%;

  ${Heading.Heading} {
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: left;
    margin-top: 6.25rem;
    margin-bottom: 2.5rem;
  }
`;

export const Group = styled.p`
  font-family: 'GT Walsheim Pro', sans-serif;
  color: ${(props) => props.theme.heading.title};
  font-weight: bold;
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.01875rem;
  margin-bottom: 1.25rem;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
`;

export const InputsContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
`;

export const InputContainer = styled.div``;
export const MapContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  height:250px;
  z-Index:0;
`;

export const InputsContainerLocation = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.25rem;
  ${Input.Input}{
    margin-bottom: 0;
  }
`;

export const Form = styled.div`
  width: 50%;
  margin-bottom: 8rem;

  ${Group} {
    &:nth-child(2) {
      margin-top: 2.5rem;
    }
  }
`;

export const Container = styled.div`
  .leaflet-container {
    z-index: 0;
    height: 300px;
  }

  background-color: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.isModalOpen && css`
    height: 100vh;
    overflow-y: hidden;
  `}
`;

export const ToolTipText = styled.span`
  visibility: hidden;
  width: 22.5rem;
  background-color: white;
  color: #00000;
  text-align: left;
  border-radius: 0.375rem;
  padding: 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-weight: normal;
  margin-left: ${(props) => ((props.isLeft && '-21.875rem') || '-2.1875rem')};
  box-shadow:0.3125rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: ${(props) => (props.isLeft ? 'auto' : '50%')};
    right:${(props) => (props.isLeft ? '10%' : 'auto')};
    margin-left: -10.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

export const ToolTip = styled.span`
  padding-left: 4px;
  position: relative;
  display: inline-block;
  ${Icon} {
    width:18px;
    height: 18px;
    bottom: 0;
  }
  &:hover {
    ${ToolTipText}{
      visibility: visible;
    }
  }
`;

export const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-top: 0.1875rem solid ${(props) => props.theme.loading.empty};
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 72px;
 
  }
`;

export const FooterContent = styled.div`
  flex:1;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  ${Button.Button} {
    position: absolute;
    top: 16px;
    &:first-child{
      left: 36px;
    }
    &:last-child{
      right: 36px;
    }
`;
