import styled from "styled-components";
import { Button } from "shared/styles";

export const Icon = styled.img``;

export const Body = styled.div`
  flex: 1;
  margin-top: 5rem;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: normal;
`;

export const Title = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.75rem;
  letter-spacing: 0.03125rem;
  color: ${(props) => props.theme.navbar.background};
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 0.8rem;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.guestRequest.font.text2};
`;

export const ModalContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1rem 2.5rem;
`;

export const ModalClose = styled.img`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  padding: 1.75rem 2.5rem;
  height: 100%;
  ${Button.Button} {
    position: absolute;
    right: 0;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  height: 3rem;
`;

export const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.section.detail.background};
  color: ${(props) => props.theme.heading.title};
  padding: 1.4375rem 2rem;
  display: flex;
  align-items: center;

  ${Icon} {
    position: relative;
    top: -0.1rem;
    margin-right: 0.75rem;
  }
`;

export const ModalHeaderTitle = styled.p`
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.01875rem;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  cursor: pointer;
  ${Icon} {
    padding-right: 0.8125rem;
  }
`;

export const Top = styled.div`
  height: 2.25rem;
`;
export const RightMenu = styled.div`
  float: right;
  align-self: center;
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

export const Bottom = styled.div`
  height: 100%;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #727272;
`;

export const EmptyListContent = styled.div`
  width: 25.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const EmptyListMessage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
`;

export const GridDashboard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:  ${(props) => props.gridTemplateColumn || "1fr"}} ;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin-top: ${(props) => props.marginTop || 0};
`;

export const FlexColumnDashboard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  margin-top:1.5rem;
  ${GridDashboard}{
    grid-template-columns: 1fr 1fr 1fr;
    margin-top:1.25rem;
  } ;
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  width: 18rem;
  height: auto;
  background-color: ${(props) => props.theme.guestRequest.background};
  border-radius: 0.625rem;
  position: relative;
  padding: 0.8rem 1rem;
  ${SubTitle} {
    font-size: 0.6rem;
    line-height: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.09375rem;
    color: ${(props) => props.theme.guestRequest.font.gray3};
  }
  &:hover {
    box-shadow: 0.125rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0rem;
  justify-content: space-between;
`;

export const Label = styled.div`
  padding: 1rem 0rem;
  font-weight: 500;
  font-size: 16px;
  color: #474747;
`;

export const CardDate = styled.div`
  position: absolute;
  right: 1.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 0.8rem;
  letter-spacing: 0.00625rem;
  color: ${(props) => props.theme.guestRequest.font.gray3};
`;

export const CardName = styled.div`
  margin-top: 0.25rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0.0125rem;
  color: ${(props) => props.theme.guestRequest.font.text1};
`;

export const CardDetail = styled.div`
  padding-top: 1.25rem;
`;

export const CardItem = styled.div`
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  paddin-top: 0.5rem;
  &:first-child {
    padding-top: 0rem;
  }
`;

export const CardItemNumber = styled.div`
  padding-right: 0.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.01875rem;
  color: ${(props) => props.theme.guestRequest.font.green};
`;

export const CardItemText = styled.div`
  align-self: center;
  font-weigth: 400;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.01rem;
  color: #474747;
`;

export const MessageBoxContainer = styled.div`
  margin-bottom: 24px;
`;
