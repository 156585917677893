import React from "react";
import { Root, Tab, Text } from "./style";

const Tabgroups = ({ data, onClick, active }) => {
  return (
    <Root>
      {Array(data.length)
        .fill()
        .map((items, index) => (
          <Tab
            onClick={() => onClick(index)}
            active={active === index}
            key={index}
          >
            <Text>{data[index]}</Text>
          </Tab>
        ))}
    </Root>
  );
};

export default Tabgroups;
