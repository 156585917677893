import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { store, ACTIONS } from 'store';
import { Heading, Button } from 'shared';
import {
  Brand,
  ButtonIcon,
  LoadingBar,
  ProfileButton,
} from 'components';
import {
  Container,
  HeadingContainer,
  Section,
  FooterContainer,
  FooterContent,
  Icon,
} from 'pages/events/steps/02/style';
import { EVENT_TYPES } from 'helpers/constants';
import { capitalize } from 'helpers/helpers';
import StandingPartySVG from 'assets/icons/standing-party.svg';
import TablePartySVG from 'assets/icons/table-party.svg';
import PersonSVG from 'assets/icons/person.svg';

import Step2of4SVG from 'assets/images/2-of-4.svg';

const Step2 = ({ setStep }) => {
  const { state, dispatch } = useContext(store);
  const [maximumCapacity, setMaximumCapacity] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (_.has(state.eventForm, 'type')) {
      setType(state.eventForm.type.toLowerCase());
      setMaximumCapacity(state.eventForm.maximum_capacity);
    }
  }, [state]);

  const dispatchEventForm = () => {
    dispatch({
      type: ACTIONS.createEvent,
      payload: {
        type: capitalize(type),
        maximum_capacity: maximumCapacity,
      },
    });
  };

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <HeadingContainer>
          <Heading
            kind="title"
            text="Event Type"
          />
        </HeadingContainer>
        <Section>
          <ButtonIcon
            isActive={type === EVENT_TYPES.standing}
            text="Standing Party"
            icon={StandingPartySVG}
            onClick={() => setType(EVENT_TYPES.standing)}
          />
          <ButtonIcon
            isActive={type === EVENT_TYPES.seated}
            text="Seated Party"
            icon={TablePartySVG}
            onClick={() => setType(EVENT_TYPES.seated)}
          />
        </Section>
        <HeadingContainer>
          <Heading
            kind="title"
            text="Event Capacity"
          />
        </HeadingContainer>
        <Section>
          <ButtonIcon
            infographic
            editable
            text="Total Guests"
            icon={PersonSVG}
            info={maximumCapacity}
            onChange={setMaximumCapacity}
          />
        </Section>
        <FooterContainer>
          <FooterContent>
          <Button
            small
            kind="secondary"
            type="button"
            text="Back"
            onClick={() => {
              dispatchEventForm();
              setStep((prevState) => prevState - 1);
            }}
          />
           <Icon src={Step2of4SVG} style={{width:'328px',height:'28px',margin:'auto'}}/>
          <Button
            small
            kind="primary"
            type="button"
            text="Next"
            onClick={() => {
              dispatchEventForm();
              setStep((prevState) => prevState + 1);
            }}
            disabled={!type || !maximumCapacity}
          />
          </FooterContent>
        </FooterContainer>
      </Container>
    </>
  );
};

const { func } = PropTypes;

Step2.propTypes = {
  setStep: func.isRequired,
};

export default Step2;
