/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

import {
  CalenderContainer,
  CalendarLabel,
  DateInputContainer,
  DateSelectedContainer,
  DateText,
  DatepickerPopupContainer,
  DatepickerPopupHeader,
  DatepickerPopupHeaderBack,
  DatepickerPopupHeaderMonthYear,
  DatepickerPopupHeaderNext,
  DatepickerPopupWeeks,
  DatepickerPopupWeeksItem,
  DatepickerPopupDays,
  DatepickerPopupDaysItem,
  Icon,
  CalendarIconContainer,
  DatepickerPopupDaysContainer,
  PlaceholderText,
} from "components/datepicker/style";

import CalendarSVG from "assets/icons/calendar-gray.svg";
import CalendarNextSVG from "assets/icons/calendar-next.svg";
import CalendarBackSVG from "assets/icons/calendar-back.svg";

const DatePicker = ({ value, label, onChange, isEmpty, primary = false }) => {
  const placeholder = "Select Date";
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [printedDays, setPrintedDays] = useState([]);
  const [weeksDays, setWeeksDays] = useState([]);
  const [valueDate, setValueDate] = useState(isEmpty ? "" : new Date());
  const [showStatus, setShowStatus] = useState(false);
  const [dropdownTrigger, setDropdownTrigger] = useState(false);

  useEffect(() => {
    if (value) {
      setCurrentMonth(value);
      setValueDate(value);
    }
  }, [value]);

  useEffect(() => {
    const tempCurrentMonth = valueDate !== "" ? valueDate : new Date();
    setCurrentMonth(tempCurrentMonth);
    setWeeksDays(weeksGenerator());
    const days = dateGenerator(tempCurrentMonth);
    setPrintedDays(days);
  }, [valueDate]);

  useEffect(() => {
    // if(!!showStatus){
    //   setShowStatus(false);
    //   setDropdownTrigger(false)
    // } else
    if (dropdownTrigger) {
      setShowStatus(true);
    } else if (!dropdownTrigger) {
      setShowStatus(false);
    }
  }, [showStatus, dropdownTrigger]);

  const weeksGenerator = () => {
    const dateFormat = "ddd";
    const days = [];
    const startDate = moment()
      .utc()
      .startOf("month")
      // 0 is for Sunday
      .isoWeekday(0);

    for (let i = 0; i < 7; i += 1) {
      const localStartDate = moment(startDate);
      days.push(localStartDate.add(i, "days").format(dateFormat).slice(0, 1));
    }

    return days;
  };

  const dateGenerator = (thisMonth) => {
    const monthStart = moment(moment(thisMonth).startOf("month"));
    const monthEnd = moment(moment(monthStart).endOf("month"));
    const startDate = moment(
      moment(monthStart)
        .startOf("month")
        // 0 is for Sunday
        .isoWeekday(0)
    );

    let endDate = moment(moment(monthEnd).isoWeekday(7));
    const numberOfDays = Math.ceil(
      moment.duration(endDate.diff(startDate)).asDays()
    );
    if (numberOfDays <= 35) {
      endDate = moment(moment(monthEnd).isoWeekday(14));
    }

    // Day of month i.e. 1 2 ... 30 31
    const dateFormat = "D";

    const days = [];
    const day = startDate;
    let formattedDate = "";

    while (day.isSameOrBefore(endDate)) {
      const week = [];
      for (let i = 0; i < 7; i += 1) {
        formattedDate = moment(startDate).format(dateFormat);
        const isWithinMonth = moment(startDate).isSame(
          moment(thisMonth),
          "month"
        );
        const dayInMonth = Number(formattedDate);

        week.push({
          day: dayInMonth,
          isWithinMonth,
          date: moment(day).format("MM-DD-YYYY"),
        });
        day.add(1, "days");
      }
      days.push(week);
    }

    return days;
  };

  // eslint-disable-next-line no-shadow
  const selectedDate = (valueDate, date, isWithinMonth) =>
    valueDate !== "" &&
    date === moment(valueDate).format("MM-DD-YYYY") &&
    !!isWithinMonth;

  // eslint-disable-next-line no-shadow
  const nextMonth = (valueDate) => {
    const tempCurrentMonth = valueDate
      ? moment(currentMonth).add(1, "months")
      : moment(currentMonth).subtract(1, "months");
    const days = dateGenerator(tempCurrentMonth);
    setCurrentMonth(tempCurrentMonth);
    setPrintedDays(days);
  };

  const selectDate = (date, isWithinMonth) => {
    if (isWithinMonth) {
      setValueDate(new Date(date));
      onChange(date);
    }
    openClose();
    setShowStatus(false);
  };

  // const removeValueDate = () => {
  //   setValueDate('');
  // };

  const openClose = () => {
    setDropdownTrigger((prevState) => !prevState);
  };

  // eslint-disable-next-line no-shadow
  const getDate = (valueDate, language, format) => {
    if (language === "Bahasa Indonesia") {
      moment.locale("id");
    } else {
      moment.locale("en");
    }

    const localLocale = moment(valueDate);
    return localLocale.format(format);
  };

  return (
    <CalenderContainer>
      <CalendarLabel>{label}</CalendarLabel>
      <DateInputContainer tabIndex={0} onClick={openClose}>
        <DateSelectedContainer>
          <DateText>
            {valueDate !== "" ? (
              getDate(valueDate, "Bahasa Indonesia", "DD-MM-YYYY")
            ) : (
              <PlaceholderText>{placeholder}</PlaceholderText>
            )}
          </DateText>
          <CalendarIconContainer>
            <Icon src={CalendarSVG} />
          </CalendarIconContainer>
        </DateSelectedContainer>
      </DateInputContainer>
      {!!showStatus && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setDropdownTrigger(false);
          }}
        >
          <DatepickerPopupContainer>
            {/* month and year */}
            <DatepickerPopupHeader>
              <DatepickerPopupHeaderBack onClick={() => nextMonth(false)}>
                <Icon src={CalendarBackSVG} />
              </DatepickerPopupHeaderBack>
              <DatepickerPopupHeaderMonthYear>
                {getDate(currentMonth, "Bahasa Indonesia", "MMMM YYYY")}
              </DatepickerPopupHeaderMonthYear>
              <DatepickerPopupHeaderNext onClick={() => nextMonth(true)}>
                <Icon className="injectable" src={CalendarNextSVG} />
              </DatepickerPopupHeaderNext>
            </DatepickerPopupHeader>
            {/* weeks */}
            <DatepickerPopupWeeks>
              {weeksDays.map((item, index) => (
                <DatepickerPopupWeeksItem key={index}>
                  {item}
                </DatepickerPopupWeeksItem>
              ))}
            </DatepickerPopupWeeks>
            {/* days */}
            <DatepickerPopupDaysContainer>
              {printedDays.map((week, iWeek) => (
                <DatepickerPopupDays key={iWeek}>
                  {week.map((day, iDay) => {
                    if (new Date() < new Date(day.date) && new Date(day.date)) {
                      return (
                        <DatepickerPopupDaysItem
                          key={iDay}
                          selectedDate={selectedDate(
                            valueDate,
                            day.date,
                            day.isWithinMonth
                          )}
                          daysInCurrentMonth={day.isWithinMonth}
                          onClick={() =>
                            day.isWithinMonth &&
                            selectDate(day.date, day.isWithinMonth)
                          }
                        >
                          {day.day}
                        </DatepickerPopupDaysItem>
                      );
                    }
                    return (
                      <DatepickerPopupDaysItem
                        disabled={!primary}
                        key={iDay}
                        selectedDate={selectedDate(
                          valueDate,
                          day.date,
                          day.isWithinMonth
                        )}
                        daysInCurrentMonth={day.isWithinMonth}
                        onClick={() => {
                          primary && selectDate(day.date, day.isWithinMonth);
                        }}
                      >
                        {day.day}
                      </DatepickerPopupDaysItem>
                    );
                  })}
                </DatepickerPopupDays>
              ))}
            </DatepickerPopupDaysContainer>
          </DatepickerPopupContainer>
        </OutsideClickHandler>
      )}
    </CalenderContainer>
  );
};

const { string, func, bool } = PropTypes;
DatePicker.propTypes = {
  value: string,
  label: string,
  onChange: func,
  isEmpty: bool,
};

DatePicker.defaultProps = {
  value: "",
  label: "",
  onChange: () => {},
  isEmpty: false,
};

export default DatePicker;
