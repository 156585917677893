import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';

import StickSVG from 'assets/images/stick-post-dashboard.svg';
import BallSVG from 'assets/images/ball-post-dashboard.svg';
import ConfettiSVG from 'assets/images/confetti-post-dashboard.svg';
import GuestAttendedSVG from 'assets/icons/guest-attended.svg';
import GuestDidntAttendedSVG from 'assets/icons/guest-didnt-attend.svg';
import PresensCollecteSVG from 'assets/icons/present-collected.svg';
import GuestRequestSVG from 'assets/icons/guest-request.svg';
import { exportExcel } from 'helpers/helpers';
import { Heading } from 'shared';

import {
  GridDashboard,
  BallDecoration,
  StickDecoration,
  ConfettiDecoration,
  Banner,
  Text,
  LinkText,
  Card,
  Icon,
  Title,
  SubTitle,
} from 'pages/dashboard/post/style';

const PostDay = ({ data }) => {
  const [onDownload, setOnDownload] = useState(false);
  const {
    arrivedGuestCount,
    guestRequestCount,
    notArrivedGuestCount,
    sendGiftsCount,
  } = data;

  const downloadReport = () => {
    setOnDownload(true)
    axiosApiInstance.get(`${ENDPOINTS('customer.dashboard.dashboardReport')}`)
      .then(({ data: { value: { attending, notAttending } } }) => {
        setOnDownload(false)
        const parsedDataAttending = parsedAttending(attending.data);
        const fileNameAttending = attending.sheetName;
        const parsedDataNotAttending = parsedNotAttending(notAttending.data);
        const fileNameNotAttending = notAttending.sheetName;
        const parsedData = [{
          data: parsedDataAttending,
          sheetName: fileNameAttending,
        },
        {
          data: parsedDataNotAttending,
          sheetName: fileNameNotAttending,
        },

        ];
        exportExcel(parsedData, 'Event Report', true);
      })
      .catch((err)=>{
        setOnDownload(false)
        console.error(err)
      })
  };

  const parsedAttending = (data) => {
    const parsed = [
      [
        'Invitation name',
        'Guest Name',
        'Phone Number',
        'Pax',
        'Arrival Status',
        'Arrival Time',
        'Guest Number',
        'Gift / Angpao',
        'Souvenir',
        'Leaving Time',
        'Notes',
      ],
    ];
    data.map((item) => {
      const arr = [
        item['Invitation name'],
        item['Guest Name'],
        item['Phone Number'],
        item.Pax,
        item['Arrival Status'],
        item['Arrival Time'],
        item['Guest Number'],
        item['Gift / Angpao'],
        item.Souvenir,
        item['Leaving Time'],
        item.Notes,
      ];
      parsed.push(arr);
    });
    return parsed;
  };

  const parsedNotAttending = (data) => {
    const parsed = [
      [
        'Invitation name',
        'Guest Name',
        'Phone Number',
        'Pax',
      ],
    ];
    data.map((item) => {
      const arr = [
        item['Invitation name'],
        item['Guest Name'],
        item['Phone Number'],
        item.Pax,
      ];
      parsed.push(arr);
    });
    return parsed;
  };

  return (
    <>
      <GridDashboard gridTemplateColumn="1fr">
        <Banner>
          <Heading
            kind="title"
            text="Congratulations on your wedding!"
          />
          <Text>
            Below is your event report or
            {' '}
            <LinkText onClick={onDownload ? ()=>{}:downloadReport}>download here</LinkText>
          </Text>
          <BallDecoration src={BallSVG} />
          <ConfettiDecoration src={ConfettiSVG} />
          <StickDecoration src={StickSVG} />
        </Banner>
      </GridDashboard>
      <GridDashboard gridTemplateColumn="1fr 1fr 1fr 1fr" marginTop="2rem">
        <Card>
          <Title>{arrivedGuestCount}</Title>
          <SubTitle>Guest attended</SubTitle>
          <Icon src={GuestAttendedSVG} />
        </Card>
        <Card>
          <Title>{notArrivedGuestCount}</Title>
          <SubTitle>Guests didn&apos;t attend</SubTitle>
          <Icon src={GuestDidntAttendedSVG} />
        </Card>
        <Card>
          <Title>{sendGiftsCount}</Title>
          <SubTitle>Presents collected</SubTitle>
          <Icon src={PresensCollecteSVG} />
        </Card>
        <Card>
          <Title>{guestRequestCount}</Title>
          <SubTitle>Total Guest Request</SubTitle>
          <Icon src={GuestRequestSVG} />
        </Card>
      </GridDashboard>
    </>
  );
};

const { shape } = PropTypes;

PostDay.propTypes = {
  data: shape({}).isRequired,
};

export default PostDay;
