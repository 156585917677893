import React, { useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import SVGInject from '@iconfu/svg-inject';
import {
  Icon,
  Container,
  Title,
  Row,
  Value,
} from 'components/assigner-category/style';
import UnionSVG from 'assets/icons/union.svg';

const AssignerCategoryComponent = ({
  title, data, removeItem, name, label,
}) => {
  useEffect(() => {
    SVGInject(document.querySelectorAll('img.injectable'));
  }, []);

  const titleArray = title && title.split(' ');

  return (
    <Container>
      {title && (
        <Title>
          <b>{titleArray[0]}</b>
          {' '}
          {titleArray.slice(1).join(' ')}
        </Title>
      )}
      {data.map((item, i) => (
        <Row
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          onClick={() => removeItem(i)}
        >
          <Value>
            {label}
            {' '}
            {item[name]}
          </Value>
          <Icon src={UnionSVG} />
        </Row>
      ))}
    </Container>
  );
};

const {
  arrayOf,
  shape,
  string,
} = PropTypes;

AssignerCategoryComponent.propTypes = {
  title: string,
  data: arrayOf(shape({})),
  setRemoveList: func,
  removeItem: func,
  name: string,
  label: string,
};

AssignerCategoryComponent.defaultProps = {
  title: '',
  data: [],
  setRemoveList: () => {},
  removeItem: () => {},
  name: '',
  label: '',
};

export default AssignerCategoryComponent;
