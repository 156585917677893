import React from 'react';
import { Cone } from 'components/decoratives/cone/style';
import ConeSVG from 'assets/images/cone.svg';

const ConeComponent = () => (
  <Cone
    src={ConeSVG}
    alt="Cone"
  />
);

export default ConeComponent;
