import styled from 'styled-components';

export const EventContainer = styled.div`
  display:${(props) => (props.isLoading && 'none') || 'block'} ;
`;

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  display: flex;
  gap: 2.5rem;
`;
