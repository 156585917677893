import styled from "styled-components";

export const Root = styled.div`
  font-family: "GT Walsheim Pro", sans-serif;
  display: flex;
  padding: 0 16px;
  gap: 10px;
`;

export const Text = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 20px;
`;

export const Tab = styled.div`
  cursor: pointer;
  padding: 10px 8px;
  border-bottom: ${(props) => (props.active ? "1px solid #096b81" : "none")};

  ${Text} {
    color: ${(props) => (props.active ? "#096b81" : "#000000")};
  }
`;
