import { createGlobalStyle } from "styled-components";
import GTWalsheimProBold from "assets/fonts/GT-Walsheim-Bold.woff2";
import GTWalsheimProRegular from "assets/fonts/GT-Walsheim-Regular.woff";
import GTWalsheimProMedium from "assets/fonts/GT-Walsheim-Medium.woff2";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  @font-face {
      font-family: "GT Walsheim Pro";
      font-weight: bold;
      src: url(${GTWalsheimProBold}) format("woff2");
  }

  @font-face {
      font-family: "GT Walsheim Pro";
      src: url(${GTWalsheimProRegular}) format("woff2");
  }

  @font-face {
      font-family: "GT Walsheim Pro";
      font-weight: 500;
      src: url(${GTWalsheimProMedium}) format("woff2");
  }

  *:focus {
      outline: none;
  }
  
  .ReactModal__Body--open {
    overflow-y: hidden;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  
  ::-webkit-scrollbar-thumb {
    background: #3A3A3A;
    border-radius:10px;
  }
  
  
  ::-webkit-scrollbar-thumb:hover {
    background: #646464;
  }

  input, textarea {
    -webkit-user-select: text;
  }

`;

export default GlobalStyle;
