import React, { useState, useContext, useEffect } from 'react';
import ENDPOINTS from 'helpers/endpoints';
import Joi from 'joi';
import { axiosApiInstance } from 'helpers/authentication';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Heading,
  Input,
} from 'shared';
import { Brand, ProfileButton } from 'components';
import {
  Container,
  HeadingContainer,
  Icon,
  Section,
  LeftSection,
  RightSection,
  ButtonContainer,
  Form,
} from 'pages/settings/style';
import LeftArrowSVG from 'assets/icons/left-arrow.svg';
import { store } from 'store';

const Settings = () => {
  const { state: { user } } = useContext(store);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const history = useHistory();

  const submitEditProfile = async () => {
    const form = {
      fullname: fullName, email,
    };

    const schema = Joi.object({
      fullname: Joi.string().trim().required(),
      email: Joi.string().trim().required(),
    });

    const value = await schema.validateAsync(form);
    axiosApiInstance.put(ENDPOINTS('customer.setting.updateProfile'), value)
      .then(({ _ }) => {

      })
      .catch(() => {
        // error message
      });
  };

  const submitEditPassword = async () => {
    if (confirmNewPassword === newPassword) {
      const form = {
        oldPassword: currentPassword,
        newPassword: confirmNewPassword,
      };

      const schema = Joi.object({
        oldPassword: Joi.string().trim().required(),
        newPassword: Joi.string().trim().required(),
      });

      const value = await schema.validateAsync(form);
      axiosApiInstance.put(ENDPOINTS('customer.setting.updatePassword'), value)
        .then(({ _ }) => {

        })
        .catch(() => {
          // error message
        });
    }
  };

  useEffect(() => {
    setFullName(user.fullname);
    setEmail(user.email);
  }, [user]);

  return (
    <>
      <Brand />
      <ProfileButton />
      <Container>
        <HeadingContainer>
          <Heading
            kind="title"
            text={(
              <p>
                <Icon src={LeftArrowSVG} />
                Settings
              </p>
            )}
            onClick={() => history.goBack()}
          />
        </HeadingContainer>
        <Section>
          <LeftSection>
            <Heading
              kind="title"
              text="Edit Profile"
              uppercase
            />
          </LeftSection>
          <RightSection>
            <Form>
              <Input
                type="text"
                label="Full Name"
                name="fullname"
                value={fullName}
                onChange={setFullName}
                placeholder="Type your full name here"
              />
              <Input
                type="text"
                label="Email"
                name="email"
                value={email}
                onChange={setEmail}
                placeholder="Type your email here"
              />
              <ButtonContainer>
                <Button
                  kind="primary"
                  type="submit"
                  text="Update"
                  onClick={submitEditProfile}
                />
              </ButtonContainer>
            </Form>
          </RightSection>
        </Section>
        <Section>
          <LeftSection>
            <Heading
              kind="title"
              text="Change Password"
              uppercase
            />
          </LeftSection>
          <RightSection>
            <Form>
              <Input
                type="password"
                label="Current Password"
                name="current-password"
                value={currentPassword}
                onChange={setCurrentPassword}
                placeholder="Type your current password here"
              />
              <Input
                type="password"
                label="New Password"
                name="new-password"
                value={newPassword}
                onChange={setNewPassword}
                placeholder="Type your new password here"
              />
              <Input
                type="password"
                label="Confirm Password"
                name="confirm-password"
                value={confirmNewPassword}
                onChange={setConfirmNewPassword}
                placeholder="Retype your new password here"
              />
              <ButtonContainer>
                <Button
                  kind="primary"
                  type="submit"
                  text="Update"
                  onClick={submitEditPassword}
                />
              </ButtonContainer>
            </Form>
          </RightSection>
        </Section>
      </Container>
    </>
  );
};

export default Settings;
