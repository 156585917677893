import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { axiosApiInstance } from 'helpers/authentication';
import ENDPOINTS from 'helpers/endpoints';
import ENDPOINTSV2 from 'helpers/endpoints-v2';
import moment from 'moment';
import {
  Heading,
  Input,
  Button,
} from 'shared';
import {
  GuestContainer,
  GuestContent,
  Card,
  CardText,
  LogoContainer,
  Icon,
} from 'pages/check-in/guest-arrival/style';
import ListContainer from 'pages/check-in/guest-arrival/list';
import LogoFooterSVG from 'assets/images/logo-footer.svg';


const GuestArrival = ({
  eventName, setGuestDetail, withVideo, eventType, guestDetail, guestList, setGuestList
}) => {
  const history = useHistory();
  const [unAssignTable, setUnAssignTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!Object.keys(guestDetail).length) {
      history.push('/check-in/guest-search');
    } else {
      setGuestList(JSON.parse(JSON.stringify(guestDetail?.guests.reduce((acc, curr) => {
        if (!curr?.arrival_status) {
          acc.push({ ...curr, selected: false })
        }
        return acc;
      }, []))))
    }
  }, [history]);


  const selectedList = guestList.reduce((acc, curr) => {
    if (curr?.selected) {
      acc.push(curr._id)
    }
    return acc;
  }, [])

  const unselectedList = guestList.reduce((acc, curr) => {
    if (!curr?.selected) {
      acc.push(curr._id)
    }
    return acc;
  }, []);

  const checkedUnAssign = guestList.reduce((acc, curr) => {
    if (!curr?.table?._id) {
      acc.push(curr._id)
    }
    return acc;
  }, [])

  // const checkedSelectedUnAssign = guestList.reduce((acc, curr) => {
  //   if (curr?.selected && !curr?.table?._id) {
  //     acc.push(curr._id)
  //   }
  //   return acc;
  // }, [])

  const checkIn = () => {
    // if (checkedSelectedUnAssign.length) {
    //   setUnAssignTable(true)
    // } else {

    // const guestPack = guestDetail.guests?.reduce((acc, curr) => {
    //   if (!curr?.arrival_status) {
    //     console.log(curr)
    //     return acc = acc + 1
    //   }

    //   return acc;
    // }, 0);

    if (eventType.toLowerCase() == 'seated') {
      // duduk chekc in di table
      if (!guestDetail?.arrival_status) {
        history.push('/check-in/guest-table');
      } else {
        history.push('/check-in/guest-thankyou');
      }
    } else {
      setIsLoading(true)
      const time_string = moment(new Date()).format('YYYY-MM-DD HH:mm');
      const arrival_or_leave = !!guestDetail.arrival_status ? 'leave_time_string' : 'arrival_time_string';
      axiosApiInstance.post(`${ENDPOINTSV2('customer.tab.checkIn')}/${guestDetail._id}?${arrival_or_leave}=${time_string}`,
        { unArrivedGuestIds: unselectedList, booth_id: "Admin Check In" }
      )
        .then((res) => {
          setIsLoading(false)
          if (!guestDetail.arrival_status) {
            setGuestDetail((prevState) => ({ ...prevState, order: res.data.value.order }));
            history.push('/check-in/guest-redenvelop');
          } else {
            history.push('/check-in/guest-thankyou');
          }
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })

        ;
    }

  };

  const guestPack = guestDetail?.guests?.reduce((acc, curr) => {
    if (!curr?.arrival_status) {
      return acc = acc + 1
    }
  }, 0);

  return (
    <>
      <GuestContainer withVideo={withVideo}>
        <GuestContent>
          <Heading
            kind="subtitle"
            text={eventName}
          />
          <Card>
            <CardText>
              {checkedUnAssign.length && eventType.toLowerCase() === 'seated' ?
                'We haven’t assigned a table for you. Please go to admin desk.' :
                `WE HAVE ALLOCATED ${guestPack || 0} PAX FOR YOU, Please tick attending guest`}
            </CardText>

            {checkedUnAssign.length && eventType.toLowerCase() === 'seated' ?
              <></> :
              <ListContainer
                data={guestList}
                setData={setGuestList}
              />}
            {checkedUnAssign.length && eventType.toLowerCase() === 'seated' ?
              <Button
                kind="primary"
                type="button"
                text="Back to Home"
                onClick={() => history.push('/check-in/guest-welcome')}
              /> :
              <Button
                kind="primary"
                type="button"
                text="Next"
                isLoading={isLoading}
                disabled={selectedList.length < 1 || isLoading}
                onClick={checkIn}
              />

            }
          </Card>
        </GuestContent>
      </GuestContainer>
      <LogoContainer>
        <Icon src={LogoFooterSVG} />
      </LogoContainer>
    </>
  );
};

const { bool, func, string } = PropTypes;


GuestArrival.propTypes = {
  eventName: string.isRequired,
  setGuestDetail: func.isRequired,
};

export default GuestArrival;
