import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import OutsideClickHandler from 'react-outside-click-handler';

Modal.setAppElement('#root');

const ModalComponent = ({
  isOpen,
  onRequestClose,
  style,
  children,
}) => (
  <Modal
    isOpen={isOpen}
    style={{
      overlay: {
        backgroundColor: 'rgba(40,40,40,0.7)',
        zIndex: '9999',
        ...style.overlay,
      },
      content: {
        border: 0,
        width: '27.5rem',
        minHeight: 'fit-content',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        borderRadius: '0.625rem',
        ...style.content,
      },
    }}
  >
    <OutsideClickHandler
      onOutsideClick={onRequestClose}
    >
      {children}
    </OutsideClickHandler>
  </Modal>
);

const {
  bool,
  func,
  shape,
  element,
} = PropTypes;

ModalComponent.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  style: shape({}),
  children: element,
};

ModalComponent.defaultProps = {
  style: {},
  children: <></>,
};

export default ModalComponent;
