import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SVGInject from '@iconfu/svg-inject';

import {
  NavbarRelative,
  Navbar,
  NavbarContainer,
  NavbarMenu,
  NavbarMenuBody,
  NavbarMenuRow,
  NavbarMenuIcon,
  Icon,
  NavbarMenuOption,
  NavbarLogo,
} from 'components/navbar-admin/style';
import DashboardSVG from 'assets/icons/dashboard.svg';
import InvoicesSVG from 'assets/icons/invoices.svg';
import EventDetailsSVG from 'assets/icons/event-details.svg';
import EventcoWhiteSVG from 'assets/images/eventco-white.svg';

const MENU_OPTIONS = [
  {
    id: '',
    option: 'Dashboard',
    icon: DashboardSVG,
  },
  {
    id: '/event',
    option: 'Event List',
    icon: EventDetailsSVG,
  },
  {
    id: '/invoices',
    option: 'Invoices',
    icon: InvoicesSVG,
  },
  {
    id: '/operators',
    option: 'Operators',
    icon: EventDetailsSVG,
  },
];

const NavbarAdminComponent = ({ redirect }) => {
  const location = useLocation();

  useEffect(() => {
    SVGInject(document.querySelectorAll('img.injectable'));
  }, []);

  return (
    <NavbarRelative>
      <Navbar>
        <NavbarContainer>
          <NavbarLogo
            src={EventcoWhiteSVG}
          />
          <NavbarMenu>
            <NavbarMenuBody>
              {MENU_OPTIONS.map((option, index) => (
                <NavbarMenuRow
                  key={option.id}
                  active={index === 0
                    ? `/admin/dashboard${option.id}` === location.pathname
                    : location.pathname.includes(`/admin/dashboard${option.id}`)}
                  to={`/admin/dashboard${option.id}`}
                >
                  <NavbarMenuIcon>
                    <Icon
                      className="injectable"
                      src={option.icon}
                    />
                  </NavbarMenuIcon>
                  <NavbarMenuOption>{option.option}</NavbarMenuOption>
                </NavbarMenuRow>
              ))}
            </NavbarMenuBody>
          </NavbarMenu>
        </NavbarContainer>
      </Navbar>
    </NavbarRelative>
  );
};

export default NavbarAdminComponent;
