import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'components/table/button/style';

const ButtonComponent = ({ text, icon, onClick }) => (
  <Button
    text={text}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    <Icon src={icon} />
    {text}
  </Button>
);

const { string, func } = PropTypes;

ButtonComponent.propTypes = {
  text: string,
  icon: string,
  onClick: func,
};

ButtonComponent.defaultProps = {
  text: '',
  icon: '',
  onClick: () => {},
};

export default ButtonComponent;
