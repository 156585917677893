import styled from "styled-components";
import { Heading, Button } from "shared/styles";

export const Container = styled.div`
  background-color: ${(props) => props.theme.modal.background};
`;

export const Icon = styled.img``;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.modal.header.text};
  z-index: 9999;

  ${Icon} {
    margin: 1rem 1.75rem;
    margin-right: 0.75rem;

    &:last-child {
      margin-left: auto;
      margin-right: 1.8125rem;
      cursor: pointer;
    }
  }

  ${Heading.Heading} {
    text-align: left;
    font-family: "GT Walsheim Pro", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: 0.01875rem;
    margin: 1.4375rem 0;
  }
`;

export const Content = styled.div`
  margin: 6.5rem 2.5rem;
`;

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: ${(props) =>
    (props.cancelButton && "space-between") || "flex-end"};
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  padding: 0.9rem 1.6rem;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.modal.header.text};

  ${Button.Button} {
    padding: ${(props) =>
      (props.cancelButton && "0.4rem 1rem") || "0.5rem 3.46875rem"};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
