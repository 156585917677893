import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import InvaitationSendSVG from "assets/icons/invitation-send.svg";
import FoodInformationSVG from "assets/icons/food-information.svg";
import GuestInvitedSVG from "assets/icons/guest-invited.svg";
import RightArrowDashboard from "assets/icons/right-arrow-dashboard.svg";
import RightArrowDashboardBlack from "assets/icons/right-arrow-dashboard-black.svg";
import { Card, ButtonIcon, DashboardCard, PieChart } from "components";
import {
  GridDashboard,
  FlexColumnDashboard,
  SubTitle,
  IconCustom,
  Text,
} from "pages/dashboard/pre-dday/style";

const PreDDay = ({ eventTimeLine, openReport, data }) => {
  const history = useHistory();
  const redirect = () => history.push("/event/guest-request");

  const {
    availableTableCount,
    fullTableCount,
    guestRequestsCount,
    invitedGuestCount,
    notSentInvitationsCount,
    totalGuestCount,
    unConfirmedGuestCount,
    willAttendGuestCount,
    guestCheckedInCount,
    guestNotYetCheckedInCount,
  } = data;
  const percentChart =
    (guestCheckedInCount / (guestCheckedInCount + guestNotYetCheckedInCount)) *
    100;
  // const percentChart = 1 / (1 + 10)*100;
  const sort = {
    invitationHasntBeenSeen: "guest have not been sent",
    guestHaveBeenInvited: "guest have been invited",
    guestWillAttend: "guest will attend",
    unConfirmedGuest: "unconfirmed guest",
    fullTable: "full table",
    availableTable: "available table",
  };

  return (
    <>
      <GridDashboard gridTemplateColumn="1fr 1fr">
        <GridDashboard>
          <Card
            isDashboard
            title={guestRequestsCount}
            subTitle={
              <>
                New guest requests
                <IconCustom src={RightArrowDashboard} />
              </>
            }
            category="wedding"
            onClick={redirect}
          />
          <ButtonIcon
            icon={FoodInformationSVG}
            isDashboard
            subTitle={
              <Text>
                Guest Food Detail
                <IconCustom src={RightArrowDashboardBlack} />
              </Text>
            }
            onClick={() => history.push("/event/food-information")}
          />
        </GridDashboard>

        <GridDashboard>
          <ButtonIcon
            icon={InvaitationSendSVG}
            isDashboard
            text={notSentInvitationsCount}
            subTitle="Invitations have not been sent "
            onClick={() => {
              history.push(
                `/event/guest-list?type=${sort.invitationHasntBeenSeen}`
              );
            }}
          />
          <ButtonIcon
            icon={GuestInvitedSVG}
            isDashboard
            text={invitedGuestCount}
            subTitle="Guests have been invited"
            onClick={() => {
              history.push(
                `/event/guest-list?type=${sort.guestHaveBeenInvited}`
              );
            }}
          />
        </GridDashboard>
      </GridDashboard>
      <GridDashboard gridTemplateColumn="2fr 1fr" marginTop="1.875rem">
        <GridDashboard>
          <FlexColumnDashboard>
            <SubTitle>ATTENDANCE</SubTitle>
            <GridDashboard gridTemplateColumn="1fr 1fr" marginTop="0.75rem">
              <DashboardCard
                title={willAttendGuestCount}
                subTitle={`/ ${totalGuestCount}`}
                text="Guests will attend"
                onClick={() => {
                  history.push(
                    `/event/guest-list?type=${sort.guestWillAttend}`
                  );
                }}
              />
              <DashboardCard
                warning
                title={unConfirmedGuestCount}
                text="Unconfirmed guests"
                onClick={() => {
                  history.push(
                    `/event/guest-list?type=${sort.unConfirmedGuest}`
                  );
                }}
              />
            </GridDashboard>
          </FlexColumnDashboard>
          <FlexColumnDashboard>
            <SubTitle>TABLE SLOT</SubTitle>
            <GridDashboard gridTemplateColumn="1fr 1fr" marginTop="0.75rem">
              <DashboardCard
                title={availableTableCount}
                text="Available table"
                onClick={() => {
                  history.push(
                    `/event/table-management?type=${sort.availableTable}`
                  );
                }}
              />
              <DashboardCard
                warning
                title={fullTableCount}
                text="Full table"
                onClick={() => {
                  history.push(
                    `/event/table-management?type=${sort.fullTable}`
                  );
                }}
              />
            </GridDashboard>
          </FlexColumnDashboard>
        </GridDashboard>
        <GridDashboard>
          <FlexColumnDashboard>
            <SubTitle marginBottom="0.75rem">
              CHECK-IN REPORT
              {/* <ToolTip>
                <Icon src={InfoSVG} />
                <ToolTipText isLeft>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Earum amet asperiores doloribus sunt quam pariatur,
                  ea animi quas necessitatibus eveniet soluta,
                  perferendis quasi est in dolorum voluptate nemo exercitationem nesciunt!
                </ToolTipText>
              </ToolTip> */}
            </SubTitle>
            {eventTimeLine === "dday" ? (
              <Card
                noCursor
                openNew={openReport}
                isDashboardReport
                isDDay
                chart={
                  <PieChart
                    center
                    size="144"
                    width="40"
                    number={percentChart.toFixed(0)}
                    fontSize="16"
                    fontWeight="bold"
                    backgroundColor="#9B558C"
                    isGray
                  />
                }
                category="birthday"
              />
            ) : (
              <Card
                noCursor
                isDashboardReport
                title="Report will be available on the D-Day"
                category="birthday"
                isDashboardReportBackground
              />
            )}
          </FlexColumnDashboard>
        </GridDashboard>
      </GridDashboard>
    </>
  );
};

const { string, func, shape } = PropTypes;

PreDDay.propTypes = {
  openReport: func,
  data: shape({}).isRequired,
  eventTimeLine: string.isRequired,
};

PreDDay.defaultProps = {
  openReport: () => {},
};

export default PreDDay;
