import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Brand } from 'components/brand/style';
import EventCoSVG from 'assets/images/eventco.svg';

const BrandComponent = ({ navbar, className }) => {
  const history = useHistory();

  return (
    <Brand
      navbar={navbar}
      className={className}
      src={EventCoSVG}
      onClick={() => history.push('/')}
    />
  );
};

const { bool, string } = PropTypes;

BrandComponent.propTypes = {
  navbar: bool,
  className: string,
};

BrandComponent.defaultProps = {
  navbar: false,
  className: '',
};

export default BrandComponent;
