import styled from 'styled-components';
import { Heading } from 'shared/styles';

export const Icon = styled.img`
`;

export const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
`;

export const SourceVideo = styled.source`
`;
export const IconNext = styled.img`
  position: absolute;
  transform: scaleX(-1);
  top: 2rem;
  right: 2rem;
  &:hover {
    cursor: pointer;
  }
`;
export const GuestContainer = styled.div`
  position: absolute;
  background-color: ${(props) => (props.withVideo && 'rgba(0,0,0,0.5)') || 'transparent'};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items ${(props) => props.alignItems || 'stretch'};
  
  ${Heading.Heading} {
    &[kind="title"] {
      font-weight: bold;
      font-size: 3.25rem;
      line-height: 4.125rem;
      text-align: center;
      letter-spacing: 0.03125rem;
      width: 500px;
      color: #FFFFFF;
    }
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 3.9375rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
