import React, { useEffect, useState } from 'react';
import { Heading, Button } from 'shared';
import ENDPOINTS from 'helpers/endpoints';
import { axiosApiInstance } from 'helpers/authentication';
import { Brand } from 'components';
import { useParams, useHistory } from 'react-router-dom';
import VerifyEmailSVG from 'assets/images/verify-email.svg';
import { Loading } from 'pages';
import {
  Container,
  Section,
  Icon,
  HeadingContainer,
} from 'pages/form/style';

const VerifyEmail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      axiosApiInstance.get(`${ENDPOINTS('customer.config.typeformLink')}/${id}`)
        .then((res) => {
          if (res && res.data && 
            res.data.value && 
            res.data.value.url
            ) {
           window.open(res.data.value.url,'_self')
          }
        })
        .catch((err) => {
          if(err.response && err.response.data&& err.response.data.m)
          {
            console.log(err.response.data.m)
          }
          // history.push('/')
          // setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading
        ? <Loading />
        : (
          <>
            <Brand />
            <Container>
              <Section>
                {/* <Icon src={VerifyEmailSVG} /> */}
                <HeadingContainer>
                 
                </HeadingContainer>
              </Section>
            </Container>
          </>
        )}

    </>
  );
};

export default VerifyEmail;
