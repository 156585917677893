/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Button,
} from 'shared';
import {
  Modal,
  ComboboxWhite,
} from 'components';
import {
  Icon,
  ModalContainer,
  ModalClose,
  ModalHeaderWithoutBadge,
  ModalButtonContainer,
} from 'pages/admin/dashboard-admin/event-details/modal/change-pic/style';
import ExitSVG from 'assets/icons/exit.svg';
import ChangePICSVG from 'assets/images/change-pic.svg';

const EventApprove = ({
  isOpen,
  onRequestClose,
  operatorList,
  setOperatorSelected,
  editPIC,
  isSubmiting,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{ content: { overflow: 'visible' } }}
  >
    <>
      <ModalClose
        src={ExitSVG}
        onClick={onRequestClose}
      />
      <ModalContainer>
        <ModalHeaderWithoutBadge>
          <Icon src={ChangePICSVG} style={{height:'100px',width:'100px',marginRight:'auto',marginLeft:'auto'}}/>

          <Heading
            kind="subtitle"
            text="Choose PIC for this event"
            marginBottom="0.5rem"
          />
        </ModalHeaderWithoutBadge>
        <ComboboxWhite
          list={operatorList}
          label="PIC"
          onChange={(value) => { setOperatorSelected(value); }}
        />
        <ModalButtonContainer>
          <Button
            small
            kind="secondary"
            type="button"
            text="Back"
            onClick={onRequestClose}
          />
          <Button
            small
            kind="primary"
            type="button"
            text="Approve"
            onClick={editPIC}
            disabled={isSubmiting}
            isLoading={isSubmiting}
          />

        </ModalButtonContainer>
      </ModalContainer>

    </>
  </Modal>
);

const { bool, func } = PropTypes;

EventApprove.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
};

export default EventApprove;
